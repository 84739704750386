import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row
} from "reactstrap";
import { reduxForm } from "redux-form";
import { getColor } from "../../utils/colors";
import * as actions from "../../actions";
import { formatExpiryDate, getBrandLogo } from "../../utils/BillingUtils";
import CardInput from "./CardInput";

class PaymentDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: this.isCardDetailsEmpty(this.props.paymentSource)
    };
  }

  isCardDetailsEmpty(paymentSource) {
    if (paymentSource && paymentSource.Last4Digits != "") {
      return false;
    }
    return true;
  }

  renderCardInput() {
    return (
      <Card style={{ height: "200px", fontSize: "14px" }}>
        <CardBody>
          <CardTitle>Card Details</CardTitle>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
            <div className="example">
              <Elements>
                <CardInput
                  onCardAdditionSuccess={() =>
                    this.setState({ editMode: false })
                  }
                  onCardAdditionFailure={() =>
                    this.setState({ editMode: false })
                  }
                />
              </Elements>
            </div>
          </StripeProvider>
        </CardBody>
      </Card>
    );
  }

  renderStoredCardDetails() {
    const secondaryColor = getColor("secondary");
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    let cardBrand = this.props.paymentSource
      ? this.props.paymentSource.Brand
      : undefined;
    return (
      <Card style={{ height: "200px", fontSize: "14px" }}>
        <CardBody
          className="d-flex flex-column"
          style={{ paddingBottom: "0px" }}
        >
          <CardTitle style={headStyle}>Payment Method</CardTitle>
        </CardBody>
        <CardBody
          className="d-flex justify-content-between"
          style={{ padding: "0px" }}
        >
          <img
            src={getBrandLogo(cardBrand)}
            style={{
              height: 70,
              border: 1
            }}
            className="pl-3 pt-3"
          />
          <CardText
            style={{
              textAlign: "right",
              fontSize: "14px"
            }}
            className="mr-4 mt-4"
          >
            XXXX XXXX XXXX{" "}
            {this.props.paymentSource && this.props.paymentSource.Last4Digits}
            <br />
            <span style={{ fontSize: "14px", color: secondaryColor }}>
              Expires{" "}
              {formatExpiryDate({
                month: this.props.paymentSource.ExpiryMonth,
                year: this.props.paymentSource.ExpiryYear
              })}
            </span>
          </CardText>
        </CardBody>
        <CardBody className="d-flex justify-content-end align-items-end">
          <CardText>
            {this.props.paymentSource && (
              <Button
                outline
                className="btn btn-default"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button>
            )}
          </CardText>
        </CardBody>
      </Card>
    );
  }

  render() {
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    const { plan, onCancel, onPaymentDetailsSubmit, handleSubmit } = this.props;
    let timeCommitment, userCommitment;
    if (plan === "payperuser") {
      userCommitment = this.props.userCommitment;
      timeCommitment = this.props.timeCommitment;
    }

    return (
      <div className="mt-5 pl-5 pr-5">
        <p className="mt-2 mb-2" style={{ fontSize: "14px" }}>
          Review your package details and enter payment information:
        </p>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
            {this.state.editMode && this.renderCardInput()}
            {!this.state.editMode && this.renderStoredCardDetails()}
          </Col>
        </Row>

        <form onSubmit={handleSubmit(onPaymentDetailsSubmit)}>
          <div className="mt-5 mb-5">
            <Button outline className="btn btn-default mt-3" onClick={onCancel}>
              <MdChevronLeft />
              Prev
            </Button>
            <Button
              outline
              disabled={this.state.editMode}
              type="submit"
              className="btn btn-default mt-3"
            >
              Next
              <MdChevronRight />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps({ cardDetails }) {
  return {
    paymentSource: cardDetails
  };
}

export default reduxForm({
  form: "orderingWizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(
  connect(
    mapStateToProps,
    actions
  )(PaymentDetailsForm)
);
