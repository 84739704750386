import React, { Component } from "react";
import { connect } from "react-redux";
import { CardElement, injectStripe } from "react-stripe-elements";
import { Button } from "reactstrap";
import * as actions from "../../actions";
import StatusMsg from "./StatusMsg";

const CARD_INPUT_SUBMITTED = 1;
const CARD_INPUT_IN_PROGRESS = 2;
const CARD_INPUT_VALID = 3;
const CARD_INPUT_NOT_STARTED = 4;

class CardInput extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.onBack = this.onBack.bind(this);
    this.state = { paymentState: CARD_INPUT_NOT_STARTED };
  }

  async submit(ev) {
    const { validationMode } = this.props;
    this.setState({ paymentState: CARD_INPUT_IN_PROGRESS });
    let { token } = await this.props.stripe.createToken();
    if (validationMode) {
      this.setState({ paymentState: CARD_INPUT_SUBMITTED });
      this.props.onCardAdditionSuccess(token);
    } else {
    await this.props.addCard(token);

    this.setState({ paymentState: CARD_INPUT_SUBMITTED });
      if (!(this.props.cardDetails && this.props.cardDetails.errorMessage)) {
      this.props.onCardAdditionSuccess();
    }
  }
  }

  onBack() {
    this.props.onCardAdditionFailure();
  }

  onStripeElementChange = element => {
    if (element.complete) {
      this.setState({ paymentState: CARD_INPUT_VALID });
    } else {
      this.setState({ paymentState: CARD_INPUT_NOT_STARTED });
    }
  };

  renderPaymentButton() {
    if (this.state.paymentState === CARD_INPUT_IN_PROGRESS)
      return (
        <div className="text-right mt-4">
          <Button outline className="btn btn-default" disabled>
            Adding Card.Please wait..
          </Button>
        </div>
      );
    else if (this.state.paymentState === CARD_INPUT_NOT_STARTED) {
      return (
        <div className="text-right mt-4">
          <Button outline className="btn btn-default" onClick={this.onBack}>
            Cancel
          </Button>
          <Button
            outline
            disabled
            className="btn btn-default"
            onClick={this.submit}
          >
            Add Card
          </Button>
        </div>
      );
    } else if (this.state.paymentState === CARD_INPUT_VALID) {
      return (
        <div className="text-right mt-4">
          <Button outline className="btn btn-default" onClick={this.onBack}>
            Cancel
          </Button>
          <Button outline className="btn btn-default" onClick={this.submit}>
            Add Card
          </Button>
        </div>
      );
    } else if (
      this.state.paymentState === CARD_INPUT_SUBMITTED &&
      this.props.cardDetails &&
      this.props.cardDetails.errorMessage
    ) {
      const errorStyle = { color: "red" };
      return (
        <div className="mt-2">
          <StatusMsg
            message={this.props.cardDetails.errorMessage}
            statusStyle={errorStyle}
          />
          <div className="text-right mt-2">
            <Button outline className="btn btn-default" onClick={this.onBack}>
              Back
            </Button>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="checkout">
        <p style={{ fontSize: "14px" }}>Enter your credit card details:</p>
        <div className="mt-3 mb-5">
          <CardElement
            onChange={element => this.onStripeElementChange(element)}
          />
        </div>
        {this.renderPaymentButton()}
      </div>
    );
  }
}
function mapStateToProps({ cardDetails }) {
  return {
    cardDetails
  };
}

export default injectStripe(
  connect(
    mapStateToProps,
    actions
  )(CardInput)
);
