import React from "react";
import Select from "react-select";
const customStylesDD = {
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "gray" }, // border style on hover
    border: "1px solid #DFDFDF", // default border color
    boxShadow: "none", // no box-shadow
    fontSize: "14px",
    fontColor: "#808080",
    backgroundColor: "white", //'#F8F9FA',
    borderRadius: 0,
    outline: "none",
    boxShadow: "none"
  })
};
export const ReduxFormSelectField = props => {
  const { input, options, defaultValue } = props;
  return (
    <Select
      {...input}
      onChange={value => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      options={options}
      styles={customStylesDD}
    />
  );
};
