import React from 'react';

import { Navbar, Nav, NavItem } from 'reactstrap';

import SourceLink from '../SourceLink';

const inputStyle = {
  flex: 1,
  justifyContent: "center",
  alignItems: "center",
  color: 'black', fontFamily: "sans-serif",
  fontSize: "0.8rem",
  textAlign: "center", 'overflowY': 'auto',

};

const Footer = () => {

  return (
    <Navbar

      style={{

        height: "35px",
        position: "absolute",
        bottom: "20px",
        width: "100%",

      }}

      className="d-flex flex-row flex-wrap justify-content-center align-items-center"
    >
      <Nav navbar>
        <NavItem style={inputStyle} >
          &copy; 2017-{new Date().getFullYear()} <a style={inputStyle} href="https://igrant.io" target="_blank" rel="noopener noreferrer" >LCubed AB</a>, Sweden &nbsp;&nbsp;&nbsp;&nbsp;
          <a style={inputStyle} href="https://igrant.io/privacy.html#cookies" target="_blank" rel="noopener noreferrer" >Cookies Policy</a> &nbsp;| &nbsp;<a href="https://igrant.io/terms.html" target="_blank" style={inputStyle}>Terms of Service</a> &nbsp;| &nbsp;<a style={inputStyle} href="https://igrant.io/privacy.html#privacy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;