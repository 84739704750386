import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import CommitmentField from "./CommitmentField";

class CommittedUsageForm extends Component {
  constructor(props) {
    super(props);
    this.handleUserCommitmentChange = this.handleUserCommitmentChange.bind(
      this
    );
    this.handleTimeCommitmentChange = this.handleTimeCommitmentChange.bind(
      this
    );

    this.convertUserCommitmentSliderValuesToActual = this.convertUserCommitmentSliderValuesToActual.bind(
      this
    );
    this.convertUserCommitmentActualValuesToSlider = this.convertUserCommitmentActualValuesToSlider.bind(
      this
    );

    this.convertTimeCommitmentSliderValuesToActual = this.convertTimeCommitmentSliderValuesToActual.bind(
      this
    );
    this.convertTimeCommitmentActualValuesToSlider = this.convertTimeCommitmentActualValuesToSlider.bind(
      this
    );

    const { payPerUser } = props;
    if (payPerUser) {
      this.userCommitmentSliderValues = this.createSliderMarks(
        payPerUser.userCommitmentValues
      );
      this.timeDisplayValues = payPerUser.timeCommitmentDiscounts.map(
        timeOption => timeOption.displayOption
      );
      this.timeCommitmentSliderValues = this.createSliderMarks(
        this.timeDisplayValues
      );

      this.defaultReservationOption = 0; // Slider Position
      this.defaultUserCommitmentOption = 0; // Slider Position

      this.state = {
        userCommitment: this.defaultUserCommitmentOption,
        timeCommitment: this.defaultReservationOption,
        pricePerUser: payPerUser.basePrice
      };
    }
  }

  createSliderMarks(labelArray) {
    const sliderMarks = {};
    let sliderPosition = 0;
    for (const label of labelArray) {
      sliderMarks[sliderPosition] = label;
      sliderPosition++;
    }
    return sliderMarks;
  }

  handleUserCommitmentChange(value) {
    this.setState(
      {
        userCommitment: value
      },
      () => this.calculatePricePerUser()
    );
  }

  handleTimeCommitmentChange(value) {
    this.setState({ timeCommitment: value }, () =>
      this.calculatePricePerUser()
    );
  }

  calculatePricePerUser() {
    let pricePerUser = 0;
    let pricePerUserWithoutTimeCommitmentDiscounts =
      this.state.userCommitment > 0
        ? this.props.payPerUser.priceCurveParamA *
          Math.pow(
            this.props.payPerUser.userCommitmentValues[
              this.state.userCommitment
            ],
            this.props.payPerUser.priceCurveParamB
          )
        : this.props.payPerUser.basePrice;

    pricePerUser =
      pricePerUserWithoutTimeCommitmentDiscounts *
      (1 -
        this.props.payPerUser.timeCommitmentDiscounts[this.state.timeCommitment]
          .value /
          100);
    this.setState({
      pricePerUser
    });
  }

  convertUserCommitmentSliderValuesToActual(value) {
    return this.userCommitmentSliderValues[value];
  }

  convertUserCommitmentActualValuesToSlider(value) {
    return this.props.payPerUser.userCommitmentValues.indexOf(value);
  }

  convertTimeCommitmentSliderValuesToActual(value) {
    return this.timeCommitmentSliderValues[value];
  }

  convertTimeCommitmentActualValuesToSlider(value) {
    return this.timeDisplayValues.indexOf(value);
  }

  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  }

  renderFields() {
    const resultStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700,
      fontStyle: "italic"
    };
    return (
      <div>
        <div className="mt-4 mb-5">
          <Field
            component={CommitmentField}
            label="User Commitment"
            name="userCommitment"
            marks={this.userCommitmentSliderValues}
            onChange={this.handleUserCommitmentChange}
            min={0}
            defaultValue={this.props.payPerUser.userCommitmentValues[0]}
            max={this.props.payPerUser.userCommitmentValues.length - 1}
            value={this.state.userCommitment}
            normalize={this.convertUserCommitmentSliderValuesToActual}
            format={this.convertUserCommitmentActualValuesToSlider}
          />
        </div>
        <div className="mt-5 mb-5">
          <Field
            component={CommitmentField}
            label="Time Commitment"
            name="timeCommitment"
            marks={this.timeCommitmentSliderValues}
            onChange={this.handleTimeCommitmentChange}
            min={0}
            defaultValue={this.timeDisplayValues[0]}
            max={this.props.payPerUser.timeCommitmentDiscounts.length - 1}
            value={this.state.timeCommitment}
            normalize={this.convertTimeCommitmentSliderValuesToActual}
            format={this.convertTimeCommitmentActualValuesToSlider}
          />
        </div>

        <div className="mt-5 ">
          <p style={{ fontSize: "14px" }}>
            Based on your selection, your average cost per user is{" "}
            <span style={resultStyle}>
              SEK {this.formatMoney(this.state.pricePerUser, 3)}
            </span>
          </p>
        </div>
      </div>
    );
  }

  render() {
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };

    const { handleSubmit, payPerUser, onCommitmentSubmit } = this.props;
    if (!payPerUser) {
      return null;
    }

    return (
      <div className="mt-5 pl-5 pr-5">
        <p className="mt-2 mb-2" style={{ fontSize: "14px" }}>
          Choose your commitment for available discount levels:
        </p>
        <form onSubmit={handleSubmit(onCommitmentSubmit)}>
          {this.renderFields()}

          <div className="mt-5 mb-5 pt-2">
            <Button
              outline
              tag={Link}
              to="/billing"
              className="btn btn-default"
            >
              <MdChevronLeft />
              prev
            </Button>
            <Button outline type="submit" className="ml-lg-2 btn btn-default">
              Next
              <MdChevronRight />
            </Button>
          </div>
        </form>{" "}
      </div>
    );
  }
}

function mapStateToProps({ pricingInfo }) {
  return { payPerUser: pricingInfo && pricingInfo.payPerUser };
}
export default reduxForm({
  form: "orderingWizard",
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount,\
  initialValues: { timeCommitment: "Monthly", userCommitment: 0 }
})(
  connect(
    mapStateToProps,
    null
  )(CommittedUsageForm)
);
