import MasterCardImage from "../assets/img/billing/cards/mastercard.png";
import VisaImage from "../assets/img/billing/cards/visa.png";

export const getBrandLogo = brand => {
  let defaultBrandLogo = "";
  let brandLogo = defaultBrandLogo; // TODO Put a default image
  if (brand) {
    switch (brand) {
      case "American Express":
        brandLogo = brandLogo + "amex.png";
        break;
      case "Discover":
        brandLogo = brandLogo + "discover.png";
        break;
      case "Diners Club":
        brandLogo = brandLogo + "dinersclub.png";
        break;
      case "JCB":
        brandLogo = brandLogo + "jcb.png";
        break;
      case "MasterCard":
        brandLogo = MasterCardImage;
        break;
      case "UnionPay":
        brandLogo = brandLogo + "unionpay.png";
        break;
      case "Visa":
        brandLogo = VisaImage;
        break;
      default:
        brandLogo = defaultBrandLogo;
        break;
    }
  }
  return brandLogo;
};

export const isEmptyObject = (o, ignoreField) => {
  return Object.keys(o).every(function(x) {
    return o[x] === "" || o[x] == null || x === ignoreField;
  });
};

export const formatExpiryDate = ({ month, year }) => {
  let expiryDate = "";
  if (month && year) {
    if (month < 10) {
      expiryDate = "0";
    }
    expiryDate = expiryDate + month + "/" + year;
  }
  return expiryDate;
};

export const formatMoney = (
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) => {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
};
