import React from "react";
import SliderWidget from "../Widget/SliderWidget";
const ReduxFormComponentSlider = props => {
  return (
    <SliderWidget
      value={props.input.value}
      onSliderChange={props.input.onChange}
      {...props}
    />
  );
};
export default ReduxFormComponentSlider;
