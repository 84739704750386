import React, { useState, useEffect } from "react";
import {
  Modal,
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody
} from "reactstrap";
import {
  AiOutlineDelete,
  AiOutlineEye,
  AiOutlineCloudDownload
} from "react-icons-latest/ai";
import {
  VscGist
} from "react-icons-latest/vsc";
import { IoCheckmark, IoClose } from "react-icons/lib/io";
import { useParams } from "react-router-dom";
import { Tooltip } from '@material-ui/core';

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from "react-bootstrap-table-next";
import LoadingOverlay from "react-loading-overlay";

import {
  showLoader,
  hideLoader
} from "../actions/loaderActions";
import {
  fetchVerifiedPresentationExchangeRecordsInCloudagent,
  deletePresentationExchangeRecordInCloudagent,
  queryDataAgreementById
} from "../services";
import { isCloudAgentDeployed, fetchCloudAgentDeploymentInfo } from "../utils/localStorageUtils";
import {
  TableLoader
} from "../components/Loader";
import { connect } from 'react-redux';

import Page from "../components/Page";
import GeneralDeleteModalV2 from "../components/GeneralDeleteModalV2";
import ExchangeInfoModal from "../components/ExchangeInfoModal";
import ViewVerifiedDataAndDataAgreementModal from "../components/ViewVerifiedDataAndDataAgreementModal";
import Loader from "../components/Loader";

// FIXME: This is a temporary fix for https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

const VerificationHistory = (props) => {

  const [adminEndpoint, setAdminEndpoint] = useState("");

  const [verificationRecords, setVerificationRecords] = useState([]);

  const [isTableLoading, setIsTableLoading] = useState(false);

  const [paginationTotalCount, setPaginationTotalCount] = useState(0);

  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);

  const [paginationPageSize, setPaginationPageSize] = useState(10);

  const [toggleExhangeInfo, SetToggleExchangeInfo] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showViewVerifiedDataAndDataAgreementModal, setShowViewVerifiedDataAndDataAgreementModal] = useState(false);

  const [ariesCloudAgentEnabled, setAriesCloudAgentEnabled] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);

  const { dataAgreementId } = useParams();

  const [currentDataAgreement, setCurrentDataAgreement] = useState(null);

  const fetchDataAgreement = async () => {

    queryDataAgreementById(dataAgreementId).then(response => {

      setCurrentDataAgreement(response.data.results[0]);

    }).catch(error => {

      // Handle error
      console.log(error);

    });

  }


  const fetchVerificationRecords = async (page = paginationCurrentPage, page_size = paginationPageSize) => {

    // Show table loader.
    setIsTableLoading(true);


    fetchVerifiedPresentationExchangeRecordsInCloudagent(dataAgreementId, page, page_size).then((res) => {

      const presentationExchangeRecords = res.data.results;
      const paginationData = res.data.pagination;

      // Set the total count of the pagination.
      setPaginationTotalCount(paginationData.totalCount);

      const data = presentationExchangeRecords.map((presentationExchangeRecord) => {

        presentationExchangeRecord.updated_at = new Date(presentationExchangeRecord.updated_at * 1000).toLocaleString("en-GB", {
          dateStyle: "long",
          timeStyle: "medium",
          hour12: true,
        });

        return presentationExchangeRecord;

      });

      // Populate table with verification records.
      setVerificationRecords(data);

      // Hide the table loader..
      setIsTableLoading(false);

    }).catch((err) => {

      // Handle error.
      console.log(err);

      setVerificationRecords([]);

      // Hide the table loader..
      setIsTableLoading(false);

    });

  };

  const deleteVerifiationHistory = async () => {

    deletePresentationExchangeRecordInCloudagent(selectedRow.presentation_exchange_id).then((res) => {

      // Fetch verification records and reload the verification history table.
      fetchVerificationRecords();

      // Close the delete modal.
      triggerToggleDeleteModal();

    }).catch((err) => {

      // Handle error.
      console.log(err);

      // Close the delete modal.
      triggerToggleDeleteModal();

    });


  }

  /**
   * Handle page change for table.
   * 
   * @param {*} page 
   * @param {*} pageSize 
   */
  const handlePageChange = (page, pageSize) => {

    setPaginationCurrentPage(page);

    setPaginationPageSize(pageSize);

    fetchVerificationRecords(page, pageSize);

  }

  /**
   * Triggered when table content changes.
   * 
   * @param {*} type 
   * @param {*} newState 
   */
  const onTableChange = (type, { page, sizePerPage }) => {
    // handle any data change here

    switch (type) {

      case 'pagination':
        handlePageChange(page, sizePerPage);
        break

    }


  }

  /**
   * Download signed data agreement json file.
   * 
   * @param {*} dataAgreement 
   */
  const downloadSignedDataAgreement = (dataAgreement) => {

    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dataAgreement, null, 4));

    const link = document.createElement("a");

    link.setAttribute("href", dataStr);

    link.setAttribute("download", "da.json");

    link.click();

  }

  /**
   * Run once when component is mounted.
   */
  useEffect(() => {

    // Check if aries cloudagent is deployed.
    if (isCloudAgentDeployed()) {

      // Cloud agent deployment info.
      const cloudAgentDeploymentInfo = fetchCloudAgentDeploymentInfo();

      // Update state to indicate cloud agent is deployed.
      setAriesCloudAgentEnabled(true);

      // Update state with cloud agent admin endpoint.
      setAdminEndpoint(cloudAgentDeploymentInfo.AdminEndpoint);

      // Fetch current data agreement records.
      fetchDataAgreement();

      // Fetch verification records and populate table.
      fetchVerificationRecords();

    } else {

      // Update state to indicate cloud agent is not deployed.
      setAriesCloudAgentEnabled(false);

    }

  }, []);

  const colDispStyle = {
    fontSize: "14px",
    cursor: "pointer",
    padding: ".35rem",
    borderWidth: "1px solid !important",
  };

  const headerDispStyle = {
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "0px 1px 3px 1px",
    borderColor: "#dee2e6",
  };

  const buttonDispStyle = {
    width: "3%",
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    borderWidth: "0px",
    borderBottomWidth: "3px",
  };

  const colDispStyleButton = {
    fontSize: "14px",
    cursor: "pointer",
    borderWidth: "0px",
    borderBottomWidth: "1px",
    padding: ".35rem",
  };

  const triggerToggleExchangeInfo = () => {

    SetToggleExchangeInfo(!toggleExhangeInfo);

  };

  const triggerToggleDeleteModal = () => setShowDeleteModal(!showDeleteModal)

  /**
   * Toggle view verified data and data agreement modal.
   */
  const toggleViewVerifiedDataAndDataAgreementModal = () => {

    setShowViewVerifiedDataAndDataAgreementModal(!showViewVerifiedDataAndDataAgreementModal);

  }

  const columnVerificationRecord = [
    { dataField: 'id', text: 'ID', hidden: true },
    {
      dataField: "updated_at",
      text: "Verification Date",
      headerStyle: { ...headerDispStyle, width: "fit-content" },
      style: colDispStyle
    },
    {
      dataField: "connection_id",
      text: "Connection ID",
      headerStyle: {
        ...headerDispStyle,
        borderRightWidth: "0px",
        width: "fit-content",
      },
      style: { ...colDispStyle, borderRightWidth: "0px" },
    },
    {
      dataField: "presentation_exchange_id",
      text: "Presentation Exchange ID",
      headerStyle: {
        ...headerDispStyle,
        borderRightWidth: "0px",
        width: "fit-content",
      },
      style: { ...colDispStyle, borderRightWidth: "0px" },
    },
    {
      dataField: "verified",
      text: "Status",
      headerStyle: { ...buttonDispStyle, textAlign: "center" },
      style: { ...colDispStyleButton, textAlign: "center" },
      formatter: (cellContent, row) =>
        cellContent === "true" ? (
          <Tooltip title='Verification successful' placement="top">
            <IoCheckmark color="green" size="20px" />
          </Tooltip>
        ) : (
          <Tooltip title='Verification failed'>
            <IoClose color="red" size="20px" />
          </Tooltip>
        )
    },
    {
      dataField: "view_data_and_data_agreement",
      text: "",
      headerStyle: buttonDispStyle,
      style: { ...colDispStyleButton, textAlign: "center" },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title='View verified data and data agreement' placement="top">
            <AiOutlineEye
              size="20px"
              onClick={() => {

                setSelectedRow(row);

                toggleViewVerifiedDataAndDataAgreementModal();

              }}
            />
          </Tooltip>
        );
      },
    },
    {
      dataField: "download_data_agreement",
      text: "",
      headerStyle: buttonDispStyle,
      style: { ...colDispStyleButton, textAlign: "center" },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title='Download signed data agreement' placement="top">
            <AiOutlineCloudDownload
              size="20px"
              onClick={() => {
                downloadSignedDataAgreement(row.data_agreement);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      dataField: "view",
      text: "",
      headerStyle: buttonDispStyle,
      style: { ...colDispStyleButton, textAlign: "center" },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title='View raw presentation exchange record' placement="top">
            <VscGist
              size="20px"
              onClick={() => {
                setSelectedRow(row);
                triggerToggleExchangeInfo();
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      dataField: 'delete',
      text: '',
      headerStyle: buttonDispStyle, style: { ...colDispStyleButton, textAlign: "center" },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title='Delete presentation exchange record' placement="top">
            <AiOutlineDelete size='20px'
              onClick={() => {
                setSelectedRow(row)
                triggerToggleDeleteModal()
              }}
            />
          </Tooltip>
        );
      },
    }
  ];

  return (
    <Page title="" breadcrumbs={[{ name: "Self-Sovereign Identity / Verification History", active: true, },]} className="TablePage">

      <Row>

        <Col>

          <Card>

            <CardTitle>

              <div

                className="card-table-heading"

                style={{

                  paddingTop: "35px",

                  display: "flex",

                  justifyContent: "space-between",

                  alignItems: "center",

                  flexWrap: "wrap"

                }}
              >

                <div

                  id="credentials-table-header-left-container"

                  style={{

                    display: "flex",

                    justifyContent: "space-between",

                    alignItems: "center"

                  }}

                >

                  {
                    currentDataAgreement ?
                      (
                        <span>Verification History - <span style={{ fontWeight: "normal" }}>{currentDataAgreement.data_agreement.purpose}</span></span>
                      ) :
                      (
                        <span>Verification History</span>
                      )
                  }


                </div>
              </div>

            </CardTitle>

            <CardSubtitle

              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

              }}

            >

              <div className="card-table-subheading">
                View all data received and their verification status. This will only be available if data verifications are configured.
              </div>

            </CardSubtitle>

            <CardBody>


              <BootstrapTable

                remote={{ pagination: true, filter: false, sort: false }}

                pagination={
                  paginationFactory(
                    {

                      page: paginationCurrentPage,

                      sizePerPage: paginationPageSize,

                      totalSize: paginationTotalCount,

                      nextPageText: ">",

                      prePageText: "<",

                      alwaysShowAllBtns: false,

                      hideSizePerPage: true,

                    }
                  )
                }

                loading={isTableLoading}

                overlay={
                  overlayFactory(
                    {
                      spinner: <TableLoader />,
                      styles: {
                        overlay: (base) => (
                          {
                            ...base,
                            background: 'rgba(198, 198, 198, 0.07)'
                          }
                        ),
                      }
                    }
                  )
                }

                onTableChange={onTableChange}

                noDataIndication={
                  () => {
                    return !isTableLoading ? <div>No data to display</div> : <div style={{ height: "300px" }}></div>
                  }
                }

                bootstrap4={true}

                data={verificationRecords}

                columns={columnVerificationRecord}

                id="btPurpose"

                keyField="thread_id"

                hover={true}

                striped={false}

              />

            </CardBody>

          </Card>

          {
            showViewVerifiedDataAndDataAgreementModal &&
            <ViewVerifiedDataAndDataAgreementModal

              presentationExchangeRecord={selectedRow}
              toggleState={showViewVerifiedDataAndDataAgreementModal}
              toggle={toggleViewVerifiedDataAndDataAgreementModal}
              modalHeaderTitleText={currentDataAgreement ? currentDataAgreement.data_agreement.purpose : ""}
              modelHeaderDescriptionText={selectedRow.presentation_exchange_id}


            />
          }


          <Modal backdrop="static" isOpen={toggleExhangeInfo} toggle={triggerToggleExchangeInfo}>

            <ExchangeInfoModal

              data={selectedRow}

              toggle={triggerToggleExchangeInfo}

              modelHeaderDescriptionText={selectedRow.presentation_exchange_id}

            />

          </Modal>

          <GeneralDeleteModalV2

            loaderTag={"GeneralDeleteModalV2ForVerificationHistory"}

            requireConfirmText={true}

            confirmText="DELETE"

            modalHeaderTitleText={<div className="delete-modal-header-title-div"> Delete Presentation Exchange Record:  <span>{currentDataAgreement && currentDataAgreement.data_agreement.purpose}</span></div>}

            modelHeaderDescriptionText={selectedRow && selectedRow.presentation_exchange_id}

            modalDescriptionText={<p> You are about to delete an existing presentation exchange record. Please type <span style={{ fontWeight: "bold" }}>DELETE</span> to confirm and click DELETE. This action is not reversible.</p>}

            deleteCallBack={() => {

              const { showLoader, hideLoader } = props;

              showLoader("GeneralDeleteModalV2ForVerificationHistory");

              deletePresentationExchangeRecordInCloudagent(selectedRow.presentation_exchange_id).then((res) => {

                // Fetch verification records and reload the verification history table.
                fetchVerificationRecords();

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForVerificationHistory");

                // Close the delete modal.
                triggerToggleDeleteModal();

              }).catch((err) => {

                // Handle error.
                console.log(err);

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForVerificationHistory");

                // Close the delete modal.
                triggerToggleDeleteModal();

              });

            }}

            toggleState={showDeleteModal}

            toggle={triggerToggleDeleteModal}

          />


        </Col>

      </Row>

    </Page>

  );

};


const mapStateToProps = (state) => {
  return {};
}

export default connect(
  mapStateToProps,
  {
    showLoader,
    hideLoader,
  }
)(VerificationHistory);