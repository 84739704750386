import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Input } from "reactstrap";

import Loader from "../components/Loader";


const GeneralDeleteModalV2 = ({ requireConfirmText, confirmText, toggleState, toggle, modalHeaderTitleText, modelHeaderDescriptionText, modalDescriptionText, deleteCallBack, loaderTag }) => {

  const [deleteConfirmationText, setDeleteConfirmationText] = useState("");

  const [isOk, setIsOk] = useState(false)

  // Delete modal doesn't require a confirmation text.
  if (!requireConfirmText) {

    setIsOk(true);

  }

  const handleCancelConfirmationText = (event) => {

    setDeleteConfirmationText(event.target.value);

    event.target.value === confirmText ? setIsOk(true) : setIsOk(false);

  };

  const resetState = () => {

    // Reset delete confirmation text.
    setDeleteConfirmationText("");

    // Reset form DELETE button enable/disable state.
    setIsOk(false);

  }

  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee"
  };

  const modalHeaderDataAgreementIdStyle = {
    fontStyle: "italic",
    color: "gray",
  };

  const modalHeaderTitleStyle = {
    marginRight: "auto",
    marginBlock: " auto",
  };

  return (

    <Modal onClosed={() => { resetState(); }} backdrop="static" unmountOnClose={true} isOpen={toggleState} toggle={() => { toggle(); }} >

      <Loader loaderTag={loaderTag} />


      <div className="modal-header" style={headerStyle}>


        <div style={{ display: "flex", justifyContent: "space-between", flexBasis: "100%" }}>


          <h1 style={modalHeaderTitleStyle}>{modalHeaderTitleText}</h1>


          <button type="button" className="close" aria-label="Close" onClick={() => { toggle(); }}>


            <span aria-hidden="true">×</span>


          </button>


        </div>


        {modelHeaderDescriptionText && <p style={modalHeaderDataAgreementIdStyle}>{modelHeaderDescriptionText}</p>}


      </div>

      <ModalBody>


        {typeof (modalDescriptionText) === "function" ? modalDescriptionText() : modalDescriptionText}


        {requireConfirmText && <Input className="mt-1" type="text" id="deleteConfirmationText" autoComplete="off" value={deleteConfirmationText} onChange={handleCancelConfirmationText} />}


      </ModalBody>


      <ModalFooter>


        <button className="btn btn-default" style={{ cursor: !isOk ? "not-allowed" : "pointer" }} size="ig" disabled={!isOk} onClick={() => { deleteCallBack(); }} >DELETE</button>


        <button size="ig" className="btn btn-default" onClick={() => { toggle(); }}>CANCEL</button>


      </ModalFooter>


    </Modal>


  );

};


export default GeneralDeleteModalV2;
