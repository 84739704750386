import React, { useState, useEffect } from "react";
import { store } from "../services/localStorageStore";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { checkTokenExpiry } from "../actions";
import { connect } from "react-redux";

function UseBackgroundService(props) {
  const history = useHistory();
  const [backgroundService, setBackgroundService] = useState(null);

  useEffect(() => {
    let bgService = "";
    if (store.getData("data")) {
      bgService = setInterval(async () => {
        const token = store.getData("data").Token.access_token;
        const decoded = jwt_decode(token);
        if (Date.now() >= decoded.exp * 1000 + 2000) {
          const result = await checkTokenExpiry();
          if(!result) {
            history.push("/login");
          }
        }
      }, 2000);

      setBackgroundService(bgService);
    }

    // make sure you clean up when you no longer need it,
    // for example when component unmounts
    function cleanup() {
      clearInterval(bgService);
    }

    return cleanup;

    // since this `hook` relies on some value from context
    // make sure you include this in your dependency array
    // so that a new background service can be created,
    // and old one be destroyed, when state from context changes
  }, []);

  // optionally, return `backgroundService`
  return <div />;
}

export default UseBackgroundService;
