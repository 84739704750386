import Page from "../components/Page";
import Steps, { Step } from "rc-steps";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { MdDone, MdErrorOutline } from "react-icons/lib/md";
import { Col, Row } from "reactstrap";
import AdminDetailsForm from "../components/onboarding/AdminDetailsForm";
import OnboardingBillingAddressForm from "../components/onboarding/OnboardingBillingAddressForm";
import OnboardingPaymentDetailsForm from "../components/onboarding/OnboardingPaymentDetailsForm";
import OnboardingServiceAgreementForm from "../components/onboarding/OnboardingServiceAgreementForm";
import OrgDetailsForm from "../components/onboarding/OrgDetailsForm";
import OtpForm from "../components/onboarding/OtpForm";
import PackageSelectionForm from "../components/onboarding/PackageSelectionForm";

const icons = {
  finish: <MdDone />,
  error: <MdErrorOutline />
};

class OnboardingWizardPage extends React.Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.firstPage = this.firstPage.bind(this);
    //this.onWizardComplete = this.onWizardComplete.bind(this);
    this.state = {
      currentStep: 1
    };
  }

  componentDidMount() {
    this.props.fetchPricingInfo();
  }

  nextPage() {
    this.setState({ currentStep: this.state.currentStep + 1, hasError: false });
  }

  previousPage() {
    this.setState({ currentStep: this.state.currentStep - 1, hasError: false });
  }
  firstPage() {
    this.setState({ currentStep: 1, hasError: false });
  }

  renderOnBoardingSteps() {
    return (
      <div className="mt-4 ml-5">
        <Steps
          direction="vertical"
          initial={0}
          current={this.state.currentStep - 1}
          icons={icons}
        >
          <Step title="Org Admin Information" description={""} />
          <Step title="Mobile Verification" description={""} />
          <Step title="Organisation Information" description={""} />
          {/* <Step title="Setup Password" description={""} />TODO - CoverImage, Logo, DataModel*/}
          <Step title="Package Selection" description={""} />
          <Step title="Payment Information" description={""} />
          <Step title="Billing Address" description={""} />
          <Step title="Service Agreement" description={""} />
        </Steps>
      </div>
    );
  }

  renderOnboardingForm() {
    const { currentStep } = this.state;
    return (
      <div>
        {currentStep === 1 && (
          <AdminDetailsForm onAdminDetailsSubmit={this.nextPage} />
        )}
        {currentStep === 2 && (
          <OtpForm onOtpSubmit={this.nextPage} onCancel={this.previousPage} />
        )}
        {currentStep === 3 && (
          <OrgDetailsForm
            onOrgDetailsSubmit={this.nextPage}
            onCancel={this.firstPage}
          />
        )}
        {currentStep === 4 && (
          <PackageSelectionForm
            onPackageSelectionSubmit={this.nextPage}
            onCancel={this.previousPage}
          />
        )}
        {currentStep === 5 && (
          <OnboardingPaymentDetailsForm
            onPaymentDetailsSubmit={this.nextPage}
            onCancel={this.previousPage}
          />
        )}
        {currentStep === 6 && (
          <OnboardingBillingAddressForm
            onAddressSubmit={this.nextPage}
            onCancel={this.previousPage}
          />
        )}
        {currentStep === 7 && (
          <OnboardingServiceAgreementForm onCancel={this.previousPage} />
        )}
      </div>
    );
  }

  render() {
    return (
      <Page className="SignUpPage" title="">
        <Row
          style={{
           // minHeight: "100vh",
            // justifyContent: "center",
            paddingTop: "10px"
          }}
        >
          <Col md={3} lg={3}>
            {this.renderOnBoardingSteps()}
          </Col>
          <Col md={8} lg={8}>
            {this.renderOnboardingForm()}
          </Col>
        </Row>
      </Page>
    );
  }
}

export default connect(
  null,
  actions
)(OnboardingWizardPage);
