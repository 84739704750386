import React from 'react';
import Select from 'react-select';
import {
  Row,
  Col,
  ButtonGroup ,
  CardText,
  CardTitle,
} from 'reactstrap';
import axios from 'axios';
import Page from '../components/Page';
import jsonData from '../setting/settings.json';
import { store } from '../services/localStorageStore'
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import {GetOrganizationDetails,} from '../components/Sources';
import { BootstrapTable, TableHeaderColumn, } from 'react-bootstrap-table';
const limittext="?limit=1024";

const api = JSON.parse(JSON.stringify(jsonData)).api ;
let subLogList=[];

const btnSz={height:'2.4rem',width:'12rem',padding:'0rem',fontSize:'12px',margin:'0px', position:'relative',top:'-6px', };

const customStylesDD = {
  control: (base, state) => ({
    ...base,
      '&:hover': { borderColor: 'gray' }, // border style on hover
       border: '1px solid #DFDFDF', // default border color
       boxShadow: 'none', // no box-shadow
       fontSize:'14px', 
       fontColor:'#808080',
       top:'-6px',
       padding:0,
       margin:0,
       backgroundColor:'white',//'#F8F9FA',
       borderRadius:0,
       outline: 'none',
})
};

function getLogList()
{

    if( store.getData('data') != null && store.getData('orgs') != null ) 
    {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
       //when no selection is done then the api is /v1/organizations/id/users
         let url = api+'v1/organizations/'+ store.getData('orgs').ID  +'/logs'+ limittext ;

         axios.get(url ,axiosConfig)
         .then((response) => {    
             subLogList =  response.data.Logs ;
         })
         .catch((err) => { 
             subLogList = [];
         }) ; 
    }
}
class LogPage extends React.Component {
  
   constructor() {
      super();
      this.updateLogs = this.updateLogs.bind(this);
      this.handleChanged = this.handleChanged.bind(this);
      this.getLogList = this.getLogList.bind(this);
      
   }

   state = {
      selectedOption:'',
      logDetails:subLogList,
      logCategoriesOptions: []
   };

   updateLogs=()=> {
    this.setState({selectedLog:null});
    this.refs.tbLogPage.handleFilterData({ TypeStr:''  });
  }

   handleChanged = (selectedOption) => {
    this.setState({ selectedLog:selectedOption });
    var sel= selectedOption.map(selectedOptions =>   selectedOptions.label );
     if(sel.length===0)
     {
      this.refs.tbLogPage.handleFilterData({ TypeStr:''  });
    }
    else
    {
      this.refs.tbLogPage.handleFilterData({
        TypeStr: { type: 'ArrayFilter', value: sel  } 
       });
    }
  }
  
  renderPaginationPanel = (props) => {
    return (
     <Row className="w-100 h-100 mt-1 m-0 p-0">
       <Col lg={6} md={6} sm={6} xs={12} style={{ top: 'auto', bottom: '100%'}}  >{ props.components.sizePerPageDropdown }</Col>
       <Col lg={6} md={6} sm={6} xs={12}  className="d-inline-flex flex-row  justify-content-end"> { props.components.pageList } </Col>
      </Row>
    );
  }

  getLogList=()=>
   {
   
       if( store.getData('data') != null && store.getData('orgs') != null ) 
       {
         var axiosConfig = {
           headers: {
               'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
               }
           }; 
          //when no selection is done then the api is /v1/organizations/id/users
            let url = api+'v1/organizations/'+ store.getData('orgs').ID  +'/logs'+ limittext ;
  
            axios.get(url ,axiosConfig)
            .then((response) => {    
                  this.setState({ logDetails : response.data.Logs });
            })
            .catch((err) => { 
               this.setState({ logDetails : [] });
            }) ; 
            url = `${api}v1/organizations/log/category`;
            axios.get(url, axiosConfig)
            .then(response => {
              const logOptions = []
              response.data.forEach( type => {
                logOptions.push({label: type.Label, value: type.Value})
              });
              this.setState({logCategoriesOptions: logOptions})})
            .catch(err => this.setState({logCategoriesOptions: []}))
       }
   }

componentDidMount() {
  GetOrganizationDetails();
  getLogList();
  this.getLogList();
}

render() {
 
 const options = {
  fontSize: '14px',
  exportCSVText: 'Download',
  className:'float-left',
  btnContextual:'btn-default',
  size:"sm",
  hideSizePerPage: false, //hide the dropdown for sizePerPage
  alwaysShowAllBtns: true, // Always show next and previous button
  withFirstAndLast: false, // Hide the going to First and Last page button
  paginationPanel: this.renderPaginationPanel,
  prePage: 'Prev', // Previous page button text
  nextPage: 'Next', // Next page button text
  firstPage: 'First', // First page button text
  lastPage: 'Last', // Last page button text
};

  return (
    <Page title="" breadcrumbs={[{ name: 'Account / Logs', active: true }]}>
      <Row>
        <Col >
             <CardTitle>
                View Logs
             </CardTitle>
             <CardText>
                Provides all logs, can also be filtered against various log categories. 
             </CardText> 
             <Row key="row1"  >
                <Row key="row2" className="w-100 h-100 mt-0 m-0 p-0">
                  <Col lg={8} md={6} sm={12} xs={12}   >
                    <Select className="mt-3 ml-0" isMulti={true}  placeholder="Filter categories"  options={this.state.logCategoriesOptions} value={this.state.selectedLog}  onChange={this.handleChanged} styles={customStylesDD} /> 
                  </Col>
                  <Col lg={4} md={6} sm={12} xs={12} className="d-inline-flex flex-column justify-content-center  ">
                    <ButtonGroup>
                      <button  className="btn btn-default w-50" style={btnSz} onClick={this.updateLogs}>View All</button> 
                    </ButtonGroup>
                  </Col>
                </Row>
              </Row>
            <BootstrapTable key="logTable" tag="logTable" ref='tbLogPage'  data={this.state.logDetails} striped={false} hover={true} earch={false} options={ options }  pagination={true} >
              <TableHeaderColumn key="lg1" className="table-secondary" isKey hidden searchable={ false } dataField='id' width='50' >No</TableHeaderColumn>
              <TableHeaderColumn key="lg2" className="table-secondary" dataField='Log' width='50%' editable={ false } dataSort={true}>Action</TableHeaderColumn>
              <TableHeaderColumn key="lg3" className="table-secondary" dataField='TypeStr' width='20%'  editable={ false } ref='catCol' dataSort={true} >Category</TableHeaderColumn>
              <TableHeaderColumn key="lg6" className="table-secondary" dataField='TimeStamp' width='30%' editable={ false } dataSort={true}>Timestamp</TableHeaderColumn>
            </BootstrapTable>
        </Col>
    </Row>
  </Page>
  );
 };
}
export default LogPage;
