import Page from "../components/Page";
import React from "react";
import {
  Col,
  Row,
  CardTitle,
  Card,
  Modal,
  CardBody,
  CardSubtitle,
} from "reactstrap";
import { Tooltip, Radio, RadioGroup, FormControl, FormControlLabel } from "@material-ui/core";
import { InsertButton } from "react-bootstrap-table";
import { BsDownload } from "react-icons-latest/bs";
import {
  AiOutlineVerticalAlignTop,
  AiOutlinePlusCircle,
  AiOutlineQrcode,
  AiOutlineCloudDownload,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineEye
} from "react-icons-latest/ai";
import { connect } from "react-redux";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from "react-bootstrap-table-next";
import LoadingOverlay from "react-loading-overlay"
import Files from 'react-files';
import ZSchema from 'z-schema';

import { GetOrganizationDetails } from "../components/Sources";
import {
  deleteDataAgreementInCloudagent,
  publishDataAgreementInCloudagent
} from '../services';
import {
  populateDataAgreementsTable,
  resetDataAgreementState,
  initDataSourceConfiguration,
  initDataUsingServiceConfiguration,
  toggleDataAgreementPanel,
  updateDataAgreementPanelMode,
  updateDataSourceConfiguration,
  resetDataAgreementPanelMode,
  updateDataAgreementTableFilter,
  updateDataAgreementTablePaginationTotalCount,
  updateDataAgreementTablePaginationCurrentPage,
  updateDataAgreementTablePaginationPageSize,
  resetDataAgreementTableToInitialState,
} from "../actions/dataAgreementActions";
import {
  initDataAgreementState
} from "../reducers/DataAgreementReducer";
import {
  createDataSourceConfigurationFromDataAgreementRecord,
  transformDataAgreementFromServerToClientFormat,
  transformDataAgreementRecordFromServerToClientFormat
} from "../utils/dataAgreementUtils";
import { showLoader, hideLoader } from "../actions/loaderActions";
import {
  TableLoader
} from "../components/Loader";

import GlobalDataPolicyConfigurationModal from "../components/GlobalDataPolicyConfigurationModal";
import DataAgreementCrudModalV2 from "../components/DataAgreementCrudModalV2";
import GeneralDeleteModalV2 from "../components/GeneralDeleteModalV2";
import DataAgreementPublishModal from "../components/DataAgreementPublishModal";
import DataAgreementQrCodeModal from "../components/DataAgreementQrCodeModal";
import OrganisationQrCodeModal from "../components/OrganisationQrCodeModal";
import Loader from '../components/Loader';

// FIXME: This is a temporary fix for https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

let fileReader;

class DataAgreementPageV2 extends React.Component {

  constructor() {
    super();
  }

  state = {
    selectedDataAgreementRecord: null,
    deleteDataAgreementModalVisibilityState: false,
    dataAgreementQrCodeModalVisibilityState: false,
    globalDataPolicyConfigurationModalVisibilityState: false,
    organisationQrCodeModalVisibilityState: false,
    publishDataAgreementModalVisibilityState: false,
    isTableLoading: false,
    paginationTotalCount: 0,
    paginationCurrentPage: 1,
    paginationPageSize: 10,
  };

  /**
   * Toggle delete data agreement modal visibility.
   */
  toggleDeleteDataAgreementModal = () => {

    this.setState({ deleteDataAgreementModalVisibilityState: !this.state.deleteDataAgreementModalVisibilityState });

  }

  /**
   * Toggle data agreement QR code modal visibility.
   */
  toggleDataAgreementQrCodeModal = () => {

    this.setState({ dataAgreementQrCodeModalVisibilityState: !this.state.dataAgreementQrCodeModalVisibilityState });

  }

  /**
   * Toggle global data policy configuration modal visibility.
   */
  toggleGlobalDataPolicyConfigurationModal = () => {

    this.setState({ globalDataPolicyConfigurationModalVisibilityState: !this.state.globalDataPolicyConfigurationModalVisibilityState });


  }

  /**
   * Toggle organisation QR code modal visibility.
   */
  toggleOrganisationQrCodeModal = () => {

    this.setState({ organisationQrCodeModalVisibilityState: !this.state.organisationQrCodeModalVisibilityState });

  }

  /**
   * Toggle data agreement publish modal visibility.
   */
  togglePublishDataAgreementModal = () => {

    this.setState({ publishDataAgreementModalVisibilityState: !this.state.publishDataAgreementModalVisibilityState });

  }


  /**
   * Triggered when data agreement QR code icon is clicked.
   * 
   * @param {*} row 
   */
  handleDataAgreementQr = (row) => {

    this.setState({ selectedDataAgreementRecord: row }, () => {

      this.setState({ dataAgreementQrCodeModalVisibilityState: true });

    });


  };



  /**
   * Fn validateDataAgreementSchema
   * 
   * Validating Data Agreement Schema by checking if basic fields are available.
   * 
   * @param {*} dataAgreement 
   */
  validateDataAgreementSchema = (dataAgreement) => {

    // Custom format for validating URLs
    ZSchema.registerFormat("urlString", function (str) {

      var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

      return !!pattern.test(str);

    });

    // Custom format for validating legal basis of processing
    ZSchema.registerFormat("legalBasisString", function (str) {

      if (str === "consent" || str === "legal_obligation" || str === "contract" || str === "vital_interest" || str === "public_task" || str === "legitimate_interest") {

        return true;

      } else {

        return false;

      }

    });

    // Custom format for validating method of use
    ZSchema.registerFormat("methodOfUseString", function (str) {

      if (str === "data-source" || str === "data-using-service" || str === "null") {

        return true;

      } else {

        return false;

      }

    });

    if (typeof dataAgreement == "object") {

      // json schema for data agreement
      const dataAgreementSchema = {

        "type": "object",

        "properties": {

          "data_policy": {

            "type": "object",

            "properties": {

              "policy_URL": {

                "type": "string",

                "format": "urlString"

              },

              "jurisdiction": {

                "type": "string"

              },

              "industry_sector": {

                "type": "string"

              },

              "data_retention_period": {

                "type": "integer"

              },

              "geographic_restriction": {

                "type": "string"

              },

              "storage_location": {

                "type": "string"

              }

            },
            "required": ["policy_URL", "jurisdiction", "industry_sector", "data_retention_period", "geographic_restriction", "storage_location"]

          },

          "purpose": {

            "type": "string"

          },

          "purpose_description": {

            "type": "string"

          },

          "lawful_basis": {

            "type": "string",

            "format": "legalBasisString"

          },

          "method_of_use": {

            "type": "string",

            "format": "methodOfUseString"

          },

          "personal_data": {

            "type": "array",

            "items": {

              "type": "object",

              "properties": {

                "attribute_id": {

                  "type": "string"

                },

                "attribute_name": {

                  "type": "string"

                },

                "attribute_description": {

                  "type": "string"

                }

              },

              "required": ["attribute_name", "attribute_description"]

            },

            "minItems": 1

          }

        },

        "required": ["purpose", "purpose_description", "lawful_basis", "method_of_use", "personal_data", "data_policy"]

      }


      const validator = new ZSchema();

      const valid = validator.validate(dataAgreement, dataAgreementSchema);

      var errors = validator.getLastErrors();

      console.log("Valid schema : ", valid);

      console.log("Validation errors : ", errors);

      if (valid) {

        // Convert data agreement from server to client format.
        const dataAgreementInClientFormat = transformDataAgreementFromServerToClientFormat(dataAgreement);

        // Replace data agreement state in redux store.
        // Open data agreement crud panel in "CREATE" mode.
        this.openDataAgreementCrudPanel("IMPORT", dataAgreementInClientFormat); // Internally crud panel will be opened in "CREATE" mode.

      } else {

        // FIXME: May be convert this to toast message ? not sure if it matches with rest of the app.

        // Notify users about the error.

        let errorMessage = "";

        errors.map((errorItem, index) => {

          let prefixCarriageReturn = "";

          if (index === 0) {

            prefixCarriageReturn = "\n"; // Prefix carriage return for first error message.

          }

          errorMessage = prefixCarriageReturn + errorItem["path"] + ": " + errorItem["message"] + errorMessage + "\n";

        })

        alert("Following errors occured while importing data agreements: " + errorMessage);

      }
    }
  }

  /**
   * Fn handleImportedDataAgreementFile
   * 
   * Handle data agreement json file contents.
   * 
   * @param {*} e 
   */
  handleImportedDataAgreementFile = (e) => {

    try {

      const content = fileReader.result;

      const jsonContent = JSON.parse(content);

      this.validateDataAgreementSchema(jsonContent);

    } catch (e) {

      // Handle error
      alert("Unable to parse the provided data agreement JSON!");

    }


  };

  /**
   * Fn onDataAgreementImportChange
   * 
   * Handle data agreement json file after selection from browse dialogue
   * 
   * 
   * @param {*} files 
   */
  onDataAgreementImportChange = (files) => {

    try {

      fileReader = new FileReader();

      fileReader.onloadend = this.handleImportedDataAgreementFile;

      fileReader.readAsText(files[0]);

    } catch (e) {

      // Handle error
      alert("Unable to parse the provided data agreement JSON!")

    }

  };

  /**
   * Fn onDataAgreementImportError
   * 
   * Handle errors occured during / after data agreemeent json file selection
   * 
   * @param {*} error 
   * @param {*} file 
   */
  onDataAgreementImportError = (error, file) => {

    console.log('error code ' + error.code + ': ' + error.message)

    // Handle error
    alert("Unable to parse the provided data agreement JSON!")

  };

  componentDidMount() {

    // Reset data agreement panel state.
    this.props.resetDataAgreementPanelMode();

    GetOrganizationDetails();

    // Reset data agreement table state.
    this.props.resetDataAgreementTableToInitialState();

    // Set page size.
    this.props.updateDataAgreementTablePaginationPageSize(this.state.paginationPageSize);

    // Set current page.
    this.props.updateDataAgreementTablePaginationCurrentPage(this.state.paginationCurrentPage);

    // Populate data agreements and personal data table.
    this.props.populateDataAgreementsTable();

  }

  /**
   * Handle page change for table.
   * 
   * @param {*} page 
   * @param {*} pageSize 
   */
  handlePageChange = (page, pageSize) => {

    // Update pagination state.
    this.setState({
      paginationCurrentPage: page,
      paginationPageSize: pageSize
    });

    // Populate data agreements and personal data table.
    const {
      populateDataAgreementsTable,
      updateDataAgreementTablePaginationPageSize,
      updateDataAgreementTablePaginationCurrentPage
    } = this.props;

    // Set page size.
    updateDataAgreementTablePaginationPageSize(pageSize);

    // Set current page.
    updateDataAgreementTablePaginationCurrentPage(page);

    // Populate data agreements table.
    populateDataAgreementsTable();

  }

  /**
   * Triggered when table content changes.
   * 
   * @param {*} type 
   * @param {*} param1 
   */
  onTableChange = (type, { page, sizePerPage }) => {

    // handle any data change here

    switch (type) {

      case 'pagination':
        this.handlePageChange(page, sizePerPage);
        break

    }

  }

  /**
   * To open data agreement crud panel.
   * 
   * @param {*} mode 
   * @param {*} dataAgreementRecord 
   */
  openDataAgreementCrudPanel = (mode, dataAgreementRecord) => {

    // Initialise data source configuration.
    this.props.initDataSourceConfiguration();

    // Initialise data using service configuration.
    this.props.initDataUsingServiceConfiguration();

    switch (mode) {

      case "IMPORT":

        // Update data agreement panel mode.
        this.props.updateDataAgreementPanelMode(dataAgreementRecord, "CREATE"); // here data agreement is already converted to client format.

        // Toggle data agreement crud panel.
        this.props.toggleDataAgreementPanel();

        break;

      case "CREATE":

        // Update data agreement panel mode.
        this.props.updateDataAgreementPanelMode(initDataAgreementState, mode);

        // Toggle data agreement crud panel.
        this.props.toggleDataAgreementPanel();

        break;


      case "READ":
      case "UPDATE":

        const { data_agreement: dataAgreement } = dataAgreementRecord;

        let dataSourceConfiguraiton = createDataSourceConfigurationFromDataAgreementRecord(dataAgreementRecord);

        this.props.updateDataSourceConfiguration(dataSourceConfiguraiton);

        let updatedDataAgreementState = transformDataAgreementRecordFromServerToClientFormat(dataAgreementRecord);

        // Update data agreement panel mode.
        this.props.updateDataAgreementPanelMode(updatedDataAgreementState, mode);

        // Toggle data agreement crud panel.
        this.props.toggleDataAgreementPanel();

        break;

    }

  }

  /**
   * Triggered when user clicks the data agreement filter radio buttons (All / Published)
   * 
   * @param {*} e 
   */
  handleDataAgreementTableFilterChange = (e) => {

    const { value } = e.target;

    this.props.updateDataAgreementTableFilter(value);

    // Populate data agreements table.
    this.props.populateDataAgreementsTable();


  }

  /**
   * Download data agreement json file.
   */
  downloadDataAgreement = (dataAgreement) => {

    const dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(dataAgreement, null, 4));

    const link = document.createElement("a");

    link.setAttribute("href", dataStr);

    link.setAttribute("download", "da.json");

    link.click();

  }

  /**
   * Triggered when component updates.
   * 
   * @param {*} prevProps 
   * @param {*} prevState 
   * @param {*} snapshot 
   */
  componentDidUpdate(prevProps, prevState, snapshot) {

    const {
      dataAgreementTable: {
        isTableLoading: oldIsTableLoading,
        paginationTotalCount: oldPaginationTotalCount,
        paginationCurrentPage: oldPaginationCurrentPage,
        paginationPageSize: oldPaginationPageSize,
        filter: oldFilter,
      }
    } = prevProps;

    const {
      dataAgreementTable: {
        isTableLoading: newIsTableLoading,
        paginationTotalCount: newPaginationTotalCount,
        paginationCurrentPage: newPaginationCurrentPage,
        paginationPageSize: newPaginationPageSize,
        filter: newFilter,

      }
    } = this.props;


    if (oldIsTableLoading !== newIsTableLoading) {

      // Update table loading state.
      this.setState({
        isTableLoading: newIsTableLoading
      });

    }

    if (oldPaginationTotalCount !== newPaginationTotalCount) {

      // Update pagination state.
      this.setState({
        paginationTotalCount: newPaginationTotalCount
      });

    }

    if (oldPaginationCurrentPage !== newPaginationCurrentPage) {

      // Update pagination state.
      this.setState({
        paginationCurrentPage: newPaginationCurrentPage
      });

    }

    if (oldPaginationPageSize !== newPaginationPageSize) {

      // Update pagination state.
      this.setState({
        paginationPageSize: newPaginationPageSize
      });

    }

    if (oldFilter !== newFilter) {

      // Update filter state.
      this.setState({
        filter: newFilter
      });

    }

  }

  render() {

    const { dataAgreementTable } = this.props;
    const { dataAgreements } = dataAgreementTable;

    const headerDispStyle = {
      backgroundColor: "#f0f0f0",
      padding: ".35rem",
      fontWeight: "bold",
      border: "solid",
      borderWidth: "0px 1px 3px 1px",
      borderColor: "#dee2e6",
    };
    const buttonDispStyle = {
      width: "3%",
      backgroundColor: "#f0f0f0",
      cursor: "pointer",
      padding: ".35rem",
      borderWidth: "0px",
      borderBottomWidth: "3px",
    };

    /**
     * Update text color to red in DA table columns if data agreement is not published.
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} rowIndex 
     * @param {*} colIndex 
     * @returns 
     */
    const colDispStyleDA = (cell, row, rowIndex, colIndex) => {

      let cellStyle = {
        fontSize: "14px",
        borderWidth: "0px",
        cursor: "pointer",
        borderBottomWidth: "1px",
        padding: ".35rem",
      }

      if (!row.publish_flag && colIndex < 5) {
        cellStyle.color = 'red';
      }

      if (colIndex === 4) {
        cellStyle.borderWidth = "0px 0px 1px 1px";
      }

      if (colIndex >= 5) {
        cellStyle.textAlign = "center";
      }

      return cellStyle;
    }

    // Data agreement table columns.
    const dataAgreementTableColumns = [
      {
        dataField: "data_agreement_id",
        text: "ID",
        hidden: true
      },
      {
        dataField: "data_agreement.purpose",
        text: "Usage Purpose",
        headerStyle: { ...headerDispStyle, width: "24%" },
        style: colDispStyleDA,
      },
      {
        dataField: "data_agreement.template_version",
        text: "Version",
        headerStyle: { ...headerDispStyle, width: "10%" },
        style: colDispStyleDA,
        formatter: (cellContent) => {
          return cellContent + ".0";
        }
      },
      {
        dataField: "method_of_use",
        text: "Data Exchange",
        headerStyle: { ...headerDispStyle, width: "14%" },
        style: colDispStyleDA,
        formatter: (cellContent) => {
          return cellContent === "data-source" ? "Data Source" : cellContent === "data-using-service" ? "Data Using Service" : "None";
        },
      },
      {
        dataField: "data_agreement.data_policy.policy_URL",
        text: "Policy URL",
        headerStyle: { ...headerDispStyle, width: "25%" },
        style: colDispStyleDA,
      },
      {
        dataField: "data_agreement.lawful_basis",
        text: "Lawful Basis Of Processing",
        headerStyle: {
          ...headerDispStyle,
          borderRightWidth: "0px",
          width: "21%",
        },
        style: colDispStyleDA,
        formatter: (cellContent) => {

          const updatedCellContent = cellContent.charAt(0).toUpperCase() + cellContent.slice(1);

          return updatedCellContent.split("_").join(" ");

        },
      },
      {
        dataField: "publish",
        text: "",
        headerStyle: buttonDispStyle,
        style: colDispStyleDA,
        formatter: (_, row) => {
          return (
            <Tooltip placement="top" title={row.publish_flag ? "Data agreement is published." : "Publish data agreement"}>
              <AiOutlineVerticalAlignTop

                disabled={true}

                size="20px"
                style={{ cursor: row.publish_flag ? "not-allowed" : "pointer" }}

                onClick={() => {

                  if (!row.publish_flag) {

                    this.setState({ selectedDataAgreementRecord: row });

                    this.setState({ publishDataAgreementModalVisibilityState: !this.state.publishDataAgreementModalVisibilityState, });

                  }

                }}

              />
            </Tooltip>

          );
        },
      },
      {
        dataField: "view",
        text: "",
        headerStyle: buttonDispStyle,
        style: colDispStyleDA,
        formatter: (_, row) => {
          return (

            <Tooltip placement="top" title={"View data agreement"}>
              <AiOutlineEye style={{ cursor: "pointer" }} id="view" size="20px" onClick={() => { this.openDataAgreementCrudPanel("READ", row); }} />
            </Tooltip>
          );
        },
      },
      {
        dataField: "download",
        text: "",
        headerStyle: buttonDispStyle,
        style: colDispStyleDA,
        formatter: (_, row) => {
          return (
            <Tooltip placement="top" title={"Download data agreement."}>
              <AiOutlineCloudDownload size="20px" onClick={() => {

                this.downloadDataAgreement(row.data_agreement);

              }} />
            </Tooltip>

          );
        },
      },

      {
        dataField: "QRcode",
        text: "",
        headerStyle: buttonDispStyle,
        style: colDispStyleDA,
        formatter: (_, row) => {
          return (
            <Tooltip placement="top" title={row.method_of_use === "data-source" ? "Data source data agreements doesn't support QR code functionality." : !row.publish_flag ? "Draft data agreements doesn't support QR code functionality." : "Generate QR code"}>
              <AiOutlineQrcode size="20px" style={{ cursor: row.method_of_use === "data-source" ? "not-allowed" : !row.publish_flag ? "not-allowed" : "pointer" }} onClick={() => { if (row.method_of_use === "data-using-service" && row.publish_flag) { this.handleDataAgreementQr(row); } }} />
            </Tooltip>
          )
        },
      },
      {
        dataField: "Edit",
        text: "",
        headerStyle: buttonDispStyle,
        style: colDispStyleDA,
        formatter: (_, row) => {
          return (
            <Tooltip placement="top" title={"Update data agreement"}>
              <AiOutlineEdit size="20px" onClick={() => { this.openDataAgreementCrudPanel("UPDATE", row); }} />
            </Tooltip>
          );
        },
      },
      {
        dataField: "Delete",
        text: "",
        headerStyle: buttonDispStyle,
        style: colDispStyleDA,
        formatter: (_, row) => {
          return (
            <Tooltip placement="top" title={"Delete data agreement"}>
              <AiOutlineDelete size="20px" onClick={() => { this.setState({ selectedDataAgreementRecord: row }); this.setState({ deleteDataAgreementModalVisibilityState: !this.state.deleteDataAgreementModalVisibilityState, }); }} />
            </Tooltip>
          );
        },
      },
    ];

    return (

      <Page title="" breadcrumbs={[{ name: "Data Agreement", active: true }]} className="TablePage">

        <Loader loaderTag={"DataAgreementPageV2"} />

        <Row>

          <Col>

            <Card>

              <CardTitle>

                <div className="card-table-heading" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>

                  <div id="data-agreement-table-header-left-container" style={{ maxWidth: "300px", width: "200px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    <span>Data Agreement</span>

                    <Tooltip placement="top" title={"Create data agreement"}>
                      <AiOutlinePlusCircle size={"25px"} style={{ cursor: "pointer" }} onClick={() => this.openDataAgreementCrudPanel("CREATE")} />
                    </Tooltip>

                    <Tooltip placement="top" title="Import Data Agreement">


                      <div className="data-agreement-import-file-container">


                        <Files className='files-dropzone' onChange={this.onDataAgreementImportChange} onError={this.onDataAgreementImportError} accepts={['application/json']} multiple={false} maxFileSize={10000} minFileSize={0} clickable>


                          <BsDownload size={"25px"} className="dataagreement-table-import-icon" />


                        </Files>


                      </div>


                    </Tooltip>

                  </div>



                  <div id="data-agreement-table-header-right-container" style={{ display: "flex", flexWrap: "wrap" }}>

                    <div id="data-agreement-filters-container" style={{ paddingTop: "10px" }}>

                      <FormControl>

                        <RadioGroup className="data-agreement-filters-radio-group" row aria-labelledby="demo-radio-buttons-group-label" value={dataAgreementTable.filter} name="radio-buttons-group">

                          <FormControlLabel onChange={this.handleDataAgreementTableFilterChange} value="All" control={<Radio color="default" />} label={<span style={{ fontSize: '14px', fontFamily: 'Helvetica, Helvetica neue' }}>All</span>} />

                          <FormControlLabel onChange={this.handleDataAgreementTableFilterChange} value="Published" control={<Radio color="default" />} label={<span style={{ fontSize: '14px', fontFamily: 'Helvetica, Helvetica neue' }}>Published</span>} />

                        </RadioGroup>

                      </FormControl>

                    </div>


                    <div id="data-agreement-table-buttons-right-container" style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>

                      <InsertButton btnContextual="btn-default" btnGlyphicon="glyphicon-plus fa-plus" style={{ cursor: "pointer" }} onClick={this.toggleGlobalDataPolicyConfigurationModal} >Global Data Policy</InsertButton>

                      <InsertButton btnContextual="btn-default" btnGlyphicon="glyphicon-plus fa-plus" style={{ cursor: "pointer" }} onClick={this.toggleOrganisationQrCodeModal}>Organisation QR Code</InsertButton>

                    </div>


                  </div>

                </div>

              </CardTitle>

              <CardSubtitle>

                <div className="card-table-subheading">Manage Data Agreements for your organisation.</div>

              </CardSubtitle>

              <CardBody>

                <BootstrapTable

                  remote={{ pagination: true, filter: false, sort: false }}

                  pagination={
                    paginationFactory(
                      {

                        page: this.state.paginationCurrentPage,

                        sizePerPage: this.state.paginationPageSize,

                        totalSize: this.state.paginationTotalCount,

                        nextPageText: ">",

                        prePageText: "<",

                        alwaysShowAllBtns: false,

                        hideSizePerPage: true,

                      }
                    )
                  }

                  loading={this.state.isTableLoading}

                  overlay={
                    overlayFactory(
                      {
                        spinner: <TableLoader />,
                        styles: {
                          overlay: (base) => (
                            {
                              ...base,
                              background: 'rgba(198, 198, 198, 0.07)'
                            }
                          ),
                        }
                      }
                    )
                  }

                  onTableChange={this.onTableChange}

                  noDataIndication={
                    () => {
                      return !this.state.isTableLoading ? <div>No data to display</div> : <div style={{ height: "300px" }}></div>
                    }
                  }

                  id="btPurpose"

                  data={dataAgreements}

                  bootstrap4={true}

                  keyField="data_agreement_id"

                  hover={true}

                  columns={dataAgreementTableColumns}

                  striped={false}></BootstrapTable>

              </CardBody>

            </Card>

          </Col>

        </Row>


        <Modal backdrop="static" isOpen={this.props.dataAgreementPanel.isOpen} unmountOnClose={true}>

          <Loader loaderTag={"DataAgreementCrudModalV2"} />

          <DataAgreementCrudModalV2

            onModalClose={() => {

              // FIXME: Only perform reload on "SAVE" or "PUBLISH" and not every time the modal closes.
              // To reload data agreement table.
              const { populateDataAgreementsTable } = this.props;
              populateDataAgreementsTable();

              // To close crud panel.
              this.props.toggleDataAgreementPanel();


            }}

          />

        </Modal>

        <GeneralDeleteModalV2

          loaderTag={"GeneralDeleteModalV2ForDataAgreements"}

          requireConfirmText={true}

          confirmText="DELETE"

          modalHeaderTitleText={<div className="delete-modal-header-title-div"> Delete Data Agreement:  <span>{this.state.selectedDataAgreementRecord && this.state.selectedDataAgreementRecord.data_agreement.purpose}</span></div>}

          modelHeaderDescriptionText={this.state.selectedDataAgreementRecord && this.state.selectedDataAgreementRecord.data_agreement.template_id}

          modalDescriptionText={<p> You are about to delete an existing data agreement. Please type <span style={{ fontWeight: "bold" }}>DELETE</span> to confirm and click OK. This action is not reversible.</p>}

          deleteCallBack={() => {

            const { showLoader, hideLoader } = this.props;

            showLoader("GeneralDeleteModalV2ForDataAgreements");

            // Perform delete data agreement and reload the data agreement table.
            deleteDataAgreementInCloudagent(this.state.selectedDataAgreementRecord.data_agreement_id).then((res) => {

              const { populateDataAgreementsTable } = this.props;

              // Reload data agreement table.
              populateDataAgreementsTable();

              // Hide the loader modal.
              hideLoader("GeneralDeleteModalV2ForDataAgreements");

              // Close the delete modal.
              this.toggleDeleteDataAgreementModal();

              // Reset the selected data agreement record.
              this.setState({
                selectedDataAgreementRecord: null
              });

            }).catch(err => {

              console.log(err);

              // Hide the loader modal.
              hideLoader("GeneralDeleteModalV2ForDataAgreements");

              // Close the delete modal.
              this.toggleDeleteDataAgreementModal();

              // Reset the selected data agreement record.
              this.setState({
                selectedDataAgreementRecord: null
              });

            })

          }}

          toggleState={this.state.deleteDataAgreementModalVisibilityState}

          toggle={this.toggleDeleteDataAgreementModal}

        />


        <DataAgreementPublishModal

          loaderTag={"DataAgreementPublishModal"}

          requireConfirmText={true}

          confirmText="PUBLISH"

          modalHeaderTitleText={<div className="publish-modal-header-title-div"> Publish Data Agreement:  <span>{this.state.selectedDataAgreementRecord && this.state.selectedDataAgreementRecord.data_agreement.purpose}</span></div>}

          modelHeaderDescriptionText={this.state.selectedDataAgreementRecord && this.state.selectedDataAgreementRecord.data_agreement.template_id}

          modalDescriptionText={<p> You are about to publish a data agreement. Please type <span style={{ fontWeight: "bold" }}>PUBLISH</span> to confirm and click PUBLISH. This action is not reversible.</p>}

          publishCallBack={() => {

            const { showLoader, hideLoader } = this.props;

            showLoader("DataAgreementPublishModal");

            // Perform publish data agreement and reload the data agreement table.
            publishDataAgreementInCloudagent(this.state.selectedDataAgreementRecord.data_agreement_id).then((res) => {

              const { populateDataAgreementsTable } = this.props;

              // Reload data agreement table.
              populateDataAgreementsTable();

              // Hide the loader modal.
              hideLoader("DataAgreementPublishModal");

              // Close the publish modal.
              this.togglePublishDataAgreementModal();

              // Reset the selected data agreement record.
              this.setState({
                selectedDataAgreementRecord: null
              });

            }).catch(err => {

              console.log(err);

              // Hide the loader modal.
              hideLoader("DataAgreementPublishModal");

              // Close the publish modal.
              this.togglePublishDataAgreementModal();

              // Reset the selected data agreement record.
              this.setState({
                selectedDataAgreementRecord: null
              });

            })

          }}

          toggleState={this.state.publishDataAgreementModalVisibilityState}

          toggle={this.togglePublishDataAgreementModal}

        />

        {this.state.dataAgreementQrCodeModalVisibilityState && (

          <DataAgreementQrCodeModal

            dataAgreementPurpose={this.state.selectedDataAgreementRecord.data_agreement.purpose}

            dataAgreementTemplateId={this.state.selectedDataAgreementRecord.data_agreement_id}

            isOpen={this.state.dataAgreementQrCodeModalVisibilityState}

            toggleIsOpen={() => {

              // Reset the selected data agreement record.
              this.setState({
                selectedDataAgreementRecord: null
              });

              this.toggleDataAgreementQrCodeModal();

            }}
          />

        )}

        {this.state.globalDataPolicyConfigurationModalVisibilityState && (

          <GlobalDataPolicyConfigurationModal

            isOpen={this.state.globalDataPolicyConfigurationModalVisibilityState}
            toggleIsOpen={this.toggleGlobalDataPolicyConfigurationModal}

          />

        )}

        {this.state.organisationQrCodeModalVisibilityState && (

          <OrganisationQrCodeModal

            isOpen={this.state.organisationQrCodeModalVisibilityState}
            toggleIsOpen={this.toggleOrganisationQrCodeModal}

          />

        )}
      </Page>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataAgreementTable: state.DataAgreementTable,
    dataAgreementPanel: state.DataAgreementPanel
  };
}

export default connect(
  mapStateToProps,
  {
    populateDataAgreementsTable,
    resetDataAgreementState,
    initDataSourceConfiguration,
    initDataUsingServiceConfiguration,
    toggleDataAgreementPanel,
    updateDataAgreementPanelMode,
    updateDataSourceConfiguration,
    resetDataAgreementPanelMode,
    showLoader,
    hideLoader,
    updateDataAgreementTableFilter,
    updateDataAgreementTablePaginationTotalCount,
    updateDataAgreementTablePaginationCurrentPage,
    updateDataAgreementTablePaginationPageSize,
    resetDataAgreementTableToInitialState,
  }
)(DataAgreementPageV2);
