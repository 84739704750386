import React from "react";
import { CardBody, FormGroup, Input } from "reactstrap";
import Select from "react-select";
import jsonData from "../setting/settings.json";
import { store } from '../services/localStorageStore';
import axios from "axios";

const api = JSON.parse(JSON.stringify(jsonData)).api;

const customStylesDD = {
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "gray" }, // border style on hover
    border: "1px solid lightgray", // default border color
    boxShadow: "none", // no box-shadow
  }),
};

export default class EditWebhookModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contentTypes: [],
      selectedContentType: "",
      selectedTriggerEvent: [],
      isOK: false,
      payloadUrl: "",
      secretKey: "",
      selectTriggerEvent: null,
      eventTypes: [],
    };
    this.handleChangeDetails = this.handleChangeDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectTrigger = this.handleSelectTrigger.bind(this);
  }

  componentDidMount() {
    const axiosConfig = {
      headers: {
        Authorization:
          "Bearer " +
          store.getData("data").Token.access_token,
      },
    };
    const reqEventTypeUrl = api + "/v1/organizations/webhooks/event-types";
    axios.get(reqEventTypeUrl, axiosConfig).then((response) => {
      this.setState({ eventTypes: response.data.EventTypes });
      const getWebhookUrl = api + "v1/organizations/" + store.getData("orgs").ID + "/webhooks/" + this.props.selectedWebhook
      axios.get(getWebhookUrl, axiosConfig).then((response) => {
        this.setState({
            selectedContentType: response.data.ContentType,
            selectedTriggerEvent: response.data.SubscribedEvents,
            isOK: true,
            payloadUrl: response.data.PayloadURL,
            secretKey: response.data.SecretKey
        });
        if (this.state.eventTypes.length === response.data.SubscribedEvents.length){
          this.setState({selectTriggerEvent: false})
        }else {
          this.setState({selectTriggerEvent: true})
        }
      });
    });
    const reqContentTypeUrl =
      api + "v1/organizations/webhooks/payload/content-types";
    axios.get(reqContentTypeUrl, axiosConfig).then((response) => {
      this.setState({
        contentTypes: response.data.ContentTypes.map((contentType, index) => {
          return { value: index + 1, label: contentType };
        }),
      });
    });
  }

  activateButton = () => {
    if (
      this.state.payloadUrl.length > 4 &&
      this.state.selectedContentType &&
      this.state.secretKey &&
      (this.state.selectedTriggerEvent.length > 0 ||
        this.state.selectTriggerEvent === false)
    ) {
      this.setState({ isOK: true });
    } else {
      this.setState({ isOK: false });
    }
  };

  handleChange = async (selectedContentType) => {
    await this.setState({ selectedContentType: selectedContentType.label });
    this.activateButton();
  };

  async handleSelectTrigger(event) {
    event.persist()
    if (event.target.value == "false") {
      await this.setState({ selectTriggerEvent: false });
      await this.setState({selectedTriggerEvent: this.state.eventTypes})
      this.activateButton();
    } else if (event.target.value == "true") {
      await this.setState({ selectTriggerEvent: true });
      this.activateButton();
    } else {
      if (event.target.checked) {
        await this.setState({
          selectedTriggerEvent: [
            ...this.state.selectedTriggerEvent,
            event.target.value,
          ],
        });
        (this.state.selectedTriggerEvent.length === this.state.eventTypes.length) ?
          await this.setState({selectTriggerEvent: false})
          :
          await this.setState({selectTriggerEvent: true});
        this.activateButton();
      } else {
        await this.setState({selectTriggerEvent: true})
        await this.setState({
          selectedTriggerEvent: this.state.selectedTriggerEvent.filter(
            (triggerEvent) => triggerEvent != event.target.value
          ),
        });
        this.activateButton();
      }
    }
  }

  handleChangeDetails(event) {
    if (event.target.name === "payloadUrl") {
      this.setState({ payloadUrl: event.target.value });
      if (
        event.target.value.length > 4 &&
        this.state.selectedContentType &&
        this.state.secretKey &&
        (this.state.selectedTriggerEvent.length > 0 ||
          this.state.selectTriggerEvent === false)
      ) {
        this.setState({ isOK: true });
      } else {
        this.setState({ isOK: false });
      }
    }
    if (event.target.name === "secretKey") {
      this.setState({ secretKey: event.target.value });
      if (
        event.target.value.length > 4 &&
        this.state.selectedContentType &&
        this.state.payloadUrl &&
        (this.state.selectedTriggerEvent.length > 0 ||
          this.state.selectTriggerEvent === false)
      ) {
        this.setState({ isOK: true });
      } else {
        this.setState({ isOK: false });
      }
    }
  }
 
  handleSaveBtnClick = async (onModalClose, setWebHookUrls, webHookUrls) => {
    const reqBody = {
      payloadurl: this.state.payloadUrl,
      contenttype: this.state.selectedContentType,
      secretkey: this.state.secretKey,
      disabled: false,
      skipsslverification: false,
    };
    this.state.selectTriggerEvent === false
      ? (reqBody.subscribedevents = this.state.eventTypes)
      : (reqBody.subscribedevents = this.state.selectedTriggerEvent);
    const axiosConfig = {
      headers: {
        Authorization:
          "Bearer " +
          store.getData("data").Token.access_token,
      },
    };
    const url =
      api +
      "v1/organizations/" +
      store.getData("orgs").ID +
      "/webhooks/" + this.props.selectedWebhook;
    try {
      const response = await axios.put(url, reqBody, axiosConfig);
    } catch (error) {
      console.log(error);
    }

    onModalClose();
  };

  render() {
    const { onModalClose, setWebHookUrls, webHookUrls } = this.props;

    const headerStyle = {
      fontSize: "16px",
      backgroundColor: "#eeeeee",
    };

    const textStyle = {
      color: "#212529",
      fontSize: "16px",
      paddingBottom: 1,
    };

    const radioStyle = {
      paddingBottom: 1,
      margin: 4,
    };

    const inputStyle = {
      color: "#495057",
      border: "1",
      borderWidth: 0,
      padding: 0,
      paddingBottom: 1,
      borderRadius: 0,
      fontSize: "16px",
      borderBottomWidth: 1.2,
      borderBottomColor: "lightgray", //'#DFE0E1',
    };

    return (
      <div style={{ backgroundColor: "white" }} className="modal-content">
        <div className="modal-header" style={headerStyle}>
          <h3>Webhook Configuration </h3>
        </div>
        <div>
          {" "}
          <CardBody>
            {" "}
            <div className="form">
              <div className="form-group" key="PayloadUrl">
                <label style={textStyle}> Payload URL* </label>
                <input
                  className="w-100 pt-0"
                  placeholder="<url>"
                  type="text"
                  style={inputStyle}
                  name="payloadUrl"
                  value={this.state.payloadUrl}
                  onChange={this.handleChangeDetails}
                />
              </div>
              <div className="form-group" key="ContentType">
                <label style={textStyle}>Content Type</label>
                <Select
                  isSearchable={false}
                  isMulti={false}
                  options={this.state.contentTypes}
                  placeholder={this.state.selectedContentType}
                  styles={customStylesDD}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group" key="SecretKey">
                <label style={textStyle}> Secret Key* </label>
                <input
                  className="w-100 pt-0"
                  placeholder=""
                  type="text"
                  style={inputStyle}
                  name="secretKey"
                  value={this.state.secretKey}
                  onChange={this.handleChangeDetails}
                />
              </div>
              <div className="form-group" key="TriggerEvents">
                <label style={textStyle}> Trigger events </label>
                <div className="form-group">
                  <input
                    type="radio"
                    style={radioStyle}
                    name="triggerEventSelect"
                    value={false}
                    checked={!this.state.selectTriggerEvent}
                    onChange={this.handleSelectTrigger}
                  />
                  All user requests
                </div>
                <div className="form-group">
                  <input
                    type="radio"
                    style={radioStyle}
                    name="triggerEventSelect"
                    value={true}
                    checked={this.state.selectTriggerEvent}
                    onChange={this.handleSelectTrigger}
                  />
                  Selected user requests
                </div>
                <div className="form-group section">
                  {this.state.eventTypes.map((event) => (
                    <div key={event} className="box">
                      <input
                        type="checkbox"
                        style={radioStyle}
                        name={event}
                        value={event}
                        checked = {this.state.selectTriggerEvent === false || this.state.selectedTriggerEvent.includes(event)}
                        onChange={this.handleSelectTrigger}
                      />
                      {event}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CardBody>
        </div>
        <div className="modal-footer">
          <button size="ig" className="btn btn-default" onClick={onModalClose}>
            Close
          </button>
          <button
            size="ig"
            className="btn btn-default"
            disabled={!this.state.isOK}
            style={{ cursor: this.state.isOK ? "pointer" : "not-allowed" }}
            onClick={() =>
              this.handleSaveBtnClick(onModalClose, setWebHookUrls, webHookUrls)
            }
          >
            Save
          </button>
        </div>
      </div>
    );
  }
}
