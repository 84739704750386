import { createStore, applyMiddleware } from "redux";
import ReduxThunk from "redux-thunk";
import reducers from "../reducers";

const store = applyMiddleware(ReduxThunk)(createStore);

const storeInstance = store(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

// storeInstance.subscribe(() => {

//     // FIXME: Persist redux state to localstorage ?

// });

export default storeInstance;