import { combineReducers } from "redux";
import PricingReducer from "./reducer_billing";
import InvoiceReducer from "./reducer_invoices";
import { OrgTypesReducer } from "./OrgReducer";
import { ValidateNewUserReducer } from "./OnboardingReducer";
import {
  CardReducer,
  AddressReducer,
  OrganizationReducer,
  ActivePackageReducer,
  ServAgreementReducer
} from "./BillingReducer";
import { CheckIsLogin } from './Authenticate'
import { CheckAPIKey } from './APIKeyValidation'
import {
  DataAgreementStateUpdate,
  ConfigureDataSource,
  ConfigureDataUsingService,
  DataAgreementTable,
  DataAgreementPanel,
  PersonalDataTable,
} from './DataAgreementReducer'
import {
  LoaderStateReducer
} from './LoaderReducer'
import { reducer as reduxForm } from "redux-form";

const rootReducer = combineReducers({
  pricingInfo: PricingReducer,
  organization: OrganizationReducer,
  activePackage: ActivePackageReducer,
  invoices: InvoiceReducer,
  form: reduxForm,
  serviceAgreement: ServAgreementReducer,
  address: AddressReducer,
  cardDetails: CardReducer,
  orgTypes: OrgTypesReducer,
  newUserValidity: ValidateNewUserReducer,
  checkIsLogin: CheckIsLogin,
  checkAPIKey: CheckAPIKey,
  DataAgreementStateUpdate: DataAgreementStateUpdate,
  ConfigureDataSource: ConfigureDataSource,
  LoaderStateReducer: LoaderStateReducer,
  ConfigureDataUsingService: ConfigureDataUsingService,
  DataAgreementTable: DataAgreementTable,
  DataAgreementPanel: DataAgreementPanel,
  PersonalDataTable: PersonalDataTable
});

export default rootReducer;
