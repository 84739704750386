const sortTime = (a, b, order='asc') => {
    const timeStampa = new Date(a).getTime();
    const timeStampb = new Date(b).getTime();
    // console.log(a,timeStampa,'comaprting',b,timeStampb)

    if (order === "asc") {
      return timeStampa - timeStampb;
    }
    return timeStampb - timeStampa;
  };

export default sortTime