import logo200Image from '../../assets/img/logo/logo_200.png';
import sidebarBgImage from '../../assets/img/sidebar/sidebar-6.jpg';
import SourceLink from '../SourceLink';
import React from 'react';
import { store } from '../../services/localStorageStore'
import { withRouter } from "react-router-dom";

import {
  MdDashboard, MdHome,
  MdStorage,
  MdAttachMoney,
  MdPerson,
  MdEqualizer,
  MdEvent,
  MdVerifiedUser,
  MdPages,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdFingerprint,

} from 'react-icons/lib/md';

import {
  AiOutlineFileProtect,
  AiOutlineSolution,
  AiOutlineCloud,
  AiOutlineIdcard,
  AiOutlineUsergroupAdd,
  AiOutlineUser,
  AiOutlineHome,
  AiOutlineApi,
  AiOutlineCalendar,
} from 'react-icons-latest/ai';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from 'reactstrap';
import bn from '../../utils/bemnames';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const navItems = [
  { to: '/start', name: 'Getting Started', Icon: AiOutlineHome },
  { to: '/dataagreement', name: 'Data Agreement', Icon: AiOutlineFileProtect },
  { to: '/personaldata', name: 'Personal Data', Icon: AiOutlineSolution },
  { to: '/enduser', name: 'Manage End Users', Icon: MdVerifiedUser },
  { to: '/billing', name: 'Billing', Icon: MdAttachMoney, mode: 'dev' },
  { to: '/manage', name: 'Manage Team', Icon: MdPerson },
  { to: '/logs', name: 'View Logs', Icon: MdEqualizer, mode: 'dev' },
  { to: '/developer', name: 'Developer API', Icon: AiOutlineApi },
  { to: '/events', name: 'Support Events*', Icon: MdEvent, mode: 'dev' },
];

const navItemsTop = [
  { to: '/start', name: 'Getting Started', Icon: AiOutlineHome },
  { to: '/dataagreement', name: 'Data Agreement', Icon: AiOutlineFileProtect },
  { to: '/personaldata', name: 'Personal Data', Icon: AiOutlineSolution },
];

const navItemsManage = [
  { name: 'Manage End Users', Icon: MdVerifiedUser, mode: 'dev' },
];

const navItemsAccount = [
  { name: 'Account', Icon: MdAttachMoney, mode: 'dev' },
];


const navItemsSupport = [
  { name: 'Support Events', Icon: MdEvent, mode: 'dev' },
];

const navItemsManageSub = [
  { to: '/subscription', name: 'Subscription', Icon: MdAttachMoney },
  { to: '/consents', name: 'Consents', Icon: MdEqualizer },
  { to: '/notifications', name: 'Notifications', Icon: MdPerson },
  { to: '/privacy', name: 'Privacy Board', Icon: MdDashboard },
];

const navItemsDataExchangeSub = [
  { to: '/aries-cloud-agent', name: 'Aries Cloud Agent', Icon: '' }
];

const navItemsSSISub = [
  { to: '/connections', name: 'Connections', Icon: '' },
  { to: '/credentials', name: 'Credentials', Icon: '' },
  { to: '/verifications', name: 'Verifications', Icon: '' }
];

const navItemsAccountSub = [
  { to: '/billing', name: 'Billing', Icon: MdAttachMoney },
  { to: '/logs', name: 'Logs', Icon: MdEqualizer },
  { to: '/manage', name: 'Team', Icon: MdPerson },
];

const navItemsSupportSub = [
  { to: '/events', name: 'User Requests', Icon: MdPerson },
  { to: '/webhooks', name: 'Webhooks', Icon: MdEqualizer }
];

const navItemsBottom = [
  { to: '/developer', name: 'Developer API', Icon: AiOutlineApi }
];
const bem = bn.create('sidebar');

class Sidebar extends React.Component {
  state = {
    isOpenComponents: true,
    isOpenContents: true,
    isOpenPages: true,
  };

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.toggleDataExchange = this.toggleDataExchange.bind(this);
    this.toggleSSI = this.toggleSSI.bind(this);
    this.toggleAccountNavbar = this.toggleAccountNavbar.bind(this);
    this.toggleSupportNavbar = this.toggleSupportNavbar.bind(this);

    this.state = {
      collapsed: true,
      ac_collapsed: true,
      de_collapsed: true,
      ssi_collapsed: true,
      support_collapsed: true
    };
  }

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  toggleDataExchange() {
    this.setState({
      de_collapsed: !this.state.de_collapsed
    });
  }

  toggleSSI() {
    this.setState({
      ssi_collapsed: !this.state.ssi_collapsed
    });
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed

    });
  }

  toggleAccountNavbar() {
    this.setState({
      ac_collapsed: !this.state.ac_collapsed
    });
  }

  toggleSupportNavbar() {
    this.setState({
      support_collapsed: !this.state.support_collapsed
    });
  }

  componentDidMount() {
    /*
   if(store.getData("iglogin")!=="1") 
    {
       window.location.href="/";
   }*/

    let data = store.getData("data");
    if (!data) {
      store.clear();
      window.location.href = "/";
    }
  }

  render() {
    let data;
    if (store.getData("data"))
      data = store.getData("data").User.ID;
    if (data) {
      return (
        <aside className={bem.b()} data-image={sidebarBgImage}>
          <div className={bem.e('background')} style={sidebarBackground} />
          <div className={bem.e('content')}>
            <Navbar className="p-0 m-2">
              <img
                src={logo200Image}
                width="100%"
                height="100%"
                className="p-0 m-0"
                alt="iGrant.io | Your Data, Your Choice.."
                onClick={() => this.props.history.push("/start")}
              />
            </Navbar>
            <Nav vertical>
              {navItemsTop.map(({ to, name, Icon, mode }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-sentencecase"
                    tag={NavLink}
                    to={to}
                    activeclassname="active">
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}{(mode === 'dev') ? '*' : ''}</span>
                  </BSNavLink>
                </NavItem>
              ))}
              <NavItem className={bem.e('nav-item')} onClick={this.toggleDataExchange} style={{ cursor: 'pointer', }}>
                <BSNavLink
                  className="text-sentencecase"
                  activeclassname="active" >
                  <AiOutlineCloud className={bem.e('nav-item-icon')} />
                  Data Exchange {this.state.de_collapsed ? <MdKeyboardArrowDown size={'25px'} style={{ paddingLeft: '3px', float: 'right' }} /> : <MdKeyboardArrowUp size={'25px'} style={{ paddingLeft: '3px' }} />} </BSNavLink>
              </NavItem>
              <Collapse isOpen={!this.state.de_collapsed} navbar>
                {navItemsDataExchangeSub.map(({ to, name, Icon, mode }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-sentencecase"
                      tag={NavLink}
                      to={to}
                      activeclassname="active">
                      <span style={{ marginLeft: '2rem' }} >{name}{(mode === 'dev') ? '*' : ''}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              <NavItem className={bem.e('nav-item')} onClick={this.toggleSSI} style={{ cursor: 'pointer', }}>
                <BSNavLink
                  className="text-sentencecase"
                  activeclassname="active" >
                  <AiOutlineIdcard style={{ width: '30px', height: '30px' }} className={bem.e('nav-item-icon')} />
                  Self-Sovereign Identity{this.state.ssi_collapsed ? <MdKeyboardArrowDown size={'25px'} style={{ paddingLeft: '3px', float: 'right' }} /> : <MdKeyboardArrowUp size={'25px'} style={{ paddingLeft: '3px' }} />} </BSNavLink>
              </NavItem>
              <Collapse isOpen={!this.state.ssi_collapsed} navbar>
                {navItemsSSISub.map(({ to, name, Icon, mode }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-sentencecase"
                      tag={NavLink}
                      to={to}
                      activeclassname="active">
                      <span style={{ marginLeft: '2rem' }} >{name}{(mode === 'dev') ? '*' : ''}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              <NavItem className={bem.e('nav-item')} onClick={this.toggleNavbar} style={{ cursor: 'pointer', }}>
                <BSNavLink
                  className="text-sentencecase"
                  activeclassname="active" >
                  <AiOutlineUsergroupAdd className={bem.e('nav-item-icon')} />
                  Manage End Users {this.state.collapsed ? <MdKeyboardArrowDown size={'25px'} style={{ paddingLeft: '3px', float: 'right' }} /> : <MdKeyboardArrowUp size={'25px'} style={{ paddingLeft: '3px' }} />} </BSNavLink>
              </NavItem>
              <Collapse isOpen={!this.state.collapsed} navbar>
                {navItemsManageSub.map(({ to, name, Icon, mode }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-sentencecase"
                      tag={NavLink}
                      to={to}
                      activeclassname="active">
                      <span style={{ marginLeft: '2rem' }} >{name}{(mode === 'dev') ? '*' : ''}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              <NavItem className={bem.e('nav-item')} onClick={this.toggleAccountNavbar} style={{ cursor: 'pointer', }}>
                <BSNavLink
                  className="text-sentencecase"
                  activeclassname="active" >
                  <AiOutlineUser className={bem.e('nav-item-icon')} />
                  Account {this.state.ac_collapsed ? <MdKeyboardArrowDown size={'25px'} style={{ paddingLeft: '3px', float: 'right' }} /> : <MdKeyboardArrowUp size={'25px'} style={{ paddingLeft: '3px' }} />} </BSNavLink>
              </NavItem>
              <Collapse isOpen={!this.state.ac_collapsed} navbar>
                {navItemsAccountSub.map(({ to, name, Icon, mode }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-sentencecase"
                      tag={NavLink}
                      to={to}
                      activeclassname="active">
                      <span style={{ marginLeft: '2rem' }} >{name}{(mode === 'dev') ? '*' : ''}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
              {navItemsBottom.map(({ to, name, Icon, mode }, index) => (
                <NavItem key={index} className={bem.e('nav-item')}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-sentencecase"
                    tag={NavLink}
                    to={to}
                    activeclassname="active">
                    <Icon className={bem.e('nav-item-icon')} />
                    <span className="">{name}{(mode === 'dev') ? '*' : ''}</span>
                  </BSNavLink>
                </NavItem>
              ))}
              <NavItem className={bem.e('nav-item')} onClick={this.toggleSupportNavbar} style={{ cursor: 'pointer', }}>
                <BSNavLink
                  className="text-sentencecase"
                  activeclassname="active" >
                  <AiOutlineCalendar className={bem.e('nav-item-icon')} />
                  Support Events {this.state.support_collapsed ? <MdKeyboardArrowDown size={'25px'} style={{ paddingLeft: '3px', float: 'right' }} /> : <MdKeyboardArrowUp size={'25px'} style={{ paddingLeft: '3px' }} />} </BSNavLink>
              </NavItem>
              <Collapse isOpen={!this.state.support_collapsed} navbar>
                {navItemsSupportSub.map(({ to, name, Icon, mode }, index) => (
                  <NavItem key={index} className={bem.e('nav-item')}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-sentencecase"
                      tag={NavLink}
                      to={to}
                      activeclassname="active">
                      <span style={{ marginLeft: '2rem' }} >{name}{(mode === 'dev') ? '*' : ''}</span>
                    </BSNavLink>
                  </NavItem>
                ))}
              </Collapse>
            </Nav>
          </div>
        </aside>
      );
    }
    else {
      store.clear();
      window.location.href = "/";
    }
  }
}

export default withRouter(Sidebar);
