import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { Button, Card, CardBody, CardText, CardTitle } from "reactstrap";
import { formValueSelector, reduxForm } from "redux-form";
import * as actions from "../../actions";
import { isEmptyObject } from "../../utils/BillingUtils";
import OnboardingAddressInput from "./OnboardingAddressInput";

class OnboardingBillingAddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = { editMode: this.isAddressEmpty(this.props.address) };
  }

  isAddressEmpty(address) {
    if (!address) {
      return true;
    }
    let isEmpty = isEmptyObject(address, "isFetching");
    return isEmpty;
  }

  renderAddressForm() {
    return (
      <OnboardingAddressInput
        onBillingAddressSave={() => this.setState({ editMode: false })}
        onCancel={() => {
          this.setState({ editMode: false });
        }}
      />
    );
  }

  renderStoredAddress() {
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    return (
      <div>
        <CardBody className="d-flex flex-column justify-content-start align-items-start ">
          <CardTitle style={headStyle}>Billing Address</CardTitle>
          <CardText className="mt-2 mb-1">
            <strong>Billing Admin:</strong>{" "}
            {this.props.address && this.props.address.Name}
          </CardText>

          <CardText>
            {this.props.address && this.props.address.Line1}
            <br />
            {this.props.address && this.props.address.Line2}
            <br />
            {this.props.address && this.props.address.City},{" "}
            {this.props.address && this.props.address.State},{" "}
            {this.props.address && this.props.address.PostalCode}
          </CardText>
        </CardBody>
        <CardBody className="d-flex justify-content-end align-items-end">
          <CardText>
            {this.props.address && (
              <Button
                outline
                className="btn btn-default"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button>
            )}
          </CardText>
        </CardBody>
      </div>
    );
  }

  render() {
    const { handleSubmit, onAddressSubmit, onCancel } = this.props;
    return (
      <div>
        <Card className="w-100">
          <CardBody>
            <CardTitle className="card-title ">Billing Address</CardTitle>
            <p style={{ fontSize: "14px" }}>
              Please enter your billing address:
            </p>
            <Card className="w-100">
              <CardBody>
                {this.state.editMode && this.renderAddressForm()}
                {!this.state.editMode && this.renderStoredAddress()}
              </CardBody>
            </Card>
            <form onSubmit={handleSubmit(onAddressSubmit)}>
              {this.state.editMode && (
                <div className="mt-2 mb-2" style={{ fontSize: "14px" }}>
                  {" "}
                  Please save your billing address to proceed further
                </div>
              )}
              <div className="mt-3 ">
                <Button
                  className="btn btn-default btn-outline-secondary text-left"
                  //style={{ margin: "0px 15px" }}
                  onClick={onCancel}
                >
                  <MdChevronLeft />
                  Prev
                </Button>

                {
                  <Button
                    type="submit"
                    className="btn btn-default btn-outline-secondary text-left"
                    disabled={this.state.editMode}
                  >
                    Next
                    <MdChevronRight />
                  </Button>
                }
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("signUpWizard");

  return {
    address: selector(state, "BillingAddress")
  };
}
export default reduxForm({
  form: "signUpWizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(
  connect(
    mapStateToProps,
    actions
  )(OnboardingBillingAddressForm)
);
