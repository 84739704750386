import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions/onboarding_actions";
import OnboardingPackageSelector from "./OnboardingPackageSelector";

class PackageSelectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {}
    };
    this.handlePackageSelectionSubmit = this.handlePackageSelectionSubmit.bind(
      this
    );
  }
  handlePackageSelectionSubmit(values) {
    const { onPackageSelectionSubmit, handleSubmit } = this.props;
    onPackageSelectionSubmit();
  }

  render() {
    const { onAdminDetailsSubmit, handleSubmit, onCancel } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit(this.handlePackageSelectionSubmit)}>
          <Card className="h-100 w-100">
            <CardBody>
              <CardBody className="d-flex flex-column justify-content-start pb-0">
                <CardTitle className="card-title">Package Selection</CardTitle>
                <p
                  className="card-subtitle mt-1 mb-1"
                  style={{ fontSize: "14px" }}
                >
                  We have plans to suit all your business needs. Choose the one
                  that is right for you below:
                </p>
              </CardBody>

              <Field
                component={OnboardingPackageSelector}
                name="selectedPackage"
                id="selectedPackage"
              />

              <div className="mt-3 mb-3">
                <Button
                  className="btn btn-default btn-outline-secondary text-left"
                  //style={{ margin: "0px 15px" }}
                  onClick={onCancel}
                >
                  <MdChevronLeft />
                  Prev
                </Button>

                {
                  <Button
                    type="submit"
                    className="btn btn-default btn-outline-secondary text-left"
                  >
                    Next
                    <MdChevronRight />
                  </Button>
                }
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(
  withRouter(
    reduxForm({
      form: "signUpWizard",
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      //enableReinitialize: true,
      keepDirtyOnReinitialize: true
    })(PackageSelectionForm)
  )
);
