import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row
} from "reactstrap";
import { reduxForm } from "redux-form";
import * as actions from "../../actions";
import { isEmptyObject } from "../../utils/BillingUtils";
import BillingAddressInput from "./BillingAddressInput";

class BillingAddressForm extends Component {
  constructor(props) {
    super(props);
    this.state = { editMode: this.isAddressEmpty(this.props.address) };
    this.saveAddress = this.saveAddress.bind(this);
  }

  isAddressEmpty(address) {
    let isEmpty = isEmptyObject(address, "isFetching");
    return isEmpty;
  }

  saveAddress(e) {
    console.log("Saving Address:", JSON.stringify(e, null, 4));
  }

  renderAddressForm() {
    return (
      <BillingAddressInput
        // address={this.props.address}
        onBillingAddressSave={() => this.setState({ editMode: false })}
        onCancel={() => this.setState({ editMode: false })}
      />
    );
  }

  renderStoredAddress() {
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    return (
      <div>
        <CardBody className="d-flex flex-column justify-content-start align-items-start ">
          <CardTitle style={headStyle}>Billing Address</CardTitle>
          <CardText className="mt-2 mb-1">
            <strong>Billing Admin:</strong>{" "}
            {this.props.address && this.props.address.Name}
          </CardText>

          <CardText>
            {this.props.address && this.props.address.Line1}
            <br />
            {this.props.address && this.props.address.Line2}
            <br />
            {this.props.address && this.props.address.City},{" "}
            {this.props.address && this.props.address.State},{" "}
            {this.props.address && this.props.address.PostalCode}
          </CardText>
        </CardBody>
        <CardBody className="d-flex justify-content-end align-items-end">
          <CardText>
            {this.props.address && (
              <Button
                outline
                className="btn btn-default"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button>
            )}
          </CardText>
        </CardBody>
      </div>
    );
  }

  render() {
    const { handleSubmit, onAddressSubmit, onCancel } = this.props;
    return (
      <div className="mt-5 pl-5 pr-5">
        <p className="mt-2 mb-2" style={{ fontSize: "14px" }}>
          Please enter/modify your billing address:
        </p>

        <Row className="mb-0">
          <Col lg={6} md={12} sm={12} xs={12} className="mb-0">
            <Card style={{ height: "275px", fontSize: "14px" }}>
              <CardBody>
                {this.state.editMode && this.renderAddressForm()}
                {!this.state.editMode && this.renderStoredAddress()}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <form onSubmit={handleSubmit(onAddressSubmit)}>
          <div className="mt-2 mb-5">
            <Button
              outline
              type="button"
              className="btn btn-default"
              onClick={onCancel}
            >
              <MdChevronLeft />
              Prev
            </Button>

            <Button
              outline
              disabled={this.state.editMode}
              type="submit"
              className="btn btn-default"
            >
              Next
              <MdChevronRight />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

function mapStateToProps({ address }) {
  return {
    address
  };
}
export default reduxForm({
  form: "orderingWizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(
  connect(
    mapStateToProps,
    actions
  )(BillingAddressForm)
);
