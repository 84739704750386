import React, { useEffect } from "react";

import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from "react-bootstrap-table-next";
import LoadingOverlay from "react-loading-overlay"
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { Tooltip, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel } from "@material-ui/core";
import {
    Col,
    Row,
    CardTitle,
    Card,
    CardBody,
    CardSubtitle,
} from "reactstrap";
import {
    AiOutlineEdit,
    AiOutlineDelete,
} from "react-icons-latest/ai";


import {
    showLoader,
    hideLoader,
} from '../actions/loaderActions';
import {
    populatePersonalDataTable,
    updatePersonalDataTableFilter,
    updatePersonalDataTablePaginationTotalCount,
    updatePersonalDataTablePaginationCurrentPage,
    updatePersonalDataTablePaginationPageSize,
    resetPersonalDataTableToInitialState
} from "../actions/dataAgreementActions";
import {
    deletePersonalDataInCloudagent,
    queryPersonalDataInCloudagent
} from "../services/index";
import {
    TableLoader
} from "../components/Loader";


import PersonalDataUpdateModalV2 from "../components/PersonalDataUpdateModalV2";
import GeneralDeleteModalV2 from "../components/GeneralDeleteModalV2";
import Page from "../components/Page";


// FIXME: This is a temporary fix for https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

const PersonalDataPage = (props) => {

    const {
        personalDataTable: {
            personalData = [],
            isTableLoading,
            paginationTotalCount,
            paginationCurrentPage,
            paginationPageSize,
            filter: tableFilter
        }
    } = props;

    const [selectedPersonalData, setSelectedPersonalData] = React.useState({});

    const [isPersonalDataUpdateModalOpen, setIsPersonalDataUpdateModalOpen] = React.useState(false);

    const [isPersonalDataDeleteModalOpen, setIsPersonalDataDeleteModalOpen] = React.useState(false);

    /**
     * Handle page change for table.
     * 
     * @param {*} page 
     * @param {*} pageSize 
     */
    const handlePageChange = (page, pageSize) => {

        // Populate data agreements and personal data table.
        const {
            populatePersonalDataTable,
            updatePersonalDataTablePaginationPageSize,
            updatePersonalDataTablePaginationCurrentPage
        } = props;

        // Set page size.
        updatePersonalDataTablePaginationPageSize(pageSize);

        // Set current page.
        updatePersonalDataTablePaginationCurrentPage(page);

        // Populate data agreements table.
        populatePersonalDataTable();

    }

    /**
     * Triggered when table content changes.
     * 
     * @param {*} type 
     * @param {*} param1 
     */
    const onTableChange = (type, { page, sizePerPage }) => {
        // handle any data change here

        switch (type) {

            case 'pagination':
                handlePageChange(page, sizePerPage);
                break

        }


    }

    /**
     * Update text color to red in DA personal data table columns if data agreement is not published.
     * 
     * @param {*} cell 
     * @param {*} row 
     * @param {*} rowIndex 
     * @param {*} colIndex 
     * @returns 
     */
    const columnStyle = (cell, row, rowIndex, colIndex) => {

        let cellStyle = {
            fontSize: "14px",
            padding: ".35rem",
            borderWidth: "1px solid !important",
            borderColor: "#dee2e6",
        }

        if (!row.data_agreement.publish_flag && colIndex < 3) {
            cellStyle.color = 'red';
        }

        if (colIndex === 2) {
            cellStyle.borderWidth = "0px 0px 1px 1px";
        }

        return cellStyle;
    }

    const headerDispStyle = {
        backgroundColor: "#f0f0f0",
        padding: ".35rem",
        fontWeight: "bold",
        border: "solid",
        borderWidth: "0px 1px 3px 1px",
        borderColor: "#dee2e6",
    };

    const buttonDispStyle = {
        width: "3%",
        backgroundColor: "#f0f0f0",
        padding: ".35rem",
        borderWidth: "0px",
        borderBottomWidth: "3px",
    };

    const colDispStyleDA = {
        fontSize: "14px",
        cursor: "pointer",
        borderWidth: "0px",
        borderBottomWidth: "1px",
        padding: ".35rem",
    };

    const columns = [

        {
            dataField: "attribute_id",
            text: "ID",
            hidden: true
        },
        {
            dataField: "attribute_name",
            text: "Data Attribute Name",
            headerStyle: { ...headerDispStyle, width: "20%" },
            style: columnStyle,
        },
        {
            dataField: "attribute_description",
            text: "Description",
            headerStyle: { ...headerDispStyle, width: "40%" },
            style: columnStyle,
        },
        {
            dataField: "data_agreement.data_agreement_usage_purpose",
            text: "Data Agreement",
            headerStyle: { ...headerDispStyle, borderRightWidth: "0px" },
            style: columnStyle,
        },
        {
            dataField: "Edit",
            text: "",
            headerStyle: buttonDispStyle,
            style: colDispStyleDA,
            formatter: (_, row) => {
                return (

                    <Tooltip placement="top" title={"Edit personal data"}>
                        <AiOutlineEdit

                            size="20px"

                            onClick={() => {

                                setSelectedPersonalData(row);

                                setIsPersonalDataUpdateModalOpen(true);


                            }}

                        />
                    </Tooltip>
                );
            },
        },
        {
            dataField: "Delete",
            text: "",
            headerStyle: buttonDispStyle,
            style: colDispStyleDA,
            formatter: (_, row) => {
                return (
                    <Tooltip placement="top" title={"Delete personal data"}>
                        <AiOutlineDelete

                            size="20px"

                            onClick={() => {

                                setSelectedPersonalData(row);

                                setIsPersonalDataDeleteModalOpen(true);

                            }}
                        />
                    </Tooltip>
                );
            },
        },

    ];

    /**
     * Same as componentDidMount()
     */
    useEffect(() => {

        // Reset personal data table to initial state.
        props.resetPersonalDataTableToInitialState();

        // Set page size.
        props.updatePersonalDataTablePaginationPageSize(paginationPageSize);

        // Set current page.
        props.updatePersonalDataTablePaginationCurrentPage(paginationCurrentPage);

        // Populate personal data table.
        props.populatePersonalDataTable();

    }, []);

    /**
     * Handle personal data table filter changes
     * 
     * @param {*} e 
     */
    const handlePersonalDataTableFilterChange = (e) => {

        const { value } = e.target;

        props.updatePersonalDataTableFilter(value);

        // Populate personal data table.
        props.populatePersonalDataTable();

    }

    /**
     * Toggle personal data update modal
     */
    const toggleUpdatePersonalDataModal = () => {

        setIsPersonalDataUpdateModalOpen(!isPersonalDataUpdateModalOpen);

    }

    /**
     * Toggle personal data delete modal
     */
    const toggleDeletePersonalDataModal = () => {

        setIsPersonalDataDeleteModalOpen(!isPersonalDataDeleteModalOpen);

    }



    return (

        <Page title="" breadcrumbs={[{ name: "Personal Data", active: true }]} className="TablePage">

            <Row>

                <Col>

                    <Card>

                        <CardTitle>

                            <div className="card-table-heading" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: "wrap" }}>


                                <div

                                    style={{
                                        padding: "20px 20px 20px 0px",
                                    }}

                                >Personal Data</div>

                                <div

                                    style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        paddingTop: "10px",
                                    }}

                                >

                                    <div>

                                        <FormControl>

                                            <RadioGroup row aria-labelledby="demo-radio-buttons-group-label" value={props.personalDataTable.filter} name="radio-buttons-group">

                                                <FormControlLabel

                                                    onChange={handlePersonalDataTableFilterChange}

                                                    value="All"

                                                    control={<Radio color="default" />}

                                                    label={<span style={{ fontSize: '14px', fontFamily: 'Helvetica, Helvetica neue' }}>All</span>}

                                                />

                                                <FormControlLabel

                                                    onChange={handlePersonalDataTableFilterChange}

                                                    value="Data Source"

                                                    control={<Radio color="default" />}

                                                    label={<span style={{ fontSize: '14px', fontFamily: 'Helvetica, Helvetica neue' }}>Data Source</span>}

                                                />

                                                <FormControlLabel

                                                    onChange={handlePersonalDataTableFilterChange}

                                                    value="Data Using Service"

                                                    control={<Radio color="default" />}

                                                    label={<span style={{ fontSize: '14px', fontFamily: 'Helvetica, Helvetica neue' }}>Data Using Service</span>}

                                                />

                                            </RadioGroup>

                                        </FormControl>

                                    </div>
                                </div>



                            </div>

                        </CardTitle>

                        <CardSubtitle>

                            <div className="card-table-subheading">Manage the personal data attributes. Personal data attributes can be used for Internal purposes, can be exposed as a Data Source or is consumed as a Data Using Service</div>

                        </CardSubtitle>

                        <CardBody>

                            <BootstrapTable

                                remote={{ pagination: true, filter: false, sort: false }}

                                pagination={
                                    paginationFactory(
                                        {

                                            page: paginationCurrentPage,

                                            sizePerPage: paginationPageSize,

                                            totalSize: paginationTotalCount,

                                            nextPageText: ">",

                                            prePageText: "<",

                                            alwaysShowAllBtns: false,

                                            hideSizePerPage: true,

                                        }
                                    )
                                }

                                loading={isTableLoading}

                                overlay={
                                    overlayFactory(
                                        {
                                            spinner: <TableLoader />,
                                            styles: {
                                                overlay: (base) => (
                                                    {
                                                        ...base,
                                                        background: 'rgba(198, 198, 198, 0.07)'
                                                    }
                                                ),
                                            }
                                        }
                                    )
                                }

                                onTableChange={onTableChange}

                                noDataIndication={
                                    () => {
                                        return !isTableLoading ? <div>No data to display</div> : <div style={{ height: "300px" }}></div>
                                    }
                                }

                                id="btPurpose"

                                data={personalData}

                                bootstrap4={true}

                                keyField="attribute_id"

                                columns={columns}

                                striped={false}

                                hover={true}

                            >
                            </BootstrapTable>

                            <PersonalDataUpdateModalV2

                                isOpen={isPersonalDataUpdateModalOpen}
                                toggleIsOpen={toggleUpdatePersonalDataModal}
                                personalData={selectedPersonalData}

                            />

                            <GeneralDeleteModalV2

                                loaderTag={"GeneralDeleteModalV2ForPersonalData"}

                                requireConfirmText={true}

                                confirmText="DELETE"

                                modalHeaderTitleText={<div className="delete-modal-header-title-div"> Delete Personal Data:  <span>{selectedPersonalData && selectedPersonalData.attribute_name}</span></div>}

                                modelHeaderDescriptionText={selectedPersonalData && selectedPersonalData.attribute_id}

                                modalDescriptionText={<p> You are about to delete an existing personal data. Please type <span style={{ fontWeight: "bold" }}>DELETE</span> to confirm and click OK. This action is not reversible.</p>}

                                deleteCallBack={() => {

                                    const { showLoader, hideLoader } = props;

                                    showLoader("GeneralDeleteModalV2ForPersonalData");

                                    // Perform delete personal data and reload the personal data table.
                                    deletePersonalDataInCloudagent(selectedPersonalData.attribute_id).then((res) => {

                                        const { populatePersonalDataTable } = props;

                                        // Reload personal data table.
                                        populatePersonalDataTable();

                                        // Hide the loader modal.
                                        hideLoader("GeneralDeleteModalV2ForPersonalData");

                                        // Close the delete modal.
                                        toggleDeletePersonalDataModal();

                                    }).catch(err => {

                                        console.log(err);

                                        // Hide the loader modal.
                                        hideLoader("GeneralDeleteModalV2ForPersonalData");

                                        // Close the delete modal.
                                        toggleDeletePersonalDataModal();

                                    })

                                }}

                                toggleState={isPersonalDataDeleteModalOpen}

                                toggle={toggleDeletePersonalDataModal}

                            />

                        </CardBody>

                    </Card>

                </Col>

            </Row>


        </Page>

    )

}

PersonalDataPage.propTypes = {

};

PersonalDataPage.defaultProps = {

};


const mapStateToProps = (state) => {
    return {
        personalDataTable: state.PersonalDataTable,
    };
}

export default connect(
    mapStateToProps,
    {
        populatePersonalDataTable,
        updatePersonalDataTableFilter,
        showLoader,
        hideLoader,
        updatePersonalDataTablePaginationTotalCount,
        updatePersonalDataTablePaginationCurrentPage,
        updatePersonalDataTablePaginationPageSize,
        resetPersonalDataTableToInitialState
    }
)(PersonalDataPage);