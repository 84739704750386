import React from "react";
import PasswordShowHide from "./PasswordShowHide";

export default ({ input, meta: { touched, error, warning } }) => {
  const style =
    touched && error
      ? { fontSize: "14px", border: "1px solid red" }
      : { fontSize: "14px" };
  return (
    <div>
      <PasswordShowHide
        style={style}
        placeholder="Password"
        value={input.value}
        onChange={input.onChange}
        password={input.value}
      />

      {/* {touched &&
        ((error && (
          <span style={{ color: "red", fontSize: "12px" }}>{error}</span>
        )) ||
        (warning && <span>{warning}</span>))}*/}
    </div>
  );
};
