import {
    TOGGLE_LOADER,
    SHOW_LOADER,
    HIDE_LOADER,
} from "../actions/action_types";


// loader init state.
export const loaderState = {
    isLoading: false,
    loaderTag: "",
    loaderText: "",
}


// loader reducer.
export const LoaderStateReducer = (state = loaderState, action) => {
    switch (action.type) {

        // Toggle loader.
        case TOGGLE_LOADER:
            return {
                ...state,
                isLoading: !state.isLoading, // toggle loader
                loaderTag: action.payload.loaderTag, // loader tag
                loaderText: action.payload.loaderText, // loader text
            }

        // Show loader.
        case SHOW_LOADER:

            return {
                ...state,
                isLoading: true, // show loader
                loaderTag: action.payload.loaderTag, // loader tag
                loaderText: action.payload.loaderText, // loader text
            }

        // Hide loader.
        case HIDE_LOADER:
            return {
                ...state,
                isLoading: false, // hide loader
                loaderTag: action.payload.loaderTag, // loader tag
            }
        default:
            return state;
    }
}