import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import {
  Card,
  Col,
  Row,
  Modal,
  CardImg,
  ModalFooter,
} from "reactstrap";
import Select from 'react-select';
import PropTypes from 'prop-types';

import {
  fetchOrgCoverImageFromLocalStorage,
  fetchOrgLogoImageFromLocalStorage,
  fetchOrgsFromLocalStorage
} from "../utils/localStorageUtils";
import {
  fetchOrganisationTypes,
  fetchGlobalDataPolicyConfiguration,
  updateGlobalDataPolicyConfiguration
} from "../services";
import {
  showLoader,
  hideLoader,
} from '../actions/loaderActions'
import Avatar from "./Avatar";
import Loader from "../components/Loader";

const editStyle = {
  padding: 0,
  fontSize: 20,
  fontBold: true,
  fontWeight: 800,
  backgroundColor: "#ffff",
  borderWidth: 1,
  borderRadius: 0,
  borderColor: "#ffff",
  borderBottomStyle: "solid",
  borderBottomColor: "#ffff",
  height: "wrap",
};


const btnSz = {
  height: "1.8rem",
  width: "10rem",
  padding: 0,
  fontSize: "12px",
};

const myStyle = {
  border: "0px",
  backgroundColor: "#ffff",
  padding: "0px",
  margin: "0px",
};

const inputDataConfigStyle = {
  color: "#495057",
  border: "0",
  borderWidth: 0,
  padding: 0,
  paddingLeft: "5px",
  paddingBottom: 0,
  borderRadius: 0,
  fontSize: "14px",
  backgroundColor: "#ffff",
  borderBottomColor: "lightgray", //'#DFE0E1',
  borderBottom: 0,
};

const dropDownStyle = {
  color: "#495057",
  border: 0,
  borderWidth: 0,
  padding: 0,
  paddingLeft: "2px",
  paddingBottom: 0,
  borderRadius: 0,
  fontSize: "14px",
  backgroundColor: "#ffff",
  borderColor: "#ffff", //'#DFE0E1',
};

const editStyleL = {
  padding: 0,
  fontSize: 14,
  fontWeight: 400,
  color: "#6C757D",
  backgroundColor: "#ffff",
  borderWidth: 1,
  borderRadius: 0,
  borderColor: "#ffff",
  borderBottomStyle: "solid",
  borderBottomColor: "#ffff",
};

const textStyle = {
  color: "#212529",
  fontSize: "16px",
  paddingBottom: 1,
};

const headerStyle = {
  fontSize: "16px",
  backgroundColor: "#eeeeee",
  display: "flex",
  alignItems: "center",
};

const GlobalDataPolicyConfigurationModal = ({ isOpen, toggleIsOpen, ...props }) => {

  const [orgCoverImage, setOrgCoverImage] = useState(null);

  const [orgLogoImage, setOrgLogoImage] = useState(null);

  const [orgName, setOrgName] = useState("");

  const [orgLocation, setOrgLocation] = useState("");

  const [orgOverview, setOrgOverview] = useState("");

  const [jurisdiction, setJurisdiction] = useState("");

  const [industryScope, setIndustryScope] = useState("");

  const [policyUrl, setPolicyUrl] = useState("");

  const [dataRetentionPeriod, setDataRetentionPeriod] = useState(0);

  const [geographicRestrictions, setGeographicRestrictions] = useState([
    {
      value: "Europe",
      label: "Europe",
    },
    {
      value: "Not restricted",
      label: "Not restricted",
    }
  ]);

  const [selectedGeographicRestriction, setSelectedGeographicRestriction] = useState("Not restricted");

  const [activateSaveBtn, setActivateSaveBtn] = useState(false);

  const [orgTypes, setOrgTypes] = useState([]);


  /**
   * Monitor changes to data policy configuration form fields.
   */
  useEffect(() => {

    // Validate data policy configuration and activate save button if all fields are valid.
    setActivateSaveBtn(validateDataPolicyConfiguration());

  }, [policyUrl, jurisdiction, selectedGeographicRestriction]);

  /**
   * Triggered on component mount.
   */
  useEffect(() => {


    // Organisation cover image.
    setOrgCoverImage(fetchOrgCoverImageFromLocalStorage());

    // Organisation logo image.
    setOrgLogoImage(fetchOrgLogoImageFromLocalStorage());

    fetchOrganisationTypes().then((response) => {

      const orgTypes = response.data;

      setOrgTypes(orgTypes);

    }).catch((error) => {

      console.log(error);

    });

    fetchGlobalDataPolicyConfiguration().then((response) => {

      const {

        Jurisdiction,

        Type: { ID: orgTypeId },

        PolicyURL,

        Restriction,

        DataRetention: { RetentionPeriod }

      } = response.data;


      setJurisdiction(Jurisdiction);

      setIndustryScope(orgTypeId);

      setPolicyUrl(PolicyURL);

      setSelectedGeographicRestriction(Restriction || "Not restricted");

      if (RetentionPeriod > 0) {

        setDataRetentionPeriod(RetentionPeriod / 31536000);

      }


    }).catch((error) => {

      console.log(error);

    });

    const orgs = fetchOrgsFromLocalStorage();
    if (orgs) {

      const { Name, Location, Description } = orgs;

      setOrgName(Name);

      setOrgLocation(Location);

      setOrgOverview(Description);

    } else {

      console.log("No organisation found.");

      // Providing default values.

      provideDefaultsForOrg();

    }

  }, [isOpen]);

  /**
   * Provide default values for org name, location and overview.
   */
  const provideDefaultsForOrg = () => {

    setOrgName("Happy Shopping Inc.");

    setOrgLocation("Stockholm, SE");

    setOrgOverview("Sample description.");

  }

  /**
   * Validate data policy configuration.
   * 
   * @returns 
   */
  const validateDataPolicyConfiguration = () => {

    let isValid = true;

    if (policyUrl.trim().length === 0) {
      isValid = false;
    }

    if (jurisdiction.trim().length === 0) {
      isValid = false;
    }

    return isValid;

  }

  /**
   * Triggered when data policy configuration fields are changed.
   * 
   * Following fields are monitored:
   * 1. Policy URL
   * 2. Jurisdiction
   * 3. Data retention period
   * 4. Industry scope
   * 
   * @param {*} event 
   */
  const handleChangeConfig = (event) => {

    if (event.target.name === "policyUrl") {

      setPolicyUrl(event.target.value);

    }

    if (event.target.name === "jurisdiction") {

      setJurisdiction(event.target.value);

    }

    if (event.target.name === "dataRetentionPeriod") {

      setDataRetentionPeriod(event.target.value)

    }


    if (event.target.name === "industryScope") {

      setIndustryScope(event.target.value);

    }

  };

  /**
   * Triggered when user selects a geographic restriction.
   * 
   * @param {*} selectedGeographicRestriction 
   */
  const handleGeographicRestrictionChange = (selectedGeographicRestriction) => {

    setSelectedGeographicRestriction(selectedGeographicRestriction.value);

  }

  /**
   * Triggered when user clicks on save button.
   */
  const onSave = () => {

    props.showLoader("GlobalDataPolicyConfigurationModal");

    const dataRetentionPeriodInSeconds = parseInt(dataRetentionPeriod) * 31536000;

    updateGlobalDataPolicyConfiguration(
      policyUrl,
      dataRetentionPeriodInSeconds,
      jurisdiction,
      industryScope,
      selectedGeographicRestriction
    ).then((response) => {

      // Handle success.

      props.hideLoader("GlobalDataPolicyConfigurationModal");

      // Close modal.
      toggleIsOpen();

    }).catch((error) => {

      // Handle error.

      console.log(error);

      props.hideLoader("GlobalDataPolicyConfigurationModal");

      // Close modal.
      toggleIsOpen();

    })

  };

  return (

    <Modal backdrop="static" unmountOnClose={true} isOpen={isOpen} toggle={toggleIsOpen}>

      <Loader loaderTag={"GlobalDataPolicyConfigurationModal"} />

      <div className="modal-content">

        <div className="modal-header" style={headerStyle}>

          <h3 style={{ marginBottom: "0px" }}>Global Data Policy Configurations </h3>

          <button type="button" className="close" aria-label="Close" onClick={toggleIsOpen}>
            <span aria-hidden="true">×</span>
          </button>

        </div>

        <div className="modal-body-without-top-padding" style={{ paddingTop: "0" }}>

          <Row>

            <Card className="flex-row" style={myStyle}>

              <CardImg top src={orgCoverImage} style={{ height: "200px", padding: "0", marginBottom: "20px" }} />

            </Card>

          </Row>

          <Row>

            <Col lg={3} md={6} sm={12} xs={12} className="mb-3" style={{ height: "20px" }}>

              <Card className="flex-row" style={myStyle}>

                <Avatar src={orgLogoImage} style={{

                  position: "absolute",

                  opacity: 1,

                  left: "20px",

                  top: "-95px",

                  width: "130px",

                  height: "130px",

                  border: "solid white 6px",

                  backgroundColor: "white",

                }}

                />

              </Card>

            </Col>

          </Row>

          <Col>

            <Row lg={6} md={6} sm={12} xs={12} style={{ marginTop: "10px" }}>

              <h2 style={editStyle}> {orgName} </h2>

            </Row>

            <Row style={{ marginBottom: "10px" }}>

              <p style={editStyleL}> {orgLocation} </p>

            </Row>

            <Row style={{ marginBottom: "10px" }}>

              <label style={{ ...textStyle, flexBasis: "100%" }}> Overview </label>

              <p style={editStyleL}> {orgOverview} </p>

            </Row>

          </Col>

          <div className="form-group" key="dataPolicyConfiguration">

            <label style={textStyle}> Global Data Policy Configurations </label>

            <table className="table-policy-configuration">

              <tbody>

                <tr>

                  <th className="table-policy-configuration-column" scope="row">Policy URL</th>

                  <td className="table-policy-configuration-column table-policy-configuration-column-right">

                    <input

                      autoComplete="off"

                      className="w-100 pt-0"

                      type="text"

                      style={inputDataConfigStyle}

                      name={"policyUrl"}

                      value={policyUrl}

                      onChange={handleChangeConfig}

                    />

                  </td>

                </tr>

                <tr>

                  <th className="table-policy-configuration-column" scope="row">Jurisdiction</th>

                  <td className="table-policy-configuration-column table-policy-configuration-column-right">

                    <input

                      autoComplete="off"

                      className="w-100 pt-0"

                      type="text"

                      style={inputDataConfigStyle}

                      name={"jurisdiction"}

                      value={jurisdiction}

                      onChange={handleChangeConfig}

                    />

                  </td>

                </tr>

                <tr>

                  <th className="table-policy-configuration-column" scope="row">Industry scope</th>

                  <td className="table-policy-configuration-column table-policy-configuration-column-right">

                    <select

                      className="w-100 pt-0"

                      type="text"

                      style={dropDownStyle}

                      name={"industryScope"}

                      value={industryScope}

                      onChange={handleChangeConfig}

                    >

                      {
                        orgTypes.map((type, i) => {
                          return (

                            <option key={i} value={type.ID}>{type.Type}</option>

                          );
                        })
                      }

                    </select>

                  </td>

                </tr>

                <tr>

                  <th className="table-policy-configuration-column" scope="row">Data retention period in year(s)</th>

                  <td className="table-policy-configuration-column table-policy-configuration-column-right">

                    <input

                      autoComplete="off"

                      className="w-100 pt-0"

                      type="number"

                      style={inputDataConfigStyle}

                      name={"dataRetentionPeriod"}

                      value={dataRetentionPeriod}

                      onChange={handleChangeConfig}
                    />

                  </td>

                </tr>

                <tr>

                  <th className="table-policy-configuration-column" scope="row">Geographic restriction</th>

                  <td className="table-policy-configuration-column table-policy-configuration-column-right">

                    <Select
                      className="w-100 pt-0"
                      type="text"
                      style={dropDownStyle}
                      name={"selectedGeographicRestriction"}
                      options={geographicRestrictions}
                      value={geographicRestrictions.filter((value) => { return value.label.trim() === selectedGeographicRestriction; })}
                      onChange={handleGeographicRestrictionChange}
                    />

                  </td>

                </tr>

              </tbody>

            </table>

          </div>

        </div>

        <ModalFooter className="modal-footer">

          <button style={btnSz} className="btn btn-default" onClick={toggleIsOpen}>Close</button>

          <button

            style={{
              ...btnSz,
              cursor: activateSaveBtn ? "pointer" : "not-allowed"
            }}

            className="btn btn-default"

            onClick={onSave}

            disabled={!activateSaveBtn}

          >Save</button>

        </ModalFooter>

      </div>



    </Modal>

  );
};

GlobalDataPolicyConfigurationModal.propTypes = {
  toggleIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

GlobalDataPolicyConfigurationModal.defaultProps = {
  toggleIsOpen: () => { },
  isOpen: false,
};


const mapStateToProps = (state) => {
  return {};
}

export default connect(
  mapStateToProps,
  {
    showLoader,
    hideLoader,
  }
)(GlobalDataPolicyConfigurationModal);
