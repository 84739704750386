import React, { Component } from "react";
import { MdChevronLeft } from "react-icons/lib/md";
import OtpInput from "react-otp-input";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { formValueSelector, reduxForm } from "redux-form";
import * as actions from "../../actions/onboarding_actions";

class OtpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: "",
      numInputs: 6,
      showResendButton: true,
      showErrorMessage: false
      //   separator: "-",
      //   isDisabled: false,
      //   hasErrored: false,
      //   isInputNum: false
    };
    this.handleOtpSubmit = this.handleOtpSubmit.bind(this);
    this.resendOtp = this.resendOtp.bind(this);
  }

  componentDidMount() {
    this.props.fetchOrgTypes();
  }
  handleOtpChange = otp => {
    this.setState({ showErrorMessage: false, otp });
  };

  async handleOtpSubmit() {
    // e.preventDefault();
    const {
      onOtpSubmit,
      handleSubmit,
      adminPhone,
      verifyPhoneOtp
    } = this.props;
    const { Result } = await verifyPhoneOtp({
      adminPhone: "+" + adminPhone,
      Otp: this.state.otp
    });
    if (Result) {
      onOtpSubmit();
    } else {
      // Invalid Otp
      this.setState({ showErrorMessage: true });
    }
  }

  async resendOtp(e) {
    e.preventDefault();
    const { adminPhone, resendOtp } = this.props;
    this.setState({ showResendButton: false });
    await resendOtp({ adminPhone: "+" + adminPhone });
  }

  render() {
    const { otp, numInputs } = this.state;
    const { handleSubmit, onCancel, newUserValidity } = this.props;
    if (
      newUserValidity &&
      !newUserValidity.isFetching &&
      newUserValidity.errorMessage
    ) {
      return (
        <Card className="w-100">
          <CardBody>
            <div className="mt-5 mb-5">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <p>
                    Your request could not be processed. Please try again later.
                  </p>
                  <p>Error: {newUserValidity.errorMessage}</p>
                  <Button outline  className="btn btn-default  mt-auto" onClick={onCancel} >           
                    OK
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
    return (
      <div>
        <Card className="h-100 w-100">
          <CardBody>
            <CardTitle className="card-title">
              Please verify your mobile number
            </CardTitle>
            <form onSubmit={handleSubmit(this.handleOtpSubmit)}>
              <h2>
                A text message with a 6-digit verification code was just sent to
                your mobile number. Please enter the code below:
              </h2>
              <div
                className="margin-top--small"
                style={{ textAlign: "center" }}
              >
                <div style={{ display: "inline-block" }}>
                  <OtpInput
                    inputStyle={{
                      width: "100%",
                      maxWidth: "3rem",
                      maxHeight: "3rem",
                      padding: "0 0.5rem",
                      margin: "0 0.25rem",
                      fontSize: "2rem",
                      borderRadius: 4,
                      border: "1px solid rgba(0,0,0,0.3)"
                    }}
                    numInputs={numInputs}
                    value={this.state.otp}
                    onChange={this.handleOtpChange}
                    shouldAutoFocus
                  />
                </div>
              </div>
              {this.state.showErrorMessage && (
                <p className="mt-3" style={{ fontSize: "14px", color: "red" }}>
                  Invalid OTP. Please check again
                </p>
              )}
              <div className="mt-3">
                <Button
                  outline
                  className="btn btn-default  text-left"
                  onClick={onCancel}
                >
                  <MdChevronLeft />
                  Prev
                </Button>
                <Button
                  outline
                  type="submit"
                  className="btn btn-default float-right "
                  disabled={otp.length < numInputs}
                >
                  Verify
                </Button>
                {this.state.showResendButton && (
                  <Button
                    outline
                    className="btn btn-default float-right "
                    onClick={this.resendOtp}
                  >
                    Resend
                  </Button>
                )}
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  if (!values["serviceAgreementStatus"]) {
    errors["serviceAgreementStatus"] = "Please agree to the Services Agreement";
  }
  return errors;
}

function mapStateToProps(state) {
  const selector = formValueSelector("signUpWizard");

  return {
    adminPhone: selector(state, "adminPhone"),
    newUserValidity: state.newUserValidity
  };
}

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "signUpWizard",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true // <------ unregister fields on unmount
  })(OtpForm)
);
