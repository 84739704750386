import React from 'react'
import axios from 'axios';
import {GetUserDetails,} from './Sources';
import UserImage from '../assets/img/products/camera_photo.png'; 
import jsonData from '../setting/settings.json';
import { store } from '../services/localStorageStore'

class UserImageUpload extends React.Component {

    constructor(props) {
      super(props);
        this.state ={
            file:null,
            userImg: UserImage,
            done:false,
        }
        this.mystate = {file: '',imagePreviewUrl: ''};
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    onFormSubmit(e){
        e.preventDefault()  
        this.fileUpload(this.state.file)
    }

    onChange(e) {
        e.preventDefault();
        this.setState({file:e.target.files[0]});
        this.mystate.file=e.target.files[0] ;
       
        let reader = new FileReader();
        let file = e.target.files[0];
        let imageType = /image.jpeg/;

        reader.onloadend = () => {
            this.mystate.file= file;
            this.mystate.imagePreviewUrl= reader.result;
            this.state.userImg= reader.result;
            this.state.userImg= reader.result;
            this.setState({userImg : reader.result });
            
        } 
  
    if (file.type.match(imageType))
    //if (file) 
    {   
         reader.readAsDataURL(file) ;
         this.fileUpload(file);
         this.setState({done:true}) ;
    }
    else
    {
        this.setState({done:false}) ;
    }
}

fileUpload(file){
    //https://staging-api.igrant.io/v1/user/image
  const url=    JSON.parse(JSON.stringify(jsonData)).api +'v1/user/image' ;
  const formData = new FormData();
  formData.append('userimage',file);
  const config = {
      headers: {
          'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
          'content-type': 'multipart/form-data',
      }
  };
     axios.post(url, formData, config)
    .then((response) => {   
        GetUserDetails(); 
       // window.location.reload();
    }); 
    return ;// axios.post(url, formData,config);
  
}

render() {
    const normalStyle = {
        position: "relative", top: "-2px",borderRadius:50,opacity: 0.45,width:'100px',height:'100px',
    }
    const doneStyle = {
        position: "relative", top: "-2px",borderRadius:50,opacity: 0.55,width:'100px',height:'100px',
    }
    return (
     <div>
      <form onSubmit={this.onFormSubmit}   >
           <label className="uptext" htmlFor="browseImg"><img style={this.state.done ? doneStyle : normalStyle} src={this.state.userImg} /></label>
           <input type="file" accept="image/jpeg,image/jpg" id="browseImg" name="browseImg" hidden={true} onChange={this.onChange}/>
      </form>
    </div>
   )
 }
}

export default UserImageUpload;
