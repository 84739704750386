import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import * as onboardingactions from "../../actions/onboarding_actions";

const renderError = ({ meta: { touched, error } }) =>
  touched && error ? (
    <span
      style={{
        fontSize: "12px",
        color: "red"
      }}
    >
      {error}
    </span>
  ) : (
    <div>
      <br />
    </div>
  );

class OnboardingServiceAgreementForm extends Component {
  constructor(props) {
    super(props);
    this.handleRegisterRequest = this.handleRegisterRequest.bind(this);
  }
  componentDidMount() {
    this.props.fetchServiceAgreementInfo();
  }

  async handleRegisterRequest(values) {
    let serviceAgreementVersion = this.props.serviceAgreement.ServiceAgreementInfo.version;
    await this.props.registerOrg({ ...values, serviceAgreementVersion });
    this.props.reset();
  }
  render() {
    const {
      onAgreementSubmit,
      onCancel,
      handleSubmit,
      serviceAgreement,
      organization,
      address,
      cardDetails
    } = this.props;
    let urlSource = null;
    if (serviceAgreement && !serviceAgreement.isFetching) {
      urlSource = `${this.props.serviceAgreement.ServiceAgreementInfo.url}?embedded=true`;
    }
    if (organization && organization.isFetching) {
      return (
        <Card className="h-100 w-100">
          <CardBody>
            <div className="mt-5 mb-5">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <Loader
                    type="ThreeDots"
                    color="#6a7075"
                    height="100"
                    width="100"
                  />
                </div>
                <div>
                  <p>Please wait while we process your information...</p>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
    if (organization && !organization.isFetching && organization.errorMessage) {
      let errorDescription = "";
      if (
        typeof organization.errorMessage === "string" ||
        organization.errorMessage instanceof String
      ) {
        errorDescription = organization.errorMessage;
      }
      //   else if (organization.errorMessage["error_description"]) {
      //     errorDescription = organization.errorMessage["error_description"];
      //   }
      else {
        const {
          response: { config: { method } } = {
            config: { name: "Unknown type" }
          }
        } = organization.errorMessage;
        const {
          response: { config: { url } } = {
            config: { name: "Unknown url" }
          }
        } = organization.errorMessage;

        const {
          response: { status } = { status: "Unknown status code" }
        } = organization.errorMessage;
        const {
          response: { statusText } = { status: "Unknown status text" }
        } = organization.errorMessage;

        const methodType = method.toUpperCase();
        errorDescription = `Failed ${methodType} request to ${url} with reason: ${statusText} (ErrorCode: ${status})`;
      }

      return (
        <Card className="h-100 w-100">
          <CardBody>
            <div className="mt-5 mb-5">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <p>
                    Your request could not be processed. Please try again later.
                  </p>
                  <p>Error Description: {errorDescription}</p>
                  <Button
                    outline
                    tag={Link}
                    to={{
                      pathname: "/"
                    }}
                    className="btn btn-default h-100 mt-auto"
                  >
                    OK
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }
    if (organization && !organization.isFetching) {
      return (
        <Card className="h-100 w-100">
          <CardBody>
            <div className="mt-5 mb-5">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <div>
                  <p>
                    Your organization is successfully enrolled in our system.
                    Please login to continue.
                  </p>
                  <Button
                    outline
                    tag={Link}
                    to={{
                      pathname: "/"
                    }}
                    className="btn btn-default mt-auto"
                  >
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      );
    }

    return (
      <div>
        <Card className="h-100 w-100">
          <CardBody>
            <CardTitle className="card-title mb-3">Service Agreement</CardTitle>

            <p className="mt-2 mb-2" style={{ fontSize: "14px" }}>
              Kindly acknowledge and agree to our Service Agreement:
            </p>
            <div>
              {urlSource && (
                <iframe
                  style={{ width: "100%", height: "200px" }}
                  src={urlSource}
                />
              )}
            </div>
            <form onSubmit={handleSubmit(this.handleRegisterRequest)}>
              <div>
                <div>
                  <Field
                    name="serviceAgreementStatus"
                    id="serviceAgreementStatus"
                    component="input"
                    type="checkbox"
                  />
                  <span className="mt-2 mb-2" style={{ fontSize: "14px" }}>
                    {" "}
                    By clicking this you agree to the Service Agreement
                  </span>
                  <div>
                    <Field
                      name="serviceAgreementStatus"
                      component={renderError}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <Button
                  className="btn btn-default btn-outline-secondary text-left"
                  //style={{ margin: "0px 15px" }}
                  onClick={onCancel}
                >
                  <MdChevronLeft />
                  Prev
                </Button>

                {
                  <Button
                    type="submit"
                    className="btn btn-default btn-outline-secondary text-left"
                  >
                    Submit
                    <MdChevronRight />
                  </Button>
                }
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  if (!values["serviceAgreementStatus"]) {
    errors["serviceAgreementStatus"] = "Please agree to the Services Agreement";
  }
  return errors;
}

function mapStateToProps({
  serviceAgreement,
  organization,
  address,
  cardDetails
}) {
  return {
    serviceAgreement,
    organization,
    address,
    cardDetails
  };
}

export default reduxForm({
  form: "signUpWizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(
  connect(
    mapStateToProps,
    { ...actions, ...onboardingactions }
  )(OnboardingServiceAgreementForm)
);
