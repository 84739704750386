import Content  from './Content';
import React from 'react';
import bgImage from '../../assets/img/bg/Business_Login.jpg';

const  pageStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: -1000,
    backgroundImage: `url("${bgImage}")`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
};

const footerStyle = {
    flex: 1,
    justifyContent: "right",
    alignItems: "right",
    color :'white', fontFamily: "sans-serif",
    fontSize: "0.8rem",
    textAlign: "right",
    marginBottom:'12px' ,
    marginRight:'12px',
     
};
const inputStyle = {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    color :'white', fontFamily: "sans-serif",
    fontSize: "0.8rem",
    textAlign: "center", marginBottom:'22px' ,
     
};

function PFooter() {
    return   <div  style={footerStyle} >
               <a href="https://igrant.io/privacy.html#cookies" target="_blank" rel="noopener noreferrer" style={inputStyle} >Cookies Policy</a> &nbsp;| &nbsp;<a href="https://igrant.io/terms.html" target="_blank" rel="noopener noreferrer" style={inputStyle} >Terms of Service</a> &nbsp;| &nbsp;
               <a href="https://igrant.io/privacy.html#privacy" target="_blank" rel="noopener noreferrer" style={inputStyle} >Privacy Policy</a> 
               &nbsp;&nbsp;&nbsp;&nbsp;&copy;&nbsp;2017-{new Date().getFullYear()} LCubed AB, Sweden 
              </div>;
}
const EmptyLayout = ({ children, ...restProps }) => (
  <main className="cr-app bg-light fixed-top"  {...restProps} style={pageStyle}>
    <Content fluid  >
      {children} 
      <PFooter />
    </Content>
  </main>
);

export default EmptyLayout;
