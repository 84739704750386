import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
  CardHeader,
  CardTitle
} from "reactstrap";
import "../../styles/components/package_selection.css";

const renderError = ({ meta: { touched, error } }) =>
  touched && error ? (
    <span
      style={{
        fontSize: "12px",
        color: "red"
      }}
    >
      {error}
    </span>
  ) : (
    <div>
      <br />
    </div>
  );

class OnboardingPackageSelector extends Component {
  constructor(props) {
    super(props);
  }

  onRadioBtnClick(selectedPackage) {
    const {
      input: { onChange }
    } = this.props;
    onChange(selectedPackage);
  }

  renderFooter() {
    const {
      input: { value }
    } = this.props;
    if (value === "freetrial") {
      return (
        <p style={{ fontSize: "14px" }}>
          <strong>Note:</strong> Plan will be automatically upgraded to starter
          package at the end of the trial period. However, you can choose to
          cancel anytime.
        </p>
      );
    }
    return null;
  }

  render() {
    const {
      input: { value }
    } = this.props;
    if (!this.props.pricingInfo) {
      return null;
    }
    var disablePayPerUser =
      process.env.REACT_APP_DISABLE_PAY_PER_USER === "true";

    return (
      <div>
        <CardBody className="pt-1 pb-0">
          <CardDeck className="packageselector">
            <Card
              className={
                "px-3 text-center " +
                (value === "freetrial" ? "activepackage" : "")
              }
            >
              <CardHeader className="h5">TRIAL</CardHeader>
              <CardTitle className="mt-5">
                {" "}
                {this.props.pricingInfo.freeTrial.price > 0
                  ? this.props.pricingInfo.freeTrial.price
                  : "FREE"}
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    Valid for {this.props.pricingInfo.freeTrial.validity}{" "}
                    {this.props.pricingInfo.freeTrial.validity > 1
                      ? "days"
                      : "day"}
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.freeTrial.usageLimit.users}{" "}
                    users
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.freeTrial.usageLimit.api} API
                    calls
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                {/*this.renderFreeTrialFooter()*/}
                <div className="mx-2 my-2">
                  {value === "freetrial" ? (
                    <strong>SELECTED</strong>
                  ) : (
                    <Button
                      outline
                      className="btn btn-default w-100"
                      onClick={() => this.onRadioBtnClick("freetrial")}
                    >
                      SELECT
                    </Button>
                  )}
                </div>
              </CardFooter>
            </Card>

            <Card
              className={
                "px-3 text-center " +
                (value === "starter" ? "activepackage" : "")
              }
            >
              <CardHeader className="h5">STARTER</CardHeader>
              <CardTitle className="mt-5">
                SEK{" "}
                <span className="price">
                  {this.props.pricingInfo.starter.price}
                </span>
                <span
                  className="h6 text-muted ml-2"
                  style={{ fontSize: "12px" }}
                >
                  / year
                </span>
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    Upto {this.props.pricingInfo.starter.usageLimit.users} users
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.starter.usageLimit.api} API
                    calls
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                <div className="mx-2 my-2">
                  {value === "starter" ? (
                    <strong>SELECTED</strong>
                  ) : (
                    <Button
                      outline
                      className="btn btn-default w-100"
                      onClick={() => this.onRadioBtnClick("starter")}
                    >
                      SELECT
                    </Button>
                  )}
                </div>
              </CardFooter>
            </Card>
            <div className="w-100 mt-2 d-none d-md-block d-lg-none" />

            <Card
              className={
                "px-3 text-center " +
                (value === "payperuser" ? "activepackage" : "")
              }
            >
              <CardHeader className="h5">PAY-PER-USER</CardHeader>
              <CardTitle className="mt-5">
                SEK{" "}
                <span className="price">
                  {this.props.pricingInfo.payPerUser.basePrice}*
                </span>
                <span
                  className="h6 text-muted ml-2"
                  style={{ fontSize: "12px" }}
                >
                  / per user per year
                </span>
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    {this.props.pricingInfo.payPerUser.usageLimit.users ===
                    "UNLIMITED"
                      ? "UNLIMITED users"
                      : "Upto " +
                        this.props.pricingInfo.payPerUser.usageLimit.users +
                        " users"}
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.payPerUser.usageLimit.api} API
                    calls
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                <div className="mx-2 my-2">
                  {value === "payperuser" ? (
                    <strong>SELECTED</strong>
                  ) : (
                    <Button
                      outline
                      disabled={disablePayPerUser}
                      className="btn btn-default w-100"
                      onClick={() => this.onRadioBtnClick("payperuser")}
                    >
                      SELECT
                    </Button>
                  )}
                </div>
              </CardFooter>
            </Card>
          </CardDeck>
          <div>
            <p
              className="py-2 mt-2 mb-0 text-right"
              style={{ fontSize: "11px", fontStyle: "italic" }}
            >
              *Further discounts based on committed usage
            </p>
          </div>{" "}
          {this.renderFooter()}
        </CardBody>
      </div>
    );
  }
}
function mapStateToProps({ pricingInfo }) {
  return {
    pricingInfo
  };
}

export default connect(
  mapStateToProps,
  null
)(OnboardingPackageSelector);
