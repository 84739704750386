import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import AddressField from "../billing/AddressField";

class OnboardingAddressInput extends Component {
  constructor(props) {
    super(props);
    this.saveAddress = this.saveAddress.bind(this);
  }
  async saveAddress(address) {
    this.props.onBillingAddressSave();
  }

  // Validation functions
  isRequiredField = value => {
    return value || typeof value === "number" ? undefined : "Required";
  };

  renderAddressForm(handleSubmit) {
    return (
      <Form onSubmit={handleSubmit(this.saveAddress)}>
        <Field
          type="text"
          component={AddressField}
          name="BillingAddress.Name"
          //id="Name"
          validate={this.isRequiredField}
          placeholder="Admin Name"
        />
        <div className="mt-1">
          <Field
            type="text"
            component={AddressField}
            name="BillingAddress.Line1"
            //id="Line1"
            validate={this.isRequiredField}
            placeholder="Address Line 1"
          />
        </div>
        <div className="mt-1">
          <Field
            type="text"
            component={AddressField}
            name="BillingAddress.Line2"
            //id="Line2"
            placeholder="Address Line 2"
          />
        </div>

        <Row className="mt-1">
          <Col md={6} className="mb-0">
            <Field
              type="text"
              component={AddressField}
              name="BillingAddress.City"
              // id="City"
              validate={this.isRequiredField}
              placeholder="City"
            />
          </Col>
          <Col md={6} className="mb-0">
            <Field
              type="text"
              component={AddressField}
              name="BillingAddress.State"
              //id="State"
              validate={this.isRequiredField}
              placeholder="State"
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={6}>
            <Field
              type="text"
              component={AddressField}
              name="BillingAddress.Country"
              //id="Country"
              validate={this.isRequiredField}
              placeholder="Country"
            />
          </Col>
          <Col md={6}>
            <Field
              type="text"
              component={AddressField}
              name="BillingAddress.PostalCode"
              //id="PostalCode"
              validate={this.isRequiredField}
              placeholder="PostalCode"
            />
          </Col>
        </Row>
        <div className="text-right">
          <Button outline type="submit" className="btn btn-default">
            Save
          </Button>
        </div>
      </Form>
    );
  }

  render() {
    const { handleSubmit, onAddressSubmit, onCancel } = this.props;
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    return this.renderAddressForm(handleSubmit);
  }
}

export default reduxForm({
  form: "signUpWizard", //Form name is same
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(
  connect(
    null,
    actions
  )(OnboardingAddressInput)
);
