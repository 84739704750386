import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { Button } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";

const renderError = ({ meta: { touched, error } }) =>
  touched && error ? (
    <span
      style={{
        fontSize: "12px",
        color: "red"
      }}
    >
      {error}
    </span>
  ) : (
    <div>
      <br />
    </div>
  );

class ServiceAgreementForm extends Component {
  componentDidMount() {
    this.props.fetchServiceAgreementInfo();
  }
  render() {
    const {
      onAgreementSubmit,
      onCancel,
      handleSubmit,
      serviceAgreement,
      type
    } = this.props;
    let urlSource = null;
    if (serviceAgreement && !serviceAgreement.isFetching) {
      urlSource = `${this.props.serviceAgreement[type].url}?embedded=true`;
    }

    return (
      <div className="mt-5 pl-5 pr-5">
        <p className="mt-2 mb-2" style={{ fontSize: "14px" }}>
          Kindly acknowledge and agree to our Service Agreement:
        </p>
        <div>
          {urlSource && (
            <iframe
              style={{ width: "100%", height: "200px" }}
              src={urlSource}
            />
          )}
        </div>
        <form onSubmit={handleSubmit(onAgreementSubmit)}>
          <div>
            <div>
              <Field
                name="serviceAgreementStatus"
                id="serviceAgreementStatus"
                component="input"
                type="checkbox"
              />
              <span className="mt-2 mb-2" style={{ fontSize: "14px" }}>
                {" "}
                By clicking this you agree to the Service Agreement
              </span>
              <div>
                <Field name="serviceAgreementStatus" component={renderError} />
              </div>
            </div>
          </div>
          <div className="mt-4 mb-5">
            {onCancel && (
              <Button
                outline
                type="button"
                className="btn btn-default"
                onClick={onCancel}
              >
                <MdChevronLeft />
                Prev
              </Button>
            )}

            <Button outline type="submit" className="btn btn-default">
              Next
              <MdChevronRight />
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

function validate(values, props) {
  const errors = {};
  if (!values["serviceAgreementStatus"]) {
    errors["serviceAgreementStatus"] = "Please agree to the Services Agreement";
  }
  return errors;
}

function mapStateToProps({ serviceAgreement }) {
  return {
    serviceAgreement
  };
}

export default reduxForm({
  form: "orderingWizard",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(
  connect(
    mapStateToProps,
    actions
  )(ServiceAgreementForm)
);
