import React, { useRef, useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
    updatePersonalDataInCloudagent,
} from "../services/index";
import {
    showLoader,
    hideLoader,
} from '../actions/loaderActions';
import {
    populatePersonalDataTable,
} from '../actions/dataAgreementActions';

import Loader from "./Loader";

const PersonalDataUpdateModalV2 = (props) => {

    const attributeDescriptionInputElement = useRef(null);

    const { isOpen, toggleIsOpen, personalData } = props;

    const [
        {
            attribute_name: attributeName = "",
            attribute_description: attributeDescription = "",
            attribute_id: attributeId = "",
        },
        setPersonalData
    ] = useState({ ...personalData });

    /**
     * Listen for changes to personal data.
     */
    useEffect(() => {

        setPersonalData({ ...personalData });

    }, [personalData]);

    const headerStyle = {
        fontSize: "16px",
        backgroundColor: "#eeeeee",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
    };

    const modalHeaderPersonalDataIdStyle = {
        fontStyle: "italic",
        color: "gray",
    };

    const footerButtonStyle = {
        height: "1.8rem",
        width: "10rem",
        padding: 0,
        fontSize: "12px",
    };

    const textStyle = {
        color: '#212529',
        fontSize: '16px',
        paddingBottom: 1,
    };

    const inputStyle = {
        height: "24px",
        color: "#495057",
        border: "1",
        borderWidth: 0,
        padding: 0,
        paddingLeft: "2px",
        paddingBottom: 0,
        borderRadius: 0,
        fontSize: "16px",
        backgroundColor: "#ffff",
        borderBottomWidth: 1.2,
        borderBottomColor: "lightgray", //'#DFE0E1',
    };

    /**
     * Triggered when attribute description input changes.
     * 
     * @param {*} event 
     */
    const handleChange = (event) => {

        const { name, value } = event.target;

        switch (name) {

            case "attributeDescription":

                setPersonalData((prevState) => (
                    {
                        ...prevState,
                        attribute_description: value
                    }
                ));

                break;

        }


    }

    /**
     * Triggered when the user clicks the save button.
     */
    const handleSaveButton = () => {

        // Show the loader.
        props.showLoader("PersonalDataUpdateModalV2");

        updatePersonalDataInCloudagent(attributeId, attributeDescription).then((response) => {

            // Hide the loader.
            props.hideLoader("PersonalDataUpdateModalV2");

            // Close the modal.
            toggleIsOpen();

            // Populate personal data table to reflect updated values.
            props.populatePersonalDataTable();


        }).catch((error) => {

            // Handle error.

            console.log(error);

            // Hide the loader.
            props.hideLoader("PersonalDataUpdateModalV2");

            // Close the modal.
            toggleIsOpen();

        });

    }

    /**
     * Triggered when modal is closed.
     */
    const modalTearDown = () => {

        // Reset the state to the initial state.
        setPersonalData({ ...personalData });

    }

    return (



        <Modal isOpen={isOpen} onClosed={modalTearDown}>

            <Loader loaderTag={"PersonalDataUpdateModalV2"} />

            <div className="modal-content">

                <div className="modal-header" style={headerStyle}>

                    <h5 className="modal-title">Edit Personal Data: {attributeName}</h5>

                    <button type="button" className="close" aria-label="Close" onClick={toggleIsOpen}><span aria-hidden="true">×</span></button>

                    <p style={modalHeaderPersonalDataIdStyle}>{attributeId}</p>

                </div>

                <ModalBody>

                    <div className='form'>

                        <div className='form-group' key="Description">

                            <label style={textStyle}>
                                Attribute Description
                                <span className="required-field">
                                    *
                                </span>
                            </label>

                            <input

                                autoComplete="off"

                                className='w-100 pt-0'

                                placeholder="Please type atleast 3 characters..."

                                ref={attributeDescriptionInputElement}

                                type='text'

                                style={{
                                    ...inputStyle
                                }}

                                name="attributeDescription"

                                value={attributeDescription}

                                onChange={handleChange}

                            />

                        </div>

                    </div>

                </ModalBody>

                <ModalFooter>

                    <button

                        style={{ ...footerButtonStyle }}

                        size="ig"

                        className="btn btn-default"

                        onClick={toggleIsOpen}

                    >
                        Close
                    </button>

                    <button

                        style={{ ...footerButtonStyle, cursor: attributeDescription.length === 0 ? "not-allowed" : "pointer" }}

                        className="btn btn-default"

                        disabled={attributeDescription.length === 0}

                        onClick={handleSaveButton}

                    >
                        Save
                    </button>

                </ModalFooter>
            </div>



        </Modal>

    );

}


PersonalDataUpdateModalV2.propTypes = {

    isOpen: PropTypes.bool.isRequired,
    personalData: PropTypes.object.isRequired,

};

PersonalDataUpdateModalV2.defaultProps = {

    isOpen: true,
    personalData: {
        attribute_name: "Age",
        attribute_description: "Age of the individual",
        attribute_id: "b9adb9c6-5ff0-4a76-9728-f8ed39cbcead"
    }

};

const mapStateToProps = (state) => {
    return {};
}

export default connect(
    mapStateToProps,
    {
        showLoader,
        hideLoader,
        populatePersonalDataTable
    }
)(PersonalDataUpdateModalV2);