import React, { useState } from "react";
import {
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FaAngleLeft } from "react-icons/lib/fa";


import { connect } from 'react-redux';
import {
  initDataSourceConfiguration,
  updateDataSourceConfiguration,
  toggleConfigureDataSourcePanel,
  addPersonalDataInDataAgreementState,
  deletePersonalDataInDataAgreementStateAction,
  replaceAllPersonalData
} from '../actions/dataAgreementActions';
import {
  showLoader,
  hideLoader,
} from '../actions/loaderActions'
import {
  fetchCloudAgentConfigForOrg,
  fetchAttributeForSchemaFromLedger
} from '../services/index';
import { Tooltip } from "@material-ui/core";

const AddCredentialModalV2 = (props) => {

  const [backButtonToolTip, setBackButtonToolTip] = useState("");
  const [personalDataPreview, setPersonalDataPreview] = useState([]);

  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
  };

  const radioStyle = {
    paddingBottom: 1,
    margin: 4,
  };

  const inputStyle = {
    color: "#495057",
    border: "1",
    borderWidth: 0,
    padding: 0,
    paddingBottom: 1,
    borderRadius: 0,
    fontSize: "16px",
    borderBottomWidth: 1.2,
    borderBottomColor: "lightgray", //'#DFE0E1',
  };

  const textStyle = {
    color: "#212529",
    fontSize: "16px",
    paddingBottom: 1,
  };

  const attributePreviewContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "30px 0px"
  };

  const attributePreviewTabletBgStyle = {
    background: "rgb(229, 229, 229)",
    borderRadius: "20px",
    color: "black",
    textAlignLast: "center",
    fontSize: "14px",
    padding: "7px"
  };

  /**
   * Triggered when schema mode is changed.
   * 
   * @param {*} schemaMode 
   */
  const selectSchemaMode = (schemaMode) => {

    if (schemaMode === "Existing") {

      props.updateDataSourceConfiguration({
        isExisting: true,
      })

    } else {

      // Reset back button tooltip message since schema mode is changed to "New".
      setBackButtonToolTip("");

      props.updateDataSourceConfiguration({
        isExisting: false,
        schemaId: "",
      })

    }

  };

  /**
   * Update existing schema in global state when changes are made.
   * 
   * @param {*} event 
   */
  const handleExistingSchemaChange = (event) => {

    const { schemaId: previousSchemaId } = props.configureDataSource;
    const currentSchemaId = event.target.value;

    if (currentSchemaId.trim().length > 0) {

      selectSchemaMode("Existing");

    } else {

      selectSchemaMode("New");

      setPersonalDataPreview([]);

    }

    if (previousSchemaId.trim() !== currentSchemaId.trim()) {
      // Mark that attributes needs to populated again.
      props.updateDataSourceConfiguration({ isAttributesPopulatedInDataAgreement: false });
    }

    props.updateDataSourceConfiguration({ schemaId: currentSchemaId });
  }

  /**
   * Triggered when back button is clicked.
   */
  const handleBackButton = () => {

    const { schemaId, isExisting } = props.configureDataSource;

    const { isAttributesPopulatedInDataAgreement } = props.configureDataSource;

    if (isExisting) {

      // Execute if attributes from existing schemaId is not populated in data agreement state.
      if (!isAttributesPopulatedInDataAgreement) {

        if (schemaId.trim().length > 0) {

          // Show loader if existing schema id is provided (or chosen)
          props.showLoader("AddCredentialModalV2", "");


          // Obtain cloudagent admin endpoint for the selected org.
          fetchCloudAgentConfigForOrg().then((response) => {

            const resp = response.data;

            if (resp.Status === 2) {

              // Fetch attributes for schema id provided.
              fetchAttributeForSchemaFromLedger(props.configureDataSource.schemaId, resp.AdminEndpoint).then((response) => {
                const resp = response.data;

                const schemaAttributesArray = resp.schema.attrNames;

                // Data agreement state from redux.
                let dataAgreementState = props.dataAgreementState;

                let toBeReplacedPds = []

                // Iterate through attributes in schema and create data agreement personal data object.
                for (let i = 0; i < schemaAttributesArray.length; i++) {
                  // Empty personal data.
                  let pd = {
                    attributeName: schemaAttributesArray[i],
                    attributeDescription: "",
                  }

                  // If method of use is data-using-service
                  // then add the new personal data with restrictions.
                  if (dataAgreementState.methodOfUse === "data-using-service") {
                    pd["restrictions"] = [
                      {
                        "schema_id": "",
                        "cred_def_id": "",
                      }
                    ];
                  }

                  toBeReplacedPds.push(pd);
                }

                // Add new personal data to the data agreement.
                props.replaceAllPersonalData(toBeReplacedPds);

                // Toggle data source panel to go back to the previous panel.
                props.toggleConfigureDataSourcePanel();

                // Hide the loader
                props.hideLoader("AddCredentialModalV2");

                // Mark that attributes are populated in data agreement state.
                props.updateDataSourceConfiguration({ isAttributesPopulatedInDataAgreement: true });


              }).catch((err) => {

                // TODO: Show toast - Failed to fetch attributes for schema id from ledger.

                // Back button is disabled.
                setBackButtonToolTip("Please provide a valid schema id.");

                // Hide the loader
                props.hideLoader("AddCredentialModalV2");

              });




            } else {

              // TODO: Show toast - cloudagent admin endpoint not found.

              // Hide the loader
              props.hideLoader("AddCredentialModalV2");

            }

          }).catch((err) => {

            // TODO: Show toast - why cloudagent endpoint could not be obtained.
            // TODO: Hide both create data agreement and configure data source panel.

            // Hide the loader
            props.hideLoader("AddCredentialModalV2");

          })


        } else {

          // Back button is disabled.
          setBackButtonToolTip("Please provide a valid schema id.");

        }


      } else {
        // Toggle data source panel to go back to the previous panel.
        props.toggleConfigureDataSourcePanel();
      }
    } else {

      // Toggle data source panel to go back to the previous panel.
      props.toggleConfigureDataSourcePanel();

    }


  };

  const handleEnterKeyPressForExistingSchema = () => {

    const { schemaId, isExisting } = props.configureDataSource;

    if (isExisting) {

      if (schemaId.trim().length > 0) {

        // Show loader if existing schema id is provided (or chosen)
        props.showLoader("AddCredentialModalV2", "");


        // Obtain cloudagent admin endpoint for the selected org.
        fetchCloudAgentConfigForOrg().then((response) => {

          const resp = response.data;

          if (resp.Status === 2) {

            // Fetch attributes for schema id provided.
            fetchAttributeForSchemaFromLedger(props.configureDataSource.schemaId, resp.AdminEndpoint).then((response) => {
              const resp = response.data;

              const schemaAttributesArray = resp.schema.attrNames;

              let toBeReplacedPds = []

              // Iterate through attributes in schema and create data agreement personal data object.
              for (let i = 0; i < schemaAttributesArray.length; i++) {
                // Empty personal data.
                let pd = {
                  attributeName: schemaAttributesArray[i],
                  attributeDescription: "",
                }

                toBeReplacedPds.push(pd);
              }

              // Add new personal data to the data agreement.
              props.replaceAllPersonalData(toBeReplacedPds);

              // Set preview content.
              setPersonalDataPreview(toBeReplacedPds);

              // Hide the loader
              props.hideLoader("AddCredentialModalV2");

              // Mark that attributes are populated in data agreement state.
              props.updateDataSourceConfiguration({ isAttributesPopulatedInDataAgreement: true });


            }).catch((err) => {

              // TODO: Show toast - Failed to fetch attributes for schema id from ledger.

              // Back button is disabled.
              setBackButtonToolTip("Please provide a valid schema id.");

              // Hide the loader
              props.hideLoader("AddCredentialModalV2");

              setPersonalDataPreview([]);

            });




          } else {

            // TODO: Show toast - cloudagent admin endpoint not found.

            // Hide the loader
            props.hideLoader("AddCredentialModalV2");

            setPersonalDataPreview([]);

          }

        }).catch((err) => {

          // TODO: Show toast - why cloudagent endpoint could not be obtained.
          // TODO: Hide both create data agreement and configure data source panel.

          // Hide the loader
          props.hideLoader("AddCredentialModalV2");

          setPersonalDataPreview([]);

        })


      } else {

        // Back button is disabled.
        setBackButtonToolTip("Please provide a valid schema id.");

        setPersonalDataPreview([]);

      }



    }

  }

  return (


    <div className="model-content-container">


      <div className="modal-content">


        <ModalHeader style={headerStyle}>


          <Tooltip title={backButtonToolTip}>


            <FaAngleLeft size="20" className="modal-back-button" onClick={handleBackButton} />


          </Tooltip>


          Choose Existing Schema: Data Source (Issuer)


        </ModalHeader>


        <ModalBody>




          <div>


            <div className="form-group">


              <label style={textStyle}>Existing Schema ID<span className="required-field">*</span>{" "}</label>


              <input

                className="w-100 pt-0"

                placeholder={`Existing Schema ID for reuse`}

                style={inputStyle}

                type="text"

                name="schemaID"

                autoComplete="off"

                defaultValue={props.configureDataSource.schemaId}

                onChange={handleExistingSchemaChange}

                onKeyPress={handleEnterKeyPressForExistingSchema}

              />


            </div>

            <div style={attributePreviewContainerStyle}>


              {personalDataPreview && personalDataPreview.map((attribute, index) => (


                <label className="bg-grey ml-2  " key={`${index}:${attribute.attributeName}`} style={attributePreviewTabletBgStyle}>
                  {attribute.attributeName}
                </label>


              ))}


            </div>


          </div>





        </ModalBody>


      </div>


    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    dataAgreementState: state.DataAgreementStateUpdate,
    configureDataSource: state.ConfigureDataSource,
    loaderStateReducer: state.LoaderStateReducer
  };
}
export default connect(
  mapStateToProps,
  {
    toggleConfigureDataSourcePanel,
    initDataSourceConfiguration,
    updateDataSourceConfiguration,
    showLoader,
    hideLoader,
    addPersonalDataInDataAgreementState,
    deletePersonalDataInDataAgreementStateAction,
    replaceAllPersonalData
  }
)(AddCredentialModalV2);
