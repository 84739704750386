import React, { Component } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
//import "./forcast_info.css";
export default class SliderWidget extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   value: { min: 0, max: 100 }
    // };
    this.onValueChange = this.onValueChange.bind(this);
    this.stepSize = 100 / this.props.num_days;
  }
  onValueChange(values) {
    //  values = values.map(value => value / this.stepSize);
    // this.props.onRangeChange(values);
    console.log(values);
    this.props.onSliderChange(values);
  }

  render() {
    return (
      <div>
        <Slider
          min={this.props.min}
          max={this.props.max}
          value={this.props.value}
          marks={this.props.marks}
          step={null}
          onChange={this.onValueChange}
          defaultValue={0}
          trackStyle={{ backgroundColor: "#696969", borderColor: "#696969" }}
          handleStyle={{
            borderColor: "#696969",
            backgroundColor: "#696969"
          }}
          dotStyle={{ borderColor: "#696969" }}
          activeDotStyle={{ borderColor: "#696969" }}
        />
      </div>
    );
  }
}
