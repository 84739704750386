import React from 'react';
import Page from '../components/Page';
import axios from 'axios';
import jsonData from '../setting/settings.json';
import { store } from '../services/localStorageStore'
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import {GetOrganizationDetails,} from '../components/Sources';
import {
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
const limittext="";//"?limit=1024";

const api = JSON.parse(JSON.stringify(jsonData)).api ;

const statustype = [ 'Request initiated', 'Request acknowledged', 'Request processed without action','Request processed with action',  ];
//const statusoth = [ 'Acknowledged', 'Action taken and closed', 'Closed without action',  ];
let subActiveList=[],subClosedList=[];

function getActiveList()
{

    if( store.getData('data') != null && store.getData('orgs') != null ) 
    {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
         let url = api+'v1/organizations/'+ store.getData('orgs').ID  +'/data-requests?status=open'+ limittext ;

         axios.get(url ,axiosConfig)
         .then((response) => {    
             subActiveList = response.data.DataRequests; 
         })
         .catch((err) => { 
             subActiveList = [];
         }) ; 
    }
}

function getClosedList()
{

    if( store.getData('data') != null && store.getData('orgs') != null ) 
    {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
       let url = api+'v1/organizations/'+ store.getData('orgs').ID  +'/data-requests?status=closed'+ limittext ;
        axios.get(url ,axiosConfig)
         .then((response) => {    
             subClosedList =response.data.DataRequests ; 
          })
         .catch((err) => { 
             subClosedList = [];
         }) ; 
    }
}
   getActiveList();
   getClosedList();

class SystemEventsPage extends React.Component {
  constructor() {
    super();
 this.getActiveList = this.getActiveList.bind(this);
 this.getClosedList = this.getClosedList.bind(this);
 this.onAfterSaveCell = this.onAfterSaveCell.bind(this);
 this.onDeleteRow = this.onDeleteRow.bind(this);
 this.onBeforeSaveCell = this.onBeforeSaveCell.bind(this);
 this.onRowSelect  = this.onRowSelect.bind(this);
  }

 state = {
    selectedOption:'',
    activeDetails:subActiveList,
    closedDetails:subClosedList,
 };

 renderPaginationPanel = (props) => {
  return (
   <Row className="w-100 h-100 mt-1 m-0 p-0">
     <Col lg={6} md={6} sm={6} xs={12} style={{ top: 'auto', bottom: '100%'}}  >{ props.components.sizePerPageDropdown }</Col>
     <Col lg={6} md={6} sm={6} xs={12}  className="d-inline-flex flex-row  justify-content-end"> { props.components.pageList } </Col>
    </Row>
  );
}

 componentDidMount() {
   GetOrganizationDetails();
   getActiveList();
   getClosedList();
   this.getActiveList();
   this.getClosedList();
}

onDeleteRow(rowKeys) {
}

 onRowSelect(row, isSelected, e) {
  let rowStr = '';
  for (const prop in row) {
    rowStr += prop + ': "' + row[prop] + '"';
  }
}

 //when edit items
 onAfterSaveCell(row, cellName, cellValue) {
 
  let idm=0;
  switch(row['StateStr'])
  {
  case 'Request initiated':
    idm=1;
    break;
  case 'Request acknowledged':
    idm=2;
    break;
  case 'Request processed without action':
    idm=6;
    break;
  case 'Request processed with action':
    idm=7;
    break;
  default:
    break;
  }
  var purData = {
 
    "State":idm,
    //"StateStr":"Request initiated",//row['status'], 
    "ClosedDate": new Date(),
    'Comment'  : row['Comment'] ,  
  }; 

  var axiosConfig = {
  headers: {
      'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
  }
  }; 
  let url = api+'v1/organizations/'+ store.getData('orgs').ID  +'/data-requests/'+ row['ID']+limittext ;
  axios.patch(url, purData,axiosConfig)
  .then((response) => {
  this.setState({ activeDetails : response.data.DataRequests });
  getActiveList();
  this.getActiveList();
  }); 
/* end editing */
  if(cellName==="status")
  {
     if((row['status']==="Request processed without action")||(row['status']==="Request processed with action"))
    {

      this.getActiveList();
      this.getClosedList();
    }
  }
  this.getActiveList();
  this.getClosedList();
}
onBeforeSaveCell(row, cellName, cellValue) {
  
}
getActiveList()
{
    if( store.getData('data') != null && store.getData('orgs') != null ) 
    {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
         let url = api+'v1/organizations/'+ store.getData('orgs').ID  +'/data-requests?status=open'+ limittext ;

         axios.get(url ,axiosConfig)
         .then((response) => {   
             var xdata= response.data.DataRequests;
             if(xdata==null )
               this.setState({ activeDetails : [] }); 
             else  
               this.setState({ activeDetails : xdata });

         })
         .catch((err) => { 
             this.setState({ activeDetails : [] });
         }) ; 
    }
} 
getClosedList()
{
    if( store.getData('data') != null && store.getData('orgs') != null ) 
    {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
       let url = api+'v1/organizations/'+ store.getData('orgs').ID  +'/data-requests?status=closed'+ limittext ;
        axios.get(url ,axiosConfig)
         .then((response) => {    
          this.setState({ closedDetails : response.data.DataRequests });
         })
         .catch((err) => { 
          this.setState({ closedDetails : [] });
         }) ; 
    }
}

  render() {
    const options = { 
      paginationPanel: this.renderPaginationPanel,
    };

    const cellEditProp = {
       mode: 'dbclick',
       blurToSave: true,
       afterSaveCell: this.onAfterSaveCell,  
       afterDeleteRow: this.onDeleteRow,
    };
    const colDispStyle = {
      cursor:'pointer',
    };
    const selectRowPropPurpose = {
         clickToSelect: true,
       };
    return (
      <Page title="" breadcrumbs={[{ name: 'System events', active: true }]}>
       
      <Row className="d-flex justify-content-right">
        <Col md="12" sm="12" xs="12" >
              <CardTitle>
                 Active Requests 
              </CardTitle>
           <BootstrapTable data={ this.state.activeDetails } striped={false} hover={true} options={ options } cellEdit={ cellEditProp} pagination={true}  selectRow={ selectRowPropPurpose }  >
              <TableHeaderColumn className="table-secondary" hidden isKey dataField='ID'  >No</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='UserID'   width='20%' editable={ false } dataSort={true}>Consumer ID</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='UserName'   width='20%' editable={ false } dataSort={true}>Consumer Name</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='RequestedDate'  width='20%' editable={ false } dataSort={true}>Date Requested</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='TypeStr'  width='15%' editable={ false } dataSort={true}>Request</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='StateStr'   width='25%' editable={{ type: 'select', options: { values: statustype }}} tdStyle={colDispStyle}  dataSort={true}>Status</TableHeaderColumn>
            </BootstrapTable>
        </Col>
      </Row>
      <Row className="d-flex justify-content-right">
        <Col md="12" sm="12" xs="12" >
              <CardTitle>
                 Closed Requests 
              </CardTitle>
           <BootstrapTable data={ this.state.closedDetails } striped={false} hover={true} options={ options} pagination={true}     >
              <TableHeaderColumn className="table-secondary" hidden isKey dataField='ID' >No</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='UserID'   width='15%' editable={ false } dataSort={true}>Consumer ID</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='UserName'   width='15%' editable={ false } dataSort={true}>Consumer Name</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='RequestedDate'  width='20%' editable={ false } dataSort={true}>Date Requested</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='TypeStr'  width='10%' editable={ false } dataSort={true}>Request</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='ClosedDate'  width='20%' editable={ false } dataSort={true}>Date Processed</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='StateStr'   width='20%' editable={ false } tdStyle={colDispStyle} dataSort={true}>Status</TableHeaderColumn>
            </BootstrapTable>
        </Col>
      </Row>
  </Page>
    );
  }
}

export default SystemEventsPage;
