import React from 'react';
import IconEye from 'react-icons/lib/fa/eye';
import IconEyeSlash from 'react-icons/lib/fa/eye-slash'; 
import jsonData from '../setting/settings.json';
import { getColor } from '../utils/colors';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import Select from 'react-select';
import axios from 'axios';
import IconCopy from 'react-icons/lib/fa/copy';  //edit
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardText,
  CardTitle,
  CardBody,
  Badge,
  FormGroup,
  ButtonGroup,
  Input,
  Label,
  Button,
} from 'reactstrap';
import { NumberWidget, IconWidget } from '../components/Widget';
import { store } from '../services/localStorageStore'
import {GetOrganizationDetails,} from '../components/Sources';
import Page from '../components/Page';
import { BootstrapTable, TableHeaderColumn,InsertButton, DeleteButton,SearchField, ExportCSVButton,} from 'react-bootstrap-table';
import { isNull } from 'util';

const secondaryColor = getColor('secondary');
const btnSz={height:'2.4rem',width:'12rem',padding:'0rem',fontSize:'12px',margin:'1px', position:'relative',top:'-14px', };

let orgpurposeDDown = [];
let orgtemplateDDown = [];
let subUserList=[];

let subUserCount, subKey, subToken,subMethod,purText,tempText;

const textStyle = {
   borderRadius:0,
   backgroundColor:'#F8F9FA',
   padding:0,
   margin:0,
   fontSize: '14px' ,
   borderTopWidth: 0,
   borderLeftWidth: 0,
   borderRightWidth : 0, 
   borderBottomWidth: 1,
   borderBottomColor: secondaryColor, 
   outline: 'none',
   boxShadow:'none', 
}

const customStylesDD = {
   control: (base, state) => ({
     ...base,
       '&:hover': { borderColor: 'gray' }, // border style on hover
        border: '1px solid #DFDFDF', // default border color
        boxShadow: 'none', // no box-shadow
        fontSize:'14px', 
        fontColor:'#808080',
        top:'-6px',
        padding:0,
        margin:0,
        backgroundColor:'white',//'#F8F9FA',
        borderRadius:0,
        outline: 'none',
})
};

function getDetails()
{
    GetOrganizationDetails();
    orgpurposeDDown = []; 
    orgtemplateDDown = [];

    if(store.getData('orgs') != null ) {
        //purpose
        var purposesDirect = store.getData('orgs').Purposes;
        for(var i=0;i<purposesDirect.length; i++)
        {
             var  orgpurDDown = {
                value: purposesDirect[i].ID,
                label: purposesDirect[i].Description,
            }  ;
            orgpurposeDDown.push(orgpurDDown);
        }; 

        var templateDirect = store.getData('orgs').Templates;
        for(var i=0;i<templateDirect.length; i++)
        {
             var  orgtempDDown = {
                value: templateDirect[i].ID,
                label: templateDirect[i].Consent,
            }  ;
            orgtemplateDDown.push(orgtempDDown);
        }; 
    }

    if( store.getData('data') != null && store.getData('orgs') != null) 
    {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
        //v1/organizations/<org-id>/users/count
        var url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/users/count';
         
        axios.get(url ,axiosConfig)
        .then((response) => {    
         subUserCount=response.data.SubscribeUserCount;
       }) ;
      
      //when no selection is done then the api is /v1/organizations/id/users
      url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/users';
      axios.get(url ,axiosConfig)
      .then((response) => {    
             subUserList=response.data.Users;
      })
      .catch((err) => { 
         subUserList =  [];
      }) ; 

      /////Inorder to get subscription-methods the below API(s) can be used. 
      ////// v1/organizations/subscribe-methods GET
      url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/subscribe-methods';
         
      axios.get(url ,axiosConfig)
      .then((response) => {    
         let data= (response.data);
 
     }) ; 

      ////// /v1/organizations/{organizationID}/subscribe-methods
      url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/subscribe-methods';
         
      axios.post(url ,axiosConfig)
      .then((response) => {    
         let data= (response.data);
        //  console.log("Org Subscribe methods 2 : " + JSON.stringify(data));   
         })
         .catch((err) => {    
          //  console.log("Org Subscribe methods Err : " + JSON.stringify(err));
         }) ; 

      ///v1/organizations/{organizationID}/subscribe-token 
       url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/subscribe-token';
         
       axios.get(url ,axiosConfig)
       .then((response) => {    
         //subKey=response.data.SubscribeUserCount;
         let data= (response.data);
         subToken=data.SubscribeToken;
         subMethod=data.SubscribeMethod;
      }) ; 
   }
   else
   {
      this.setState({  SubscribeUserCount:0, });
   }
   
};

function createCustomInsertButton (onClick) {
   return ( 
     <InsertButton style={btnSz}
      btnText='Add Users' 
      btnContextual='btn-default' 
    />
 );
}

function createCustomExportCSVButton (onClick)  {
   return (
     <ExportCSVButton style={btnSz}
       btnText='Download'
       btnContextual='btn-default'  className="w-50"
      />
   );
 }

function createCustomClearButton (onClick) {
   return (
     <button outline className="btn btn-default mt-0 mb-0 " onClick={ onClick }>View All</button>
   );
 }


class EndUserPage extends React.Component {
  
   constructor() {
      super();

      this.updateData = this.updateData.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.getSubscribedUserCount=this.getSubscribedUserCount.bind(this);
      this.getSubscribedDesc=this.getSubscribedDesc.bind(this);
      this.getSubscribedUsersList=this.getSubscribedUsersList.bind(this); 
      this.handleFilterPurpose = this.handleFilterPurpose.bind(this);
      this.handleFilterTemplate = this.handleFilterTemplate.bind(this); 
      this.createCustomToolBar = this.createCustomToolBar.bind(this); 
   }


   state = {
       show: true,
       selectedPurpose: null,
       selectedTemplate: null,
       SubscribeUserCount:subUserCount,
       OrgSubMethodSelected:{ value: 1, label: "Key-Based" },
       SubscribeToken: subToken,
       SubscribeMethod: subMethod,
       subcribedUserList : subUserList,
   };
   
    handleChange = (OrgSubMethodSelected) => {
      this.setState({ OrgSubMethodSelected });
   }

   createCustomToolBar (props) {
      return (
        <Row className="w-100 h-100 mt-3 m-0 p-0">
          <Col lg={4} md={6} sm={12} xs={12}   >
            <Select placeholder="Filter by purpose" value={this.state.selectedPurpose} options={orgpurposeDDown} styles={customStylesDD}    onClick={this.handleFilterPurpose}  onChange={this.handleFilterPurpose} /> 
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} >
            <Select placeholder="Filter by attribute" value={this.state.selectedTemplate} options={orgtemplateDDown}  styles={customStylesDD} onClick={this.handleFilterTemplate }  onChange={this.handleFilterTemplate}/>  
         </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="d-inline-flex flex-column justify-content-center  ">
            <ButtonGroup>
               <button outline className="btn btn-default w-50" style={btnSz} onClick={this.updateData}>View All</button>{ props.components.insertBtn }{ props.components.exportCSVBtn }</ButtonGroup>
         </Col>
       </Row>
      );
   } 
   
   updateData=()=> {
      this.setState({ subcribedUserList : subUserList });
      this.setState({selectedPurpose:null});
      this.setState({selectedTemplate:null });
      purText=null;
      tempText=null;
   }

   handleFilterPurpose(selectedPurpose) {
      this.setState({ selectedPurpose });
      purText=selectedPurpose.value;
        ///need to get all values realted to it
       if( store.getData('data')  && purText  ) 
       {
         this.setState({ subcribedUserList :  [] });
         if(tempText)
         {
          var axiosConfig = {
             headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
             }; 
         ///v1/organizations/{orgID}/purposes/{purposeID}/attributes/{attributeID}/consented/users
            var url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/purposes/' + purText + '/attributes/'+ tempText + '/consented/users';
         //   var url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/5c2d1731a8ce8b0001c936fc/purposes/5c2d1b99a8ce8b0001c93702/attributes/5c2d1b9ea8ce8b0001c93703/consented/users';
            axios.get(url ,axiosConfig)
            .then((response) => {    
               this.setState({ subcribedUserList : (isNull(response.data.Users))?[] : response.data.Users});
            })
            .catch((err) => {
               this.setState({ subcribedUserList :  [] });
            }) ; 
          }
        ////end extract
      }
    }
   
   handleFilterTemplate(selectedTemplate) {
      this.setState({ selectedTemplate });
      tempText = selectedTemplate.value;
       ///need to get all values realted to it
       if( store.getData('data') && tempText) 
       {
        this.setState({ subcribedUserList :  [] });
        if(purText)
        {
         var axiosConfig = {
             headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
             }; 
          ///v1/organizations/{orgID}/purposes/{purposeID}/attributes/{attributeID}/consented/users
         var url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/purposes/' + purText + '/attributes/'+ tempText + '/consented/users';
        ///var url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/5c2d1731a8ce8b0001c936fc/purposes/5c2d1b99a8ce8b0001c93702/attributes/5c2d1b9ea8ce8b0001c93703/consented/users';
              
         axios.get(url ,axiosConfig)
         .then((response) => {    
            this.setState({ subcribedUserList : (isNull(response.data.Users))?[] : response.data.Users});
         })
         .catch((err) => {
            this.setState({ subcribedUserList :  [] });
          }) ; 
         }
        ////end extract
      }  
   }

   getSubscribedUserCount()
   {
       if( store.getData('data') != null  && store.getData('orgs') != null ) 
       {
         var axiosConfig = {
           headers: {
               'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
               }
           }; 
           //v1/organizations/<org-id>/users/count
           var url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/users/count';
            
            axios.get(url ,axiosConfig)
           .then((response) => {    
               let resp=response.data.SubscribeUserCount;
               this.setState({  SubscribeUserCount:resp, });
          }) ; 
       }
       else
       {
         this.setState({  SubscribeUserCount:0, });
       }
   }

   getSubscribedDesc()
   {
       if( store.getData('data') != null && store.getData('orgs') != null) 
       {
         var axiosConfig = {
           headers: {
               'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
               }
           }; 
       
       ///v1/organizations/{organizationID}/subscribe-token 
       var url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/subscribe-token';
                
         axios.get(url ,axiosConfig)
         .then((response) => {    
            let data= (response.data);
               this.setState({  SubscribeToken:data.SubscribeToken,   SubscribeMethod:data.SubscribeMethod, });
         }) ; 
       }
   }

   getSubscribedUsersList()
   {
       if( store.getData('data') != null  && store.getData('orgs') != null ) 
       {
         var axiosConfig = {
           headers: {
               'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
               }
           }; 
          //when no selection is done then the api is /v1/organizations/id/users
            let url = JSON.parse(JSON.stringify(jsonData)).api +'v1/organizations/'+ store.getData('orgs').ID  +'/users';
  
            axios.get(url ,axiosConfig)
            .then((response) => {    
                  this.setState({ subcribedUserList :  response.data.Users});
            })
            .catch((err) => { 
               this.setState({ subcribedUserList :  [] });
            }) ; 
       }
   }

   componentDidMount() {
      getDetails(); 
      this.getSubscribedUserCount();
      ///this.getSubscribedDesc();
      this.getSubscribedUsersList();
   }

render() {
   getDetails();
 const aStyle = {
     color : 'white',fontWeight:400,fontSize: "0.9rem",
 };

 const options = {
   fontSize: '14px',
   exportCSVBtn: createCustomExportCSVButton,
   insertText: 'Add Users',
   clearSearch: true,
   clearSearchBtn: createCustomClearButton,
   exportCSVText: 'Download',
   insertBtn: createCustomInsertButton, 
   toolBar: this.createCustomToolBar,
   hideSizePerPage: true, //hide the dropdown for sizePerPage
   alwaysShowAllBtns: true, // Always show next and previous button
   withFirstAndLast: false, // Hide the going to First and Last page button
   prePage: 'Prev', // Previous page button text
   nextPage: 'Next', // Next page button text
  };

 const mvback = {
     outline: 'none',
     border:'0px',
     boxShadow:'none',
 };

 const editStyle = {
    border:'1',
    borderRadius:0,
    backgroundColor:'white',
    padding:2,
    fontSize: 14 ,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth : 0, 
    borderBottomWidth: 1,
    borderBottomColor: 'white', 
    outline: 'none',
    boxShadow:'none', 
  }


 const OrgSubMethodOptions = [
   { value: 1, label: "Key-Based" },
   { value: 2, label: "Bank ID" },
   { value: 3, label: "Org IDAM" },
 ];

  return (
    <Page title="" breadcrumbs={[{ name: 'Manage End Users', active: true }]}>
     <style>
                {this.css}
            </style>
      <Row className="d-flex justify-content-right">
        <Col md="12" sm="12" xs="12" >
             <CardTitle>
                End Users
             </CardTitle>
             <CardText>
             Provides an overview of end users and do basic queries on the consent data. 
             </CardText>
        </Col>
     </Row>
     <Row>
      <Col lg={4} md={6} sm={12} xs={12}>
          <Card  className="h-100 w-100">    
                <CardBody className="d-flex flex-column justify-content-start ">
                    <CardTitle>Current Subscribed Users</CardTitle>
                    <CardText> {this.state.SubscribeUserCount} </CardText>
                  </CardBody>
          </Card>
      </Col>
       <Col lg={4} md={6} sm={12} xs={12}>
        <Card  className="h-100 w-100">    
               <CardBody className="d-flex flex-column justify-content-start ">
                  <CardTitle>Organisation Subscription Method</CardTitle>
                  <CardText className="pt-2 pb-0"> <Select isMulti={true} components={{ ClearIndicator: null  }} options={OrgSubMethodOptions} styles={customStylesDD}  value={this.state.OrgSubMethodSelected} onChange={this.handleChange} />  </CardText>
               </CardBody>
        </Card>
     </Col>
     <Col lg={4} md={6} sm={12} xs={12}>
       <Card  className="h-100 w-100">    
               <CardBody className="d-flex flex-column justify-content-start ">
                  <CardTitle>Subscription Key</CardTitle>
                  <CardText><ButtonGroup>XYZA-ABS1-3BCD <span className="ml-5" style={{cursor:'pointer',}}>RENEW</span></ButtonGroup></CardText>
                </CardBody>
        </Card>
     </Col>
    </Row>
    <Row >
        <Col md="12" sm="12" xs="12" className="mt-0 ">
              <CardTitle>
                 Subscribed Users 
              </CardTitle>
           <BootstrapTable key="enduserTable" className="mt-0" data={this.state.subcribedUserList} pagination={true} striped={false} hover={true}  search={ false }    options={options} insertRow={false} exportCSV={ true } >
              <TableHeaderColumn hidden hiddenOnInsert className="table-secondary" isKey dataField='id' width='50' >No</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='ID'    width='200' editable={ false }>Subscriber ID</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='Name'  width='200' editable={ false }>Name</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='Email' width='200' editable={ false }>Email</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='Phone' width='200' editable={ false }>Telephone Number</TableHeaderColumn>
           </BootstrapTable>
        </Col>
    </Row>
    </Page>
  );
 };
}
export default EndUserPage;
