import React from "react";
import { Input } from "reactstrap";
export default ({
  type,
  placeholder,
  input,
  meta: { touched, error, warning }
}) => {
  const style =
    touched && error
      ? { fontSize: "14px", border: "1px solid red" }
      : { fontSize: "14px" };
  return (
    <Input
      style={style}
      type={type}
      placeholder={placeholder}
      value={input.value}
      onChange={input.onChange}
    />
  );
};
