import { store } from "../services/localStorageStore"
import DefaultOrgCoverImage from "../assets/img/bg/background_org.jpg";
import DefaultOrgLogoImage from "../assets/img/bg/background_company.jpg";

/**
 * Fetch iGrant.io api key for a user from localstorage.
 * 
 * @returns 
 */
export const fetchApiKey = () => {

    return store.getData("data") ? store.getData("data").User.APIKey : "";

}

/**
 * Fetch iGrant.io access token from localstorage.
 * 
 * @returns 
 */
export const fetchAccessToken = () => {

    return store.getData("data") ? store.getData("data").Token.access_token : "";

}

/**
 * Fetch org cover image from localstorage if exists, else return default image.
 * 
 * @returns 
 */
export const fetchOrgCoverImageFromLocalStorage = () => {

    if (
        store.getData("orgs") &&
        store.getData("orgs").CoverImageURL.trim().length > 0
    ) {

        return store.getData("orgs").CoverImageURL + "/web";

    } else {

        return DefaultOrgCoverImage;

    }
}

/**
 * Fetch org logo image from localstorage if exists, else return default image.
 * 
 * @returns 
 */
export const fetchOrgLogoImageFromLocalStorage = () => {

    if (
        store.getData("orgs") &&
        store.getData("orgs").LogoImageURL.trim().length > 0
    ) {

        return store.getData("orgs").LogoImageURL + "/web";

    } else {

        return DefaultOrgLogoImage;

    }
}

/**
 * Fetch the current org details from localstorage.
 */
export const fetchOrgsFromLocalStorage = () => {

    return store.getData("orgs");

}

/**
 * Returns true if cloudagent is deployed.
 */
export const isCloudAgentDeployed = () => {

    const orgs = store.getData("orgs");

    if (orgs) {

        const cloudAgentDeployment = orgs.AriesCloudAgentDeployment;

        if (cloudAgentDeployment.Status === 2) {

            return true;

        }

    }

    return false;

}

export const fetchCloudAgentDeploymentInfo = () => {
    const orgs = store.getData("orgs");

    if (orgs) {

        return orgs.AriesCloudAgentDeployment;

    }

    return null;
}