import Page from "../components/Page";
import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { getColor } from "../utils/colors";
import * as actions from "../actions";
import AddressDisplay from "../components/billing/AddressDisplay";
import Toggle from '../components/Toggle';
import { store } from '../services/localStorageStore'
import CardDisplay from "../components/billing/CardDisplay";
import InvoiceList from "../components/billing/InvoiceList";
import PackageSelector from "../components/billing/PackageSelector";
import PricingSimulator from "../components/billing/PricingSimulator";
import UsageDisplay from "../components/billing/UsageDisplay";
import { NumberWidget } from "../components/Widget";

class DashboardPage extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      packageSelected: 0 // 0 for general package and 1 for covid package
    }
    this.setPackageSelection = this.setPackageSelection.bind(this)
  }
  componentDidMount() {
    // this is needed, because InfiniteCalendar forces window scroll
    window.scrollTo(0, 0);
    this.props.fetchPricingInfo();
    this.props.fetchCurrentPackage().then(res => {
      this.setState({packageSelected: this.props.activePackage.PlanCategory? (this.props.activePackage.PlanCategory === 'normalplan'? 0 : 1) : 0})
    })
    this.props.fetchInvoices();
    this.props.fetchOrganization();
    
  }

  renderPricingSimulator() {
    return this.props.pricingInfo ? (
      <PricingSimulator {...this.props.pricingInfo.payPerUser} />
    ) : null;
  }

  renderPackageSelector() {
    return this.props.pricingInfo ? (
      <PackageSelector {...this.props.pricingInfo} packageSelected={this.state.packageSelected} />
    ) : null;
  }

  setPackageSelection(){
    this.setState({packageSelected: this.state.packageSelected === 0 ? 1 : 0 })
  }

  render() {
    const secondaryColor = getColor("secondary");
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };

    return (
      <Page
        className="DashboardPage"
        title=""
        breadcrumbs={[{ name: "Account / Billing", active: true }]}
      >
        <Row>
          <Col lg={4} md={6} sm={6} xs={12}>
            <NumberWidget
              className="h-100"
              title="Monthly Users  "
              subtitle="This month"
              number={this.props.currentMonthUsersMax}
              color="secondary"
              progress={{
                value: this.props.percentageUsersWithRespectToLastMonth,
                label: "Last month"
              }}
            />
          </Col>

          <Col lg={4} md={6} sm={6} xs={12}>
            <CardDisplay
              className="h-100"
              style={{ minHeight: 130 }}
              paymentSource={this.props.paymentSource}
            />
          </Col>

          <Col lg={4} md={6} sm={6} xs={12}>
            <AddressDisplay
              className="h-100"
              style={{ minHeight: 130 }}
              address={this.props.address}
            />
          </Col>
        </Row>

        <Row>
          <Col lg={8} md={12} sm={12} xs={12}>
            <Card className="h-100" style={{ fontSize: "14px" }}>
              <CardBody className="d-flex flex-column justify-content-start pb-0">
                <CardTitle className="card-title" style={headStyle}>
                  
                  <div className="sub-card">
                  <h5 style= {{marginBottom: '8px', paddingTop:'2px', fontWeight: 'bold'}}>Package Selector</h5>  
                  {!(this.state.packageSelected === 0) &&  <span className='package-toggle'>
                    General
                    <Toggle onToggle={this.setPackageSelection} active={  true }  activeLabel={"ON"} deactiveLabel={"OFF"} /> 
                    Covid Package
                  </span>}
                  {!(this.state.packageSelected ) &&  <span className='package-toggle'>
                    General
                    <Toggle deactiveColor="#454446" onToggle={this.setPackageSelection} active={  false }  activeLabel={"ON"} deactiveLabel={"OFF"} /> 
                    Covid Package
                  </span>}
                </div>
                </CardTitle>
                <p className="card-subtitle mt-1" style={{ fontSize: "14px" }}>
                  We have plans to suit all your business needs. Choose the one
                  that is right for you below:
                </p>
              </CardBody>
              <CardBody className="pt-1 pb-0">
                {this.renderPackageSelector()}
              </CardBody>
            </Card>
          </Col>

          <Col lg={4} md={12} sm={12} xs={12}>
            <Card className="h-100" style={{ fontSize: "14px" }}>
              <CardBody className="d-flex flex-column justify-content-start pb-0">
                {" "}
                <CardTitle className="card-title" style={headStyle}>
                  {" "}
                  Pay-per-user Pricing Simulator
                </CardTitle>
                <p
                  className="card-subtitle  text-justify mt-1"
                  style={{ fontSize: "14px" }}
                >
                  Use this tool to know your pay-per-user costs:
                </p>
              </CardBody>
              <CardBody>{this.renderPricingSimulator()}</CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className="mb-3">
              <CardBody className="d-flex flex-column justify-content-start pb-0">
                <CardTitle className="card-title" style={headStyle}>
                  Invoice History
                </CardTitle>
                <InvoiceList invoices={this.props.invoices} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}
function mapStateToProps({
  pricingInfo,
  invoices,
  address,
  cardDetails,
  activePackage,
  organization
}) {
  let percentageUsersWithRespectToLastMonth = 0;
  if (
    organization &&
    organization.BillingInfo &&
    organization.BillingInfo.MaxUserCounter &&
    organization.BillingInfo.PrevMonthUsers
  ) {
    percentageUsersWithRespectToLastMonth =
      (organization.BillingInfo.MaxUserCounter /
        organization.BillingInfo.PrevMonthUsers) *
      100;
  }

  return {
    activePackage,
    pricingInfo,
    invoices,
    address,
    percentageUsersWithRespectToLastMonth,
    paymentSource: cardDetails,
    currentMonthUsersMax:
      organization &&
      organization.BillingInfo &&
      organization.BillingInfo.MaxUserCounter,
    prevMonthUsers:
      organization &&
      organization.BillingInfo &&
      organization.BillingInfo.PrevMonthUsers
  };
}

export default connect(
  mapStateToProps,
  actions
)(DashboardPage);
