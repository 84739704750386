import React from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Input,
} from 'reactstrap';
import jsonData from '../setting/settings.json';
import Page from '../components/Page';
import { UserCard } from '../components/Card';
import { store } from '../services/localStorageStore'
import UserImageUpload   from '../components/UserImageUpload'; 
import {GetUserDetails,} from '../components/Sources';
import UserImage from '../assets/img/users/user.jpg';
const api = JSON.parse(JSON.stringify(jsonData)).api;

var userImage='';
if((store.getData('data') != null) && (store.getData('data').User.ImageID!=0))
    userImage=  api + 'v1/users/'  + ( (store.getData('data') != null)?(store.getData('data').User.ID):'' )  + '/image/' + ((store.getData('data') != null)?(store.getData('data').User.ImageID):'' ) +  '/web' ;
else
    userImage=UserImage;
var userName= (store.getData('data') != null)?(store.getData('data').User.Name):'' ;               
var userEmail =(store.getData('data') != null)?store.getData("data").User.Email:'' ;                  
var userPhone =(store.getData('data') != null)?store.getData("data").User.Phone:'' ;  

class ProfilePage extends React.Component {

    constructor () {
     super()
        this.state = {
            status:'' ,
            statusType:'' ,
            user_org:'',  
            u_name:'',
            u_email:'',
            u_password:'',
            u_website:'',
            u_phone:'',
          
        }
        this.handleChangeDetails = this.handleChangeDetails.bind(this);
        this.handleSetAllDetails =this.handleSetAllDetails.bind(this);
    }

    state = {
        modal: false,
        modal_backdrop: false,
        modal_logodrop: false,
        modal_nested: false,
        backdrop: true,
    };

    toggle = modalType => () => {
       
       if (!modalType) {
                return this.setState({
        modal: !this.state.modal,
        });
        }

        this.setState({
          [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
        });
        };


    getNewValues()
    {
        GetUserDetails();
        if((store.getData('data') != null) && (store.getData('data').User.ImageID!=0))
            userImage=  api + 'v1/users/'  + ( (store.getData('data') != null)?(store.getData('data').User.ID):'' )  + '/image/' + ((store.getData('data') != null)?(store.getData('data').User.ImageID):'' ) +  '/web' ;
        else
            userImage=UserImage;
        userName= (store.getData('data') != null)?(store.getData('data').User.Name):'' ;               
        userEmail =(store.getData('data') != null)?store.getData("data").User.Email:'' ;  
        userPhone =(store.getData('data') != null)?store.getData("data").User.Phone:'' ;  
    }

    handleChangeDetails(event) {
        if(event.target.name === 'u_name' )  this.setState({u_name: event.target.value});
        if(event.target.name === 'u_email' )  this.setState({u_email: event.target.value});
        if(event.target.name === 'u_password' )  this.setState({u_password: event.target.value});
        if(event.target.name === 'u_website' )  this.setState({u_website: event.target.value});
        if(event.target.name === 'u_phone' )  this.setState({u_phone: event.target.value});

        this.setState({status: ''})
    }
   
    setName()
    { 
        if(this.state.u_name.trim().length<3)
        {
            this.setState({status :  'Error : Invalid User Name' }); 
            // this.refs.u_name.focus();
        }
        else 
        { 
            var url = api + 'v1/user'
            var data = {
                'name' : this.state.u_name,
                'Phone' : this.state.u_phone,
            };
     
            var axiosConfig = {
                headers: {
                    'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
            }; 
            axios.patch(url, data,axiosConfig)
             .then((response) => {
                 this.setState({status :  'Information Updated'  }); 
              GetUserDetails();
        })
        .catch((err) => {
            this.setState({status :  'Error : Information Not Updated'  }); 
          });
        }
    }

    handleSetAllDetails(event) {
        this.setName();this.props.onUpdate(null);
    }

    componentDidMount() {
        this.getNewValues();
        this.setState({u_name: userName} );
        this.setState({u_email: userEmail} );
        this.setState({u_phone: userPhone} );
        this.setState({u_name: userName} );
    }

    componentWillUnmount() {
       GetUserDetails();
    }


 render() {
     this.getNewValues();
      const inputStyle = {
          borderRadius :0, 
          borderLeftWidth : 0,
          borderTopWidth : 0, 
          borderRightWidth : 0, 
          backgroundColor:'white' , borderBottomWidth : '0', 
      };

  return (
    <Page title="" breadcrumbs={[{ name: 'Settings', active: true }]}>
       <Row>
        <Col xl={6} lg={12} md={12}>
          <Card>
             Personal details 
            <CardBody>
              <Form method="POST">
               <FormGroup>
                   <UserCard src={userImage} title={this.state.u_name} subtitle="" text="" 
                    style={{  border:'solid white 1px' }}/> <UserImageUpload/>
                </FormGroup>
                <FormGroup>
                  <Input  style={inputStyle}
                    type="name"
                    placeholder="Name" ref="u_name" name="u_name" value={this.state.u_name} onChange={this.handleChangeDetails}
                  />
                </FormGroup>
                <FormGroup>
                  <Input  disabled ={'true'}  style={inputStyle}
                    type="email"
                    placeholder="email@website.com" ref="u_email" name="u_email" value={this.state.u_email} onChange={this.handleChangeDetails} 
                  />
                </FormGroup>
                <FormGroup>
                 <Input  disabled ={'true'} style={inputStyle }
                    type="number"
                    id="exampleNumber"
                    placeholder="Phone Number" ref="u_phone" name="u_phone" value={this.state.u_phone} onChange={this.handleChangeDetails}
                  />
                </FormGroup>
                <FormGroup>
                  <Input style={inputStyle}
                    type="password" 
                    placeholder="Password" ref="u_password" name="u_password" value={this.state.u_password} onChange={this.handleChangeDetails}
                  />
                </FormGroup>
                <FormGroup> 
                  <Button outline className="btn btn-default float-right" onClick={this.handleSetAllDetails}>Change Info</Button>
                </FormGroup>
                
              </Form>
            </CardBody>
          </Card>
          <Card>
            Personal details 
            <CardBody>
            </CardBody>
          </Card>
        </Col>
        <Col xl={6} lg={12} md={12}>
  
        </Col>
      </Row>
    </Page>
  );
 } 
 }
export default ProfilePage;
