import { store } from "../services/localStorageStore"

import jsonData from "../setting/settings.json";

/**
 * Construct cloud agent admin url.
 * 
 * @param {*} orgId 
 * @returns 
 */
export const constructCloudAgentAdminUrl = (orgId) => {

    return "https://cloudagent.igrant.io/v1/" + orgId + "/admin";
}

/**
 * Fetch cloud agent admin url.
 * 
 * @returns 
 */
export const fetchCloudAgentAdminUrl = () => {

    return store.getData("orgs") ? store.getData("orgs").AriesCloudAgentDeployment.AdminEndpoint : constructCloudAgentAdminUrl("sampleOrgId");

}

/**
 * Fetches the backend url from the settings.json file. (postfixed with backslash ("/"))
 * 
 * @returns 
 */
export const fetchBackendUrl = () => {

    return JSON.parse(JSON.stringify(jsonData)).api;

}
