import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row
} from "reactstrap";
import { formValueSelector } from "redux-form";
import { getColor } from "../../utils/colors";
import * as actions from "../../actions";
import { formatExpiryDate, getBrandLogo } from "../../utils/BillingUtils";
import CardInput from "../billing/CardInput";

class CardValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: this.isCardDetailsEmpty(this.props.paymentSource)
    };
  }

  isCardDetailsEmpty(paymentSource) {
    if (paymentSource && paymentSource.Last4Digits != "") {
      return false;
    }
    return true;
  }

  renderCardInput() {
    const {
      input: { onChange }
    } = this.props;
    return (
      <Card className="h-100 w-100">
        <CardBody>
          <CardTitle>Card Details</CardTitle>
          <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}>
            <div className="example">
              <Elements>
                <CardInput
                  validationMode={true}
                  onCardAdditionSuccess={token => {
                    this.setState({ editMode: false });
                    onChange(token);
                  }}
                  onCardAdditionFailure={() =>
                    this.setState({ editMode: false })
                  }
                />
              </Elements>
            </div>
          </StripeProvider>
        </CardBody>
      </Card>
    );
  }

  renderStoredCardDetails() {
    const secondaryColor = getColor("secondary");
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };

    if (!this.props.paymentSource) {
      return null;
    }
    const { brand, last4, exp_month, exp_year } = this.props.paymentSource;
    return (
      <Card style={{ height: "200px", fontSize: "14px" }}>
        <CardBody
          className="d-flex flex-column"
          style={{ paddingBottom: "0px" }}
        >
          <CardTitle style={headStyle}>Payment Method</CardTitle>
        </CardBody>
        <CardBody
          className="d-flex justify-content-between"
          style={{ padding: "0px" }}
        >
          <img
            src={getBrandLogo(brand)}
            style={{
              height: 70,
              border: 1
            }}
            className="pl-3 pt-3"
          />
          <CardText
            style={{
              textAlign: "right",
              fontSize: "14px"
            }}
            className="mr-4 mt-4"
          >
            XXXX XXXX XXXX {last4}
            <br />
            <span style={{ fontSize: "14px", color: secondaryColor }}>
              Expires {formatExpiryDate({ month: exp_month, year: exp_year })}
            </span>
          </CardText>
        </CardBody>
        <CardBody className="d-flex justify-content-end align-items-end">
          <CardText>
            {this.props.paymentSource && (
              <Button
                outline
                className="btn btn-default"
                onClick={() => this.setState({ editMode: true })}
              >
                Edit
              </Button>
            )}
          </CardText>
        </CardBody>
      </Card>
    );
  }

  render() {
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    const { plan, onCancel, onPaymentDetailsSubmit } = this.props;
    let timeCommitment, userCommitment;
    if (plan === "payperuser") {
      userCommitment = this.props.userCommitment;
      timeCommitment = this.props.timeCommitment;
    }

    return (
      <div>
        <p style={{ fontSize: "14px" }}>
          Please enter your payment information:
        </p>

        {this.state.editMode && this.renderCardInput()}
        {!this.state.editMode && this.renderStoredCardDetails()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("signUpWizard");

  return {
    paymentSource: selector(state, "onboardingCard.card")
  };
}

export default connect(
  mapStateToProps,
  actions
)(CardValidation);
