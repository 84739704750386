import React, { useState } from "react";
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { Tooltip } from '@material-ui/core';
import { ClickAwayListener } from '@material-ui/core';

import SyntaxHighlighter from 'react-syntax-highlighter';

const ExchangeInfoModal = (props) => {

  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
  };

  const [openCopyToolTip, setOpenCopyToolTip] = React.useState(false);

  /**
   * Copy to clipboard.
   */
  const handleTooltipClose = () => {

    // Close "Copied" tooltip.
    setOpenCopyToolTip(false);

  };

  const handleTooltipOpen = () => {

    // Show "Copied" tooltip.
    setOpenCopyToolTip(true);

  }

  return (
    <div>

      <div className="modal-header" style={headerStyle}>


        <div style={{ display: "flex", justifyContent: "space-between", flexBasis: "100%", alignItems: "center" }}>

          <div>

            <h1
              style={{
                marginRight: "auto",
                marginBlock: "auto",
                overflow: "hidden",
                width: "400px",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              Raw Exchange Record: {props.data.presentation_request.name}
            </h1>


          </div>

          <button type="button" className="close" aria-label="Close" onClick={props.toggle}>


            <span aria-hidden="true">×</span>


          </button>


        </div>


        {props.modelHeaderDescriptionText && <p style={{ fontStyle: "italic", color: "gray", }}>{props.modelHeaderDescriptionText}</p>}


      </div>

      <div className="modal-body-with-zero-padding">

        <SyntaxHighlighter customStyle={{ paddingBottom: "60px", marginBottom: "0px", background: "#fff" }} showLineNumbers={true} language="json" style={docco}>
          {JSON.stringify(props.data, null, 4)}
        </SyntaxHighlighter>

      </div>

      <div className="modal-footer" style={{ background: "#fff" }}>

        <button size="ig" className="btn btn-default" onClick={props.toggle}>Close</button>

        <ClickAwayListener onClickAway={handleTooltipClose} mouseEvent="onMouseUp">
          <Tooltip
            style={{ zIndex: '1' }}
            arrow="true"
            placement="left"
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={openCopyToolTip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
          >
            <button
              size="ig"
              className="btn btn-default"
              onClick={() => {

                // Show "Copied" tooltip.
                handleTooltipOpen();

                // Copy the JSON data to clipboard.
                navigator.clipboard.writeText(JSON.stringify(props.data, null, 4))

              }}

            >Copy
            </button>

          </Tooltip>
        </ClickAwayListener>

      </div>

    </div>

  );

};

export default ExchangeInfoModal;
