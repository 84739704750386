import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Form, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions";
import AddressField from "./AddressField";

class BillingAddressInput extends Component {
  constructor(props) {
    super(props);
    this.saveAddress = this.saveAddress.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }
  async saveAddress(address) {
    console.log("address:", address);
    await this.props.addBillingAddress(address);

    this.props.onBillingAddressSave();
  }

  onCancel() {
    this.props.onCancel();
  }

  renderAddressForm(handleSubmit) {
    return (
      <Form onSubmit={handleSubmit(this.saveAddress)}>
        <Field
          type="text"
          component={AddressField}
          name="Name"
          id="Name"
          placeholder="Admin Name"
        />
        <div className="mt-1">
          <Field
            type="text"
            component={AddressField}
            name="Line1"
            id="Line1"
            placeholder="Address Line 1"
          />
        </div>
        <div className="mt-1">
          <Field
            type="text"
            component={AddressField}
            name="Line2"
            id="Line2"
            placeholder="Address Line 2"
          />
        </div>

        <Row className="mt-1">
          <Col md={6} className="mb-0">
            <Field
              type="text"
              component={AddressField}
              name="City"
              id="City"
              placeholder="City"
            />
          </Col>
          <Col md={6} className="mb-0">
            <Field
              type="text"
              component={AddressField}
              name="State"
              id="State"
              placeholder="State"
            />
          </Col>
        </Row>
        <Row className="mt-1">
          <Col md={6}>
            <Field
              type="text"
              component={AddressField}
              name="Country"
              id="Country"
              placeholder="Country"
            />
          </Col>
          <Col md={6}>
            <Field
              type="text"
              component={AddressField}
              name="PostalCode"
              id="PostalCode"
              placeholder="PostalCode"
            />
          </Col>
        </Row>
        <div className="text-right">
          <Button outline className="btn btn-default" onClick={this.onCancel}>
            Cancel
          </Button>{" "}
          <Button outline type="submit" className="btn btn-default">
            Save
          </Button>
        </div>
      </Form>
    );
  }

  render() {
    const { handleSubmit, onAddressSubmit, onCancel } = this.props;
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    return this.renderAddressForm(handleSubmit);
  }
}
// function mapStateToProps({ organization }) {
//   return {
//     initialValues: organization && organization.BillingInfo.Address
//   };
// }

const FormDecoratedComponent = reduxForm({
  form: "billingaddress",
  enableReinitialize: true,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true
})(BillingAddressInput);

const ConnectedAndFormDecoratedComponent = connect(
  state => ({
    initialValues: state.address
  }),
  actions
)(FormDecoratedComponent);

export default ConnectedAndFormDecoratedComponent;
