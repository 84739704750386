import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardDeck,
  CardFooter,
  CardHeader,
  CardTitle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import * as actions from "../../actions";
import "../../styles/components/package_selection.css";

import { CANCEL_CONFIRMATION_STRING } from "./BillingConstants";

class PackageSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cancelPopUp: false,
      activePlanExistPopUp: false,
      userCommitment: 0,
      timeCommitment: 0,
      cancelConfirmationText: ""
    };

    this.toggleCancelPopUp = this.toggleCancelPopUp.bind(this);
    this.toggleActivePlanExistPopUp = this.toggleActivePlanExistPopUp.bind(
      this
    );
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.userCommitmentSliderValues = this.createSliderMarks(
      props.payPerUser.userCommitmentValues
    );

    this.timeCommitmentSliderValues = this.createSliderMarks(
      props.payPerUser.timeCommitmentDiscounts.map(
        timeOption => timeOption.displayOption
      )
    );
    this.handleUserCommitmentChange = this.handleUserCommitmentChange.bind(
      this
    );
    this.handleTimeCommitmentChange = this.handleTimeCommitmentChange.bind(
      this
    );
    this.handleCancelConfirmationText = this.handleCancelConfirmationText.bind(
      this
    );
  }

  toggleCancelPopUp() {
    if (this.state.cancelPopUp) {
      this.setState({
        cancelPopUp: !this.state.cancelPopUp,
        cancelConfirmationText: ""
      });
    } else {
      this.setState({
        cancelPopUp: !this.state.cancelPopUp,
        userCommitment: 0,
        timeCommitment: 0,
        cancelConfirmationText: ""
      });
    }
  }

  toggleActivePlanExistPopUp() {
    this.setState({
      activePlanExistPopUp: !this.state.activePlanExistPopUp
    });
  }

  cancelSubscription() {
    if (
      this.state.cancelConfirmationText &&
      this.state.cancelConfirmationText.toUpperCase() ===
        CANCEL_CONFIRMATION_STRING
    ) {
      this.props.cancelSubscription(this.props.activePackage.PlanType);
      this.setState({
        cancelPopUp: !this.state.cancelPopUp
      });
    }
  }

  createSliderMarks(labelArray) {
    const sliderMarks = {};
    let sliderPosition = 0;
    if (labelArray) {
      for (const label of labelArray) {
        sliderMarks[sliderPosition] = label;
        sliderPosition++;
      }
    }

    return sliderMarks;
  }

  handleUserCommitmentChange(value) {
    this.setState({
      userCommitment: value
    });
  }

  handleTimeCommitmentChange(value) {
    this.setState({ timeCommitment: value });
  }

  handleCancelConfirmationText(e) {
    this.setState({ cancelConfirmationText: e.target.value });
  }

  renderFreeTrialFooter() {
    if (this.props.trialExpired) {
      return <div className="mx-2 mt-2 mb-3">EXPIRED</div>;
    } else if (
      this.props.activePackage &&
      this.props.activePackage.PlanType === "freetrial" &&
      this.props.activePackage.TrialEnd > new Date().getTime() / 1000
    ) {
      let validityString =
        "Expiry: " +
        moment(
          new Date(this.props.activePackage.TrialEnd * 1000).getTime()
        ).format("ll");
      return (
        <div>
          <div className="mt-1" style={{ fontSize: "10px" }}>
            {" "}
            {validityString}
          </div>
          <div className="mx-2 my-2">
            <Button
              outline
              className="btn btn-default w-100"
              onClick={this.toggleCancelPopUp}
            >
              Terminate
            </Button>
          </div>
        </div>
      );
    } else if (
      this.props.activePackage &&
      this.props.activePackage.isFetching
    ) {
      return null;
    } else {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            tag={Link}
            to={{
              pathname: "/orderingwizard",
              state: {
                plan: "freetrial"
              }
            }}
            className="btn btn-default w-100"
          >
            SELECT
          </Button>
        </div>
      );
    }
  }

  renderFreeTrialVerifierFooter() {
    if (this.props.trialExpired) {
      return <div className="mx-2 mt-2 mb-3">EXPIRED</div>;
    } else if (
      this.props.activePackage &&
      this.props.activePackage.PlanType === "freetrialverifier" &&
      this.props.activePackage.TrialEnd > new Date().getTime() / 1000
    ) {
      let validityString =
        "Expiry: " +
        moment(
          new Date(this.props.activePackage.TrialEnd * 1000).getTime()
        ).format("ll");
      return (
        <div>
          <div className="mt-1" style={{ fontSize: "10px" }}>
            {" "}
            {validityString}
          </div>
          <div className="mx-2 my-2">
            <Button
              outline
              className="btn btn-default w-100"
              onClick={this.toggleCancelPopUp}
            >
              Terminate
            </Button>
          </div>
        </div>
      );
    } else if (
      this.props.activePackage &&
      this.props.activePackage.isFetching
    ) {
      return null;
    } else {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            tag={Link}
            to={{
              pathname: "/orderingwizard",
              state: {
                plan: "freetrialverifier"
              }
            }}
            className="btn btn-default w-100"
          >
            SELECT
          </Button>
        </div>
      );
    }
  }

  renderStarterPlanFooter() {
    if (
      this.props.activePackage &&
      this.props.activePackage.PlanType === "starter"
    ) {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            className="btn btn-default w-100"
            onClick={this.toggleCancelPopUp}
          >
            Terminate
          </Button>
        </div>
      );
    } else if (
      this.props.activePackage &&
      this.props.activePackage.isFetching
    ) {
      return null;
    } else if (
      this.props.activePackage &&
      (this.props.activePackage.PlanType === "payperuser" ||
        this.props.activePackage.PlanType === "freetrial"||
        this.props.activePackage.PlanType === "freetrialverifier"||
        this.props.activePackage.PlanType === "premiumverifier"||
        this.props.activePackage.PlanType === "starterverifier")
    ) {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            className="btn btn-default w-100"
            onClick={this.toggleActivePlanExistPopUp}
          >
            Select
          </Button>
        </div>
      );
    } else {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            tag={Link}
            to={{
              pathname: "/orderingwizard",
              state: {
                plan: "starter"
              }
            }}
            className="btn btn-default w-100"
          >
            SELECT
          </Button>
        </div>
      );
    }
  }

  renderStarterVerifierPlanFooter() {
    if (
      this.props.activePackage &&
      this.props.activePackage.PlanType === "starterverifier"
    ) {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            className="btn btn-default w-100"
            onClick={this.toggleCancelPopUp}
          >
            Terminate
          </Button>
        </div>
      );
    } else if (
      this.props.activePackage &&
      this.props.activePackage.isFetching
    ) {
      return null;
    } else if (
      this.props.activePackage &&
      (this.props.activePackage.PlanType === "premiumverifier" ||
        this.props.activePackage.PlanType === "freetrialverifier"||
        this.props.activePackage.PlanType === "starter"||
        this.props.activePackage.PlanType === "freetrial"||
        this.props.activePackage.PlanType === "payperuser")
    ) {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            className="btn btn-default w-100"
            onClick={this.toggleActivePlanExistPopUp}
          >
            Select
          </Button>
        </div>
      );
    } else {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            tag={Link}
            to={{
              pathname: "/orderingwizard",
              state: {
                plan: "starterverifier"
              }
            }}
            className="btn btn-default w-100"
          >
            SELECT
          </Button>
        </div>
      );
    }
  }

  renderPayPerUserFooter() {
    var disablePayPerUser =
      process.env.REACT_APP_DISABLE_PAY_PER_USER === "true";
    if (
      this.props.activePackage &&
      this.props.activePackage.PlanType === "payperuser"
    ) {
      let userCommitmentString = `User Commitment: ${this.props.selectedUserCommitment}`;
      let timeCommitmentString = `Time Commitment: ${this.props.selectedTimeCommitment}`;

      return (
        <div>
          <div
            className="mt-1"
            style={{ fontSize: "10px", textAlign: "center" }}
          >
            <div style={{ display: "inline-block", textAlign: "left" }}>
              {userCommitmentString}
              <br />
              {timeCommitmentString}
            </div>
          </div>
          <div className="mx-2 my-2">
            <Button
              outline
              tag={Link}
              to={{
                pathname: "/managepayperuser",
                state: {
                  timeCommitment: this.props.selectedTimeCommitment,
                  userCommitment: this.props.selectedUserCommitment,
                  planType: this.props.activePackage.PlanType
                }
              }}
              className="btn btn-default w-100"
            >
              Manage
            </Button>
          </div>
        </div>
      );
    } else if (
      this.props.activePackage &&
      this.props.activePackage.isFetching
    ) {
      return null;
    } else if (
      this.props.activePackage &&
      (this.props.activePackage.PlanType === "starter" ||
        this.props.activePackage.PlanType === "starterverifier"||
        this.props.activePackage.PlanType === "freetrialverifier"||
        this.props.activePackage.PlanType === "premiumverifier"||
        this.props.activePackage.PlanType === "freetrial")
    ) {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            disabled={disablePayPerUser}
            className="btn btn-default w-100"
            onClick={this.toggleActivePlanExistPopUp}
          >
            Select
          </Button>
        </div>
      );
    } else {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            disabled={disablePayPerUser}
            tag={Link}
            to={{
              pathname: "/orderingwizard",
              state: {
                plan: "payperuser",
                ...this.props.payPerUser
              }
            }}
            className="btn btn-default w-100"
          >
            SELECT
          </Button>
        </div>
      );
    }
  }

  renderPremiumVerifier() {
    if (
      this.props.activePackage &&
      this.props.activePackage.PlanType === "premiumverifier"
    ) {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            className="btn btn-default w-100"
            onClick={this.toggleCancelPopUp}
          >
            Terminate
          </Button>
        </div>
      );
    } else if (
      this.props.activePackage &&
      this.props.activePackage.isFetching
    ) {
      return null;
    } else if (
      this.props.activePackage &&
      (this.props.activePackage.PlanType === "starterverifier" ||
        this.props.activePackage.PlanType === "freetrialverifier"||
        this.props.activePackage.PlanType === "freetrial"||
        this.props.activePackage.PlanType === "starter"||
        this.props.activePackage.PlanType === "payperuser")
    ) {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            className="btn btn-default w-100"
            onClick={this.toggleActivePlanExistPopUp}
          >
            Select
          </Button>
        </div>
      );
    } else {
      return (
        <div className="mx-2 my-2">
          <Button
            outline
            tag={Link}
            to={{
              pathname: "/orderingwizard",
              state: {
                plan: "premiumverifier"
              }
            }}
            className="btn btn-default w-100"
          >
            SELECT
          </Button>
        </div>
      );
    }
  }
  
  //<div className="d-md-none mt-1" />

  render() {
    return (
      <div>
        
          {this.props.packageSelected ===0 ?
            <CardDeck className="packageselector">
              <Card
              className={
                "px-3 text-center " +
                (this.props.activePackage &&
                this.props.activePackage.PlanType === "freetrial" &&
                this.props.activePackage.TrialEnd > new Date().getTime() / 1000
                  ? "activepackage"
                  : "")
              }
            >
              <CardHeader className="h5">TRIAL</CardHeader>
              <CardTitle className="mt-5">
                {this.props.pricingInfo.freeTrial.price > 0
                  ? this.props.pricingInfo.freeTrial.price
                  : "FREE"}
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    Valid for {this.props.pricingInfo.freeTrial.validity}{" "}
                    {this.props.pricingInfo.freeTrial.validity > 1
                      ? "days"
                      : "day"}
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.freeTrial.usageLimit.users} users
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.freeTrial.usageLimit.api} API
                    calls
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                {this.renderFreeTrialFooter()}
              </CardFooter>
            </Card>
            <Card
              className={
                "px-3 text-center " +
                (this.props.activePackage &&
                this.props.activePackage.PlanType === "starter"
                  ? "activepackage"
                  : "")
              }
            >
              <CardHeader className="h5">STARTER</CardHeader>
              <CardTitle className="mt-5">
                SEK{" "}
                <span className="price">
                  {this.props.pricingInfo.starter.price}
                </span>
                <span className="h6 text-muted ml-2" style={{ fontSize: "12px" }}>
                  / year
                </span>
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    Upto {this.props.pricingInfo.starter.usageLimit.users} users
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.starter.usageLimit.api} API calls
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                {this.renderStarterPlanFooter()}
              </CardFooter>
            </Card>
            <div className="w-100 mt-2 d-none d-md-block d-lg-none" />

            <Card
              className={
                "px-3 text-center " +
                (this.props.activePackage &&
                this.props.activePackage.PlanType === "payperuser"
                  ? "activepackage"
                  : "")
              }
            >
              <CardHeader className="h5">PAY-PER-USER</CardHeader>
              <CardTitle className="mt-5">
                SEK{" "}
                <span className="price">
                  {this.props.pricingInfo.payPerUser.basePrice}*
                </span>
                <span className="h6 text-muted ml-2" style={{ fontSize: "12px" }}>
                  / per user per year
                </span>
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    {this.props.pricingInfo.payPerUser.usageLimit.users ===
                    "UNLIMITED"
                      ? "UNLIMITED users"
                      : "Upto " +
                        this.props.pricingInfo.payPerUser.usageLimit.users +
                        " users"}
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.payPerUser.usageLimit.api} API
                    calls
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                {this.renderPayPerUserFooter()}
              </CardFooter>
            </Card>
          </CardDeck>
          :  
          <CardDeck className="packageselector">
            <Card
              className={
                "px-3 text-center " +
                (this.props.activePackage &&
                this.props.activePackage.PlanType === "freetrialverifier" &&
                this.props.activePackage.TrialEnd > new Date().getTime() / 1000
                  ? "activepackage"
                  : "")
              }
            >
              <CardHeader className="h5">TRIAL</CardHeader>
              <CardTitle className="mt-5">
                {this.props.pricingInfo.freeTrialVerifier.price > 0
                  ? this.props.pricingInfo.freeTrialVerifier.price
                  : "FREE"}
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    Valid for {this.props.pricingInfo.freeTrialVerifier.validity}{" "}
                    {this.props.pricingInfo.freeTrialVerifier.validity > 1
                      ? "days"
                      : "day"}
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.freeTrialVerifier.usageLimit.users} users
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.freeTrialVerifier.usageLimit.verifications} verifications
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.freeTrialVerifier.usageLimit.verifierappactivation} verifier app activation
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                {this.renderFreeTrialVerifierFooter()}
              </CardFooter>
            </Card>

            <Card
              className={
                "px-3 text-center " +
                (this.props.activePackage &&
                this.props.activePackage.PlanType === "starterverifier"
                  ? "activepackage"
                  : "")
              }
            >
              <CardHeader className="h5">STARTER</CardHeader>
              <CardTitle className="mt-5">
                SEK{" "}
                <span className="price">
                  {this.props.pricingInfo.starterVerifier.price}
                </span>
                <span className="h6 text-muted ml-2" style={{ fontSize: "12px" }}>
                  / year
                </span>
              </CardTitle>
              <CardBody>
                <ul className="list-unstyled mb-4">
                  <li>
                    Upto {this.props.pricingInfo.starterVerifier.usageLimit.users} users
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.starterVerifier.usageLimit.verifications} verifications
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.starterVerifier.usageLimit.verifierappactivation} verifier app activation
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                {this.renderStarterVerifierPlanFooter()}
              </CardFooter>
            </Card>
            <div className="w-100 mt-2 d-none d-md-block d-lg-none" />

            <Card
              className={
                "px-3 text-center " +
                (this.props.activePackage &&
                this.props.activePackage.PlanType === "premiumverifier"
                  ? "activepackage"
                  : "")
              }
            >
              <CardHeader className="h5">PREMIUM</CardHeader>
              <CardTitle className="mt-5">
                SEK{" "}
                <span className="price">
                  {this.props.pricingInfo.premiumVerifier.price}
                </span>
                <span className="h6 text-muted ml-2" style={{ fontSize: "12px" }}>
                  / year
                </span>
              </CardTitle>
              <CardBody>
              <ul className="list-unstyled mb-4">
                  <li>
                    Upto {this.props.pricingInfo.premiumVerifier.usageLimit.users} users
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.premiumVerifier.usageLimit.verifications} verifications
                  </li>
                  <li>
                    Upto {this.props.pricingInfo.premiumVerifier.usageLimit.verifierappactivation} verifier app activation
                  </li>
                </ul>
              </CardBody>
              <CardFooter className="px-0 py-0">
                {this.renderPremiumVerifier()}
              </CardFooter>
            </Card>
          </CardDeck>
        }
          

          
        <div>
          <p
            className="py-2 mt-2 mb-0 text-right"
            style={{ fontSize: "11px", fontStyle: "italic" }}
          >
            *Further discounts based on committed usage
          </p>
        </div>
        <Modal backdrop="static" isOpen={this.state.cancelPopUp} toggle={this.toggleCancelPopUp}>
          <ModalHeader toggle={this.toggleCancelPopUp}>
            <strong>Are you sure?</strong>
          </ModalHeader>
          <ModalBody>
            Please type TERMINATE to terminate the selected package and click OK
            <Input
              className="mt-1"
              type="text"
              id="cancelConfirmationText"
              value={this.state.cancelConfirmationText}
              onChange={this.handleCancelConfirmationText}
            />
          </ModalBody>
          <div className="mt-1">
            <ModalFooter>
              <Button
                outline
                className="btn btn-default"
                onClick={this.toggleCancelPopUp}
              >
                Cancel
              </Button>
              <div className="text-center">
                <Button
                  outline
                  className="btn btn-default"
                  onClick={this.cancelSubscription}
                >
                  OK
                </Button>
              </div>
            </ModalFooter>
          </div>
        </Modal>
        <Modal
          backdrop="static"
          isOpen={this.state.activePlanExistPopUp}
          toggle={this.toggleActivePlanExistPopUp}
        >
          <ModalHeader toggle={this.toggleActivePlanExistPopUp}>
            <strong>Active plan exists!</strong>
          </ModalHeader>
          <ModalBody>
            {this.props.activePackage
              ? "You are already subscribed to " +
                this.props.activePackage.PlanType +
                " package. Please terminate current package to select a new package."
              : ""}
          </ModalBody>
          <div className="mt-1">
            <ModalFooter>
              <div className="text-center">
                <Button
                  outline
                  className="btn btn-default"
                  onClick={this.toggleActivePlanExistPopUp}
                >
                  OK
                </Button>
              </div>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps({ activePackage, organization, pricingInfo }) {
  return {
    activePackage,
    pricingInfo,
    trialExpired: organization && organization.BillingInfo && organization.BillingInfo.FreeTrialExpired,
    selectedTimeCommitment:
      organization && organization.BillingInfo && organization.BillingInfo.PayPerUserInfo.TimeCommitment,
    selectedUserCommitment:
      organization && organization.BillingInfo && organization.BillingInfo.PayPerUserInfo.UserCommitment
  };
}

export default connect(
  mapStateToProps,
  actions
)(PackageSelector);
