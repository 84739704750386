import axios from 'axios'
import { store } from './localStorageStore';
import jsonData from '../setting/settings.json';

export const userLogout = () => {
  const url = JSON.parse(JSON.stringify(jsonData)).api + "v1/users/logout";
  const data = {
    refresh_token: store.getData("data").Token.refresh_token,
    clientid: "igrant-ios-app",
  };

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
    },
  };

  axios
    .post(url, JSON.stringify(data), JSON.stringify(axiosConfig))
    .catch((err) => console.log(err));
  store.clear();
  window.location = "/";
};
