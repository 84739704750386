import Page from "../Page";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  Input
} from "reactstrap";
import * as actions from "../../actions";
import { CANCEL_CONFIRMATION_STRING } from "./BillingConstants";
import BillingAddressInput from "./BillingAddressInput";
import CardInput from "./CardInput";

class PayPerUserManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      cancelConfirmationText: ""
    };
    this.timerID = -1;
    this.redirectToBillingDashboard = this.redirectToBillingDashboard.bind(
      this
    );
    this.cancelSubscription = this.cancelSubscription.bind(this);
    this.handleCancelConfirmationText = this.handleCancelConfirmationText.bind(
      this
    );
  }

  redirectToBillingDashboard() {
    this.props.history.push("/billing");
  }

  componentDidMount() {
    const {
      timeCommitment,
      userCommitment,
      planType
    } = this.props.location.state;

    if (!timeCommitment || userCommitment === undefined || !planType) {
      this.redirectToBillingDashboard();
    }
  }

  async cancelSubscription() {
    const { planType } = this.props.location.state;
    if (
      this.state.cancelConfirmationText &&
      this.state.cancelConfirmationText.toUpperCase() ===
        CANCEL_CONFIRMATION_STRING
    ) {
      await this.props.cancelSubscription(planType);
    }
    this.redirectToBillingDashboard();
  }

  handleCancelConfirmationText(e) {
    this.setState({ cancelConfirmationText: e.target.value });
  }

  render() {
    const { param } = this.props.location.state;

    let breadCrumbData =
      "Account / Billing / Package Selector / Manage Pay-per-user";

    return (
      <Page title="" breadcrumbs={[{ name: breadCrumbData, active: true }]}>
        {" "}
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            <CardTitle>Manage Pay-per-user</CardTitle>
            <CardText style={{ fontSize: "14px" }}>
              Please choose from the following options:
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12} sm={12} xs={12}>
            <Card className="h-100" style={{ fontSize: "14px" }}>
              <CardBody className="d-flex flex-column justify-content-start ">
                <CardTitle className="card-title">Update Commitments</CardTitle>
                <p className="card-subtitle mt-1" style={{ fontSize: "14px" }}>
                  Coming Soon!
                </p>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6} md={12} sm={12} xs={12}>
            <Card className="h-100" style={{ fontSize: "14px" }}>
              <CardBody className="d-flex flex-column justify-content-start ">
                {" "}
                <CardTitle className="card-title"> Terminate Package</CardTitle>
                <p
                  className="card-subtitle  text-justify mt-1"
                  style={{ fontSize: "14px" }}
                >
                  If you chose to terminate the package, the amount for the
                  remaining committed period (if any) will be deducted from your
                  payment source. Please type TERMINATE and press OK if you wish
                  to proceed.
                </p>
                <Input
                  style={{ fontSize: "14px" }}
                  className="mt-2"
                  type="text"
                  id="cancelConfirmationText"
                  value={this.state.cancelConfirmationText}
                  onChange={this.handleCancelConfirmationText}
                />
                <div className="mt-2 text-right">
                  <Button
                    outline
                    className="btn btn-default"
                    onClick={this.toggleCancelPopUp}
                  >
                    Cancel
                  </Button>

                  <Button
                    outline
                    className="btn btn-default"
                    onClick={this.cancelSubscription}
                  >
                    OK
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="text-left mt-3">
          <Button
            outline
            className="btn btn-default"
            onClick={this.redirectToBillingDashboard}
          >
            back
          </Button>
        </div>
      </Page>
    );
  }
}
export default connect(
  null,
  actions
)(PayPerUserManager);
