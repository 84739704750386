import axios from "axios";
import jsonData from "../setting/settings.json";
import jwtDecode from "jwt-decode";
import { store } from '../services/localStorageStore'
import {
  ADD_ADDRESS_FAILURE,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  ADD_CARD_FAILURE,
  ADD_CARD_REQUEST,
  ADD_CARD_SUCCESS,
  CANCEL_PLAN_FAILURE,
  CANCEL_PLAN_REQUEST,
  CANCEL_PLAN_SUCCESS,
  FETCH_ACTIVE_PLAN_FAILURE,
  FETCH_ACTIVE_PLAN_REQUEST,
  FETCH_ACTIVE_PLAN_SUCCESS,
  FETCH_ADDRESS_FAILURE,
  FETCH_ADDRESS_REQUEST,
  FETCH_ADDRESS_SUCCESS,
  FETCH_CARD_FAILURE,
  FETCH_CARD_REQUEST,
  FETCH_CARD_SUCCESS,
  FETCH_INVOICES_ERROR,
  FETCH_INVOICES_SUCCESS,
  FETCH_ORG_FAILURE,
  FETCH_ORG_SUCCESS,
  FETCH_PRICING_INFO,
  FETCH_SERV_AGREEMENT_FAILURE,
  FETCH_SERV_AGREEMENT_REQUEST,
  FETCH_SERV_AGREEMENT_SUCCESS,
  SUBSCRIBE_PLAN_FAILURE,
  SUBSCRIBE_PLAN_REQUEST,
  SUBSCRIBE_PLAN_SUCCESS,
  IS_LOGIN,
  IS_API_KEY_VALID
} from "./action_types";

export const fetchPricingInfo = () => async dispatch => {
  // TODO - Move to different env files
  const url = JSON.parse(JSON.stringify(jsonData)).api + "v1/pricing";
  let response;
  let error = null;
  let data = store.getData("data");
  let axiosConfig = null;
  if (data) {
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };
  }
  try {
    response = await axios.get(url, axiosConfig);
  } catch (err) {
    error = err;
  }
  if (response && response.status === 204) {
    error = { response: { data: "", status: 204, statusText: "No Content" } };
  }
  dispatch({ type: FETCH_PRICING_INFO, payload: response, error });
};

export const addCard = token => async (dispatch, getState) => {
  let response,
    error,
    axiosConfig,
    orgID,
    paymentSourceUrl,
    billingRegistrationID;
  let data = store.getData("data");
  dispatch({ type: ADD_CARD_REQUEST });
  if (data) {
    orgID = data.User.Roles[0].OrgID;
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };

    paymentSourceUrl =
      JSON.parse(JSON.stringify(jsonData)).api +
      `v1/billing/organizations/${orgID}/paymentsource`;
    // TODO. This is temporary.Need to remove this and move registration
    // at the time of creating an org
    billingRegistrationID = getState().organization.BillingInfo
      .BillingRegistrationID;
    if (billingRegistrationID === "") {
      let registrationStatus = await registerBilling(orgID);
      if (!registrationStatus) {
        dispatch({
          type: ADD_CARD_FAILURE,
          error: "Failed to add card! Please try again."
        });
        return;
      }
    }

    try {
      response = await axios.post(paymentSourceUrl, token, axiosConfig);
    } catch (err) {
      error = err;
    }

    if (error) {
      dispatch({
        type: ADD_CARD_FAILURE,
        error: "Failed to add card! Please try again."
      });
    } else {
      let cardDetails;
      if (response && response.data) {
        cardDetails =
          response.data.Organization.BillingInfo.DefaultPaymentSource;
        dispatch({ type: ADD_CARD_SUCCESS, payload: cardDetails });
      } else {
        dispatch({
          type: ADD_CARD_FAILURE,
          error: "Failed to add card! Please try again."
        });
      }
    }
  } else {
    dispatch({
      type: ADD_CARD_FAILURE,
      error: "Failed to add card! Please try again."
    });
  }
};

export const subscribePlan = subscriptionInfo => async dispatch => {
  let response, error, axiosConfig, orgID, subscribeUrl;
  let data = store.getData("data");
  const {
    plan,
    timeCommitment,
    userCommitment,
    serviceAgreementVersion
  } = subscriptionInfo;
  dispatch({ type: SUBSCRIBE_PLAN_REQUEST });
  if (data) {
    orgID = data.User.Roles[0].OrgID;
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };

    subscribeUrl =
      JSON.parse(JSON.stringify(jsonData)).api +
      `v1/billing/plans/${plan}/organizations/${orgID}`;
    try {
      response = await axios.post(
        subscribeUrl,
        { serviceAgreementVersion, timeCommitment, userCommitment },
        axiosConfig
      );
    } catch (err) {
      error = err;
    }
    if (error) {
      dispatch({
        type: SUBSCRIBE_PLAN_FAILURE,
        error: "Error occured! Please try again."
      });
    } else {
      let subscriptionInfo;
      if (response && response.data) {
        subscriptionInfo = response.data.SubscriptionInfo;
        dispatch({ type: SUBSCRIBE_PLAN_SUCCESS, payload: subscriptionInfo });
      } else {
        dispatch({
          type: SUBSCRIBE_PLAN_FAILURE,
          error: "Error occured! Please try again."
        });
      }
    }
  } else {
    dispatch({
      type: SUBSCRIBE_PLAN_FAILURE,
      error: "Error occured! Please try again."
    });
  }
};

export const addBillingAddress = address => async (dispatch, getState) => {
  let response, url, error, axiosConfig, orgID, billingRegistrationID;
  let data = store.getData("data");
  dispatch({ type: ADD_ADDRESS_REQUEST });
  if (data) {
    orgID = data.User.Roles[0].OrgID;
    url =
      JSON.parse(JSON.stringify(jsonData)).api +
      `v1/billing/organizations/${orgID}/address`;
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };

    // TODO. This is temporary.Need to remove this and move registration
    // at the time of creating an org
    billingRegistrationID = getState().organization.BillingInfo
      .BillingRegistrationID;
    if (billingRegistrationID === "") {
      let registrationStatus = await registerBilling(orgID);
      if (!registrationStatus) {
        dispatch({
          type: ADD_ADDRESS_FAILURE,
          error: "Failed to add address! Please try again."
        });
        return;
      }
    }

    try {
      response = await axios.post(url, address, axiosConfig);
    } catch (err) {
      error = err;
    }

    if (error) {
      dispatch({
        type: ADD_ADDRESS_FAILURE,
        error: "Failed to add address! Please try again."
      });
    } else {
      let billingAddress;
      if (response && response.data) {
        billingAddress = response.data.Organization.BillingInfo.Address;
        dispatch({ type: ADD_ADDRESS_SUCCESS, payload: billingAddress });
      } else {
        dispatch({
          type: ADD_ADDRESS_FAILURE,
          error: "Failed to add address! Please try again."
        });
      }
    }
  }
};

export const fetchOrganization = () => async dispatch => {
  let response, error, axiosConfig, orgID;
  let data = store.getData("data");
  dispatch({ type: FETCH_ADDRESS_REQUEST });
  dispatch({ type: FETCH_CARD_REQUEST });
  if (data) {
    let url = JSON.parse(JSON.stringify(jsonData)).api + "v1/organizations";
    orgID = data.User.Roles[0].OrgID;
    url = url + "/" + orgID;
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };

    try {
      response = await axios.get(url, axiosConfig);
    } catch (err) {
      error = err;
    }
    if (error) {
      dispatch({
        type: FETCH_ORG_FAILURE,
        error: "Failed to fetch organization details"
      });
      dispatch({
        type: FETCH_CARD_FAILURE,
        error: "Failed to fetch card details"
      });
      dispatch({
        type: FETCH_ADDRESS_FAILURE,
        error: "Failed to fetch address"
      });
    } else {
      let organization, billingAddress, cardDetails;

      if (response && response.data) {
        organization = response.data.Organization;
        cardDetails =
          response.data.Organization.BillingInfo.DefaultPaymentSource;
        billingAddress = response.data.Organization.BillingInfo.Address;
        dispatch({ type: FETCH_ORG_SUCCESS, payload: organization });
        dispatch({ type: FETCH_CARD_SUCCESS, payload: cardDetails });
        dispatch({ type: FETCH_ADDRESS_SUCCESS, payload: billingAddress });
      } else {
        dispatch({
          type: FETCH_ORG_FAILURE,
          error: "Failed to fetch organization details"
        });
        dispatch({
          type: FETCH_CARD_FAILURE,
          error: "Failed to fetch card details"
        });
        dispatch({
          type: FETCH_ADDRESS_FAILURE,
          error: "Failed to fetch address"
        });
      }
    }
  } else {
    dispatch({
      type: FETCH_ORG_FAILURE,
      error: "Failed to fetch organization details"
    });
    dispatch({
      type: FETCH_CARD_FAILURE,
      error: "Failed to fetch card details"
    });
    dispatch({
      type: FETCH_ADDRESS_FAILURE,
      error: "Failed to fetch address"
    });
  }
};

export const fetchCurrentPackage = () => async dispatch => {
  let response, error, axiosConfig, orgID;
  let data = store.getData("data");
  dispatch({ type: FETCH_ACTIVE_PLAN_REQUEST });
  if (data) {
    orgID = data.User.Roles[0].OrgID;
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };

    let url =
      JSON.parse(JSON.stringify(jsonData)).api +
      `/v1/billing/organizations/${orgID}/plan`;

    try {
      response = await axios.get(url, axiosConfig);
    } catch (err) {
      error = err;
    }

    if (error) {
      dispatch({
        type: FETCH_ACTIVE_PLAN_FAILURE,
        error: "Unable to fetch active package"
      });
    } else {
      let subscriptionInfo;
      if (response && response.data) {
        subscriptionInfo = response.data.SubscriptionInfo;
        dispatch({
          type: FETCH_ACTIVE_PLAN_SUCCESS,
          payload: subscriptionInfo
        });
      } else {
        dispatch({
          type: FETCH_ACTIVE_PLAN_FAILURE,
          error: "Unable to fetch active package"
        });
      }
    }
  } else {
    dispatch({
      type: FETCH_ACTIVE_PLAN_FAILURE,
      error: "Unable to fetch active package"
    });
  }
};

export const fetchServiceAgreementInfo = () => async dispatch => {
  let response, error, axiosConfig;
  let data = store.getData("data");
  dispatch({ type: FETCH_SERV_AGREEMENT_REQUEST });

  let url =
    JSON.parse(JSON.stringify(jsonData)).api + "v1/billing/serviceagreement";
  try {
    response = await axios.get(url);
  } catch (err) {
    error = err;
  }
  if (error) {
    dispatch({
      type: FETCH_SERV_AGREEMENT_FAILURE,
      error: "Unable to fetch service agreement info"
    });
  } else {
    let servAgreementInfo;
    if (response && response.data) {
      servAgreementInfo = response.data;
      dispatch({
        type: FETCH_SERV_AGREEMENT_SUCCESS,
        payload: servAgreementInfo
      });
    } else {
      dispatch({
        type: FETCH_SERV_AGREEMENT_FAILURE,
        error: "Unable to fetch service agreement info"
      });
    }
  }
};

export const cancelSubscription = plan => async dispatch => {
  let error, axiosConfig, orgID;
  let data = store.getData("data");
  dispatch({ type: CANCEL_PLAN_REQUEST });
  if (data) {
    orgID = data.User.Roles[0].OrgID;
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };

    let url =
      JSON.parse(JSON.stringify(jsonData)).api +
      `v1/billing/organizations/${orgID}/plan/${plan}`;

    try {
      await axios.delete(url, axiosConfig);
    } catch (err) {
      error = err;
    }
    if (error) {
      dispatch({
        type: CANCEL_PLAN_FAILURE,
        error: "Error occured during cancel plan"
      });
    } else {
      dispatch({ type: CANCEL_PLAN_SUCCESS, payload: null });
      // fetch organization again to update the state
      dispatch(fetchOrganization());
    }
  } else {
    dispatch({
      type: CANCEL_PLAN_FAILURE,
      error: "Error occured during cancel plan"
    });
  }
};

export const fetchInvoices = () => async dispatch => {
  let response, error, axiosConfig, orgID;
  let data = store.getData("data");
  if (data) {
    orgID = data.User.Roles[0].OrgID;
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };
    let url =
      JSON.parse(JSON.stringify(jsonData)).api +
      `/v1/billing/organizations/${orgID}/invoices`;

    try {
      response = await axios.get(url, axiosConfig);
    } catch (err) {
      error = err;
    }
    if (error) {
      dispatch({ type: FETCH_INVOICES_ERROR, payload: response });
    } else {
      dispatch({ type: FETCH_INVOICES_SUCCESS, payload: response });
    }
  }
};

// TODO This is a temperoary hack to register the already created organizations.
// Registeration to billing will be done on creating an orgainization
const registerBilling = async orgID => {
  let data = store.getData("data");
  let axiosConfig = null,
    response,
    error = null,
    organization = null,
    registrationUrl;

  if (data) {
    axiosConfig = {
      headers: {
        Authorization: "Bearer " + data.Token.access_token
      }
    };
    registrationUrl =
      JSON.parse(JSON.stringify(jsonData)).api +
      `v1/billing/organizations/${orgID}`;
  }
  try {
    response = await axios.post(registrationUrl, {}, axiosConfig);
    if (response && response.data) {
      organization = response.data.Organization;
    }
  } catch (err) {
    error = err;
  }
  if (!error && organization) {
    return true;
  } else {
    return false;
  }
};

export const checkTokenExpiry = async () => {
  const api = JSON.parse(JSON.stringify(jsonData)).api;
  if (store.getData("data") != null && store.getData("orgs") != null) {
    const axiosConfig = {
      headers: {
        Authorization: "Bearer " + store.getData("data").Token.access_token,
      },
    };
    const roles = store.getData("data").User.Roles;
    try {
      for (let c = 0; c < roles.length; c++) {
        if (roles[c].RoleID === 1) {
          const url =
            api +
            "v1/organizations/" +
            store.getData("data").User.Roles[c].OrgID;
          const response = await axios.get(url, axiosConfig);
          if (response.status === 200) {
            break;
          }
        }
      }
    } catch (error) {
      if (error.response.status === 401) {
        const url = api + "v1/users/token";
        const body = {
          refreshtoken: store.getData("data").Token.refresh_token,
          clientid: "igrant-ios-app",
        };
        try {
          const response = await axios.post(url, body);
          if (response.status === 200) {
            const userData = store.getData("data");
            userData.Token = response.data;
            store.writeData("data", userData);
            return true
          }
        } catch (error) {
          store.clear()
          return false
        }
      }
    }
  } else {
    store.clear()
    return false
  }
};

export const checkIsAuthenticated = () => async dispatch => {
  const api = JSON.parse(JSON.stringify(jsonData)).api
  if (
    store.getData("data") != null &&
    store.getData("orgs") != null
  ) {
    const axiosConfig = {
      headers: {
        Authorization: "Bearer " + store.getData("data").Token.access_token,
      },
    };
    const roles = store.getData("data").User.Roles;
    try {
      for (let c = 0; c < roles.length; c++) {
        if (roles[c].RoleID === 1) {


          const url = api + "v1/organizations/" + store.getData("data").User.Roles[c].OrgID;

          const response = await axios.get(url, axiosConfig);

          if (response.status && response.status === 200) {
            dispatch({ type: IS_LOGIN, payload: { isLogin: true } });
            break;
          }


        }
      }
    } catch (error) {


      if (error.response && error.response.status && error.response.status === 401) {


        const url = api + "v1/users/token";
        const body = {
          refreshtoken: store.getData("data").Token.refresh_token,
          clientid: "igrant-ios-app",
        };


        try {


          const response = await axios.post(url, body);


          if (response.status && response.status === 200) {


            const userData = store.getData("data");
            userData.Token = response.data;
            store.writeData("data", userData);
            dispatch({ type: IS_LOGIN, payload: { isLogin: true } });


          }


        } catch (error) {
          dispatch({ type: IS_LOGIN, payload: { isLogin: false } });
        }
      }
    }
  } else {
    dispatch({ type: IS_LOGIN, payload: { isLogin: false } });
  }
}

export const checkAPIKeyExpiry = () => async (dispatch) => {
  if (store.getData("data") != null && store.getData("orgs") != undefined) {
    if (store.getData("data").User.APIKey.trim().length > 0) {
      const decodedApi = jwtDecode(store.getData("data").User.APIKey);
      if (decodedApi.exp * 1000 < Date.now()) {
        dispatch({ type: IS_API_KEY_VALID, payload: { apiKeyExpired: true } });
      }
    }


  }
};

export const setAPIKeyValid = () => async (dispatch) => {
  dispatch({ type: IS_API_KEY_VALID, payload: { apiKeyExpired: false } });
};