import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { Field, formValueSelector, reduxForm } from "redux-form";
import * as actions from "../../actions";
import CardValidation from "./CardValidation";

class OnboardingPaymentDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {}
    };
    this.handlePaymentDetailsSubmit = this.handlePaymentDetailsSubmit.bind(
      this
    );
  }
  handlePaymentDetailsSubmit(values) {
    const { onPaymentDetailsSubmit } = this.props;
    onPaymentDetailsSubmit();
  }

  render() {
    const { onAdminDetailsSubmit, handleSubmit, onCancel } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit(this.handlePaymentDetailsSubmit)}>
          <Card className="h-100 w-100">
            <CardBody>
              <CardTitle className="card-title mb-3">
                Payment Information
              </CardTitle>

              <Field
                component={CardValidation}
                name="onboardingCard"
                id="onboardingCard"
              />
              {!(
                this.props.paymentSource && this.props.paymentSource.last4
              ) && (
                <div className="mt-2 mb-2" style={{ fontSize: "14px" }}>
                  {" "}
                  Please add your credit card to proceed further
                </div>
              )}
              <div className="mt-3">
                <Button
                  className="btn btn-default btn-outline-secondary text-left"
                  //style={{ margin: "0px 15px" }}
                  onClick={onCancel}
                >
                  <MdChevronLeft />
                  Prev
                </Button>

                {
                  <Button
                    type="submit"
                    className="btn btn-default btn-outline-secondary text-left"
                    disabled={
                      !(
                        this.props.paymentSource &&
                        this.props.paymentSource.last4
                      )
                    }
                  >
                    Next
                    <MdChevronRight />
                  </Button>
                }
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("signUpWizard");

  return {
    paymentSource: selector(state, "onboardingCard.card")
  };
}
export default connect(
  mapStateToProps,
  actions
)(
  withRouter(
    reduxForm({
      form: "signUpWizard",
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
      enableReinitialize: true,
      keepDirtyOnReinitialize: true
    })(OnboardingPaymentDetailsForm)
  )
);
