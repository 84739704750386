import {
    ADD_PERSONAL_DATA_IN_DATA_AGREEMENT,
    INIT_DATA_AGREEMENT_STATE,
    REMOVE_PERSONAL_DATA_IN_DATA_AGREEMENT,
    UPDATE_PERSONAL_DATA_IN_DATA_AGREEMENT,
    UPDATE_DATA_CONTROLLER_NAME,
    UPDATE_JURISDICTION,
    UPDATE_INDUSTRY_SECTOR,
    UPDATE_DATA_RETENTION_PERIOD,
    UPDATE_POLICY_URL,
    UPDATE_DATA_CONTROLLER_URL,
    UPDATE_LAWFUL_BASIS,
    UPDATE_USAGE_PURPOSE,
    UPDATE_USAGE_PURPOSE_DESCRIPTION,
    UPDATE_METHOD_OF_USE,
    REPLACE_ALL_PERSONAL_DATA,
    UPDATE_DATA_SOURCE_CONFIGURATION,
    INIT_DATA_SOURCE_CONFIGURATION,
    TOGGLE_CONFIGURE_DATA_SOURCE_PANEL,
    VALIDATE_DATA_AGREEMENT_FORM,
    INIT_DATA_USING_SERVICE_CONFIGURATION,
    TOGGLE_CONFIGURE_DATA_USING_SERVICE_PANEL,
    UPDATE_GEOGRAPHIC_RESTRICTION,
    POPULATE_DATA_AGREEMENTS_TABLE,
    TOGGLE_DATA_AGREEMENT_PANEL,
    UPDATE_DATA_AGREEMENT_PANEL_MODE,
    REPLACE_DATA_AGREEMENT_STATE,
    INIT_DATA_AGREEMENT_PANEL_MODE,
    UPDATE_DATA_AGREEMENTS_TABLE_FILTER,
    POPULATE_PERSONAL_DATA_TABLE,
    UPDATE_PERSONAL_DATA_TABLE_FILTER,
    DATA_AGREEMENTS_TABLE_UPDATE_IS_TABLE_LOADING,
    DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_TOTAL_COUNT,
    DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_CURRENT_PAGE,
    DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_PAGE_SIZE,
    RESET_DATA_AGREEMENTS_TABLE,
    RESET_PERSONAL_DATA_TABLE,
    PERSONAL_DATA_TABLE_UPDATE_IS_TABLE_LOADING,
    PERSONAL_DATA_TABLE_UPDATE_PAGINATION_TOTAL_COUNT,
    PERSONAL_DATA_TABLE_UPDATE_PAGINATION_CURRENT_PAGE,
    PERSONAL_DATA_TABLE_UPDATE_PAGINATION_PAGE_SIZE
} from "../actions/action_types";


// Initial state for data agreement.
export const initDataAgreementState = {
    dataControllerName: "",
    dataControllerUrl: "",
    dataPolicy: {
        policyUrl: "https://igrant.io/policy_default.html",
        jurisdiction: "",
        industrySector: "",
        dataRetentionPeriod: 0,
        geographicRestriction: "Europe",
        storageLocation: "Europe",
    },
    usagePurpose: "",
    usagePurposeDescription: "",
    lawfulBasis: "consent",
    methodOfUse: "data-source",
    personalData: [
        {
            attributeName: "",
            attributeDescription: "",
        }
    ],
    isValid: false,
    templateId: "",
    templateVersion: 1,
};

// Data agreement state reducer.
export const DataAgreementStateUpdate = (state = initDataAgreementState, action) => {

    switch (action.type) {

        // Add personal data to data agreement state.
        case ADD_PERSONAL_DATA_IN_DATA_AGREEMENT:
            return { ...state, personalData: [...state.personalData, action.payload.personalData] };

        // Remove personal data from data agreement state.
        case REMOVE_PERSONAL_DATA_IN_DATA_AGREEMENT:
            return { ...state, personalData: state.personalData.filter((pd, index) => index !== action.payload.index) };

        // Update personal data in data agreement state.
        case UPDATE_PERSONAL_DATA_IN_DATA_AGREEMENT:
            return { ...state, personalData: state.personalData.map((pd, index) => { return index === action.payload.index ? { ...pd, ...action.payload.personalData } : pd }) };

        // Update data controller name in data agreement state.
        case UPDATE_DATA_CONTROLLER_NAME:
            return { ...state, dataControllerName: action.payload.dataControllerName };

        // Update jurisdiction in data policy in data agreement state.
        case UPDATE_JURISDICTION:
            return { ...state, dataPolicy: { ...state.dataPolicy, jurisdiction: action.payload.jurisdiction } };

        // Update industry sector in data policy in data agreement state.
        case UPDATE_INDUSTRY_SECTOR:
            return { ...state, dataPolicy: { ...state.dataPolicy, industrySector: action.payload.industrySector } };

        // Update data retention period in data policy in data agreement state.
        case UPDATE_DATA_RETENTION_PERIOD:
            return { ...state, dataPolicy: { ...state.dataPolicy, dataRetentionPeriod: action.payload.dataRetentionPeriod } };

        // Update policy url in data policy in data agreement state.
        case UPDATE_POLICY_URL:
            return { ...state, dataPolicy: { ...state.dataPolicy, policyUrl: action.payload.policyUrl } };

        // Update data controller url in data policy in data agreement state.
        case UPDATE_DATA_CONTROLLER_URL:
            return { ...state, dataControllerUrl: action.payload.dataControllerUrl };

        // Update lawful basis in data policy in data agreement state.
        case UPDATE_LAWFUL_BASIS:
            return { ...state, lawfulBasis: action.payload.lawfulBasis };

        // Update usage purpose in data policy in data agreement state.
        case UPDATE_USAGE_PURPOSE:
            return { ...state, usagePurpose: action.payload.usagePurpose };

        // Update usage purpose description in data policy in data agreement state.
        case UPDATE_USAGE_PURPOSE_DESCRIPTION:
            return { ...state, usagePurposeDescription: action.payload.usagePurposeDescription };

        // Update method of use in data policy in data agreement state.
        case UPDATE_METHOD_OF_USE:
            return { ...state, methodOfUse: action.payload.methodOfUse };

        case UPDATE_GEOGRAPHIC_RESTRICTION:
            return { ...state, dataPolicy: { ...state.dataPolicy, geographicRestriction: action.payload.geographicRestriction } };

        // Replace all personal data in data agreement state.
        case REPLACE_ALL_PERSONAL_DATA:
            return { ...state, personalData: action.payload.personalData };

        // Reset data agreement state.
        case INIT_DATA_AGREEMENT_STATE:
            state = initDataAgreementState
            return state;

        // Valid status of data agreement form.
        case VALIDATE_DATA_AGREEMENT_FORM:
            return { ...state, isValid: action.payload.isValid };

        // Replace data agreement state
        case REPLACE_DATA_AGREEMENT_STATE:
            return { ...state, ...action.payload.dataAgreementState };

        default:
            return state;
    }
};

// Data source configuration init state.
export const initConfigureDataSource = {
    isExisting: false, // true if schema identifier exists in ledger
    schemaId: "", // schema identifier
    openConfigureDataSourcePanel: false, // true if "CONFIGURE" data source panel is open
    isAttributesPopulatedInDataAgreement: false, // true if attributes from schemaId are populated in data agreement
}


// Data source configuration "panel" state reducer.
export const ConfigureDataSource = (state = initConfigureDataSource, action) => {


    switch (action.type) {

        // Reset data source configuration state.
        case INIT_DATA_SOURCE_CONFIGURATION:
            state = initConfigureDataSource
            return state;

        // Update data source configuration
        case UPDATE_DATA_SOURCE_CONFIGURATION:
            return { ...state, ...action.payload.dataSourceConfiguration };

        // Toggle "CONFIGURE" button for data source.
        case TOGGLE_CONFIGURE_DATA_SOURCE_PANEL:
            return { ...state, openConfigureDataSourcePanel: !state.openConfigureDataSourcePanel };

        default:
            return state;
    }
}

// Data using service configuration init state.
const initConfigureDataUsingService = {
    openConfigureDataUsingServicePanel: false, // true if "CONFIGURE" data using service panel is open
}

// Data using service "panel" state reducer.
export const ConfigureDataUsingService = (state = initConfigureDataUsingService, action) => {

    switch (action.type) {

        // Reset data using service configuration state.
        case INIT_DATA_USING_SERVICE_CONFIGURATION:
            state = initConfigureDataUsingService
            return state;

        // Toggle "CONFIGURE" button for data using service.
        case TOGGLE_CONFIGURE_DATA_USING_SERVICE_PANEL:
            return { ...state, openConfigureDataUsingServicePanel: !state.openConfigureDataUsingServicePanel };

        default:
            return state;
    }

}

// Data agreements table init state.
const initDataAgreementTable = {
    dataAgreements: [],
    filter: "All",
    isTableLoading: false,
    paginationTotalCount: 0,
    paginationCurrentPage: 1,
    paginationPageSize: 10,
}

// Data agreements table reducer.
export const DataAgreementTable = (state = initDataAgreementTable, action) => {

    switch (action.type) {

        // Reset data agreements table state to initial state.
        case RESET_DATA_AGREEMENTS_TABLE:
            state = initDataAgreementTable
            return state;

        // Populate data agreements table with data agreements. (this includes personal data)
        case POPULATE_DATA_AGREEMENTS_TABLE:
            return {
                ...state,
                dataAgreements: action.payload.dataAgreements,
            };

        // Update data agreement table filter state. (All / Published)
        case UPDATE_DATA_AGREEMENTS_TABLE_FILTER:

            switch (action.payload.filter) {

                case "All":
                    return {
                        ...state,
                        filter: action.payload.filter,
                    };

                case "Published":

                    return {
                        ...state,
                        filter: action.payload.filter,
                    };

            }

        // Update data agreement table pagination - is table loading state.
        case DATA_AGREEMENTS_TABLE_UPDATE_IS_TABLE_LOADING:
            return { ...state, isTableLoading: action.payload.isTableLoading };

        // Update data agreement table pagination - total count state.
        case DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_TOTAL_COUNT:
            return { ...state, paginationTotalCount: action.payload.totalCount };

        // Update data agreement table pagination - current page state.
        case DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_CURRENT_PAGE:
            return { ...state, paginationCurrentPage: action.payload.currentPage };

        // Update data agreement table pagination - page size state.
        case DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_PAGE_SIZE:
            return { ...state, paginationPageSize: action.payload.pageSize };

        default:
            return state;

    }


}


// Personal data table init state

const initPersonalDataTable = {
    filter: "All",
    personalData: [],
    isTableLoading: false,
    paginationTotalCount: 0,
    paginationCurrentPage: 1,
    paginationPageSize: 10,

}

// Personal data table reducer
export const PersonalDataTable = (state = initPersonalDataTable, action) => {

    switch (action.type) {

        // Reset personal data table state to initial state.
        case RESET_DATA_AGREEMENTS_TABLE:
            state = initPersonalDataTable
            return state;

        // Populate personal data table with personal data.
        case POPULATE_PERSONAL_DATA_TABLE:
            return {
                ...state,
                personalData: action.payload.personalData,
            };

        // Update personal data table filter state. (All / Data Source/ Data Using Service)
        case UPDATE_PERSONAL_DATA_TABLE_FILTER:

            return {
                ...state,
                filter: action.payload.filter,
            };

        // Update personal data table pagination - is table loading state.
        case PERSONAL_DATA_TABLE_UPDATE_IS_TABLE_LOADING:
            return { ...state, isTableLoading: action.payload.isTableLoading };

        // Update personal data table pagination - total count state.
        case PERSONAL_DATA_TABLE_UPDATE_PAGINATION_TOTAL_COUNT:
            return { ...state, paginationTotalCount: action.payload.totalCount };

        // Update personal data table pagination - current page state.
        case PERSONAL_DATA_TABLE_UPDATE_PAGINATION_CURRENT_PAGE:
            return { ...state, paginationCurrentPage: action.payload.currentPage };

        // Update personal data table pagination - page size state.
        case PERSONAL_DATA_TABLE_UPDATE_PAGINATION_PAGE_SIZE:
            return { ...state, paginationPageSize: action.payload.pageSize };

        default:
            return state;
    }
}


// Data agreement panel init state. (CRUD panel)
const initDataAgreementPanel = {
    isOpen: false,
    mode: "", // "CREATE" or "EDIT" or "READ"
}

// Data agreement panel reducer.
export const DataAgreementPanel = (state = initDataAgreementPanel, action) => {

    switch (action.type) {

        // Reset data agreement panel state.
        case INIT_DATA_AGREEMENT_PANEL_MODE:
            state = initDataAgreementPanel;
            return state;

        // Toggle data agreement panel.
        case TOGGLE_DATA_AGREEMENT_PANEL:
            return { ...state, isOpen: !state.isOpen };

        // Update data agreement panel mode.
        case UPDATE_DATA_AGREEMENT_PANEL_MODE:
            return { ...state, mode: action.payload.mode };

        default:
            return state;

    }

}
