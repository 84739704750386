import Steps, { Step } from "rc-steps";
import React, { Component } from "react";
import { MdDone, MdErrorOutline } from "react-icons/lib/md";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { CardText, CardTitle, Col, Row } from "reactstrap";
import Page from "../../components/Page";
import "../../styles/components/rc_steps_iconfont.css";
import "../../styles/components/rc_steps_index.css";
import BillingAddressForm from "./BillingAddressForm";
import CommittedUsageForm from "./CommittedUsageForm";
import OrderConfirmationForm from "./OrderConfirmationForm";
import PaymentDetailsForm from "./PaymentDetailsForm";
import ServiceAgreementForm from "./ServiceAgreementForm";

const icons = {
  finish: <MdDone />,
  error: <MdErrorOutline />
};

const planNamesMap = {freetrail: 'freeTrial', starter: 'starter', freetrialverifier:'freeTrialVerifier', starterverifier:'starterVerifier', premiumverifier:'premiumVerifier'}

class OrderingWizard extends Component {
  constructor(props) {
    super(props);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.onWizardComplete = this.onWizardComplete.bind(this);
    this.state = {
      currentPage: 1
    };
  }

  nextPage() {
    this.setState({ currentPage: this.state.currentPage + 1, hasError: false });
  }

  previousPage() {
    this.setState({ currentPage: this.state.currentPage - 1, hasError: false });
  }

  onWizardComplete() {
    this.props.history.push("/billing");
  }

  renderStepsForPayPerUser(renderForSmallDevices) {
    if (renderForSmallDevices) {
      return (
        <Steps
          labelPlacement="vertical"
          initial={0}
          size="small"
          current={this.state.currentPage - 1}
          icons={icons}
        >
          <Step title="" />
          <Step title="" />
          <Step title="" />
          <Step title="" />
          <Step title="" />
        </Steps>
      );
    } else {
      return (
        <Steps
          labelPlacement="vertical"
          initial={0}
          current={this.state.currentPage - 1}
          icons={icons}
        >
          <Step title="Committed Usage" />
          <Step title="Service Agreement" />
          <Step title="Billing Address" />
          <Step title="Payment Details" />
          <Step title="Review & Confirm Order" />
        </Steps>
      );
    }
  }

  renderFormForPayPerUser(plan, agreementType) {
    const { currentPage } = this.state;
    return (
      <div>
        {currentPage === 1 && (
          <CommittedUsageForm onCommitmentSubmit={this.nextPage} />
        )}
        {currentPage === 2 && (
          <ServiceAgreementForm
            type={agreementType}
            onCancel={this.previousPage}
            onAgreementSubmit={this.nextPage}
          />
        )}
        {currentPage === 3 && (
          <BillingAddressForm
            onAddressSubmit={this.nextPage}
            onCancel={this.previousPage}
          />
        )}
        {currentPage === 4 && (
          <PaymentDetailsForm
            onPaymentDetailsSubmit={this.nextPage}
            onCancel={this.previousPage}
            plan={plan}
          />
        )}
        {currentPage === 5 && (
          <OrderConfirmationForm
            onOrderComplete={this.nextPage}
            onCancel={this.previousPage}
            plan={plan}
            planPrice={this.props.pricingInfo.starter.price}
          />
        )}
      </div>
    );
  }

  renderStepsForStarterAndFreeTrial(renderForSmallDevices) {
    if (renderForSmallDevices) {
      return (
        <Steps
          labelPlacement="vertical"
          initial={0}
          size="small"
          current={this.state.currentPage - 1}
          icons={icons}
        >
          <Step title="" />
          <Step title="" />
          <Step title="" />
          <Step title="" />
        </Steps>
      );
    } else {
      return (
        <Steps
          labelPlacement="vertical"
          initial={0}
          current={this.state.currentPage - 1}
          icons={icons}
        >
          <Step title="Service Agreement" />
          <Step title="Billing Address" />
          <Step title="Payment Details" />
          <Step title="Review & Confirm Order" />
        </Steps>
      );
    }
  }
  // <Step title="Order Confirmation" />
  renderFormForStarterAndFreeTrial(plan, agreementType) {
    const { currentPage } = this.state;
    return (
      <div>
        {currentPage === 1 && (
          <ServiceAgreementForm type={agreementType} onAgreementSubmit={this.nextPage} />
        )}
        {currentPage === 2 && (
          <BillingAddressForm
            onAddressSubmit={this.nextPage}
            onCancel={this.previousPage}
          />
        )}
        {currentPage === 3 && (
          <PaymentDetailsForm
            onPaymentDetailsSubmit={this.nextPage}
            onCancel={this.previousPage}
            plan={plan}
          />
        )}
        {currentPage === 4 && (
          <OrderConfirmationForm
            onOrderComplete={this.nextPage}
            onCancel={this.previousPage}
            plan={plan}
            planPrice={this.props.pricingInfo[planNamesMap[plan]].price}
          />
        )}
      </div>
    );
  }

  redirectToBillingDashboard() {
    this.props.history.push("/billing");
  }

  componentDidMount() {
    const { plan } = this.props.location.state;

    if (!this.props.organization || !plan || !this.props.pricingInfo) {
      this.redirectToBillingDashboard();
    }
  }
  render() {
    const { plan } = this.props.location.state;

    return (
      <Page
        title=""
        breadcrumbs={[
          { name: "Billing", active: false },
          { name: "Package Selector", active: true }
        ]}
      >
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            <CardTitle>Ordering</CardTitle>
            <CardText style={{ fontSize: "14px" }}>
              Please follow the steps to complete your order:
            </CardText>
          </Col>
        </Row>

        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12} xs={12} className="pl-0">
            <div>
              <div className="d-none d-md-block pl-5 pr-5">
                {plan === "payperuser" && this.renderStepsForPayPerUser()}
                {(plan === "starter" || plan === "starterverifier"|| plan ==="premiumverifier") && this.renderStepsForStarterAndFreeTrial()}
                {(plan === "freetrial" || plan === "freetrialverifier") &&
                  this.renderStepsForStarterAndFreeTrial()}
              </div>
              <div className="d-block d-md-none">
                {" "}
                {plan === "payperuser" && this.renderStepsForPayPerUser(true)}
                {(plan === "starter" || plan === "starterverifier"|| plan ==="premiumverifier")&&
                  this.renderStepsForStarterAndFreeTrial(true)}
                {(plan === "freetrial" || plan === "freetrialverifier") &&
                  this.renderStepsForStarterAndFreeTrial(true)}
              </div>
              {plan === "payperuser" &&
                this.renderFormForPayPerUser("payperuser", 'ServiceAgreementInfo')}
              {plan === "starter" &&
                this.renderFormForStarterAndFreeTrial("starter", 'ServiceAgreementInfo')}
              {plan === "freetrial" &&
                this.renderFormForStarterAndFreeTrial("freetrial", 'ServiceAgreementInfo')}
              {(plan === "freetrialverifier" || plan === "starterverifier" ||plan === "premiumverifier")&&
                this.renderFormForStarterAndFreeTrial(plan, 'VerifierServiceAgreementInfo')}
            </div>
          </Col>
        </Row>
      </Page>
    );
  }
}
function mapStateToProps({ organization, pricingInfo }) {
  return {
    organization,
    pricingInfo
  };
}
export default withRouter(
  connect(
    mapStateToProps,
    null
  )(OrderingWizard)
);
