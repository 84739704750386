//Subscrption Version 2.3
import React from "react";
import jsonData from "../setting/settings.json";
import Select from "react-select";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import { MdAddCircleOutline } from "react-icons/lib/md";
import { withRouter, Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  CardBody,
  ButtonGroup,
  Modal,
} from "reactstrap";
import { FaEdit, FaTrashO } from "react-icons/lib/fa";
import { store } from "../services/localStorageStore";
import { GetOrganizationDetails } from "../components/Sources";
import Page from "../components/Page";
import Toggle from "../components/Toggle";
import AddSubscriptionMethodModal from "../components/AddSubscriptionMethodModal";
import EditSubscriptionMethodModal from "../components/EditSubscriptionMethodModal";
import GeneralDeleteModal from "../components/GeneralDeleteModal"

const api = JSON.parse(JSON.stringify(jsonData)).api;

let subUserCount = "",
  subKey = "",
  subMethod = "",
  setMethodData = "";

const customStylesDD = {
  control: (base, state) => ({
    ...base,
    "&:hover": { borderColor: "gray" }, // border style on hover
    border: "1px solid #DFDFDF", // default border color
    boxShadow: "none", // no box-shadow
    fontSize: "14px",
    fontColor: "#808080",
    top: "-6px",
    padding: 0,
    margin: 0,
    backgroundColor: "white", //'#F8F9FA',
    borderRadius: 0,
    outline: "none",
  }),
};

const OrgSubMethodOptions = [
  { value: 1, label: "Key-Based" },
  /* { value: 2, label: "Bank ID" },
     { value: 3, label: "Org IDAM" },*/
];

class SubscriptionPage extends React.Component {
  constructor() {
    super();
    this.getDetails = this.getDetails.bind(this);
    this.handleRenew = this.handleRenew.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.toggleSub = this.toggleSub.bind(this);
    this.triggerToggleSubMethod = this.triggerToggleSubMethod.bind(this);
    this.triggerToggleEditSubMethod = this.triggerToggleEditSubMethod.bind(
      this
    );
    this.deleteMethod = this.deleteMethod.bind(this);
    this.deleteToggle = this.deleteToggle.bind(this);
  }

  state = {
    show: true,
    SubscribeUserCount: subUserCount,
    OrgSubMethodSelected: setMethodData,
    SubscribeKey: subKey,
    SubscribeMethod: subMethod,
    toggleSubMethod: false,
    toggleEditSubMethod: false,
    availableSubscriptionMethod: [],
    openIDRedirectUri: '',
    toggleDelete: false
  };

  deleteToggle() {
    this.setState({ toggleDelete: !this.state.toggleDelete });
  }
  
  triggerToggleSubMethod() {
    this.setState({ toggleSubMethod: !this.state.toggleSubMethod });
  }

  triggerToggleEditSubMethod() {
    this.setState({ toggleEditSubMethod: !this.state.toggleEditSubMethod });
  }

  deleteMethod() {



    if (
      store.getData("data") != null &&
      store.getData("orgs") != null
    ) {

      const axiosConfig = {
        headers: {
          Authorization: "Bearer " + store.getData("data").Token.access_token,
        },
      };
      if (this.state.OrgSubMethodSelected.length) {
        if (this.state.OrgSubMethodSelected[0].ID === 2) {
          const url =
            api + `v1/organizations/${store.getData("orgs").ID}/idp/open-id`;
          axios
            .delete(url, axiosConfig)
            .then((response) => {
              if (response.status === 204) {
                const url =
                  api +
                  `v1/organizations/${store.getData("orgs").ID}/subscribe-method`;
                axios
                  .post(url, { subscribemethodid: 0 }, axiosConfig)
                  .then((response) => {
                    this.getDetails();
                  })
                  .catch((err) => console.log(err));
              }
            })
            .catch((err) => console.log(err));
        } else {
          const url =
            api + `v1/organizations/${store.getData("orgs").ID}/subscribe-method`;
          axios
            .post(url, { subscribemethodid: 0 }, axiosConfig)
            .then((response) => {
              this.getDetails();
            })
            .catch((err) => console.log(err));
        }
      }

    } else {
    }




    this.deleteToggle()
  }

  getDetails() {
    let data = store.getData("data");
    let orgs = store.getData("orgs");
    var url = "";
    if (data && orgs) {
      var axiosConfig = {
        headers: {
          Authorization: "Bearer " + data.Token.access_token,
        },
      };
      //v1/organizations/<org-id>/users/count
      url =
        api + "v1/organizations/" + store.getData("orgs").ID + "/users/count";

      axios
        .get(url, axiosConfig)
        .then((response) => {
          subUserCount = response.data.SubscribeUserCount;
          this.setState({
            SubscribeUserCount: response.data.SubscribeUserCount,
          });
        })
        .catch((err) => {
          subUserCount = 0;
          this.setState({ SubscribeUserCount: 0 });
        });
      url =
        api +
        "v1/organizations/" +
        store.getData("orgs").ID +
        "/subscribe-method";

      axios.get(url, axiosConfig).then((response) => {
        const data = response.data.ID ? [response.data] : [];
        this.setState({ OrgSubMethodSelected: data });
      });
      url = api + "v1/organizations/subscribe-methods";
      axios.get(url, axiosConfig).then((response) => {
        let data = response.data.Methods.filter((method) => method.ID > 0);
        data = data.map((method) => {
          return { value: method.ID, label: method.Method };
        });
        this.setState({ availableSubscriptionMethod: data });
      });
      url = api + `/v1/organizations/${store.getData("orgs").ID}/idp/open-id/redirect-uri`;
      axios.get(url, axiosConfig).then(response => {
        this.setState({ openIDRedirectUri: response.data.RedirectURI })
      })
    } else {
      subUserCount = 0;
    }
  }

  toggleSub(val) {
    if (store.getData("data") != null) {
      let data = store.getData("data");

      var datam = {};

      var axiosConfig = {
        headers: {
          Authorization: "Bearer " + data.Token.access_token,
        },
      };
      var url = "";

      if (val === "ON") {
        url =
          api +
          "v1/organizations/" +
          store.getData("orgs").ID +
          "/subscription/enable";
      } else {
        url =
          api +
          "v1/organizations/" +
          store.getData("orgs").ID +
          "/subscription/disable";
      }
      axios.post(url, datam, axiosConfig).then((response) => {
        store.writeData("orgs", response.data.Organization);
        // this.getDetails();
      });
    }
  }

  handleCancel() { }

  handleRenew() {
    if (store.getData("data") != null) {
      let data = store.getData("data");

      var datam = {
        SubscribeMethodID: 2,
      };

      var axiosConfig = {
        headers: {
          Authorization: "Bearer " + data.Token.access_token,
        },
      };

      var url =
        api +
        "v1/organizations/" +
        store.getData("orgs").ID +
        "/subscribe-key/renew";
      axios.post(url, datam, axiosConfig).then((response) => {
        let data = response.data;
        subKey = data.SubscribeKey;
        this.setState({ SubscribeKey: subKey });
        // this.state.SubscribeKey =data.SubscribeKey;
      });
    }
  }
  handleChange = (OrgSubMethodSelected) => {
    ///Time being locked from selection
    this.setState({ OrgSubMethodSelected });
    if (JSON.stringify(OrgSubMethodSelected).length === 2) {
      setMethodData = null;
      subMethod = null;
      subKey = null;
      this.setState({ SubscribeMethod: null, SubscribeKey: null });
      // this.state.SubscribeMethod=null ;
      // this.state.SubscribeKey=null;
      this.setSubscritionMethod(0);
    } else {
      setMethodData = { value: 1, label: "Key-Based" };
      subMethod = "Key-Based";
      this.state.SubscribeMethod = "Key-Based";
      this.setSubscritionMethod(1);

      //add code here to get deatils
      let data = store.getData("data");
      let orgs = store.getData("orgs");

      if (data && orgs) {
        ///v1/organizations/{organizationID}/subscribe-key
        var url =
          api +
          "v1/organizations/" +
          store.getData("orgs").ID +
          "/subscribe-key";
        var axiosConfig = {
          headers: { Authorization: "Bearer " + data.Token.access_token },
        };

        axios.get(url, axiosConfig).then((response) => {
          let data = response.data;
          subKey = data.SubscribeKey;
          this.setState({ SubscribeKey: subKey + " " });
        });
      }
    }
  };

  setSubscritionMethod = (subID) => {
    let data = store.getData("data");
    let orgs = store.getData("orgs");

    if (data && orgs) {
      var axiosConfig = {
        headers: { Authorization: "Bearer " + data.Token.access_token },
      };
      var url =
        api +
        "v1/organizations/" +
        store.getData("orgs").ID +
        "/subscribe-method";
      var datam = {
        SubscribeMethodID: subID,
      };
      axios.get(url, axiosConfig).then((response) => {
        axios.post(url, datam, axiosConfig);
      });
    }
  };

  componentDidMount() {
    GetOrganizationDetails();
    this.getDetails();
  }

  render() {
    const colDispStyle = {
      fontWeight: "bold",
      fontSize: "14px",
      cursor: "pointer",
      padding: ".35rem",
      borderWidth: "1px solid !important",
      borderColor: "#dee2e6",
    };
    const buttonDispStyle = { width: "3%" };
    const colDispStyleButton = {
      fontSize: "14px",
      cursor: "pointer",
      borderWidth: "1px 0px",
      padding: ".35rem",
    };
    const columnSubMethod = [
      {
        dataField: "Method",
        text: "",
        headerStyle: { width: "84%" },
        style: { ...colDispStyle, borderRightWidth: "0px" },
      },
      {
        dataField: "Edit",
        text: "",
        headerStyle: buttonDispStyle,
        style: { ...colDispStyleButton },
        formatter: (cellContent, row) => {
          return (
            <FaEdit
              size="20px"
              onClick={() => {
                this.triggerToggleEditSubMethod();
              }}
            />
          );
        },
      },
      {
        dataField: "Delete",
        text: "",
        headerStyle: buttonDispStyle,
        style: { ...colDispStyleButton, borderLeftWidth: "0px" },
        formatter: (cellContent, row) => {
          return (
            <FaTrashO
              size="20px"
              onClick={() => {
                this.deleteToggle()
              }}
            />
          );
        },
      },
    ];

    if (
      this.state.SubscribeKey === " " &&
      this.state.SubscribeMethod === "Key-Based"
    ) {
      this.getDetails();
    }
    return (
      <Page
        title=""
        breadcrumbs={[
          { name: "Manage End Users / Subscription", active: true },
        ]}
      >
        <Row className="d-flex justify-content-right">
          <Col md="12" sm="12" xs="12">
            <CardTitle>Subscription Management</CardTitle>
            <CardText>To manage end user subscription functions</CardText>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="h-100 w-100">
              <CardBody className="d-flex flex-column justify-content-start ">
                {store.getData("orgs") &&
                  (store.getData("orgs").DataRetention.Enabled ||
                    !store.getData("orgs").DataRetention.Enabled) ? (
                  <ButtonGroup className="d-flex justify-content-between flex-row mb-0">
                    <div className="sub-card">
                      <h5
                        style={{
                          marginBottom: "8px",
                          paddingTop: "2px",
                          fontWeight: "bold",
                        }}
                      >
                        Enable End User Subscription
                      </h5>
                      <Toggle
                        onToggle={this.toggleSub}
                        active={
                          store.getData("orgs") != null
                            ? store.getData("orgs").DataRetention.Enabled
                            : false
                        }
                        activeLabel={"ON"}
                        deactiveLabel={"OFF"}
                      />
                    </div>
                    <div className="sub-card">
                      <h5
                        style={{
                          marginBottom: "8px",
                          paddingTop: "2px",
                          fontWeight: "bold",
                        }}
                      >
                        Current Subscribed Users
                      </h5>
                      <input
                        className="subscription-count-field"
                        type="number"
                        disabled
                        value={this.state.SubscribeUserCount}
                      />
                    </div>
                  </ButtonGroup>
                ) : (
                  <span>
                    <ButtonGroup className="d-flex flex-row justify-content-between mb-0">
                      <CardTitle className="mb-0 pb-0">
                        Enable End User Subscription
                      </CardTitle>
                      <span
                        className="ml-5"
                        style={{
                          fontSize: 16,
                          fontBold: true,
                          fontWeight: 800,
                        }}
                      >
                        DISABLED
                      </span>
                    </ButtonGroup>
                    <p className="card-subtitle mt-1" style={{ fontSize: 14 }}>
                      You need to be enrolled in a package for listing your
                      organisation in iGrant.io mobile application.
                      <br />
                      You can do this in <Link to={"/billing"}>
                        billing
                      </Link>{" "}
                      page. <br />
                      Your users can only subscribe once the organisation is set
                      to enabled.
                    </p>
                  </span>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="subscription-method-container">
            <h4 style={{ fontWeight: "bold" }}>
              {" "}
              Organisation subscription method{" "}
              <MdAddCircleOutline
                size={"25px"}
                style={{ cursor: "pointer" }}
                onClick={this.triggerToggleSubMethod}
              />
            </h4>
            {this.state.OrgSubMethodSelected.length ? (
              <BootstrapTable
                id="subscriptionMethod"
                headerStyle={{ display: "none" }}
                style={{ border: "0px" }}
                data={this.state.OrgSubMethodSelected}
                bootstrap4={true}
                keyField="Method"
                columns={columnSubMethod}
                striped={false}
              ></BootstrapTable>
            ) : (
              " "
            )}
            {this.state.OrgSubMethodSelected.length && this.state.OrgSubMethodSelected[0].ID === 2 ? (
              <CardText className={"openID_info_msg"}>
                Note : Remember to configure <a style={{ fontSize: '14px' }} href={this.state.openIDRedirectUri}>{this.state.openIDRedirectUri}</a> as valid redirect_uri in
                corresponding OpenID client configured in organisation external
                identity provider.
              </CardText>
            ) : (
              ""
            )}
          </Col>
        </Row>
        <Row>
          <Col md="12" sm="12" xs="12" className="mt-0 ">
            <CardTitle></CardTitle>
          </Col>
        </Row>
        <Modal
          backdrop="static"
          isOpen={this.state.toggleSubMethod}
          toggle={this.triggerToggleSubMethod}
        >
          <AddSubscriptionMethodModal
            updateSubscriptionPage={this.getDetails}
            toggle={this.triggerToggleSubMethod}
            availableSubscriptionMethod={this.state.availableSubscriptionMethod}
            OrgSubMethodSelected={this.state.OrgSubMethodSelected}
          />
        </Modal>
        <Modal
          backdrop="static"
          isOpen={this.state.toggleEditSubMethod}
          toggle={this.triggerToggleEditSubMethod}
        >
          <EditSubscriptionMethodModal
            updateSubscriptionPage={this.getDetails}
            toggle={this.triggerToggleEditSubMethod}
            availableSubscriptionMethod={this.state.availableSubscriptionMethod}
            OrgSubMethodSelected={this.state.OrgSubMethodSelected}
          />
        </Modal>
        <GeneralDeleteModal 
          toggle={this.deleteToggle} 
          type='' 
          message='You are about to delete the subscription method. This action is not reversible.'
          delete={ () => this.deleteMethod()}
          toggleState={this.state.toggleDelete}
        />
      </Page>
    );
  }
}
export default SubscriptionPage;
