import React from 'react';
import Popover from '@material-ui/core/Popover';
import { Link } from 'react-router-dom';

export default function PopoverWidget({ element, text, size, link, cursor }) {

  const linkStyle = { fontSize: '14px', fontBold: true, fontWeight: 600, margin: 0, paddingLeft: '4px', textDecoration: 'none' };
  const divStyle = { boxShadow: 'none', outline: 'none', };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const textStyle = {
    color: 'white', fontWeight: 300, fontSize: "14px",
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (

    <span onMouseLeave={handleClose}>
      {element(size, handleClick, handleClick)}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ cursor: cursor ? cursor : 'not-allowed' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="p-2 m-2" style={{ fontSize: '14px', }} onMouseLeave={handleClose}>
          {text}<br />
          {link(linkStyle)}<br />
        </div>
      </Popover>
    </span>
  );
}