import { FETCH_PRICING_INFO } from "../actions/action_types";
export default function(state = null, action) {
  switch (action.type) {
    case FETCH_PRICING_INFO:
      return action.payload && action.payload.data ? action.payload.data : null;
    default:
      break;
  }
  return state;
}
