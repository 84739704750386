import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";

const GeneralDeleteModal = (props) => {
  const [cancelConfirmationText, setCancelConfirmationText] = useState("");
  const [deleteConfirmText, setDeleteConfirmText] = useState(props.confirmText);

  const [isOk, setIsOk] = useState(props.type !== "secure");

  const handleCancelConfirmationText = (event) => {
    setCancelConfirmationText(event.target.value);
    event.target.value === deleteConfirmText ? setIsOk(true) : setIsOk(false);
  };
  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
  };

  return (
    <Modal
      backdrop="static"
      isOpen={props.toggleState}
      toggle={() => {
        props.toggle();
      }}
    >
      <div className="modal-header" style={headerStyle}>
        <h3 className='modal-header-text'>{props.header}</h3>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => {
            props.toggle();
          }}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <ModalBody>
        {typeof (props.message) === "function" ? props.message() : props.message}
        <br />
        {props.type === "secure" ? (
          <Input
            className="mt-1"
            type="text"
            id="cancelConfirmationText"
            value={cancelConfirmationText}
            onChange={handleCancelConfirmationText}
          />
        ) : (
          ""
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-default"
          size="ig"
          disabled={!isOk}
          onClick={() => {
            props.delete();
            setCancelConfirmationText("");
          }}
        >
          OK
        </button>
        <button
          size="ig"
          className="btn btn-default"
          onClick={() => {
            props.toggle();
            setCancelConfirmationText("");
          }}
        >
          CANCEL
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default GeneralDeleteModal;
