import logo200Image from "../assets/img/logo/iGrant_210_55_BW.svg";
import nextImage from "../assets/img/logo/next2.png";
import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Popover,
} from "reactstrap";
import { withRouter, Link, Redirect } from "react-router-dom";
import { GetOrganizationDetails, GetUserDetails } from "../components/Sources";
import axios from "axios";
import jsonData from "../setting/settings.json";
import { store } from "../services/localStorageStore";
import { connect } from "react-redux";
import { checkIsAuthenticated } from "../actions/";

const api = JSON.parse(JSON.stringify(jsonData)).api;

class AuthForm extends React.Component {

  constructor() {

    super();

    this.state = {
      user_id: "",
      user_password: "",
      user_name: "",
      user_admin: false,
      status: "",
      valid: false,
    };

    this.handleChange = this.handleChange.bind(this);

    this.handleKeyPress = this.handleKeyPress.bind(this);

  }

  clearStorage() {

    store.deleteData("data");

    store.deleteData("iglogin");

    store.deleteData("orgs");

    store.clear();

  }

  clearInput() {

    this.clearStorage();

    this.setState({ user_password: "" });

  }

  componentDidMount() {

    if (this.state.valid) {

      this.props.GetUserDetails();

      this.props.history.push("/start");

    }

  }

  componentWillUnmount() {

    GetOrganizationDetails();

  }

  get isLogin() {

    return this.props.authState === STATE_LOGIN;

  }

  get isSignup() {

    return this.props.authState === STATE_SIGNUP;

  }

  changeAuthState = (authState) => (event) => {

    event.preventDefault();

    this.props.onChangeAuthState(authState);

  };

  handleChange = (event) => {
    if (event.target.name === "user_id")
      this.setState({ user_id: event.target.value });
    if (event.target.name === "user_password")
      this.setState({ user_password: event.target.value });
    if (event.target.name === "user_name")
      this.setState({ user_name: event.target.value });
    if (event.target.name === "user_admin")
      this.setState({ user_admin: !this.state.user_admin });
    this.setState({ status: "   " });
  };

  handleKeyPress = (event) => {
    if (event.key == "Enter") {
      this.handleSubmit();
    }
  };

  handleLogIn() {


    //lower based on the call on 22-8-2019  based on the new org registration
    //need to change according with DUNS
    if (this.state.user_id.length > 5 || this.state.user_password.length > 4) {


      //  var url = 'https://staging-api.igrant.io/v1.1/users/login';
      var url = api + "v1/users/admin/login";
      var roles = "";
      var data = {
        username: this.state.user_id,
        password: this.state.user_password,
      };

      
      var axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axios
        .post(url, JSON.stringify(data), JSON.stringify(axiosConfig))
        .then((response) => {
          this.setState({ status: "" });

          let resp = JSON.stringify(response.data);
          if (JSON.parse(resp).User.ID) {
            store.writeData("data", response.data);
            axiosConfig = {
              headers: {
                Authorization:
                  "Bearer " + store.getData("data").Token.access_token,
              },
            };

            roles = store.getData("data").User.Roles;
            for (var c = 0; c < roles.length; c++) {
              if (roles[c].RoleID === 1) {
                url =
                  api +
                  "v1/organizations/" +
                  store.getData("data").User.Roles[c].OrgID;
                axios.get(url, axiosConfig).then(async (response) => {
                  store.writeData("orgs", response.data.Organization);
                  this.props.history.push("/start");
                });
                break;
              }
            }
          } else {
            this.setState({
              status: "Your ID or password was incorrect, Please try again.",
            });
            this.setState({ valid: false });
            this.clearInput();
          }
        })
        .catch((err) => {
          this.setState({
            status: "Your ID or password was incorrect, Please try again.",
          });
          this.setState({ valid: false });
          this.clearInput();
        });
    } else {
      
      this.setState({
        status: "Your ID or password was incorrect, Please try again.",
      });

      this.setState({ valid: false });

      this.clearInput();

    }
  }

  handleSignup() {
    // var url = 'https://staging-api.igrant.io/v1/users/register';
    var url = api + "v1/users/register";
    var data = {
      username: this.state.user_id,
      name: this.state.user_name,
      password: this.state.user_password,
      isadmin: this.state.user_admin,
    };
    var axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
      },
    };

    axios
      .post(url, JSON.stringify(data), JSON.stringify(axiosConfig))
      .then((response) => {
        this.setState({ status: "Thank you for enrolling " });
      })
      .catch((err) => {
        this.setState({ status: "Please provide valid details!!!" });
        this.clearInput();
      });

    store.deleteData("iglogin");
    store.deleteData("data");
  }

  handleMailChimp() {
    var url = "https://us6.api.mailchimp.com/3.0";
    var data = {
      email: this.state.user_id,
      fname: this.state.user_name,
      company: this.state.user_password,
    };
    var axiosConfig = {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        user: "3995f50ae97661e5b4d3df67a",
        id: "d34bd5358b",
      },
    };
    axios
      .post(url, JSON.stringify(data), JSON.stringify(axiosConfig))
      .then((response) => {
        this.setState({ status: "Thank you for enrolling " });
      })
      .catch((err) => {
        this.setState({ status: "Please provide valid details!!!" });
      });
  }

  handleSubmit = (event) => {
    if (this.props.authState === STATE_LOGIN) {
      this.handleLogIn();
    }

    if (this.props.authState === STATE_SIGNUP) {
      //this.handleSignup();
      this.handleMailChimp();
    }
  };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return "Already in iGrant.io? Login now";
    }

    if (!buttonText && this.isSignup) {
      return "Not an iGrant.io? Enroll now";
    }

    return buttonText;
  }

  render() {
    const {
      showLogo,
      usernameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      emailLabel,
      emailInputProps,
      phoneLabel,
      phoneInputProps,
      children,
      onLogoClick,
    } = this.props;

    const formStyle = {
      padding: "2rem",
    };

    const inputStyle = {
      borderWidth: 0,
      borderRadius: 1,
      borderBottomColor: "#dfdfdf",
      borderBottomWidth: 1,
    };

    const inputStyleEmail = {
      borderWidth: 1.2,
      borderTopLeftRadius: 14,
      borderTopRightRadius: 14,
      borderBottomWidth: 1.2,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      outline: "none",
      fontSize: "14px",
      boxShadow: "none",
    };

    const inputStyleName = {
      borderWidth: 0,
      borderLeftWidth: 1.2,
      borderRightWidth: 1.2,
      borderBottomWidth: 1.2,
      borderRadius: 0,
      outline: "none",
      fontSize: "14px",
      boxShadow: "none",
    };

    const inputStylePwd = {
      borderTopWidth: 0,
      borderBottomWidth: 1.2,
      borderLeftWidth: 1.2,
      borderRightWidth: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomLeftRadius: 14,
      borderBottomRightRadius: 0,
      fontSize: "14px",
      outline: "none",
      boxShadow: "none",
    };

    const divStyle = {
      height: 0,
    };

    const inputStyleImg = {
      borderLeftWidth: 0,
      borderTopWidth: 0,
      borderRightWidth: 1.2,
      borderBottomRightRadius: 14,
      backgroundColor: "white",
    };

    const textStyle = {
      color: "white",
      fontWeight: 400,
      fontSize: "14px",
    };

    const textStyle1 = {
      color: "white",
      fontWeight: 400,
      fontSize: "0.9rem",
    };

    return this.props.isLogin ? (
      <Redirect to={this.props.history.location.state ? this.props.history.location.state.from : '/start'} />
    ) : (
      <Form onSubmit={this.handleSubmit} style={formStyle}>
        {showLogo && (
          <div className="text-center pb-2">
            <img
              src={logo200Image}
              className="rounded"
              style={{ cursor: "pointer" }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        <br />
        {this.isSignup ? (
          <FormGroup>
            <Input
              {...emailInputProps}
              ref="uname"
              style={inputStyleEmail}
              name="user_id"
              value={this.state.user_id}
              onChange={this.handleChange}
              required
            />
            <div style={divStyle} />
            <Input
              {...confirmPasswordInputProps}
              style={inputStyleName}
              name="user_name"
              value={this.state.user_name}
              onChange={this.handleChange}
              required
            />
            <InputGroup>
              <Input
                {...phoneInputProps}
                style={inputStylePwd}
                name="user_password"
                value={this.state.user_password}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                required
              />
              <InputGroupAddon addonType="prepend" style={{ marginRight: 0 }}>
                <InputGroupText style={inputStyleImg}>
                  <img
                    src={nextImage}
                    style={{ cursor: "pointer" }}
                    alt=">>"
                    onClick={this.handleSubmit}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        ) : (
          <FormGroup>
            <Input
              {...usernameInputProps}
              ref="uname"
              style={inputStyleEmail}
              name="user_id"
              value={this.state.user_id}
              onChange={this.handleChange}
              required
            />
            <div style={divStyle} />
            <InputGroup>
              <Input
                {...passwordInputProps}
                style={inputStylePwd}
                name="user_password"
                value={this.state.user_password}
                onChange={this.handleChange}
                onKeyPress={this.handleKeyPress}
                required
              />
              <InputGroupAddon addonType="prepend" style={{ marginRight: 0 }}>
                <InputGroupText style={inputStyleImg}>
                  <img
                    src={nextImage}
                    style={{ cursor: "pointer" }}
                    alt=">>"
                    onClick={this.handleSubmit}
                  />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
        )}

        <div className="col text-center pt-1">
          <pre>
            {this.isSignup ? (
              <Label style={{ height: "6px", fontSize: "12px" }}>
                {this.state.status}
              </Label>
            ) : (
              <Label style={{ height: "42px", fontSize: "12px" }}>
                {this.state.status}
              </Label>
            )}
          </pre>

          <hr style={{ backgroundColor: "white" }} />
          <div className="text-center pt-1">
            <h6>
              {this.isSignup ? (
                <div style={textStyle}>
                  Already an iGrant.io business? &nbsp;
                  <a
                    href="#login"
                    style={textStyle}
                    onClick={this.changeAuthState(STATE_LOGIN)}
                  >
                    Login now.
                  </a>{" "}
                </div>
              ) : (
                <div style={textStyle}>
                  {" "}
                  Not an iGrant.io business? &nbsp;
                  <Link style={textStyle} to={"/onboarding"}>
                    Enroll Now
                  </Link>
                </div>
              )}
            </h6>
          </div>
        </div>
        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = "LOGIN";
export const STATE_SIGNUP = "SIGNUP";

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  emailLabel: PropTypes.string,
  emailInputProps: PropTypes.object,
  ppasswordLabel: PropTypes.string,
  ppasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: "LOGIN",
  showLogo: true,
  usernameLabel: "Email",
  usernameInputProps: {
    type: "email",
    placeholder: "iGrant.io admin ID",
  },
  passwordLabel: "Password",
  passwordInputProps: {
    type: "password",
    placeholder: "Password",
  },
  confirmPasswordLabel: "Name",
  confirmPasswordInputProps: {
    type: "text",
    placeholder: "Your name",
  },
  emailLabel: "Email",
  emailInputProps: {
    type: "email",
    placeholder: "email ID",
  },
  phoneLabel: "Phone",
  phoneInputProps: {
    type: "phone",
    placeholder: "Phone no",
  },
  onLogoClick: () => { },
};

function mapStateToProps(state) {
  return state.checkIsLogin;
}

export default withRouter(connect(mapStateToProps)(AuthForm));
