import React from 'react'
import axios, { post } from 'axios';
import {GetOrganizationDetails,} from './Sources';
import companyImage from '../assets/img/products/camera_photo1.png'; 
import jsonData from '../setting/settings.json';
import { store } from '../services/localStorageStore';
import { withRouter } from 'react-router-dom'  
  
const api = JSON.parse(JSON.stringify(jsonData)).api;

class OrgImageUpload extends React.Component {

    constructor(props) {
      super(props);
        this.state ={
            file:null,
        }
        this.mystate = {file: '',imagePreviewUrl: ''};
        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChange = this.onChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    onFormSubmit(e){
        e.preventDefault()  
        this.fileUpload(this.state.file)
     }

    onChange(e) {
        e.preventDefault();
        this.setState({file:e.target.files[0]});
        this.mystate.file=e.target.files[0] ;
   

        let reader = new FileReader();
        let file = e.target.files[0];
        let imageType =/image.jpeg/;

        reader.onloadend = () => {
        this.mystate.file= file;
        this.mystate.imagePreviewUrl= reader.result;
    
    } 

    if (file.type.match(imageType))
    {
        reader.readAsDataURL(file) ;
        this.fileUpload(file);
        GetOrganizationDetails();
    }
 }

fileUpload(file){
  if(store.getData('data') != null  &&  store.getData('orgs') != null) 
  {
  const url=api + 'v1/organizations/' + store.getData('orgs').ID  + '/coverimage';
  const formData = new FormData();
  formData.append('orgimage',file);

  const config = {
      headers: {
          'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
          'content-type': 'multipart/form-data',
      }
  };
 
   axios.post(url, formData, config)
   .then((response) => {
     store.writeData('orgs',response.data.Organization)
     this.props.history.push('/start')
   }); 
  }
 return ; 
}

  render() {
    return (
     <div>
      <form onSubmit={this.onFormSubmit}>
        <label className="uptext" htmlFor="browse"><img style={{borderRadius:50,opacity: 0.45,}} src={companyImage} /></label>
        <input type="file" accept="image/jpeg,image/jpg" id="browse" name="browse" hidden={true} onChange={this.onChange}/>
      </form>
    </div>
   )
  }
}
export default withRouter(OrgImageUpload);
 