import React from 'react';
import jsonData from '../setting/settings.json';
import Select from 'react-select';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import axios from 'axios';
import {
  Row,
  Col,
  CardText,
  CardTitle,
  ButtonGroup,
} from 'reactstrap';
import {GetOrganizationDetails,} from '../components/Sources';
import { store } from '../services/localStorageStore'
import Page from '../components/Page';
import { BootstrapTable, TableHeaderColumn,InsertButton, ExportCSVButton,} from 'react-bootstrap-table';
import { isNull } from 'util';
 
const api = JSON.parse(JSON.stringify(jsonData)).api ;

const btnSz={height:'2.4rem',width:'12rem',padding:'0rem',fontSize:'12px',margin:'1px', position:'relative',top:'-14px', };

let orgpurposeDDown = [];
let orgtemplateDDown = [];
let subUserList=[];
let purText,tempText;
const limittext=""//; "?limit=50"; ///remarked for time being to reduced repeated calls

const customStylesDD = {
   control: (base, state) => ({
     ...base,
       '&:hover': { borderColor: 'gray' }, // border style on hover
        border: '1px solid #DFDFDF', // default border color
        boxShadow: 'none', // no box-shadow
        fontSize:'14px', 
        fontColor:'#808080',
        top:'-6px',
        padding:0,
        margin:0,
        backgroundColor:'white',//'#F8F9FA',
        borderRadius:0,
})
};

function getDetails()
{
    GetOrganizationDetails();
    orgpurposeDDown = []; 
    orgtemplateDDown = [];

    if(store.getData('orgs') != null ) {
        //purpose
        
        var purposesDirect = store.getData('orgs').Purposes;
        for(var i=0;i<purposesDirect.length; i++)
        {
             var  orgpurDDown = {
                value: purposesDirect[i].ID,
                label: purposesDirect[i].Name,
            }  ;
            orgpurposeDDown.push(orgpurDDown);
        }; 
   }

   if( store.getData('data') != null && store.getData('orgs') != null) 
   {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
           
      //when no selection is done then the api is /v1/organizations/id/users
      var url = api +'v1/organizations/'+ store.getData('orgs').ID +'/users'+ limittext ;
      axios.get(url ,axiosConfig)
      .then((response) => {    
             subUserList=response.data.Users;
      })
      .catch((err) => { 
         subUserList =  [];
      }) ; 
 
   }
  
};

function createCustomInsertButton (onClick) {
   return ( 
     <InsertButton style={btnSz}
      btnText='Add Users' 
      btnContextual='btn-default' 
    />
 );
}

function createCustomExportCSVButton (onClick)  {
   return (
     <ExportCSVButton style={btnSz}
       btnText='Download'
       btnContextual='btn-default'  className="w-50"
      />
   );
 }

function createCustomClearButton (onClick) {
   return (
     <button  className="btn btn-default mt-0 mb-0 " onClick={ onClick }>View All</button>
   );
 }


class ConsentsPage extends React.Component {
  
   constructor() {
      super();

      this.updateData = this.updateData.bind(this);
      this.getTemplateList=this.getTemplateList.bind(this); 
      this.getSubscribedUsersList=this.getSubscribedUsersList.bind(this); 
      this.handleFilterPurpose = this.handleFilterPurpose.bind(this);
      this.handleFilterTemplate = this.handleFilterTemplate.bind(this); 
      this.createCustomToolBar = this.createCustomToolBar.bind(this); 
   }

   state = {
       show: true,
       selectedPurpose: null,
       selectedTemplate: null,
       subtemplate:[] ,
       subcribedUserList : subUserList,
   };
   
   
 
   createCustomToolBar (props) {
      return (
        <Row className="w-100 h-100 mt-1 m-0 p-0">
          <Col lg={4} md={6} sm={12} xs={12}   >
            <Select key="purposeListUI" placeholder="Filter by purpose" value={this.state.selectedPurpose} options={orgpurposeDDown} styles={customStylesDD}    onClick={this.handleFilterPurpose}  onChange={this.handleFilterPurpose} /> 
          </Col>
          <Col lg={4} md={6} sm={12} xs={12} >
            <Select key="templateListUI" placeholder="Filter by attribute" value={this.state.selectedTemplate} options={this.state.subtemplate}  styles={customStylesDD} onClick={this.handleFilterTemplate }  onChange={this.handleFilterTemplate}/>  
         </Col>
          <Col lg={4} md={6} sm={12} xs={12} className="d-inline-flex flex-column justify-content-center  ">
            <ButtonGroup>
               <button  className="btn btn-default w-50" style={btnSz} onClick={this.updateData}>View All</button>{ props.components.insertBtn }{ props.components.exportCSVBtn }</ButtonGroup>
         </Col>
       </Row>
      );
   } 
   
   renderPaginationPanel = (props) => {
      return (
       <Row className="w-100 h-100 mt-1 m-0 p-0">
         <Col lg={6} md={6} sm={6} xs={12} style={{ top: 'auto', bottom: '100%'}}  >{ props.components.sizePerPageDropdown }</Col>
         <Col lg={6} md={6} sm={6} xs={12}  className="d-inline-flex flex-row  justify-content-end"> { props.components.pageList } </Col>
        </Row>
      );
    }
  
   updateData=()=> {
      this.setState({ subcribedUserList : subUserList });
      this.setState({selectedPurpose:null});
      this.setState({selectedTemplate:null });
      this.setState({subtemplate:[] });
      //this.state.subtemplate=[];
      purText=null;
      tempText=null;
   };

   getTemplateList=()=> {
      //this.state.subtemplate=[];
      this.setState({subtemplate:[] });
      let subtemp=[];
      if(store.getData('orgs') != null ) {
         var templateDirect = store.getData('orgs').Templates;
              for(var i=0;i<templateDirect.length; i++)
              {
                   var  orgtempDDown = {
                      value: templateDirect[i].ID,
                      label: templateDirect[i].Consent,
                  }  ;
                  subtemp.push(orgtempDDown);
              }; 
              //this.state.subtemplate=subtemp; 
              this.setState({subtemplate:subtemp });
      }
   };

   handleFilterPurpose(selectedPurpose) {
      GetOrganizationDetails();
      this.state.selectedPurpose=selectedPurpose;
      this.state.selectedTemplate=null;
      this.state.subtemplate=[];tempText=null;
      purText=selectedPurpose.value;
      let url="";
        ///need to get all values realted to it
       if( store.getData('data') && store.getData('orgs') && purText  ) 
       {
         this.setState({ subcribedUserList :  [] });

         var axiosConfig = {
            headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
            }; 
 
         {  
            //1 fill the template array with the list  the api is api /v1/organizations/ID/purposes/ID 
            url = api +'v1/organizations/'+ store.getData('orgs').ID +'/purposes/' + purText + limittext ;
            axios.get(url ,axiosConfig)
            .then((response) => {   
               store.writeData("temp", response.data);
               var templateDirect = store.getData('temp').Templates;
               let subtemp=[];
               for(var i=0;i<templateDirect.length; i++)
               {
                  var  orgtempDDown = {
                     value: templateDirect[i].ID,
                     label: templateDirect[i].Consent,
                  }  ;
                  subtemp.push(orgtempDDown);
               }; 
               this.state.subtemplate=subtemp;
         
            })
            .catch((err) => { 
               this.setState({ ssubtemplate :  [] });
            }) ; 
             
         //2 fille the list with all the purpose user
         ///v1/organizations/{orgID}/purposes/{purposeID}/attributes/{attributeID}/consented/users  i.e purpose only
         url = api+'v1/organizations/'+ store.getData('orgs').ID +'/purposes/' + purText + '/consented/users'+ limittext ;
         }
         axios.get(url ,axiosConfig)
            .then((response) => {    
               this.setState({ subcribedUserList : (isNull(response.data.Users))?[] : response.data.Users});
            })
            .catch((err) => {
               this.setState({ subcribedUserList :  [] });
            }) ; 
         
        ////end extract
      }
    };
   
   handleFilterTemplate(selectedTemplate) {

      this.setState({ selectedTemplate });
      tempText = selectedTemplate.value;
       ///need to get all values realted to it
       if( store.getData('data') && tempText) 
       {
        this.setState({ subcribedUserList :  [] });
        if(purText)
        {
         var axiosConfig = {
             headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
             }; 
          ///v1/organizations/{orgID}/purposes/{purposeID}/attributes/{attributeID}/consented/users
         var url = api+'v1/organizations/'+ store.getData('orgs').ID +'/purposes/' + purText + '/attributes/'+ tempText + '/consented/users' + limittext ;
              
         axios.get(url ,axiosConfig)
         .then((response) => {    
            this.setState({ subcribedUserList : (isNull(response.data.Users))?[] : response.data.Users});
         })
         .catch((err) => {
            this.setState({ subcribedUserList :  [] });
          }) ; 
         }
        ////end extract
      }  
   };

   getSubscribedUsersList()
   {
       if( store.getData('data') != null && store.getData('orgs') != null ) 
       {
         var axiosConfig = {
           headers: {
               'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
               }
           }; 
          //when no selection is done then the api is /v1/organizations/id/users
            let url = api+'v1/organizations/'+ store.getData('orgs').ID +'/users'+ limittext ;
  
            axios.get(url ,axiosConfig)
            .then((response) => {    
                  this.setState({ subcribedUserList :  response.data.Users});
            })
            .catch((err) => { 
               this.setState({ subcribedUserList :  [] });
            }) ; 
       }
   };

   componentDidMount() {
      getDetails(); 
      this.getSubscribedUsersList();
   }

render() {
  getDetails();

 const options = {
   fontSize: '14px',
   exportCSVBtn: createCustomExportCSVButton,
   insertText: 'Add Users',
   clearSearch: true,
   clearSearchBtn: createCustomClearButton,
   exportCSVText: 'Download',
   insertBtn: createCustomInsertButton, 
   toolBar: this.createCustomToolBar,
   paginationPanel: this.renderPaginationPanel,
   hideSizePerPage: false, //hide the dropdown for sizePerPage
   alwaysShowAllBtns: true, // Always show next and previous button
   withFirstAndLast: false, // Hide the going to First and Last page button
   prePage: 'Prev', // Previous page button text
   nextPage: 'Next', // Next page button text
   firstPage: 'First', // First page button text
   lastPage: 'Last', // Last page button text
  };

  const columns = [{
   dataField: 'id',
   text: 'Product ID'
   }, {
      dataField: 'name',
      text: 'Product Name'
   }, {
      dataField: 'price',
      text: 'Product Price'
   }];

  return (
    <Page title="" breadcrumbs={[{ name: 'Manage End Users / Consents', active: true }]}>
      <Row key="row1">
        <Col key="col1">
             <CardTitle>
               User Consent  
             </CardTitle>
             <CardText>
              Do basic queries on the consent data. 
             </CardText>
             <BootstrapTable key="enduserTable" className="mt-0" data={this.state.subcribedUserList} pagination={true} striped={false} hover={true}  search={ false }    options={options} insertRow={false} exportCSV={ true }   >
              <TableHeaderColumn key="a1" hidden hiddenOnInsert className="table-secondary" isKey={true} dataField='ID' width='50' >No</TableHeaderColumn>
              <TableHeaderColumn key="a2" className="table-secondary" dataField='ID'    width='20%' editable={ false } dataSort={true}>Subscriber ID</TableHeaderColumn>
              <TableHeaderColumn key="a3"className="table-secondary" dataField='Name'  width='30%' editable={ false } dataSort={true}>Name</TableHeaderColumn>
              <TableHeaderColumn key="a4" className="table-secondary" dataField='Email' width='30%' editable={ false } dataSort={true}>Email</TableHeaderColumn>
              <TableHeaderColumn key="a5" className="table-secondary" dataField='Phone' width='20%' editable={ false } dataSort={true}>Telephone Number</TableHeaderColumn>
           </BootstrapTable>
        </Col>
    </Row>
   </Page>
  );
 };
}
export default ConsentsPage;
