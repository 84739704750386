import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle } from "reactstrap";
import { getColor } from "../../utils/colors";
import { formatExpiryDate, getBrandLogo } from "../../utils/BillingUtils";
import Typography from "../Typography";
export default ({ paymentSource, ...restProps }) => {
  let isCardStored = paymentSource && paymentSource.Last4Digits !== "";
  let isFetchCompleted = paymentSource && !paymentSource.isFetching;
  const secondaryColor = getColor("secondary");
  let cardBrand = paymentSource ? paymentSource.Brand : undefined;
  return (
    <Card {...restProps} style={{ minWidth: "200px" }}>
      <CardBody>
        <CardTitle className="mb-0">Payment Method</CardTitle>
        {isCardStored && isFetchCompleted && (
          <div
            style={{ fontSize: "14px" }}
            className="d-flex justify-content-between mt-1 mb-0"
          >
            <img
              src={getBrandLogo(cardBrand)}
              style={{
                height: 40,
                border: 1
              }}
            />

            <Typography className="text-right small mb-1">
              XXXX XXXX XXXX {paymentSource && paymentSource.Last4Digits}
              <br />
              <span style={{ color: secondaryColor }}>
                Expires{" "}
                {formatExpiryDate({
                  month: paymentSource.ExpiryMonth,
                  year: paymentSource.ExpiryYear
                })}
              </span>
            </Typography>
          </div>
        )}
        {!isCardStored && isFetchCompleted && (
          <CardText className=" mt-2" style={{ fontSize: "14px" }}>
            No payment method stored. Please add.
          </CardText>
        )}
        <div className="text-right">
          {isCardStored && isFetchCompleted && (
            <Button
              outline
              tag={Link}
              to={{
                pathname: "/billingdetails",
                state: {
                  param: "paymentmethod",
                  value: paymentSource
                }
              }}
              className="btn btn-default"
            >
              EDIT
            </Button>
          )}
          {!isCardStored && isFetchCompleted && (
            <Button
              outline
              tag={Link}
              to={{
                pathname: "/billingdetails",
                state: {
                  param: "paymentmethod",
                  value: paymentSource
                }
              }}
              className="btn btn-default"
            >
              ADD
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
