import React from 'react';
import jsonData from '../setting/settings.json';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  CardBody,
  ButtonGroup, Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import Page from '../components/Page';
import { store } from '../services/localStorageStore'

let Status = '', ver = '', agentServiceEndpoint = '', adminEndpoint = '', statusStr = '', ledgerNetwork = '', ledgerURL = '';
let dashboardDDVersions = [];
const deploymentRegion = [{ label: 'Europe: Frankfurt', value: 'Europe: Frankfurt' }];
const protocols = [
  { rfc: '0003', name: 'Protocols', link: 'https://github.com/hyperledger/aries-rfcs/tree/9b7ab9814f2e7d1108f74aca6f3d2e5d62899473/concepts/0003-protocols' },
  { rfc: '0004', name: 'Agents', link: 'https://github.com/hyperledger/aries-rfcs/tree/f1e420f76abd9ff4af5c15d375fa6cf8c2cacb33/concepts/0004-agents' },
  { rfc: '0005', name: 'DIDcomm', link: 'https://github.com/hyperledger/aries-rfcs/tree/f1e420f76abd9ff4af5c15d375fa6cf8c2cacb33/concepts/0005-didcomm' },
  { rfc: '0008', name: 'Message id and threading', link: 'https://github.com/hyperledger/aries-rfcs/tree/64e5e55c123b2efaf38f4b0911a71a1c40a7f29d/concepts/0008-message-id-and-threading' },
  { rfc: '0011', name: 'Decorators', link: 'https://github.com/hyperledger/aries-rfcs/tree/965a975f953d72e370d2b6fb28eec538ec756c6d/concepts/0011-decorators' },
  { rfc: '0015', name: 'ACKs', link: 'https://github.com/hyperledger/aries-rfcs/tree/5cc750f0fe18e3918401489066566f22474e25a8/features/0015-acks' },
  { rfc: '0017', name: 'Attachments', link: 'https://github.com/hyperledger/aries-rfcs/tree/64e5e55c123b2efaf38f4b0911a71a1c40a7f29d/concepts/0017-attachments' },
  { rfc: '0019', name: 'Encryption envelope', link: 'https://github.com/hyperledger/aries-rfcs/tree/9b0aaa39df7e8bd434126c4b33c097aae78d65bf/features/0019-encryption-envelope' },
  { rfc: '0020', name: 'Message types', link: 'https://github.com/hyperledger/aries-rfcs/tree/64e5e55c123b2efaf38f4b0911a71a1c40a7f29d/concepts/0020-message-types' },
  { rfc: '0025', name: 'DIDcomm transports', link: 'https://github.com/hyperledger/aries-rfcs/tree/b490ebe492985e1be9804fc0763119238b2e51ab/features/0025-didcomm-transports' },
  { rfc: '0031', name: 'Discover features', link: 'https://github.com/hyperledger/aries-rfcs/tree/master/features/0031-discover-features' },
  { rfc: '0035', name: 'Report problem', link: 'https://github.com/hyperledger/aries-rfcs/tree/89d14c15ab35b667e7a9d04fe42d4d48b10468cf/features/0035-report-problem' },
  { rfc: '0036', name: 'Issue credential', link: 'https://github.com/hyperledger/aries-rfcs/tree/master/features/0036-issue-credential' },
  { rfc: '0037', name: 'Present proof', link: 'https://github.com/hyperledger/aries-rfcs/tree/master/features/0037-present-proof' },
  { rfc: '0046', name: 'Mediators and relays', link: 'https://github.com/hyperledger/aries-rfcs/tree/64e5e55c123b2efaf38f4b0911a71a1c40a7f29d/concepts/0046-mediators-and-relays' },
  { rfc: '0047', name: 'JSON LD compatibility', link: 'https://github.com/hyperledger/aries-rfcs/tree/53c2e7accc8394c9c7b09563c0eec3c564c133c6/concepts/0047-json-ld-compatibility' },
  { rfc: '0048', name: 'Trust ping', link: 'https://github.com/hyperledger/aries-rfcs/tree/master/features/0048-trust-ping' },
  { rfc: '0050', name: 'Wallets', link: 'https://github.com/hyperledger/aries-rfcs/tree/64e5e55c123b2efaf38f4b0911a71a1c40a7f29d/concepts/0050-wallets' },
  { rfc: '0056', name: 'Service decorator', link: 'https://github.com/hyperledger/aries-rfcs/tree/527849ec3aa2a8fd47a7bb6c57f918ff8bcb5e8c/features/0056-service-decorator' },
  { rfc: '0094', name: 'Cross domain messaging', link: 'https://github.com/hyperledger/aries-rfcs/tree/64e5e55c123b2efaf38f4b0911a71a1c40a7f29d/concepts/0094-cross-domain-messaging' },
  { rfc: '0160', name: 'Connection protocol', link: 'https://github.com/hyperledger/aries-rfcs/tree/9b0aaa39df7e8bd434126c4b33c097aae78d65bf/features/0160-connection-protocol' }
]
const apiMain = JSON.parse(JSON.stringify(jsonData)).api + "v1/";
const api = apiMain + "organizations/";

const headerStyle = {
  fontSize: '16px',
  backgroundColor: '#eeeeee',
};
const textStyle = {
  color: '#212529',
  fontSize: '16px',
  paddingBottom: 1,
};
const inputStyle = {
  color: '#495057',
  border: '1',
  borderWidth: 0,
  padding: 0,
  paddingBottom: 1,
  borderRadius: 0,
  fontSize: '16px',
  borderBottomWidth: 1.2,
  borderBottomColor: 'lightgray',//'#DFE0E1', 
};

const customStylesDD = {
  control: (base, state) => ({
    ...base,
    '&:hover': { borderColor: 'gray' }, // border style on hover
    border: '1px solid lightgray', // default border color
    boxShadow: 'none', // no box-shadow
  })
};

async function addReleaseVersions() {

  dashboardDDVersions = [];
  let data = store.getData("data");
  let orgs = store.getData('orgs');

  if (data && orgs) {

    var url = apiMain + '/aries-cloudagent/releases';
    var axiosConfig = {
      headers: {
        'Authorization': 'Bearer ' + data.Token.access_token,
      }
    };

    const response = await axios.get(url, axiosConfig)
    let resp = response.data
    if (resp != null) {
      for (var i = 0; i < resp.length; i++) {
        let versionInfo = {
          value: resp[i].version,
          label: resp[i].version,
          text: resp[i].timestamp,
        };
        dashboardDDVersions.push(versionInfo);
      };
      dashboardDDVersions.sort((a, b) => b.text - a.text)
    }

  };

}

//addReleaseVersions(); 

class AriesCloudAgentPage extends React.Component {
  constructor() {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.getDetails = this.getDetails.bind(this);
    this.getLedgerNetworks = this.getLedgerNetworks.bind(this)
    this.activateButton = this.activateButton.bind(this)
    addReleaseVersions();
  }

  state = {
    status: '',
    ver: '',
    selectedOption: '',
    agentServiceEndpoint: '',
    statusStr: '',
    show: false,
    isOKBtn: false,
    ledgerNetwork: '',
    ledgerURL: '',
    ledgerNetworks: [],
    selectedLedgerNetwork: '',
    currentLedgerNetwork: '',
    ledgerInfo: []
  };

  toggleConfig = () => {
    this.setState({
      show: !this.state.show,
      selectedOption: dashboardDDVersions.filter((versionInfo) => versionInfo.value === ver)[0]
    })
  }

  confirmConfig = () => {
    let data = store.getData("data");
    let orgs = store.getData('orgs');

    if (data && orgs) {
      const axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + data.Token.access_token,
        }
      };

      switch (Status) {
        case 2:
          let url = api + orgs.ID + '/aries-cloudagent/version'
          axios.patch(url, { version: this.state.selectedOption.value }, axiosConfig)
            .then((response) => {
              this.getDetails();
            });
          break;

        default:
          url = api + orgs.ID + '/aries-cloudagent';
          axios.post(url, { version: this.state.selectedOption.value, ledgerid: this.state.selectedLedgerNetwork.value }, axiosConfig)
            .then((response) => {
              this.getDetails();
            });
          break;

      }
    }
    this.setState({ show: !this.state.show })
  };

  handleLedgerNetworkChange = (selectedOption) => {
    if (JSON.stringify(this.state.selectedLedgerNetwork) !== JSON.stringify(selectedOption)) {
      this.setState({ selectedLedgerNetwork: selectedOption })
      this.activateButton(selectedOption, 'ledger')
    }
    else this.setState({ isOKBtn: false });
  }

  handleChange = (selectedOption) => {
    if (JSON.stringify(this.state.selectedOption) !== JSON.stringify(selectedOption)) {
      this.setState({ selectedOption });
      this.activateButton(selectedOption, 'version')
    }
    else this.setState({ isOKBtn: false });
  }

  activateButton = (current, field) => {
    if (this.state.status === 2) {
      this.setState({ isOKBtn: true })
    } else {
      if (field === 'version') {
        this.state.selectedLedgerNetwork && current ? this.setState({ isOKBtn: true }) : this.setState({ isOKBtn: false })
      }
      if (field === 'ledger') {
        current && this.state.selectedOption ? this.setState({ isOKBtn: true }) : this.setState({ isOKBtn: false })
      }
    }
  }
  getDetails() {
    let data = store.getData("data");
    let orgs = store.getData('orgs');

    if (data && orgs) {
      let axiosConfig = {
        headers: {
          'Authorization': 'Bearer ' + data.Token.access_token,
        }
      };
      let url = api + orgs.ID + '/aries-cloudagent';

      axios.get(url, axiosConfig)
        .then((response) => {
          let resp = JSON.parse(JSON.stringify(response.data));
          Status = resp.Status;
          switch (resp.Status) {
            case 2:
              ver = resp.Version;
              agentServiceEndpoint = resp.AgentServiceEndpoint;
              adminEndpoint = resp.AdminEndpoint
              ledgerNetwork = resp.LedgerName
              ledgerURL = resp.LedgerURL
              statusStr = resp.StatusStr;
              break;
            default:
              ver = '<Not Deployed>';
              agentServiceEndpoint = '<Not Deployed>';
              adminEndpoint = '<Not Deployed>';
              ledgerNetwork = '<Not Deployed>';
              statusStr = resp.StatusStr;
              break;
          }
          statusStr = statusStr.toUpperCase();
          this.setState({
            status: Status,
            ver,
            agentServiceEndpoint,
            adminEndpoint,
            ledgerNetwork,
            ledgerURL,
            statusStr,
            status: resp.Status,
            currentLedgerNetwork: { value: resp.LedgerID, label: resp.LedgerName }
          });
        });
    }
  };

  getLedgerNetworks() {
    const data = store.getData("data");
    let url = apiMain + 'aries-cloudagent/ledger-networks'
    const axiosConfig = {
      headers: {
        'Authorization': 'Bearer ' + data.Token.access_token,
      }
    };
    axios.get(url, axiosConfig).then((response) => {
      const tempLedgerNetworks = []
      this.setState({ ledgerInfo: response.data })
      response.data.forEach(ledger => {
        tempLedgerNetworks.push({ value: ledger.ID, label: ledger.Str })
      });
      this.setState({ ledgerNetworks: tempLedgerNetworks })
    })
  }

  componentDidMount() {
    this.getDetails();
    this.getLedgerNetworks()

  };
  render() {
    return (
      <Page title="" breadcrumbs={[{ name: 'Data Exchange / Aries Cloud Agent', active: true }]}>
        <Row className="d-flex justify-content-right">
          <Col md="12" sm="12" xs="12" >
            <CardTitle>
              Aries Cloud Agent
            </CardTitle>
            <CardText>
              Manage Data Sources (Issuer) and Data Using Service (Verifier) configurations.
            </CardText>
          </Col>
        </Row>
        <Row className="d-flex justify-content-right">
          <Col md="12" sm="12" xs="12" >
            <Card className="h-100 w-100">
              <CardBody className="d-flex flex-column justify-content-start h-100 w-100">
                <ButtonGroup className="d-flex justify-content-between" style={{ fontSize: 14, }}>
                  <CardTitle className="pt-1">Current Status</CardTitle>
                  <span><span style={{ fontSize: 16, fontBold: true, fontWeight: 800, }} className="pt-1 pr-2">{this.state.statusStr}</span> <button className="btn btn-default" disabled={(this.state.status === 1) ? true : false} style={{ cursor: (this.state.status === 1) ? 'not-allowed' : 'pointer' }} onClick={this.toggleConfig}>Configure</button> </span>
                </ButtonGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Modal
          backdrop="static"
          isOpen={this.state.show}
          toggle={this.toggleConfig}
        >
          <ModalHeader toggle={this.toggleConfig} style={headerStyle} >Aries Cloud Agent Settings</ModalHeader>
          <ModalBody className='pb-0 '>
            <div className='form-group ' key="status" >
              <FormGroup className="mt-0 mb-0">
                <label style={textStyle} > Deployment Status:</label>
                <span style={{ fontSize: 16, float: 'right' }} className="pt-1 pr-2">{this.state.statusStr}</span>
              </FormGroup>
            </div>
            <div className='form-group ' key="Release" >
              <label style={textStyle} > Deployed Version:</label>
              <FormGroup className="mt-0 mb-0">
                <Select isSearchable={false} ref="release" isMulti={false} options={dashboardDDVersions} name="release" value={this.state.selectedOption || ''} styles={customStylesDD} onChange={this.handleChange} />
              </FormGroup>
            </div>
            <div className='form-group ' key="ledgerNetwork" >
              <label style={textStyle} > Ledger Network:</label>
              <FormGroup className="mt-0 mb-0">
                <Select isDisabled={this.state.status === 2 ? true : false} isSearchable={false} ref="ledgerNetwork" isMulti={false} options={this.state.ledgerNetworks} name="ledgerNetwork" value={this.state.status === 2 ? this.state.currentLedgerNetwork : this.state.selectedLedgerNetwork} styles={customStylesDD} onChange={this.handleLedgerNetworkChange} />
              </FormGroup>
            </div>
            <div className='form-group' key="deploymentRegion" >
              <label style={textStyle} > Deployment Region:</label>
              <FormGroup className="mt-0 mb-0">
                <Select isSearchable={false} ref="deploymentRegion" isMulti={false} options={deploymentRegion} name="deploymentRegion" value={deploymentRegion} styles={customStylesDD} />
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter className='modal-footer' >
            <button size="ig" className='btn btn-default' onClick={this.toggleConfig} >CLOSE</button>
            <button size="ig" className='btn btn-default' disabled={!this.state.isOKBtn} style={{ cursor: (this.state.isOKBtn) ? 'pointer' : 'not-allowed' }} onClick={this.confirmConfig}>CONFIRM</button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col>
            <Card>
              <CardBody className="d-flex flex-column justify-content-start ">
                <CardTitle>Settings</CardTitle>
                <Form style={{ fontSize: '14px', }}>
                  <FormGroup row className="mt-0 mb-0">
                    <Label sm={4} className="pr-0 pt-1 pb-0 ">
                      Deployed version:
                    </Label>
                    <Col sm={7} className="pr-0 pt-1 text-muted">
                      {this.state.ver}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-0 mb-0">
                    <Label sm={4} className="pr-0 pt-1 pb-0 ">
                      Agent service endpoint:
                    </Label>
                    <Col sm={7} className="pr-0 pt-1 text-muted">
                      {(this.state.agentServiceEndpoint === '<Not Deployed>') ? '<Not Deployed>' : (
                        <a href={this.state.agentServiceEndpoint} target="_blank" style={{ color: 'blue', fontSize: '14px', }} htmlFor="em2">{this.state.agentServiceEndpoint}</a>)}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-0 mb-0">
                    <Label sm={4} className="pr-0 pt-1 pb-0 ">
                      Admin endpoint:
                    </Label>
                    <Col sm={7} className="pr-0 pt-1 text-muted">
                      {(this.state.adminEndpoint === '<Not Deployed>') ? '<Not Deployed>' : (
                        <a href={this.state.adminEndpoint} target="_blank" style={{ color: 'blue', fontSize: '14px', }} htmlFor="em2">{this.state.adminEndpoint}</a>)}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-0 mb-0">
                    <Label sm={4} className="pr-0 pt-1 pb-0 ">
                      Ledger network (URL):
                    </Label>
                    <Col sm={7} className="pr-0 pt-1 text-muted">
                      {(this.state.ledgerNetwork === '<Not Deployed>' || this.state.ledgerNetwork === '') ? '<Not Deployed>' : (
                        <span>{this.state.ledgerNetwork} {this.state.ledgerURL ? (<span> (<a href={this.state.ledgerURL} target="_blank" style={{ color: 'blue', fontSize: '14px', }} htmlFor="em2">{this.state.ledgerURL}</a>)</span>) : ''}</span>)}
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-0 mb-0">
                    <Label sm={4} className="pr-0 pt-1 pb-0 ">
                      Deployment Region:
                    </Label>
                    <Col sm={7} className="pr-0 pt-1 text-muted">
                      <span> {this.state.status === 2 ? 'Europe - Frankurt' : '<Not Deployed>'} </span>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
            <p style={{ fontSize: "14px" }}>

              {this.state.ledgerInfo.filter(ledger => ledger.ID === this.state.currentLedgerNetwork.value).length ?

                this.state.ledgerInfo.filter(ledger => ledger.ID === this.state.currentLedgerNetwork.value)[0].RegisterDIDHTMLText ?
                  <span><strong>Note:</strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: this.state.ledgerInfo.filter(ledger => ledger.ID === this.state.currentLedgerNetwork.value)[0].RegisterDIDHTMLText
                      }} />
                  </span>
                  :
                  ""
                :
                ""
              }
            </p>
          </Col>
        </Row>
        <CardTitle>Supported Protocols</CardTitle>
        <Row>
          <Col>
            <Card>
              <CardBody className="d-flex flex-column justify-content-start " style={{ padding: '0px 10px 0px 10px', overflow: 'hidden' }}>
                <Form style={{ fontSize: '14px', }}>
                  {protocols.map((protocol, i) => (
                    <FormGroup key={i} row className="mt-0 mb-0" style={{ borderBottom: 'solid', borderBottomWidth: '1px', borderBottomColor: '#dee2e6' }}>
                      <Label sm={4} className="pr-0 pt-1 pb-0 " style={{ borderRight: 'solid', borderRightWidth: '1px', borderRightColor: '#dee2e6' }}>
                        RFC {' ' + protocol.rfc}
                      </Label>
                      <Col sm={7} className="pr-0 pt-1 text-muted">
                        <a href={protocol.link} target="_blank" style={{ color: 'blue', fontSize: '14px', }} htmlFor="em2">{protocol.name}</a>
                      </Col>
                    </FormGroup>
                  ))
                  }
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  };
}
export default AriesCloudAgentPage;

