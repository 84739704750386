import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom'
import { checkIsAuthenticated } from "../../actions";


const ProtectedRoute = ({
  component: Component,
  layout: Layout,
  path,
  isLogin,
  checkIsAuthenticated,
  ...rest
}) => {
  const [checkComplete, isCheckComplete] = useState(false);
  useEffect( () => {
    async function check() {
      await checkIsAuthenticated();
      isCheckComplete(true)
    }
    check();
  }, []);
  const history = useHistory()
  return checkComplete? 
   (
    <Route
      {...rest}
      render={(props) => {
        return isLogin ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          history.push('/', { from: path })
        );
      }}
    />
  )
  :
  <div/>
};

function mapStateToProps(state) {
  return state.checkIsLogin;
}

export default connect(mapStateToProps, { checkIsAuthenticated })(
  ProtectedRoute
);
