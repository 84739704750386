import { userLogout } from './user'
export const store = {

  /**
   * @param {any} item 
   * @description This Function will fetch the data from local storage and parse it and return the data as valid js data type
   */
  getData: (item) => {
    try {
      return JSON.parse(localStorage.getItem(item));
    } catch (error) {
      console.log(error)
      userLogout()
    }
  },
  /**
   * @param {any} key 
   * @param {any} data 
   * @description This Function will stringfy the value and store it in localstorage
   */
  writeData: (key, data) => {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  },
  /**
   * @param {any} item 
   * @description This Function will delete the given key from local storage
   */
  deleteData: (item) => {
    try {
      localStorage.removeItem(item);
    } catch (error) {
      console.log(error);
    }
  },
  /**
  * @description This Function will clear all the data from local storage
  */
  clear: () => localStorage.clear()
}
