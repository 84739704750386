import React, { useState, useEffect } from "react";
import Page from "../components/Page";

import axios from "axios";
import jsonData from "../setting/settings.json";
import { store } from '../services/localStorageStore'
import { IoCheckmark, IoClose } from "react-icons/lib/io";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import { InsertButton } from "react-bootstrap-table";
import AddWebhokkModal from "../components/AddWebhookModal";
import EditWebhokkModal from "../components/EditWebhookModal";
import WebhookLogPage from "../components/WebhookLogPage";


const api = JSON.parse(JSON.stringify(jsonData)).api;

const WebhookPage = (props) => {
  const [webHookUrls, setWebHookUrls] = useState(null);
  const { buttonLabel, className } = props;
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false)
  
  const toggleAdd = () => setAddModal(!addModal);

  const toggleEdit = (ID) => {
    setIsVisible(false);
    setSelectedWebhook(ID);
    setEditModal(!editModal);
  };

  const toggleDelete = (ID) => {
    setIsVisible(false);
    setSelectedWebhook(ID);
    setDeleteModal(!deleteModal);
  };

  const toggleDelivery = (ID) => {
    if (ID == selectedWebhook){
      setIsVisible(false)
      setSelectedWebhook("")
    } else {
      setIsVisible(true);
      setSelectedWebhook(ID);
    }
  };

  const callSetWebHookUrls = (data) => {
    setWebHookUrls(data);
  };

  const onDeleteWebhook = async (event) => {
    const axiosConfig = {
      headers: {
        Authorization:
          "Bearer " +
          store.getData("data").Token.access_token,
      },
    };
    const url =
      api +
      "v1/organizations/" +
      store.getData("orgs").ID +
      "/webhooks/" +
      selectedWebhook;
    try {
      await axios.delete(url, axiosConfig);
    } catch (error) {
      console.log(error);
    }
    setDeleteModal(!deleteModal);
    setRefreshPage(!refreshPage)
  };

  useEffect(() => {
    const axiosConfig = {
      headers: {
        Authorization:
          "Bearer " +
          store.getData("data").Token.access_token,
      },
    };
    const url =
      api +
      "v1/organizations/" +
      store.getData("orgs").ID +
      "/webhooks";
    axios.get(url, axiosConfig).then((response) => {
      setWebHookUrls(response.data);
    });
  }, [refreshPage]);
  return (
    <Page title="" breadcrumbs={[{ name: "Webhooks", active: true }]}>
      <CardTitle>Manage Webhooks</CardTitle>
      <CardText>
        Manage webhooks for user events. Webhooks allow external services to be
        notified when certain events happen.
      </CardText>
      <Row>
        <Col>
          <InsertButton
            className="button-float-right"
            btnContextual="btn-default"
            btnGlyphicon="glyphicon-plus fa-plus"
            btnText="ADD WEBHOOKS"
            onClick={toggleAdd}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="h- w-100">
            {webHookUrls
              ? webHookUrls.map(
                  ({ PayloadURL, IsLastDeliverySuccess, ID }, index) => (
                    <CardBody
                      key={index}
                      className="d-flex flex-column justify-content-start "
                    >
                      <ButtonGroup className="d-flex flex-row justify-content-between mb-0">
                        <Row>
                          <Col>
                            {IsLastDeliverySuccess ? (
                              <IoCheckmark fill="green" />
                            ) : (
                              <IoClose fill="red" />
                            )}
                          </Col>
                          <CardText
                            className="card-list text-color-blue"
                            id={ID}
                            onClick={() => {
                              toggleDelivery(ID);
                            }}
                          >
                            {PayloadURL}
                          </CardText>
                        </Row>
                        <Row>
                          <Col>
                            <InsertButton
                              btnContextual="btn-default"
                              btnGlyphicon="glyphicon-plus fa-plus"
                              btnText="EDIT"
                              onClick={() => {
                                toggleEdit(ID);
                              }}
                            />
                          </Col>
                          <Col>
                            <InsertButton
                              btnContextual="btn-default"
                              btnGlyphicon="glyphicon-plus fa-plus"
                              btnText="DELETE"
                              name={ID}
                              onClick={() => {
                                toggleDelete(ID);
                              }}
                            />
                          </Col>
                        </Row>
                      </ButtonGroup>
                      {(isVisible && ID == selectedWebhook) ? <WebhookLogPage selectedWebhook={selectedWebhook} /> : "" }
                    </CardBody>
                  )
                )
              : ""}
            <Modal backdrop="static" isOpen={addModal} toggle={() => {
                setAddModal(!addModal);
                setRefreshPage(!refreshPage)
              }} className={className}>
              <AddWebhokkModal
                setWebHookUrls={callSetWebHookUrls}
                webHookUrls={webHookUrls}
                refreshPage = {() => {setRefreshPage(!refreshPage)}}
                onModalClose={() => {
                  setAddModal(!addModal);
                  setRefreshPage(!refreshPage)
                }}
              />
            </Modal>
            <Modal
              backdrop="static"
              isOpen={deleteModal}
              toggle={() => {
                setDeleteModal(!deleteModal);
                setSelectedWebhook("");
                setRefreshPage(!refreshPage)
              }}
              className={className}
            >
              <ModalHeader 
                toggle={() => {
                  setDeleteModal(!deleteModal);
                  setSelectedWebhook("");
                  setRefreshPage(!refreshPage)
                }}
              >
                Delete Webhook
              </ModalHeader>
              <ModalBody>
                You are about to delete an existing webhook. This action is not
                reversible.
              </ModalBody>
              <ModalFooter>
                <button
                  className="btn btn-default"
                  size="ig"
                  onClick={onDeleteWebhook}
                >
                  OK
                </button>
                <button
                  size="ig"
                  className="btn btn-default"
                  onClick={() => {
                    setDeleteModal(!deleteModal);
                    setSelectedWebhook("");
                    setRefreshPage(!refreshPage)
                  }}
                >
                  CANCEL
                </button>
              </ModalFooter>
            </Modal>
            <Modal
              backdrop="static"
              isOpen={editModal}
              toggle={() => {
                setEditModal(!editModal);
                setSelectedWebhook("");
                setRefreshPage(!refreshPage)
              }}
              className={className}
            >
              <EditWebhokkModal
                setWebHookUrls={callSetWebHookUrls}
                webHookUrls={webHookUrls}
                onModalClose={() => {
                  setEditModal(!editModal);
                  setSelectedWebhook("");
                  setRefreshPage(!refreshPage)
                }}
                selectedWebhook={selectedWebhook}
              />
            </Modal>
          </Card>
        </Col>
      </Row>
    </Page>
  );
};

export default WebhookPage;
