import React from 'react';
import bn from '../../utils/bemnames';
import { withRouter, } from 'react-router-dom';
import {
  Navbar,
  // NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  ListGroup,
  ListGroupItem,
  Button,
} from 'reactstrap';
import jsonData from '../../setting/settings.json';
import {
  MdNotificationsActive,
  MdNotificationsNone,
  MdSettingsApplications,
  MdClearAll,
  MdExitToApp,
} from 'react-icons/lib/md';

import Avatar from '../Avatar';
import { userLogout } from '../../services/user'
import { UserCard } from '../Card';
import {GetUserDetails,} from '../Sources';
import withBadge from '../../hocs/withBadge';
import UserImage from '../../assets/img/users/user.jpg';
import moment from 'moment';
import { notificationsData } from '../../demos/header';
import axios from 'axios'
import { store } from '../../services/localStorageStore';

const bem = bn.create('header');

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
    orgName: '',
    userText: '',
    userSubtitle: '',
    userTitle: '',
    userImage: ''
  };

  componentDidMount() {
    this.setState({orgName: (store.getData('orgs') != null)? store.getData('orgs').Name : '',
                  userText: (store.getData('data') != null)?"Last visit: " +  moment(store.getData('data').User.LastVisit ).format('LLL'):'',
                  userSubtitle: (store.getData('data') != null)?store.getData("data").User.Email:'',
                  userTitle: (store.getData('data') != null)?(store.getData('data').User.Name):'',
                  userImage: ((store.getData('data') != null) && (store.getData('data').User.ImageID!=0))? JSON.parse(JSON.stringify(jsonData)).api + 'v1/users/'  + ( (store.getData('data') != null)?(store.getData('data').User.ID):'' )  + '/image/' + ((store.getData('data') != null)?(store.getData('data').User.ImageID):'' ) +  '/web': UserImage
                })
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
    
};

 toggleUserCardPopover = () => {
    this.setState({
    isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
 
  };
 userProfile = () => {
    window.location.href="/profile";
 };
  

 
  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

 render() {
    return (
    
      <Navbar light expand className={bem.b('bg-white')}>  
        <Nav navbar className="mr-2">
          <Button outline size="sm" className="btn btn-default btn-round" onClick={this.handleSidebarControlButton}>
            <MdClearAll size={25} />
          </Button>
        </Nav>
        <Nav navbar>

        </Nav>
        <Nav navbar className={bem.e('nav-right')}>
        <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
                <span className='org-name'>
                  {this.state.orgName}
                </span>
            </NavLink>
          </NavItem>
           <NavItem>
            <NavLink id="Popover2">
              <Avatar src={this.state.userImage}
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}>
              <PopoverBody className="p-0">
                <UserCard
                  src={this.state.userImage}
                  title={this.state.userTitle}                
                  subtitle={this.state.userSubtitle}                  
                  text= {this.state.userText}     
                  className="border-primary">
                  <ListGroup flush>
                     <ListGroupItem tag="a" href="manage" className="border-0 border-light bg-light" >
                       <MdSettingsApplications /> Settings
                     </ListGroupItem>
                     <ListGroupItem tag="button" action className="border-0 border-light bg-light" onClick={userLogout}>
                      <MdExitToApp /> Signout
                     </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default withRouter(Header);