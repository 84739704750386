import { STATE_LOGIN, STATE_SIGNUP } from "./components/AuthForm";
import GAListener from "./components/GAListener";
import {
  EmptyLayout,
  LayoutRoute,
  MainLayout,
  OnboardingLayout
} from "./components/Layout";
import AuthModalPage from "./pages/AuthModalPage";
import AuthPage from "./pages/AuthPage";
import DeveloperPage from "./pages/DeveloperPage";
import ButtonPage from "./pages/ButtonPage";
import DashboardPage from "./pages/DashboardPage";
import DataAgreementPageV2 from "./pages/DataAgreementPageV2";
import PersonalDataPage from "./pages/PersonalDataPage";
import EndUserPage from "./pages/EndUserPage";
import LogPage from "./pages/LogPage";
import ManagePage from "./pages/ManagePage";
import StartPage from "./pages/StartPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import ConsentsPage from "./pages/ConsentsPage";
import NotificationsPage from "./pages/NotificationsPage";
import ModalPage from "./pages/ModalPage";
import OrganizationPage from "./pages/OrganizationPage";
import ProfilePage from "./pages/ProfilePage";
import PrivacyDashboardPage from "./pages/PrivacyDashboardPage";
import WidgetPage from "./pages/WidgetPage";
import SystemEventsPage from "./pages/SystemEventsPage";
import WebhookPage from "./pages/WebhookPage"
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import OrderingWizard from "./components/billing/OrderingWizard";
import "./styles/reduction.css";
import BillingDetails from "./components/billing/BillingDetails";
import PayPerUserManager from "./components/billing/PayPerUserManager";
import OnboardingWizardPage from "./pages/OnboardingWizardPage";
import AriesCloudAgentPage from "./pages/AriesCloudAgentPage";
import ConnectionPage from "./pages/ConnectionPage";
import CredentialPage from "./pages/CredentialPage";
import VerificationsPage from "./pages/VerificationsPage";
import VerificationHistory from "./pages/VerificationHistory";
import IssuanceHistory from "./pages/IssuanceHistory";
import ProtectedRoute from './components/Layout/ProtectedRoute'
import { connect } from "react-redux";
import { checkIsAuthenticated } from "./actions";


const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class App extends React.Component {

  componentDidMount() {
    this.props.checkIsAuthenticated()
  }

  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <LayoutRoute
              exact
              path="/signup"
              layout={EmptyLayout}
              component={props => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />
            <LayoutRoute
              exact
              path="/login-modal"
              layout={MainLayout}
              component={AuthModalPage}
            />
            <LayoutRoute
              exact
              path="/billing"
              layout={MainLayout}
              component={DashboardPage}
            />
            <LayoutRoute
              exact
              path="/buttons"
              layout={MainLayout}
              component={ButtonPage}
            />
            <ProtectedRoute
              exact
              path="/start"
              layout={MainLayout}
              component={StartPage}
            />
            <ProtectedRoute
              exact
              path="/aries-cloud-agent"
              layout={MainLayout}
              component={AriesCloudAgentPage}
            />
            <ProtectedRoute
              exact
              path="/connections"
              layout={MainLayout}
              component={ConnectionPage}
            />
            <ProtectedRoute
              exact
              path="/credentials"
              layout={MainLayout}
              component={CredentialPage}
            />
            <ProtectedRoute
              exact
              path="/issuance-history/:dataAgreementId"
              layout={MainLayout}
              component={IssuanceHistory}
            />
            <ProtectedRoute
              exact
              path="/verifications"
              layout={MainLayout}
              component={VerificationsPage}
            />
            <ProtectedRoute
              exact
              path="/verification-history/:dataAgreementId"
              layout={MainLayout}
              component={VerificationHistory}
            />
            <ProtectedRoute
              exact
              path="/widgets"
              layout={MainLayout}
              component={WidgetPage}
            />
            <ProtectedRoute
              exact
              path="/manage"
              layout={MainLayout}
              component={ManagePage}
            />
            <ProtectedRoute
              exact
              path="/subscription"
              layout={MainLayout}
              component={SubscriptionPage}
            />
            <ProtectedRoute
              exact
              path="/consents"
              layout={MainLayout}
              component={ConsentsPage}
            />
            <ProtectedRoute
              exact
              path="/notifications"
              layout={MainLayout}
              component={NotificationsPage}
            />
            <ProtectedRoute
              exact
              path="/privacy"
              layout={MainLayout}
              component={PrivacyDashboardPage}
            />
            <ProtectedRoute
              exact
              path="/developer"
              layout={MainLayout}
              component={DeveloperPage}
            />
            <ProtectedRoute
              exact
              path="/systemevents"
              layout={MainLayout}
              component={ModalPage}
            />
            <ProtectedRoute
              exact
              path="/events"
              layout={MainLayout}
              component={SystemEventsPage}
            />
            <ProtectedRoute
              exact
              path="/webhooks"
              layout={MainLayout}
              component={WebhookPage}
            />
            {/* <ProtectedRoute
              exact
              path="/dataagreement"
              layout={MainLayout}
              component={DataAgreementPage}
            /> */}
            <ProtectedRoute
              exact
              path="/dataagreement"
              layout={MainLayout}
              component={DataAgreementPageV2}
            />
            <ProtectedRoute
              exact
              path="/personaldata"
              layout={MainLayout}
              component={PersonalDataPage}
            />
            <ProtectedRoute
              exact
              path="/logs"
              layout={MainLayout}
              component={LogPage}
            />
            <LayoutRoute
              exact
              path="/register"
              layout={MainLayout}
              component={AuthPage}
            />
            <LayoutRoute
              exact
              path="/orgs"
              layout={MainLayout}
              component={OrganizationPage}
            />
            <ProtectedRoute
              exact
              path="/profile"
              layout={MainLayout}
              component={ProfilePage}
            />
            <LayoutRoute
              exact
              path="/enduser"
              layout={MainLayout}
              component={EndUserPage}
            />
            <LayoutRoute
              exact
              path="/orderingwizard"
              layout={MainLayout}
              component={OrderingWizard}
            />
            <LayoutRoute
              exact
              path="/billingdetails"
              layout={MainLayout}
              component={BillingDetails}
            />
            <LayoutRoute
              exact
              path="/managepayperuser"
              layout={MainLayout}
              component={PayPerUserManager}
            />
            <LayoutRoute
              exact
              path="/onboarding"
              layout={OnboardingLayout}
              component={OnboardingWizardPage}
            />

            <Redirect to="/" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

// export default componentQueries(query)(App);
function mapStateToProps(state) {
  return state.checkIsLogin;
}


export default connect(
  mapStateToProps,
  { checkIsAuthenticated }
)(componentQueries(query)(App));