import {
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_ERROR
} from "../actions/action_types";
export default function(state = null, action) {
  switch (action.type) {
    case FETCH_INVOICES_SUCCESS:
      return action.payload && action.payload.data ? action.payload.data : null;
    case FETCH_INVOICES_ERROR:
      return [];
    default:
      break;
  }
  return state;
}
