import {
  VALIDATE_NEW_USER_REQUEST,
  VALIDATE_NEW_USER_FAILURE,
  VALIDATE_NEW_USER_SUCCESS
} from "../actions/action_types";

export const ValidateNewUserReducer = (state = {}, action) => {
  switch (action.type) {
    case VALIDATE_NEW_USER_REQUEST:
      return { ...state, isFetching: true };
    case VALIDATE_NEW_USER_SUCCESS:
      return { data: action.payload, isFetching: false };
    case VALIDATE_NEW_USER_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};
