import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions/onboarding_actions";
import { countryData } from "./../../utils/countryCodes";
import ReduxFormInputField from "./ReduxFormInputField";
import { ReduxFormSelectField } from "./ReduxFormSelectField";

const renderError = ({ meta: { touched, error } }) =>
  touched && error ? (
    <span
      style={{
        fontSize: "12px",
        color: "red"
      }}
    >
      {error}
    </span>
  ) : (
    <div>
      <br />
    </div>
  );

class OrgDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {}
    };
    this.handleOrgDetailsSubmit = this.handleOrgDetailsSubmit.bind(this);

    this.countryList = countryData.map(country => ({
      value: country.Name,
      label: country.Name
    }));
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [key]: value
      }
    }));
  };

  async handleOrgDetailsSubmit(values) {
    const { onOrgDetailsSubmit, handleSubmit, newUserValidity } = this.props;

    onOrgDetailsSubmit();
  }

  // Validation functions
  isRequiredField = value => {
    return value || typeof value === "number" ? undefined : "Required";
  };

  render() {
    const {
      onAdminDetailsSubmit,
      handleSubmit,
      onCancel,
      orgCategories,
      organization
    } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit(this.handleOrgDetailsSubmit)}>
          <Card className="h-100 w-100">
            <CardBody>
              <CardTitle className="card-title mb-3">
                Organisation Details
              </CardTitle>
              <FormGroup>
                <Label htmlFor="orgName" style={{ fontSize: "14px" }}>
                  Organisation Name
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="text"
                    component={ReduxFormInputField}
                    name="orgName"
                    id="orgName"
                    validate={this.isRequiredField}
                    placeholder="Organisation Name"
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="orgCategory" style={{ fontSize: "14px" }}>
                  Organisation Category
                </Label>
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    name="orgCategory"
                    component={ReduxFormSelectField}
                    options={orgCategories}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="city" style={{ fontSize: "14px" }}>
                  City
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="text"
                    component={ReduxFormInputField}
                    name="city"
                    id="city"
                    placeholder="City"
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="country" style={{ fontSize: "14px" }}>
                  Country
                </Label>
                <Col
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    name="country"
                    component={ReduxFormSelectField}
                    options={this.countryList}
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="orgNum" style={{ fontSize: "14px" }}>
                  Organisation Number
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="text"
                    component={ReduxFormInputField}
                    name="orgNum"
                    id="orgNum"
                    validate={this.isRequiredField}
                    placeholder="Organisation Number"
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="vat" style={{ fontSize: "14px" }}>
                  VAT
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="text"
                    component={ReduxFormInputField}
                    name="vat"
                    id="vat"
                    validate={this.isRequiredField}
                    placeholder="VAT"
                  />
                </Col>
              </FormGroup>
              <div>
                <div>
                  <Field
                    name="authorisationStatus"
                    id="authorisationStatus"
                    component="input"
                    type="checkbox"
                  />
                  <span className="mt-2 mb-2" style={{ fontSize: "14px" }}>
                    {" "}
                    I declare that all the above information is correct and I am
                    authorized to provide the above information on behalf of the
                    organisation
                  </span>
                  <div>
                    <Field name="authorisationStatus" component={renderError} />
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <Button
                  className="btn btn-default btn-outline-secondary text-left"
                  onClick={onCancel}
                >
                  <MdChevronLeft />
                  Prev
                </Button>

                <Button
                  type="submit"
                  className="btn btn-default btn-outline-secondary text-left"
                >
                  Next
                  <MdChevronRight />
                </Button>
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    );
  }
}
function validate(values, props) {
  const errors = {};
  if (!values["authorisationStatus"]) {
    errors["authorisationStatus"] = "Please confirm your authority";
  }
  return errors;
}

function mapStateToProps({ orgTypes, organization }) {
  let initialValues = {};
  if (orgTypes) {
    initialValues.orgCategory = {
      value: orgTypes.data[0].ID,
      label: orgTypes.data[0].Type
    };
  }
  const countryList = countryData.map(country => ({
    value: country.Name,
    label: country.Name
  }));
  initialValues.selectedPackage = "freetrial";
  initialValues.country =
    countryList[countryList.findIndex(country => country.value === "Sweden")];
  return {
    orgCategories: orgTypes.data.map(orgType => ({
      value: orgType.ID,
      label: orgType.Type
    })),
    organization,
    initialValues
  };
}

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "signUpWizard",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    //enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate
  })(OrgDetailsForm)
);
