import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody
} from "reactstrap";
import { Tooltip } from '@material-ui/core';
import { Link } from "react-router-dom";
import {
  AiOutlinePlusCircle,
  AiOutlineDelete,
} from "react-icons-latest/ai";
import { connect } from "react-redux";

import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from "react-bootstrap-table-next";
import LoadingOverlay from "react-loading-overlay";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import Page from "../components/Page";
import GeneralDeleteModalV2 from "../components/GeneralDeleteModalV2";
import OrganisationQrCodeModal from "../components/OrganisationQrCodeModal";
import sortTime from "../utils/sortTime";

import { showLoader, hideLoader } from "../actions/loaderActions";
import { PopoverWidget } from "../components/Widget";
import { isCloudAgentDeployed, fetchCloudAgentDeploymentInfo } from "../utils/localStorageUtils";
import {
  fetchConnectionsInCloudAgent,
  deleteConnectionsInCloudagent
} from "../services/index";
import {
  TableLoader
} from "../components/Loader";

// FIXME: This is a temporary fix for https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

const ConnectionPage = (props) => {

  const [ariesCloudAgentEnabled, setAriesCloudAgentEnabled] = useState(false);

  const [adminEndpoint, setAdminEndpoint] = useState("");

  const [isTableLoading, setIsTableLoading] = useState(false);

  const [connections, setConnections] = useState([]);

  const [paginationTotalCount, setPaginationTotalCount] = useState(0);

  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);

  const [paginationPageSize, setPaginationPageSize] = useState(10);

  const [connectionsQrModalVisibilityState, setConnectionsQrModalVisibilityState] = useState(false);

  const [deleteConnectionsModalVisibilityState, setDeleteConnectionsModalVisibilityState] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const inputStyle = {
    width: '85%',
    color: "#495057",
    border: "1",
    borderWidth: 0,
    padding: 0,
    paddingBottom: 1,
    borderRadius: 0,
    fontSize: "16px",
    borderBottomWidth: 1.2,
    borderBottomColor: "lightgray", //'#DFE0E1',
  };

  /**
   * Fetch all connections from the cloud agent.
   */
  const fetchConnections = (page = paginationCurrentPage, page_size = paginationPageSize) => {

    // Show table loader.
    setIsTableLoading(true);

    fetchConnectionsInCloudAgent(page, page_size).then((response) => {

      const connectionRecords = response.data.results;
      const paginationData = response.data.pagination;

      // Set the total count of the pagination.
      setPaginationTotalCount(paginationData.totalCount);

        const data = connectionRecords.filter(obj => {
          return obj.their_label !=='MyData-DID-Registry'
        }).map((connection) => {
     
        connection.updated_at = new Date(connection.updated_at * 1000).toLocaleString("en-GB", {
          dateStyle: "long",
          timeStyle: "medium",
          hour12: true,
        });

        return connection;

      });

      // Set table data.
      setConnections(data);

      // Hide the table loader..
      setIsTableLoading(false);

    }).catch((err) => {

      // Handle error.

      console.log(err);

      // Set table data.
      setConnections([]);

      // Hide the table loader..
      setIsTableLoading(false);

    });

  };

  /**
   * Handle page change for table.
   * 
   * @param {*} page 
   * @param {*} pageSize 
   */
  const handlePageChange = (page, pageSize) => {

    setPaginationCurrentPage(page);

    setPaginationPageSize(pageSize);

    fetchConnections(page, pageSize);

  }

  /**
   * Triggered when table content changes.
   * 
   * @param {*} type 
   * @param {*} newState 
   */
  const onTableChange = (type, { page, sizePerPage }) => {
    // handle any data change here

    switch (type) {

      case 'pagination':
        handlePageChange(page, sizePerPage);
        break

    }


  }

  /**
   * Run once when component is mounted.
   */
  useEffect(() => {

    // Check if aries cloudagent is deployed.
    if (isCloudAgentDeployed()) {

      // Cloud agent deployment info.
      const cloudAgentDeploymentInfo = fetchCloudAgentDeploymentInfo();

      // Update state to indicate cloud agent is deployed.
      setAriesCloudAgentEnabled(true);

      // Update state with cloud agent admin endpoint.
      setAdminEndpoint(cloudAgentDeploymentInfo.AdminEndpoint);

      // Fetch connections from cloud agent, if admin endpoint is available.
      fetchConnections();

    } else {

      // Update state to indicate cloud agent is not deployed.
      setAriesCloudAgentEnabled(false);

    }


  }, []);

  const colDispStyle = {
    fontSize: "14px",
    padding: ".35rem",
    borderWidth: "1px solid !important",
  };

  const headerDispStyle = {
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    fontWeight: "bold",
    border: 'solid',
    borderWidth: "0px 1px 3px 1px",
    borderColor: '#dee2e6'
  };

  const buttonDispStyle = {
    width: "3%",
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    borderWidth: "0px",
    borderBottomWidth: "3px",
  };

  const colDispStyleButton = {
    fontSize: "14px",
    cursor: "pointer",
    borderWidth: "0px",
    borderBottomWidth: "1px",
    padding: ".35rem",
  };

  const SearchBarStyle = {
    minWidth: "200px",
    height: "33px",
    position: "relative",
    float: "right",
    marginBottom: "0px",
  };

  const columnConnection = [
    {
      dataField: "their_label",
      text: "Their Label",
      headerStyle: { ...headerDispStyle, width: "15%" },
      style: colDispStyle,
    },
    {
      dataField: "my_did",
      text: "My Decentralised Identifier",
      headerStyle: { ...headerDispStyle, width: "15%" },
      style: colDispStyle,
    },
    {
      dataField: "their_did",
      text: "Their Decentralised Identifier",
      headerStyle: { ...headerDispStyle, width: "15%" },
      style: colDispStyle,
    },
    {
      dataField: "updated_at",
      text: "Connected on",
      sortFunc: sortTime,
      headerStyle: { ...headerDispStyle, width: "14%" },
      style: colDispStyle
    },
    {
      dataField: "connection_id",
      text: "Connection Identifier",
      headerStyle: {
        ...headerDispStyle,
        borderRightWidth: "0px",
        width: "17%",
      },
      style: { ...colDispStyle, borderRightWidth: "0px" },
    },
    {
      dataField: "Delete",
      text: "",
      headerStyle: buttonDispStyle,
      style: { ...colDispStyleButton, textAlign: "center" },
      formatter: (cellContent, row) => {
        return (
          <Tooltip title='Delete connection'>
            <AiOutlineDelete
              size="20px"
              onClick={() => {
                setSelectedRow(row)
                setDeleteConnectionsModalVisibilityState(!deleteConnectionsModalVisibilityState)
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  const AddConnectionPopover = (size, onClick, onMouseOver) => {
    return (
      <AiOutlinePlusCircle
        size={"25px"}
        style={{ cursor: "pointer" }}
        onClick={onClick}
        onMouseOver={onMouseOver}
      />
    );
  };

  const triggerToggleQR = () => {
    setConnectionsQrModalVisibilityState(!connectionsQrModalVisibilityState);
  };

  const AriesCloudAgentLink = (linkStyle) => {
    return (
      <Link style={linkStyle} to={"/aries-cloud-agent"}>
        Deploy Aries cloud agent
      </Link>
    );
  };

  const ConnectionRFCLink = (linkStyle) => {
    return (
      <a
        style={linkStyle}
        href="https://github.com/hyperledger/aries-rfcs/tree/9b0aaa39df7e8bd434126c4b33c097aae78d65bf/features/0160-connection-protocol"
        target="_blank"
      >
        Click Here
      </a>
    );
  };

  /**
   * Toggle delete connections modal.
   */
  const toggleDeleteConnectionsModal = () => {

    setDeleteConnectionsModalVisibilityState(!deleteConnectionsModalVisibilityState);

  }

  /**
   * Toggle connection QR modal.
   */
  const toggleConnectionsQrModal = () => {

    setConnectionsQrModalVisibilityState(!connectionsQrModalVisibilityState);

    // Reload the connections table, to show the new connection entry (if available)
    fetchConnections(
      paginationCurrentPage,
      paginationPageSize,
    );


  }

  return (

    <Page title="" breadcrumbs={[{ name: "Self-Sovereign Identity / Connections", active: true },]} className="TablePage">

      <Row>

        <Col>

          <Card>

            <CardTitle>

              <div

                className="card-table-heading"

                style={{

                  paddingTop: "35px",

                  display: "flex",

                  justifyContent: "space-between",

                  alignItems: "center",

                  flexWrap: "wrap"

                }}
              >

                <div

                  id="connections-table-header-left-container"

                  style={{

                    maxWidth: "300px",

                    width: "130px",

                    display: "flex",

                    justifyContent: "space-between",

                    alignItems: "center"

                  }}

                >

                  <span>Connections</span>

                  {ariesCloudAgentEnabled ?

                    (

                      <Tooltip

                        title={"Create connection"}

                        placement={"top"}

                      >

                        <AiOutlinePlusCircle

                          size={"25px"}

                          style={{
                            cursor: "pointer",
                          }}

                          onClick={triggerToggleQR}

                        />

                      </Tooltip>


                    ) :

                    (

                      <PopoverWidget

                        element={AddConnectionPopover}

                        text="Deploy Aries cloud agent to enable the use of QR code"

                        size="25px"

                        link={AriesCloudAgentLink}

                      />

                    )}

                </div>



              </div>

            </CardTitle>



            <div>

              <CardSubtitle

                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",

                }}

              >

                <div className="card-table-subheading">
                  Manages connections towards your organisations
                </div>

              </CardSubtitle>


              <CardBody>



                <div className="connection-table">

                  <BootstrapTable

                    remote={{ pagination: true, filter: false, sort: false }}

                    pagination={
                      paginationFactory(
                        {

                          page: paginationCurrentPage,

                          sizePerPage: paginationPageSize,

                          totalSize: paginationTotalCount,

                          nextPageText: ">",

                          prePageText: "<",

                          alwaysShowAllBtns: false,

                          hideSizePerPage: true,

                        }
                      )
                    }

                    loading={isTableLoading}

                    overlay={
                      overlayFactory(
                        {
                          spinner: <TableLoader />,
                          styles: {
                            overlay: (base) => (
                              {
                                ...base,
                                background: 'rgba(198, 198, 198, 0.07)'
                              }
                            ),
                          }
                        }
                      )
                    }

                    bootstrap4={true}

                    id="btPurpose"

                    keyField="connection_id"

                    hover={true}

                    striped={false}

                    data={connections}

                    columns={columnConnection}

                    onTableChange={onTableChange}

                    noDataIndication={
                      () => {
                        return !isTableLoading ? <div>No data to display</div> : <div style={{ height: "300px" }}></div>
                      }
                    }

                  />
                </div>



              </CardBody>

            </div>







          </Card>


          <GeneralDeleteModalV2

            loaderTag={"GeneralDeleteModalV2ForConnections"}

            requireConfirmText={true}

            confirmText="DELETE"

            modalHeaderTitleText={<div className="delete-modal-header-title-div"> Delete Connection:  <span>{selectedRow && selectedRow.their_label}</span></div>}

            modelHeaderDescriptionText={selectedRow && selectedRow.connection_id}

            modalDescriptionText={<p> You are about to delete an existing connection. Please type <span style={{ fontWeight: "bold" }}>DELETE</span> to confirm and click DELETE. This action is not reversible.</p>}

            deleteCallBack={() => {

              const { showLoader, hideLoader } = props;

              showLoader("GeneralDeleteModalV2ForConnections");

              // Perform delete connections and reload the connections table.
              deleteConnectionsInCloudagent(selectedRow.connection_id).then((res) => {

                // Reload connections table.
                fetchConnections(
                  paginationCurrentPage,
                  paginationPageSize,
                );

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForConnections");

                // Close the delete modal.
                toggleDeleteConnectionsModal();

              }).catch(err => {

                console.log(err);

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForConnections");

                // Close the delete modal.
                toggleDeleteConnectionsModal();

              })

            }}

            toggleState={deleteConnectionsModalVisibilityState}

            toggle={toggleDeleteConnectionsModal}

          />

          {
            connectionsQrModalVisibilityState &&
            <OrganisationQrCodeModal

              isOpen={connectionsQrModalVisibilityState}
              toggleIsOpen={toggleConnectionsQrModal}
            />
          }



        </Col>


      </Row>


    </Page>


  );

};

const mapStateToProps = (state) => {
  return {};
}

export default connect(
  mapStateToProps,
  {
    showLoader,
    hideLoader,
  }
)(ConnectionPage);
