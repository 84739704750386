import React from 'react';
import jsonData from '../setting/settings.json';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  CardBody,
  ButtonGroup,Form,FormGroup,Label,Modal,ModalHeader,ModalBody,ModalFooter,
} from 'reactstrap';
import Select from 'react-select';
import Page from '../components/Page';
import { store } from '../services/localStorageStore'

let Status='',Ver='',DnsName='',Ip='',Msg='', selectedVersion='', currentHostAddress='';
let dashboardDDVersions = [];

const apiMain = JSON.parse(JSON.stringify(jsonData)).api + "v1/" ;
const api =  apiMain + "organizations/";

const headerStyle = {
    fontSize: '16px',
    backgroundColor: '#eeeeee',
 }; 
 const textStyle = {
     color:'#212529',
     fontSize: '16px',
     paddingBottom:1,
 };
 const inputStyle = {
    color:'#495057',
    border:'1',
    borderWidth: 0,
    padding:0,
    paddingBottom:1,
    borderRadius:0,
    fontSize: '16px',
    borderBottomWidth: 1.2,
    borderBottomColor: 'lightgray',//'#DFE0E1', 
 };

 const customStylesDD = {
  control: (base, state) => ({
     ...base,
       '&:hover': { borderColor: 'gray' }, // border style on hover
        border: '1px solid lightgray', // default border color
        boxShadow: 'none', // no box-shadow
 })
 };

 function addReleaseVersions() {

   dashboardDDVersions = [];
  
   let data = store.getData("data");
   let orgs = store.getData('orgs');
       
   if( data && orgs ) 
   {
   ///v1//privacy-dashboard/docker/images
   var url = apiMain +'privacy-dashboard/docker/images';
   var axiosConfig = {
    headers: {
        'Authorization': 'Bearer ' + data.Token.access_token,
        }
    }; 
   
   axios.get(url  ,  axiosConfig)
   .then((response) => {   
          
        let resp= JSON.parse(JSON.stringify(response.data));
          
        for(let i=0;i<resp.length; i++)
        {
          let orgpurDDown = {
                value: resp[i].version,
                label: resp[i].version,
                text: resp[i].timestamp,
              }  ;
          dashboardDDVersions.push(orgpurDDown);
          dashboardDDVersions.sort((a,b) => b.text - a.text)
          dashboardDDVersions = dashboardDDVersions.slice(0,3)
        }; 

       }) ; 
 }
}

//addReleaseVersions(); 

class PrivacyDashboardPage extends React.Component {
   constructor() {
      super();
      this.handleChangeDetails = this.handleChangeDetails.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.getDetails = this.getDetails.bind(this);
      addReleaseVersions();
    }

    state = {
        status:Status,
        ver:Ver,
        currentVersion: '',
        currentHostName: '',
        dns:'',
        release:'',
        selectedOption:'',
        dnsname:DnsName,
        ip:Ip,
        msg:Msg,
        process:0,
        show: false,
        isOKBtn: false,
    };

   handleCancel()
   {
   };

   updateConfig = modalType => () => {
    if (!modalType) {
      let selectedVersion = dashboardDDVersions.filter(version => version.value === this.state.currentVersion)
      return this.setState({
        show: !this.state.show,
        dns: this.state.currentHostName,
        release : selectedVersion.length ? selectedVersion[0].value : '',
        selectedOption: selectedVersion.length ? selectedVersion[0] : '',
        isOKBtn: false,
      });
    }
   };
  
confirmConfig= modalType => () => {
    let data = store.getData("data");
    let orgs = store.getData('orgs');
    var url = '';

  if( data && orgs ) 
  {
      var dat= {
        'HostName' : this.state.dns+".igrant.io",
        'version' : this.state.release, 
        'Status':1,
      };
 
      var dataHost= {
        'HostName' : this.state.dns+".igrant.io",
      };

      var dataVersion= {
        'version' : this.state.release, 
      };

      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + data.Token.access_token,
            }
        }; 

        switch(Status)
        {
          case 2:  
            url = api + orgs.ID +'/privacy-dashboards/hostname';
 
            axios.patch(url , dataHost, axiosConfig) 
            .then((response) => {   
              let url = api + orgs.ID +'/privacy-dashboards/version';
              axios.patch(url , dataVersion, axiosConfig) 
              .then((response) => {   
                this.getDetails() ;
            }) ; 
            }).catch(()=> {
              let url = api + orgs.ID +'/privacy-dashboards/version';
              axios.patch(url , dataVersion, axiosConfig) 
              .then((response) => {   
                this.getDetails() ;
            }) ; 
            })
            break;

            default:  
            url = api + orgs.ID +'/privacy-dashboards';

            axios.post(url , dat, axiosConfig) 
            .then((response) => {   
              this.getDetails() ;
            }) ; 
            break;

          }
          this.setState({isOKBtn: false})
      }
     
      if (!modalType) {
        return this.setState({
          show: !this.state.show,
        });}
      
 };

 handleChange = (selectedOption) => {
  let valRel = JSON.parse( JSON.stringify( selectedOption )).value;

  this.setState({ selectedOption , release: valRel });
 
  if(this.state.dns.length>2 &&  valRel.length!==0)
  {
     this.setState({isOKBtn: true} );  
  }
  else
  {
   this.setState({isOKBtn: false} ); 
  }
}  

   handleChangeDetails(event) {
       if( event.target.name === 'dns' )  this.setState({dns: event.target.value}) ;
  
        if(event.target.value.length>2 && this.state.release.length!==0)
        {
          this.setState({isOKBtn: true} );  
        }
        else
        {
          this.setState({isOKBtn: false}); 
        }
   }
 
  getDetails ()  
  {
      let data = store.getData("data");
      let orgs = store.getData('orgs');
 
      if( data && orgs ) 
      {
          var axiosConfig = {
            headers: {
                'Authorization': 'Bearer ' + data.Token.access_token,
                }
          }; 
          var url = api + orgs.ID +'/privacy-dashboards';

           axios.get(url ,axiosConfig)
            .then((response) => {   
              let resp= JSON.parse(JSON.stringify(response.data));
              Status=resp.Status;
              switch(resp.Status)
              {
                case 2:
                  Ver='Release ' + resp.Version;
                  DnsName=resp.HostName;
                  //Ip=resp.IPAddress;  
                  Msg='Deployed ';
                  selectedVersion = dashboardDDVersions.filter(version => version.value === resp.Version)
                  selectedVersion = selectedVersion.length ? selectedVersion[0] : ''
                  currentHostAddress = DnsName.slice(0, -10)
                  break;
               default:
                  Ver='<Not Deployed>';
                  DnsName='<Not Deployed>';
                 // Ip='<Not Deployed>';
                  Msg=resp.StatusStr;
                 break;   
              }
              Msg= Msg.toUpperCase();
              this.setState({ status:Status,
              ver:  Ver,
              dnsname: DnsName,
              ip: Ip,
              msg: Msg,
              selectedOption: selectedVersion,
              currentVersion: resp.Version,
              dns: currentHostAddress,
              currentHostName: currentHostAddress
            });
          }) ;
        }
      };

    componentDidMount() {
      this.getDetails();
    };
    render() {
        return (  
           <Page title="" breadcrumbs={[{ name: 'Manage End Users / Privacy Dashboard', active: true }]}>
              <Row className="d-flex justify-content-right">
               <Col md="12" sm="12" xs="12" >
                  <CardTitle>
                    Privacy Board
                  </CardTitle>
                  <CardText>
                    Manage deployment of privacy dashboard towards end users.
                  </CardText>
               </Col>
             </Row>
             <Row className="d-flex justify-content-right">
               <Col md="12" sm="12" xs="12" >
                  <Card  className="h-100 w-100">    
                      <CardBody className="d-flex flex-column justify-content-start h-100 w-100">
                         <ButtonGroup className="d-flex justify-content-between"  style={{ fontSize: 14 , }}> 
                            <CardTitle className="pt-1">Current Status</CardTitle>
                            <span><span style={{ fontSize: 16 ,fontBold: true,fontWeight:800, }} className="pt-1 pr-2">{this.state.msg}</span> <button  className="btn btn-default" disabled={(this.state.status===1)?true:false} style={{cursor:(this.state.status===1)?'not-allowed':'pointer'}}   onClick={this.updateConfig()}>Configure</button> </span>
                         </ButtonGroup>
                     </CardBody>
                  </Card>
               </Col>
            </Row>
            <Modal
                  backdrop="static"
                  isOpen={this.state.show}
                  toggle={this.updateConfig()}
                >
               <ModalHeader toggle={this.updateConfig()} style={ headerStyle } >Privacy Dashboard Configuration</ModalHeader>
               <ModalBody className='pb-0 '>
               <div className='form-group ' key="Release" >
                 <label   style={textStyle } > Dashboard release:</label>
                 <FormGroup   className="mt-0 mb-0">
                   <Select isSearchable={false} ref="release" isMulti={false} options={dashboardDDVersions}  defaultValue={{  label:'label_3', value: 'v 1.1.3', }} name="release" value={this.state.selectedOption || ''}  styles={customStylesDD} onChange={this.handleChange} />
                 </FormGroup>
                </div>
                <div className='form-group ' key="Dns" >
                 <label   style={textStyle } > Deployed domain address:</label>
                 <FormGroup   className="mt-0 mb-0">
                   <input className='w-75 pt-0'  ref="dns" type='text' placeholder="Please type atleast 3 characters" style={inputStyle } name="dns" value={this.state.dns} onChange={this.handleChangeDetails} /> 
                   <label  className='w-25 pt-0 mt-0 mb-0'  style={{ borderColor:'#495057',border:'2',}}  > .igrant.io</label>
                 </FormGroup>
                </div>
                </ModalBody>
                  <ModalFooter className='modal-footer' >
                    <button size="ig" className='btn btn-default'  onClick={this.updateConfig()} >Close</button>
                    <button size="ig" className='btn btn-default' disabled={ !this.state.isOKBtn } style={{cursor:(this.state.isOKBtn)?'pointer':'not-allowed'}}  onClick={this.confirmConfig()}>Confirm</button>
                  </ModalFooter>
            </Modal>
             <Row>
             <Col>
                <Card>    
                  <CardBody className="d-flex flex-column justify-content-start ">
                    <CardTitle>Privacy Dashboard Settings</CardTitle>
                 <Form style={{fontSize:'14px',}}>
                    <FormGroup row className="mt-0 mb-0">
                      <Label for="userName" sm={4} className="pr-0 pt-1 pb-0 ">
                      Deployed {(this.state.msg)?'version':'release'}:
                      </Label>
                      <Col sm={7} className="pr-0 pt-1 text-muted">                                             
                        {this.state.ver}  
                      </Col>
                    </FormGroup>
                    <FormGroup row className="mt-0 mb-0">
                      <Label for="userName" sm={4} className="pr-0 pt-1 pb-0 ">
                      Deployed domain address:
                      </Label>
                      <Col sm={7} className="pr-0 pt-1 text-muted">    
                      {(this.state.dnsname==='<Not Deployed>')? '<Not Deployed>' : (                               
                        <a href={'https://'+this.state.dnsname} target="_blank" style={{color:'blue',fontSize:'14px',}} htmlFor="em2">{this.state.dnsname}</a>  )}
                      </Col>
                    </FormGroup>
                </Form>
               </CardBody>
              </Card>
             </Col>
            </Row> 
           </Page>
         );
        };
       } 
export default PrivacyDashboardPage;

