import React, { useState, useEffect } from "react";
import {
  Modal,
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody
} from "reactstrap";
import {
  AiOutlineHistory,
  AiOutlinePlusCircle,
  AiOutlineEye,
  AiOutlineDelete,
  AiOutlineSend,
  AiOutlineEdit
} from "react-icons-latest/ai";
import { Link } from "react-router-dom";
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from "react-bootstrap-table-next";
import LoadingOverlay from "react-loading-overlay"

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import Page from "../components/Page";
import GeneralDeleteModalV2 from "../components/GeneralDeleteModalV2";
import IssueCredentialModal from "../components/IssueCredentialModal";
import DataAgreementCrudModalV2 from "../components/DataAgreementCrudModalV2";
import Loader from "../components/Loader";

import { PopoverWidget } from "../components/Widget";
import { isCloudAgentDeployed, fetchCloudAgentDeploymentInfo } from '../utils/localStorageUtils';
import { queryPublishedDataSourceDataAgreements, deleteDataAgreementInCloudagent } from '../services';
import {
  showLoader,
  hideLoader
} from "../actions/loaderActions";
import {
  toggleDataAgreementPanel,
  initDataSourceConfiguration,
  initDataUsingServiceConfiguration,
  updateDataAgreementPanelMode,
  updateDataSourceConfiguration
} from "../actions/dataAgreementActions";
import {
  createDataSourceConfigurationFromDataAgreementRecord,
  transformDataAgreementFromServerToClientFormat,
  transformDataAgreementRecordFromServerToClientFormat
} from "../utils/dataAgreementUtils";
import {
  initDataAgreementState
} from "../reducers/DataAgreementReducer";
import {
  TableLoader
} from "../components/Loader";

// FIXME: This is a temporary fix for https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined

const CredentialPage = (props) => {

  const [ariesCloudAgentEnabled, setAriesCloudAgentEnabled] = useState(false);

  const [adminEndpoint, setAdminEndpoint] = useState("");

  const [credentials, setCredentials] = useState([]);

  const [isTableLoading, setIsTableLoading] = useState(false);

  const [paginationTotalCount, setPaginationTotalCount] = useState(0);

  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);

  const [paginationPageSize, setPaginationPageSize] = useState(10);

  const [issueCredentialModalVisibilityState, setIssueCredentialModalVisibilityState] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const [deleteCredentialsModalVisibilityState, setDeleteCredentialsModalVisibilityState] = useState(false);

  const history = useHistory();

  /**
   * Fetch credential templates.
   */
  const fetchCredentialTemplates = async (page = paginationCurrentPage, page_size = paginationPageSize) => {

    // Show table loader.
    setIsTableLoading(true);

    queryPublishedDataSourceDataAgreements(page, page_size).then(response => {

      const data = response.data.results;
      const paginationData = response.data.pagination;

      // Set the total count of the pagination.
      setPaginationTotalCount(paginationData.totalCount);

      setCredentials(data);

      // Hide the table loader..
      setIsTableLoading(false);

    }).catch(error => {

      // Handle error

      console.log(error);

      setCredentials([]);

      // Hide the table loader..
      setIsTableLoading(false);

    });



  };

  /**
   * Handle page change for table.
   * 
   * @param {*} page 
   * @param {*} pageSize 
   */
  const handlePageChange = (page, pageSize) => {

    setPaginationCurrentPage(page);

    setPaginationPageSize(pageSize);

    fetchCredentialTemplates(page, pageSize);

  }

  /**
   * Triggered when table content changes.
   * 
   * @param {*} type 
   * @param {*} newState 
   */
  const onTableChange = (type, { page, sizePerPage }) => {
    // handle any data change here

    switch (type) {

      case 'pagination':
        handlePageChange(page, sizePerPage);
        break

    }


  }

  /**
   * Run once when component is mounted.
   */
  useEffect(() => {

    // Check if aries cloudagent is deployed.
    if (isCloudAgentDeployed()) {

      // Cloud agent deployment info.
      const cloudAgentDeploymentInfo = fetchCloudAgentDeploymentInfo();

      // Update state to indicate cloud agent is deployed.
      setAriesCloudAgentEnabled(true);

      // Update state with cloud agent admin endpoint.
      setAdminEndpoint(cloudAgentDeploymentInfo.AdminEndpoint);

      // Fetch DS data agreements, if admin endpoint is available.
      fetchCredentialTemplates();

    } else {

      // Update state to indicate cloud agent is not deployed.
      setAriesCloudAgentEnabled(false);

    }

  }, []);

  const colDispStyle = {
    fontSize: "14px",
    padding: ".35rem",
    borderWidth: "1px solid !important",
  };

  const headerDispStyle = {
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "0px 1px 3px 1px",
    borderColor: "#dee2e6",
  };

  const buttonDispStyle = {
    width: "3%",
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    borderWidth: "0px",
    borderBottomWidth: "3px",
  };

  const colDispStyleButton = {
    fontSize: "14px",
    cursor: "pointer",
    borderWidth: "0px",
    borderBottomWidth: "1px",
    padding: ".35rem",
  };

  const tableColumns = [
    {

      dataField: "data_agreement.purpose",

      text: "Name",

      headerStyle: { ...headerDispStyle, width: "21%" },

      style: colDispStyle,

    },
    {
      dataField: "data_agreement.template_version",

      text: "Version",

      headerStyle: { ...headerDispStyle, width: "3%" },

      style: colDispStyle,

      formatter: (cellContent) => {
        return cellContent + ".0";
      }
    },
    {
      dataField: "schema_id",

      text: "Schema Identifier",

      headerStyle: { ...headerDispStyle, width: "30%" },

      style: colDispStyle,

    },
    {
      dataField: "cred_def_id",

      text: "Credential Template Identifier",

      headerStyle: {
        ...headerDispStyle,
        borderRightWidth: "0px",
        width: "30%",
      },

      style: { ...colDispStyle, borderRightWidth: "0px" },
    },
    {
      dataField: "",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {
        return (

          <Tooltip placement="top" title='View credential template'>

            <AiOutlineEye

              id="view"

              size="20px"

              onClick={() => {

                openDataAgreementCrudPanel("READ", row);

              }}

            />

          </Tooltip>
        );
      },
    },

    {

      dataField: "History",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip title='Issuance history' placement="top">

            <AiOutlineHistory

              id="issuance-history"

              size="20px"

              onClick={() => {

                history.push('/issuance-history/' + row.data_agreement_id);

              }}

            />
          </Tooltip>

        );
      },
    },
    {
      dataField: "Issue",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {
        return (

          <Tooltip placement="top" title='Issue credential'>

            <AiOutlineSend

              id="issue"

              size="20px"

              onClick={() => {

                setSelectedRow(row);

                toggleIssueCredentialModal();

              }}

            />
          </Tooltip>
        );
      },
    },
    {
      dataField: "Edit",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip placement="top" title='Edit credential template'>

            <AiOutlineEdit

              id="view"

              size="20px"

              onClick={() => {

                openDataAgreementCrudPanel("UPDATE", row);

              }}

            />

          </Tooltip>

        );

      }

    },
    {
      dataField: "Delete",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip placement="top" title='Delete credential template'>

            <AiOutlineDelete

              size="20px"

              onClick={async () => {

                setSelectedRow(row);

                toggleDeleteModal();

              }}
            />

          </Tooltip>
        );
      },
    },
  ];

  const AddCredentialPopover = (size, onClick, onMouseOver) => {
    return (
      <AiOutlinePlusCircle
        size={"25px"}
        style={{ cursor: "pointer" }}
        onClick={onClick}
        onMouseOver={onMouseOver}
      />
    );
  };

  /**
   * Toggle issue credential modal.
   */
  const toggleIssueCredentialModal = () => {

    setIssueCredentialModalVisibilityState(!issueCredentialModalVisibilityState);

  };

  /**
   * Toggle delete credential templates modal.
   * 
   * @param {*} row 
   */
  const toggleDeleteModal = (row) => {

    setDeleteCredentialsModalVisibilityState(!deleteCredentialsModalVisibilityState);

  }

  const AriesCloudAgentLink = (linkStyle) => {
    return (
      <Link style={linkStyle} to={"/aries-cloud-agent"}>
        Deploy Aries cloud agent
      </Link>
    );
  };

  /**
   * To open data agreement crud panel.
   * 
   * @param {*} mode 
   * @param {*} dataAgreementRecord 
   */
  const openDataAgreementCrudPanel = (mode, dataAgreementRecord) => {

    // Initialise data source configuration.
    props.initDataSourceConfiguration();

    // Initialise data using service configuration.
    props.initDataUsingServiceConfiguration();

    switch (mode) {

      case "CREATE":

        // Update data agreement panel mode.
        props.updateDataAgreementPanelMode(initDataAgreementState, mode);

        // Toggle data agreement crud panel.
        props.toggleDataAgreementPanel();

        break;


      case "READ":
      case "UPDATE":

        const { data_agreement: dataAgreement } = dataAgreementRecord;

        let dataSourceConfiguraiton = createDataSourceConfigurationFromDataAgreementRecord(dataAgreementRecord);

        props.updateDataSourceConfiguration(dataSourceConfiguraiton);

        let updatedDataAgreementState = transformDataAgreementRecordFromServerToClientFormat(dataAgreementRecord);

        // Update data agreement panel mode.
        props.updateDataAgreementPanelMode(updatedDataAgreementState, mode);

        // Toggle data agreement crud panel.
        props.toggleDataAgreementPanel();

        break;

    }

  }

  return (
    <Page title="" breadcrumbs={[{ name: "Self-Sovereign Identity / Credentials", active: true },]} className="TablePage">

      <Row>

        <Col>

          <Card>

            <CardTitle>

              <div

                className="card-table-heading"

                style={{

                  paddingTop: "35px",

                  display: "flex",

                  justifyContent: "space-between",

                  alignItems: "center",

                  flexWrap: "wrap"

                }}
              >

                <div

                  id="credentials-table-header-left-container"

                  style={{

                    maxWidth: "300px",

                    width: "120px",

                    display: "flex",

                    justifyContent: "space-between",

                    alignItems: "center"

                  }}

                >

                  <span>Credentials</span>

                  {ariesCloudAgentEnabled ? (

                    <AiOutlinePlusCircle

                      size={"25px"}

                      style={{
                        cursor: "pointer",
                      }}

                      onClick={() => {


                        openDataAgreementCrudPanel("CREATE");


                      }}
                    />

                  ) : (
                    <PopoverWidget

                      element={AddCredentialPopover}

                      text="Deploy Aries cloud agent to enable the creation of credentials."

                      size="25px"

                      link={AriesCloudAgentLink}

                    />
                  )}

                </div>
              </div>


            </CardTitle>

            <CardSubtitle

              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

              }}

            >

              <div className="card-table-subheading">
                Manages issue of data credentials towards end-users
              </div>

            </CardSubtitle>

            <CardBody>

              {
                credentials &&
                <BootstrapTable

                  remote={{ pagination: true, filter: false, sort: false }}

                  pagination={
                    paginationFactory(
                      {

                        page: paginationCurrentPage,

                        sizePerPage: paginationPageSize,

                        totalSize: paginationTotalCount,

                        nextPageText: ">",

                        prePageText: "<",

                        alwaysShowAllBtns: false,

                        hideSizePerPage: true,

                      }
                    )
                  }

                  loading={isTableLoading}

                  overlay={
                    overlayFactory(
                      {
                        spinner: <TableLoader />,
                        styles: {
                          overlay: (base) => (
                            {
                              ...base,
                              background: 'rgba(198, 198, 198, 0.07)'
                            }
                          ),
                        }
                      }
                    )
                  }

                  onTableChange={onTableChange}

                  noDataIndication={
                    () => {
                      return !isTableLoading ? <div>No data to display</div> : <div style={{ height: "300px" }}></div>
                    }
                  }

                  id="btPurpose"

                  data={credentials}

                  bootstrap4={true}

                  keyField="data_agreement_id"

                  hover={true}

                  columns={tableColumns}

                  striped={false}

                ></BootstrapTable>
              }

            </CardBody>

          </Card>


          {
            selectedRow &&
            <Modal backdrop="static" isOpen={issueCredentialModalVisibilityState} toggle={toggleIssueCredentialModal}>

              <Loader

                loaderTag={"IssueCredentialModal"}

              />

              <IssueCredentialModal

                dataAgreementId={selectedRow.data_agreement_id}
                dataAgreementPurpose={selectedRow.data_agreement.purpose}
                dataAgreementPersonalData={selectedRow.data_agreement.personal_data}
                dataAgreementCredDefId={selectedRow.cred_def_id}
                toggle={toggleIssueCredentialModal}

              />

            </Modal>
          }

          <Modal backdrop="static" isOpen={props.dataAgreementPanel.isOpen} unmountOnClose={true}>

            <Loader loaderTag={"DataAgreementCrudModalV2"} />

            <DataAgreementCrudModalV2

              fromCredentialsPage={true}

              onModalClose={() => {

                // Reload credentials table.
                fetchCredentialTemplates();

                // To close crud panel.
                props.toggleDataAgreementPanel();


              }}

            />

          </Modal>

          <GeneralDeleteModalV2

            loaderTag={"GeneralDeleteModalV2ForCredentials"}

            requireConfirmText={true}

            confirmText="DELETE"

            modalHeaderTitleText={<div className="delete-modal-header-title-div"> Delete Credential Template:  <span>{selectedRow && selectedRow.data_agreement.purpose}</span></div>}

            modelHeaderDescriptionText={selectedRow && selectedRow.cred_def_id}

            modalDescriptionText={<p> You are about to delete an existing credential template. Please type <span style={{ fontWeight: "bold" }}>DELETE</span> to confirm and click DELETE. This action is not reversible.</p>}

            deleteCallBack={() => {

              const { showLoader, hideLoader } = props;

              showLoader("GeneralDeleteModalV2ForCredentials");

              // Perform delete credential template and reload the credentials table.
              deleteDataAgreementInCloudagent(selectedRow.data_agreement_id).then((res) => {

                // Reload credentials table.
                fetchCredentialTemplates();

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForCredentials");

                // Close the delete modal.
                toggleDeleteModal();

              }).catch(err => {

                console.log(err);

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForCredentials");

                // Close the delete modal.
                toggleDeleteModal();

              })

            }}

            toggleState={deleteCredentialsModalVisibilityState}

            toggle={toggleDeleteModal}

          />

        </Col>
      </Row>
    </Page>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAgreementPanel: state.DataAgreementPanel,
  };
}

export default connect(
  mapStateToProps,
  {
    showLoader,
    hideLoader,
    toggleDataAgreementPanel,
    initDataSourceConfiguration,
    initDataUsingServiceConfiguration,
    updateDataAgreementPanelMode,
    updateDataSourceConfiguration
  }
)(CredentialPage);
