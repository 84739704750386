import React, { Component } from "react";
import { MdChevronLeft, MdChevronRight } from "react-icons/lib/md";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row
} from "reactstrap";
import { formValueSelector, reduxForm } from "redux-form";
import * as actions from "../../actions";
import StatusMsg from "./StatusMsg";

class OrderConfirmationForm extends Component {
  constructor(props) {
    super(props);
    this.processOrder = this.processOrder.bind(this);
    this.redirectToBillingDashboard = this.redirectToBillingDashboard.bind(
      this
    );
  }

  processOrder() {
    const {
      plan,
      timeCommitment,
      userCommitment,
      serviceAgreementAgreed,
      serviceAgreementVersion
    } = this.props;
    if (serviceAgreementAgreed) {
      this.props.subscribePlan({
        plan,
        timeCommitment,
        userCommitment,
        serviceAgreementVersion
      });
    }
  }

  renderPlanDetails(plan) {
    if (plan === "starter" || plan === "starterverifier") {
      return (
        <div>
          <p className="h3">
            <strong>{plan === "starter" ? "STARTER PACKAGE" : "STARTER VERIFIER PACKAGE"}</strong> - SEK {this.props.planPrice}
          </p>
          <p className="h3">Validity : One Year</p>
        </div>
      );
    } else if (plan === "payperuser") {
      return (
        <div style={{ textAlign: "left" }}>
          {" "}
          <p className="h3 ">
            <strong>PAY-PER-USER PACKAGE</strong>
          </p>{" "}
          <br />
          <CardText>
            Committed Users: {this.props.userCommitment}
            <br />
            Committed Time Period: {this.props.timeCommitment}
            <br />
          </CardText>
        </div>
      );
    } else if (plan === "freetrial" || plan === 'freetrailverifier') {
      return (
        <div>
          <p className="h3">
            <strong>{plan === "freetrial" ? "FREE TRIAL" : "FREE TRAIL VERIFIER"}</strong>
          </p>
          <p className="h3">Validity : 30 days</p>
          <p style={{ fontSize: "14px" }}>
            Plan will be automatically upgraded to starter package at the end of
            the trial period. However, you can choose to cancel anytime.
          </p>
        </div>
      );
    }
    else if(plan === "premiumverifier"){
      return (
      <div>
        <p className="h3">
          <strong>PREMIUM PACKAGE</strong> - SEK {this.props.planPrice}
        </p>
        <p className="h3">Validity : One Year</p>
      </div>
      );
    }
  }

  redirectToBillingDashboard() {
    this.props.history.push("/billing");
  }

  render() {
    const headStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700
    };
    const {
      plan,
      onCancel,
      handleSubmit,
      onOrderComplete,
      activePackage
    } = this.props;
    let timeCommitment, userCommitment;
    if (plan === "payperuser") {
      userCommitment = this.props.userCommitment;
      timeCommitment = this.props.timeCommitment;
    }
    if (activePackage.isFetching) {
      return (
        <div className="mt-5 mb-5">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div>
              <Loader
                type="ThreeDots"
                color="#6a7075"
                height="100"
                width="100"
              />
            </div>
            <div>
              <p>Confirming your order. Please wait...</p>
            </div>
          </div>
        </div>
      );
    } else if (activePackage.errorMessage) {
      // ordering failed
      return (
        <div>
          <div className="d-flex justify-content-center mt-5 mb-5">
            {" "}
            <StatusMsg
              message="Your order is not successful. Please try again. Press OK to go back to billing dashboard."
              statusStyle={{ color: "red" }}
            />
          </div>
          <div className="d-flex justify-content-center mt-5 mb-5">
            {" "}
            <Button
              outline
              type="button"
              className="btn btn-default text-center"
              onClick={this.redirectToBillingDashboard}
            >
              OK
            </Button>
          </div>
        </div>
      );
    } else if (activePackage.PlanType && activePackage.PlanType === plan) {
      // ordering success
      return (
        <div>
          <div className="mt-5 pl-5 pr-5">
            <Row>
              <Col lg={6} md={12} sm={12} xs={12}>
                <Card style={{ height: "200px", fontSize: "14px" }}>
                  <CardBody className="d-flex flex-column justify-content-start align-items-start">
                    <CardTitle style={headStyle}>Package Details</CardTitle>
                  </CardBody>
                  <CardBody>{this.renderPlanDetails(plan)}</CardBody>
                </Card>
              </Col>
            </Row>

            <div className="mt-2 mb-2">
              <StatusMsg
                message="Your order is confirmed. Press OK to go back to billing dashboard."
                statusStyle={{ color: "green" }}
              />
            </div>

            <div className=" mb-5">
              <Button
                outline
                type="button"
                className="btn btn-default mt-3"
                onClick={this.redirectToBillingDashboard}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-5 pl-5 pr-5">
          <p className="mt-2 mb-2" style={{ fontSize: "14px" }}>
            Review your package details and confirm your order:
          </p>
          <Row>
            <Col lg={6} md={12} sm={12} xs={12}>
              <Card style={{ height: "200px", fontSize: "14px" }}>
                <CardBody className="d-flex flex-column justify-content-start align-items-start">
                  <CardTitle style={headStyle}>Package Details</CardTitle>
                </CardBody>
                <CardBody>{this.renderPlanDetails(plan)}</CardBody>
              </Card>
            </Col>
          </Row>
          <form onSubmit={handleSubmit(onOrderComplete)}>
            <div className="mt-5 mb-5">
              <Button
                outline
                type="button"
                className="btn btn-default mt-3"
                onClick={onCancel}
              >
                <MdChevronLeft />
                Prev
              </Button>
              <Button
                outline
                type="button"
                className="btn btn-default mt-3"
                onClick={this.processOrder}
              >
                Confirm Order
                <MdChevronRight />
              </Button>
            </div>
          </form>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  const selector = formValueSelector("orderingWizard");
  if (state.organization) {
    return {
      userCommitment: selector(state, "userCommitment"),
      timeCommitment: selector(state, "timeCommitment"),
      serviceAgreementAgreed: selector(state, "serviceAgreementStatus"),
      planDetails: state.organization.BillingInfo,
      serviceAgreementVersion: state.serviceAgreement.version,
      activePackage: state.activePackage
    };
  }
  return {};
}
export default reduxForm({
  form: "orderingWizard", //Form name is same
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(
  withRouter(
    connect(
      mapStateToProps,
      actions
    )(OrderConfirmationForm)
  )
);
