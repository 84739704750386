import {
  ADD_ADDRESS_FAILURE,
  ADD_ADDRESS_SUCCESS,
  ADD_CARD_FAILURE,
  ADD_CARD_SUCCESS,
  CANCEL_PLAN_FAILURE,
  CANCEL_PLAN_REQUEST,
  CANCEL_PLAN_SUCCESS,
  FETCH_ACTIVE_PLAN_FAILURE,
  FETCH_ACTIVE_PLAN_REQUEST,
  FETCH_ACTIVE_PLAN_SUCCESS,
  FETCH_ADDRESS_FAILURE,
  FETCH_ADDRESS_REQUEST,
  FETCH_ADDRESS_SUCCESS,
  FETCH_CARD_FAILURE,
  FETCH_CARD_REQUEST,
  FETCH_CARD_SUCCESS,
  FETCH_ORG_FAILURE,
  FETCH_ORG_REQUEST,
  FETCH_ORG_SUCCESS,
  FETCH_SERV_AGREEMENT_FAILURE,
  FETCH_SERV_AGREEMENT_REQUEST,
  FETCH_SERV_AGREEMENT_SUCCESS,
  SUBSCRIBE_PLAN_FAILURE,
  SUBSCRIBE_PLAN_REQUEST,
  SUBSCRIBE_PLAN_SUCCESS,
  REG_ORG_REQUEST,
  REG_ORG_SUCCESS,
  REG_ORG_FAILURE
} from "../actions/action_types";
export const CardReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_CARD_REQUEST:
      // case ADD_CARD_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_CARD_SUCCESS:
    case ADD_CARD_SUCCESS:
      return { ...action.payload, isFetching: false };
    case FETCH_CARD_FAILURE:
    case ADD_CARD_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};

export const AddressReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_ADDRESS_REQUEST:
      //  case ADD_ADDRESS_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_ADDRESS_SUCCESS:
    case ADD_ADDRESS_SUCCESS:
      return { ...action.payload, isFetching: false };
    case FETCH_ADDRESS_FAILURE:
    case ADD_ADDRESS_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};

export const OrganizationReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_ORG_REQUEST:
    case REG_ORG_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_ORG_SUCCESS:
    case REG_ORG_SUCCESS:
      return { ...action.payload, isFetching: false };
    case FETCH_ORG_FAILURE:
    case REG_ORG_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};

export const ActivePackageReducer = (state = null, action) => {
  switch (action.type) {
    case SUBSCRIBE_PLAN_REQUEST:
    case FETCH_ACTIVE_PLAN_REQUEST:
    case CANCEL_PLAN_REQUEST:
      return { ...state, isFetching: true };
    case SUBSCRIBE_PLAN_SUCCESS:
    case FETCH_ACTIVE_PLAN_SUCCESS:
      return { ...action.payload, isFetching: false };
    case CANCEL_PLAN_SUCCESS: // null payload
      return { ...action.payload, isFetching: false };
    case SUBSCRIBE_PLAN_FAILURE:
    case FETCH_ACTIVE_PLAN_FAILURE:
    case CANCEL_PLAN_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};

export const ServAgreementReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_SERV_AGREEMENT_REQUEST:
      return { ...state, isFetching: true };

    case FETCH_SERV_AGREEMENT_SUCCESS:
      return { ...action.payload, isFetching: false };

    case FETCH_SERV_AGREEMENT_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};
