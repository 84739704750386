import axios from "axios";
import jsonData from "../setting/settings.json";
import {
  FETCH_ORG_TYPES_FAILURE,
  FETCH_ORG_TYPES_REQUEST,
  FETCH_ORG_TYPES_SUCCESS,
  REG_ORG_FAILURE,
  REG_ORG_REQUEST,
  REG_ORG_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_REQUEST,
  RESEND_OTP_SUCCESS,
  VALIDATE_NEW_USER_FAILURE,
  VALIDATE_NEW_USER_REQUEST,
  VALIDATE_NEW_USER_SUCCESS
} from "./action_types";

export const registerOrg = regInfo => async dispatch => {
  let addUserResponse,
    error,
    addUserUrl,
    userLoginUrl,
    userLoginResponse,
    addOrgUrl,
    addOrgResponse,
    billingRegistrationUrl,
    billingRegistrationResponse,
    addCardUrl,
    addCardResponse,
    addAddressUrl,
    addAddressResponse,
    subscribePlanUrl,
    subscribePlanResponse;

  const {
    adminEmail,
    adminName,
    adminPassword,
    adminPhone,
    orgCategory,
    orgName,
    onboardingCard,
    BillingAddress,
    selectedPackage,
    serviceAgreementVersion,
    city,
    country
  } = regInfo;
  dispatch({ type: REG_ORG_REQUEST });
  let timeCommitment = 0; //TODO
  let userCommitment = 0; //TODO
  let baseUrl = JSON.parse(JSON.stringify(jsonData)).api;

  addUserUrl = baseUrl + `v1/users/register`;
  userLoginUrl = baseUrl + "v1/users/login";
  addOrgUrl = baseUrl + "v1/organizations";
  billingRegistrationUrl = baseUrl + `v1/billing/organizations/`;
  try {
    addUserResponse = await axios.post(addUserUrl, {
      username: adminEmail,
      name: adminName,
      password: adminPassword,
      phone: "+" + adminPhone,
      isadmin: true
    });

    userLoginResponse = await axios.post(userLoginUrl, {
      Username: adminEmail,
      Password: adminPassword
    });

    if (
      !(
        userLoginResponse &&
        userLoginResponse.data &&
        userLoginResponse.data.access_token
      )
    ) {
      dispatch({
        type: REG_ORG_FAILURE,
        error: `Received empty/incorrect response for ${userLoginUrl}`
      });
      return;
    }
    let axiosConfig = {
      headers: {
        Authorization: "Bearer " + userLoginResponse.data.access_token
      }
    };
    let location = city ? city + ", " + country.value : country.value;
    addOrgResponse = await axios.post(
      addOrgUrl,
      {
        Name: orgName,
        TypeID: orgCategory.value,
        Location: location
      },
      axiosConfig
    );

    if (!(addOrgResponse && addOrgResponse.data)) {
      dispatch({
        type: REG_ORG_FAILURE,
        error: `Received empty/incorrect response for ${addOrgUrl}`
      });
      return;
    }
    let orgID = addOrgResponse.data.Organization.ID;
    billingRegistrationUrl = billingRegistrationUrl + orgID;
    billingRegistrationResponse = await axios.post(
      billingRegistrationUrl,
      {},
      axiosConfig
    );
    if (!billingRegistrationResponse) {
      dispatch({
        type: REG_ORG_FAILURE,
        error: `Received empty/incorrect response for ${billingRegistrationUrl}`
      });
      return;
    }
    addCardUrl = baseUrl + `v1/billing/organizations/${orgID}/paymentsource`;
    addCardResponse = await axios.post(addCardUrl, onboardingCard, axiosConfig);
    if (!addCardResponse) {
      dispatch({
        type: REG_ORG_FAILURE,
        error: `Received empty/incorrect response for ${addCardUrl}`
      });
      return;
    }

    addAddressUrl = baseUrl + `v1/billing/organizations/${orgID}/address`;
    addAddressResponse = await axios.post(
      addAddressUrl,
      BillingAddress,
      axiosConfig
    );
    if (!addAddressResponse) {
      dispatch({
        type: REG_ORG_FAILURE,
        error: `Received empty/incorrect response for ${addAddressUrl}`
      });
      return;
    }

    subscribePlanUrl =
      baseUrl + `v1/billing/plans/${selectedPackage}/organizations/${orgID}`;
    subscribePlanResponse = await axios.post(
      subscribePlanUrl,
      { serviceAgreementVersion, timeCommitment, userCommitment },
      axiosConfig
    );
    if (!subscribePlanResponse) {
      dispatch({
        type: REG_ORG_FAILURE,
        error: `Received empty/incorrect response for ${subscribePlanUrl}`
      });
      return;
    }

    dispatch({
      type: REG_ORG_SUCCESS,
      payload: addOrgResponse.data.Organization
    });
  } catch (err) {
    error = err;
  }
  if (error) {
    dispatch({
      type: REG_ORG_FAILURE,
      error /*: "Adding organisation failed"*/
    });
  }
};

export const validateNewUserDetails = userDetails => async dispatch => {
  let response, error, validateEmailUrl, validatePhoneUrl, verifyPhoneUrl;
  const { adminEmail, adminPhone } = userDetails;

  dispatch({ type: VALIDATE_NEW_USER_REQUEST });

  validateEmailUrl =
    JSON.parse(JSON.stringify(jsonData)).api + `v1/users/validate/email`;
  validatePhoneUrl =
    JSON.parse(JSON.stringify(jsonData)).api + `v1/users/validate/phone`;
  verifyPhoneUrl =
    JSON.parse(JSON.stringify(jsonData)).api + `v1/users/verify/phone`;

  try {
    response = await axios.post(validateEmailUrl, {
      email: adminEmail
    });
    if (response && response.data) {
      const { Result, Message } = response.data;

      if (Result) {
        //Validate Phone Number
        try {
          response = await axios.post(validatePhoneUrl, {
            phone: adminPhone
          });
          if (response && response.data) {
            const { Result, Message } = response.data;
            if (Result) {
              //Generate Otp
              try {
                response = await axios.post(verifyPhoneUrl, {
                  phone: adminPhone
                });
                dispatch({
                  type: VALIDATE_NEW_USER_SUCCESS,
                  payload: {}
                });
              } catch (err) {
                error = err;
              }
              if (error) {
                dispatch({
                  type: VALIDATE_NEW_USER_FAILURE,
                  error: "Failed to generate OTP"
                });
              }
            } else {
              dispatch({
                type: VALIDATE_NEW_USER_FAILURE,
                error: Message
              });
            }
          } else {
            dispatch({
              type: VALIDATE_NEW_USER_FAILURE,
              error: "Phone number not valid"
            });
          }
        } catch (err) {
          error = err;
        }
        if (error) {
          dispatch({
            type: VALIDATE_NEW_USER_FAILURE,
            error: "Email not valid"
          });
        }
      } else {
        dispatch({
          type: VALIDATE_NEW_USER_FAILURE,
          error: Message
        });
      }
      // dispatch({ type: REG_ORG_SUCCESS, payload: subscriptionInfo });
    } else {
      dispatch({
        type: VALIDATE_NEW_USER_FAILURE,
        error: "Email not valid"
      });
    }
  } catch (err) {
    error = err;
  }
  if (error) {
    dispatch({
      type: VALIDATE_NEW_USER_FAILURE,
      error: "Unknown error"
    });
  }
};

export const verifyPhoneOtp = OtpReq => async dispatch => {
  let response, error, verifyPhoneUrl;

  //dispatch({ type: VERIFY_PHONE_OTP_REQUEST });

  verifyPhoneUrl =
    JSON.parse(JSON.stringify(jsonData)).api + `v1/users/verify/otp`;
  const { Otp, adminPhone } = OtpReq;
  try {
    response = await axios.post(verifyPhoneUrl, {
      Phone: adminPhone,
      Otp
    });
    if (response && response.data) {
      return response.data;
    } else {
      //   dispatch({
      //     type: VERIFY_PHONE_OTP_FAILURE,
      //     error: "Error occured! Please try again."
      //   });
      return {
        Result: false,
        Message: "Unknown error"
      };
    }
  } catch (err) {
    error = err;
  }
  if (error) {
    // dispatch({
    //   type: VERIFY_PHONE_OTP_FAILURE,
    //   error: "Error occured! Please try again."
    // });
    return {
      Result: false,
      Message: "Unknown error"
    };
  }
};

export const resendOtp = OtpReq => async dispatch => {
  let response, error, verifyPhoneUrl;

  dispatch({ type: RESEND_OTP_REQUEST });

  verifyPhoneUrl =
    JSON.parse(JSON.stringify(jsonData)).api + `v1/users/verify/phone`;
  const { adminPhone } = OtpReq;
  try {
    response = await axios.post(verifyPhoneUrl, {
      Phone: adminPhone
    });
    if (response && response.data) {
      dispatch({ type: RESEND_OTP_SUCCESS, payload: response.data });
    } else {
      dispatch({
        type: RESEND_OTP_FAILURE,
        error: "Resend OTP failed"
      });
    }
  } catch (err) {
    error = err;
  }
  if (error) {
    dispatch({
      type: RESEND_OTP_FAILURE,
      error: "Resend OTP failed"
    });
  }
};

export const fetchOrgTypes = () => async dispatch => {
  let response, error, fetchOrgTypesUrl;

  dispatch({ type: FETCH_ORG_TYPES_REQUEST });

  fetchOrgTypesUrl =
    JSON.parse(JSON.stringify(jsonData)).api + `v1/organizations/types`;

  try {
    response = await axios.get(fetchOrgTypesUrl);
    if (response && response.data) {
      dispatch({ type: FETCH_ORG_TYPES_SUCCESS, payload: response.data });
    } else {
      dispatch({
        type: FETCH_ORG_TYPES_FAILURE,
        error: "Failed to fetch organization types"
      });
    }
  } catch (err) {
    error = err;
  }
  if (error) {
    dispatch({
      type: FETCH_ORG_TYPES_FAILURE,
      error: "Failed to fetch organization types"
    });
  }
};
