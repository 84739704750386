import {
  IS_LOGIN
} from "../actions/action_types";

export const CheckIsLogin = (state = { isLogin: false }, action) => {
  switch (action.type) {
    case IS_LOGIN:
      return { ...state, isLogin: action.payload.isLogin };
    default:
      return state;
  }
};


// // Data agreement panel init state. (CRUD panel)
// const cloudAgentDeploymentState = {
//   isOpen: false,
//   mode: "", // "CREATE" or "EDIT" or "READ"
// }

// // Data agreement panel reducer.
// export const DataAgreementPanel = (state = initDataAgreementPanel, action) => {

//   switch (action.type) {

//       // Reset data agreement panel state.
//       case INIT_DATA_AGREEMENT_PANEL_MODE:
//           state = initDataAgreementPanel;
//           return state;

//       // Toggle data agreement panel.
//       case TOGGLE_DATA_AGREEMENT_PANEL:
//           return { ...state, isOpen: !state.isOpen };

//       // Update data agreement panel mode.
//       case UPDATE_DATA_AGREEMENT_PANEL_MODE:
//           return { ...state, mode: action.payload.mode };

//       default:
//           return state;

//   }

// }