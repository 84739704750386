import moment from "moment";
import React, { Component } from "react";
import IconDownload from "react-icons/lib/fa/download";
import { Table } from "reactstrap";

export default class InvoiceList extends Component {
  constructor(props) {
    super(props);
  }

  formatDate(val) {
    return moment.unix(val).format("DD-MMM-YYYY");
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // TODO - Move to a utility class
  formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  }

  renderEmptyList() {
    return <p>No Invoices!</p>;
  }

  renderInvoiceItems() {
    return this.props.invoices.map(invoice => (
      <tr key={invoice.InvoiceNumber}>
        <td>{this.formatDate(invoice.InvoiceDate)}</td>
        <td>{invoice.Description}</td>
        <td>SEK {this.formatMoney(invoice.DueAmount / 100)}</td>
        <td>{this.capitalizeFirstLetter(invoice.PaymentStatus)}</td>
        <td align="center">
          <a download="Invoice.pdf" href={invoice.InvoiceURL}>
            <span className="text-black">
              {" "}
              <IconDownload />{" "}
            </span>{" "}
          </a>
        </td>
      </tr>
    ));
  }

  renderInvoices() {
    return (
      <Table responsive bordered hover>
        <thead>
          <tr className="table-secondary">
            <th>Invoice Date</th>
            <th>Invoice Details</th>
            <th>Invoice Amount</th>
            <th>Payment Status</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody style={{ fontSize: "14px" }}>{this.renderInvoiceItems()}</tbody>
      </Table>
    );
  }

  render() {
    if (this.props.invoices != null) {
      if (this.props.invoices.length > 0) {
        return this.renderInvoices();
      } else {
        return this.renderEmptyList();
      }
    } else {
      return "";
    }
  }
}
