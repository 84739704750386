import paginationFactory from "react-bootstrap-table2-paginator";
import overlayFactory from 'react-bootstrap-table2-overlay';
import BootstrapTable from "react-bootstrap-table-next";
import LoadingOverlay from "react-loading-overlay";

import React, { useState, useEffect } from "react";
import {
  Modal,
  Col,
  Row,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  AiOutlineHistory,
  AiOutlineEye,
  AiOutlineDelete,
  AiOutlineQrcode,
  AiOutlinePlusCircle,
  AiOutlineSend,
  AiOutlineEdit
} from "react-icons-latest/ai";
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { PopoverWidget } from "../components/Widget";
import { isCloudAgentDeployed, fetchCloudAgentDeploymentInfo } from '../utils/localStorageUtils';
import {
  queryPublishedDataUsingServiceDataAgreements,
  deleteDataAgreementInCloudagent,
} from "../services";
import {
  showLoader,
  hideLoader
} from "../actions/loaderActions";
import {
  toggleDataAgreementPanel,
  initDataSourceConfiguration,
  initDataUsingServiceConfiguration,
  updateDataAgreementPanelMode,
  updateDataSourceConfiguration,
  updateMethodOfUseInDataAgreementState,
  replaceAllPersonalData
} from "../actions/dataAgreementActions";
import {
  createDataSourceConfigurationFromDataAgreementRecord,
  transformDataAgreementFromServerToClientFormat,
  transformDataAgreementRecordFromServerToClientFormat
} from "../utils/dataAgreementUtils";
import {
  initDataAgreementState
} from "../reducers/DataAgreementReducer";
import {
  TableLoader
} from "../components/Loader";

import Page from "../components/Page";
import VerificationRequestModal from "../components/VerificationRequestModal";
import Loader from "../components/Loader";
import GeneralDeleteModalV2 from "../components/GeneralDeleteModalV2";
import DataAgreementQrCodeModal from "../components/DataAgreementQrCodeModal";
import DataAgreementCrudModalV2 from "../components/DataAgreementCrudModalV2";


// FIXME: This is a temporary fix for https://github.com/derrickpelletier/react-loading-overlay/pull/57
LoadingOverlay.propTypes = undefined


const VerificationsPage = (props) => {

  const [ariesCloudAgentEnabled, setAriesCloudAgentEnabled] = useState(false);

  const [adminEndpoint, setAdminEndpoint] = useState("");

  const [verificationRecords, setVerificationRecords] = useState([]);

  const [isTableLoading, setIsTableLoading] = useState(false);

  const [paginationTotalCount, setPaginationTotalCount] = useState(0);

  const [paginationCurrentPage, setPaginationCurrentPage] = useState(1);

  const [paginationPageSize, setPaginationPageSize] = useState(10);

  const [dataAgreementQrCodeModalVisibilityState, setDataAgreementQrCodeModalVisibilityState] = useState(false);

  const [verificationRequestModalVisibilityState, setVerificationRequestModalVisibilityState] = useState(false)

  const [selectedRow, setSelectedRow] = useState('')

  const [deleteVerificationsModalVisibilityState, setDeleteVerificationsModalVisibilityState] = useState(false);

  const history = useHistory();

  /**
   * Run once when component is mounted.
   */
  useEffect(() => {

    // Check if aries cloudagent is deployed.
    if (isCloudAgentDeployed()) {

      // Cloud agent deployment info.
      const cloudAgentDeploymentInfo = fetchCloudAgentDeploymentInfo();

      // Update state to indicate cloud agent is deployed.
      setAriesCloudAgentEnabled(true);

      // Update state with cloud agent admin endpoint.
      setAdminEndpoint(cloudAgentDeploymentInfo.AdminEndpoint);

      // Fetch DUS data agreements, if admin endpoint is available.
      fetchVerificationTemplates();

    } else {

      // Update state to indicate cloud agent is not deployed.
      setAriesCloudAgentEnabled(false);

    }

  }, []);


  /**
   * Fetch verification templates.
   */
  const fetchVerificationTemplates = async (page = paginationCurrentPage, page_size = paginationPageSize) => {

    // Show table loader.
    setIsTableLoading(true);

    queryPublishedDataUsingServiceDataAgreements(page, page_size).then((res) => {

      const data = res.data.results;
      const paginationData = res.data.pagination;

      // Set the total count of the pagination.
      setPaginationTotalCount(paginationData.totalCount);

      setVerificationRecords(data);

      // Hide the table loader..
      setIsTableLoading(false);

    }).catch((err) => {

      // Handle error

      console.log(err);

      setVerificationRecords([]);

      // Hide the table loader..
      setIsTableLoading(false);

    });


  };

  /**
   * Handle page change for table.
   * 
   * @param {*} page 
   * @param {*} pageSize 
   */
  const handlePageChange = (page, pageSize) => {

    setPaginationCurrentPage(page);

    setPaginationPageSize(pageSize);

    fetchVerificationTemplates(page, pageSize);

  }

  /**
   * Triggered when table content changes.
   * 
   * @param {*} type 
   * @param {*} newState 
   */
  const onTableChange = (type, { page, sizePerPage }) => {
    // handle any data change here

    switch (type) {

      case 'pagination':
        handlePageChange(page, sizePerPage);
        break

    }


  }

  /**
   * Toggle delete modal.
   */
  const toggleDeleteModal = () => {

    setDeleteVerificationsModalVisibilityState(!deleteVerificationsModalVisibilityState);

  }

  /**
   * Toggle verification request modal.
   */
  const toggleVerificationRequestModal = () => {

    setVerificationRequestModalVisibilityState(!verificationRequestModalVisibilityState);

  }


  const colDispStyle = {
    fontSize: "14px",
    cursor: "pointer",
    padding: ".35rem",
    borderWidth: "1px solid !important",
  };

  const headerDispStyle = {
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    fontWeight: "bold",
    border: "solid",
    borderWidth: "0px 1px 3px 1px",
    borderColor: "#dee2e6",
  };

  const buttonDispStyle = {
    width: "3%",
    backgroundColor: "#f0f0f0",
    padding: ".35rem",
    borderWidth: "0px",
    borderBottomWidth: "3px",
  };

  const colDispStyleButton = {
    fontSize: "14px",
    cursor: "pointer",
    borderWidth: "0px",
    borderBottomWidth: "1px",
    padding: ".35rem",
  };

  const tableColumns = [

    {

      dataField: "data_agreement.purpose",

      text: "Name",

      headerStyle: { ...headerDispStyle, width: "30%" },

      style: colDispStyle,

    },
    {

      dataField: "data_agreement.template_version",

      text: "Version",

      headerStyle: { ...headerDispStyle, width: "3%" },

      style: colDispStyle,

      formatter: (cellContent) => {

        return cellContent + ".0";

      }

    },
    {

      dataField: "data_agreement_id",

      text: "Verification Template Identifier",

      headerStyle: {

        ...headerDispStyle,

        borderRightWidth: "0px",

        width: "35%",

      },
      style: {

        ...colDispStyle,

        borderRightWidth: "0px"

      },
    },
    {

      dataField: "",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (
          <Tooltip title='View verification template' placement="top">

            <AiOutlineEye

              id="view"

              size="20px"

              onClick={() => {

                openDataAgreementCrudPanel("READ", row);

              }}

            />
          </Tooltip>

        );

      },

    },
    {

      dataField: "History",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip title='Verification history' placement="top">

            <AiOutlineHistory

              id="verification-history"

              size="20px"

              onClick={() => {

                history.push('/verification-history/' + row.data_agreement_id);

              }}

            />
          </Tooltip>

        );
      },
    },
    {

      dataField: "verify",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip title='Send verification request' placement="top">

            <AiOutlineSend

              size="20px"

              onClick={() => {

                setSelectedRow(row);

                toggleVerificationRequestModal();

              }}

            />
          </Tooltip>

        );

      },

    },
    {

      dataField: "QR",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip title='Generate QR code' placement="top">

            <AiOutlineQrcode

              size="20px"

              onClick={() => {

                setSelectedRow(row);

                toggleDataAgreementQrCodeModal();

              }}

            />
          </Tooltip>
        );
      },
    },
    {

      dataField: "Edit",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip title='Edit verification template' placement="top">

            <AiOutlineEdit

              size="20px"

              onClick={async () => {

                openDataAgreementCrudPanel("UPDATE", row);

              }}

            />
          </Tooltip>

        );

      }

    },
    {

      dataField: "Delete",

      text: "",

      headerStyle: buttonDispStyle,

      style: { ...colDispStyleButton, textAlign: "center" },

      formatter: (cellContent, row) => {

        return (

          <Tooltip title='Delete verification template' placement="top">

            <AiOutlineDelete

              size="20px"

              onClick={async () => {

                setSelectedRow(row);

                toggleDeleteModal();

              }}

            />
          </Tooltip>
        );
      },
    },
  ];

  const AddVerificationPopover = (size, onClick, onMouseOver) => {
    return (
      <AiOutlinePlusCircle
        size={"25px"}
        style={{ cursor: "pointer" }}
        onClick={onClick}
        onMouseOver={onMouseOver}
      />
    );
  };

  const AriesCloudAgentLink = (linkStyle) => {
    return (
      <Link style={linkStyle} to={"/aries-cloud-agent"}>
        Deploy Aries cloud agent
      </Link>
    );
  };

  /**
   * Toggle data agreement qr code modal.
   */
  const toggleDataAgreementQrCodeModal = () => {

    setDataAgreementQrCodeModalVisibilityState(!dataAgreementQrCodeModalVisibilityState);

  };

  /**
   * To open data agreement crud panel.
   * 
   * @param {*} mode 
   * @param {*} dataAgreementRecord 
   */
  const openDataAgreementCrudPanel = (mode, dataAgreementRecord) => {

    // Initialise data source configuration.
    props.initDataSourceConfiguration();

    // Initialise data using service configuration.
    props.initDataUsingServiceConfiguration();

    switch (mode) {

      case "CREATE":

        // Update data agreement panel mode.
        props.updateDataAgreementPanelMode(initDataAgreementState, mode);

        // Reset data source configuration
        props.initDataSourceConfiguration();

        props.updateMethodOfUseInDataAgreementState("data-using-service");

        // Updater personal data to include empty restrictions
        const pd = initDataAgreementState.personalData;

        const updatedPd = pd.map((value, index) => { return { ...value, restrictions: [{ "schema_id": "", "cred_def_id": "" }] } });

        props.replaceAllPersonalData(updatedPd);

        // Toggle data agreement crud panel.
        props.toggleDataAgreementPanel();

        break;


      case "READ":
      case "UPDATE":

        const { data_agreement: dataAgreement } = dataAgreementRecord;

        let dataSourceConfiguraiton = createDataSourceConfigurationFromDataAgreementRecord(dataAgreementRecord);

        props.updateDataSourceConfiguration(dataSourceConfiguraiton);

        let updatedDataAgreementState = transformDataAgreementRecordFromServerToClientFormat(dataAgreementRecord);

        // Update data agreement panel mode.
        props.updateDataAgreementPanelMode(updatedDataAgreementState, mode);

        // Toggle data agreement crud panel.
        props.toggleDataAgreementPanel();

        break;

    }

  }

  return (

    <Page title="" breadcrumbs={[{ name: "Self-Sovereign Identity / Verifications", active: true },]} className="TablePage" >

      <Row>

        <Col>

          <Card>

            <CardTitle>

              <div

                className="card-table-heading"

                style={{

                  paddingTop: "35px",

                  display: "flex",

                  justifyContent: "space-between",

                  alignItems: "center",

                  flexWrap: "wrap"

                }}
              >

                <div

                  id="credentials-table-header-left-container"

                  style={{

                    maxWidth: "300px",

                    width: "130px",

                    display: "flex",

                    justifyContent: "space-between",

                    alignItems: "center"

                  }}

                >


                  <span>Verifications</span>

                  {ariesCloudAgentEnabled ? (

                    <AiOutlinePlusCircle

                      size={"25px"}

                      style={{
                        cursor: "pointer",
                      }}

                      onClick={() => {

                        openDataAgreementCrudPanel("CREATE");

                      }}

                    />

                  ) : (

                    <PopoverWidget

                      element={AddVerificationPopover}

                      text="Deploy Aries cloud agent to enable this feature"

                      size="25px"

                      link={AriesCloudAgentLink}

                    />

                  )}


                </div>

              </div>

            </CardTitle>

            <CardSubtitle

              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",

              }}

            >
              <div className="card-table-subheading">
                Manages verifications of data received from the end-users
              </div>

            </CardSubtitle>

            <CardBody>


              {
                verificationRecords &&
                <BootstrapTable

                  remote={{ pagination: true, filter: false, sort: false }}

                  pagination={
                    paginationFactory(
                      {

                        page: paginationCurrentPage,

                        sizePerPage: paginationPageSize,

                        totalSize: paginationTotalCount,

                        nextPageText: ">",

                        prePageText: "<",

                        alwaysShowAllBtns: false,

                        hideSizePerPage: true,

                      }
                    )
                  }

                  loading={isTableLoading}

                  overlay={
                    overlayFactory(
                      {
                        spinner: <TableLoader />,
                        styles: {
                          overlay: (base) => (
                            {
                              ...base,
                              background: 'rgba(198, 198, 198, 0.07)'
                            }
                          ),
                        }
                      }
                    )
                  }

                  onTableChange={onTableChange}

                  noDataIndication={
                    () => {
                      return !isTableLoading ? <div>No data to display</div> : <div style={{ height: "300px" }}></div>
                    }
                  }

                  id="btPurpose"

                  data={verificationRecords}

                  bootstrap4={true}

                  keyField="data_agreement_id"

                  hover={true}

                  columns={tableColumns}

                  striped={false}

                >
                </BootstrapTable>
              }


            </CardBody>

          </Card>


          {
            selectedRow &&
            <Modal backdrop="static" isOpen={verificationRequestModalVisibilityState} toggle={toggleVerificationRequestModal}>

              <Loader loaderTag={"VerificationRequestModal"} />

              <VerificationRequestModal

                dataAgreementId={selectedRow.data_agreement_id}

                dataAgreementPurpose={selectedRow.data_agreement.purpose}

                toggle={toggleVerificationRequestModal}

              />

            </Modal>
          }

          <Modal backdrop="static" isOpen={props.dataAgreementPanel.isOpen} unmountOnClose={true}>

            <Loader loaderTag={"DataAgreementCrudModalV2"} />

            <DataAgreementCrudModalV2

              fromVerificationsPage={true}

              onModalClose={() => {

                // Reload verifications table.
                fetchVerificationTemplates();

                // To close crud panel.
                props.toggleDataAgreementPanel();


              }}

            />

          </Modal>



          <GeneralDeleteModalV2

            loaderTag={"GeneralDeleteModalV2ForVerifications"}

            requireConfirmText={true}

            confirmText="DELETE"

            modalHeaderTitleText={<div className="delete-modal-header-title-div"> Delete Verification Template:  <span>{selectedRow && selectedRow.data_agreement.purpose}</span></div>}

            modelHeaderDescriptionText={selectedRow && selectedRow.data_agreement_id}

            modalDescriptionText={<p> You are about to delete an existing verification template. Please type <span style={{ fontWeight: "bold" }}>DELETE</span> to confirm and click DELETE. This action is not reversible.</p>}

            deleteCallBack={() => {

              const { showLoader, hideLoader } = props;

              showLoader("GeneralDeleteModalV2ForVerifications");

              // Perform delete verification template and reload the verifications table.
              deleteDataAgreementInCloudagent(selectedRow.data_agreement_id).then((res) => {

                // Reload verifications table.
                fetchVerificationTemplates();

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForVerifications");

                // Close the delete modal.
                toggleDeleteModal();

              }).catch(err => {

                console.log(err);

                // Hide the loader modal.
                hideLoader("GeneralDeleteModalV2ForVerifications");

                // Close the delete modal.
                toggleDeleteModal();

              })

            }}

            toggleState={deleteVerificationsModalVisibilityState}

            toggle={toggleDeleteModal}

          />

          {dataAgreementQrCodeModalVisibilityState && (

            <DataAgreementQrCodeModal

              dataAgreementPurpose={selectedRow.data_agreement.purpose}

              dataAgreementTemplateId={selectedRow.data_agreement_id}

              isOpen={dataAgreementQrCodeModalVisibilityState}

              toggleIsOpen={() => {

                // Reset the selected data agreement record.
                setSelectedRow(null);

                toggleDataAgreementQrCodeModal();

              }}
            />

          )}



        </Col>

      </Row>

    </Page>

  );

};

const mapStateToProps = (state) => {
  return {
    dataAgreementPanel: state.DataAgreementPanel,
  };
}

export default connect(
  mapStateToProps,
  {
    showLoader,
    hideLoader,
    toggleDataAgreementPanel,
    initDataSourceConfiguration,
    initDataUsingServiceConfiguration,
    updateDataAgreementPanelMode,
    updateDataSourceConfiguration,
    updateMethodOfUseInDataAgreementState,
    replaceAllPersonalData
  }
)(VerificationsPage);
