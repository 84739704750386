import React from "react";
import { connect } from 'react-redux';
import {
    toggleLoader
} from '../actions/loaderActions';


export const TableLoader = (props) => {
    return (
        <div className="table-loader-container-v1">
            <div className="loader-v1"></div>
        </div>
    )
}


const Loader = (props) => {

    return (

        props.LoaderStateReducer.isLoading &&
        props.LoaderStateReducer.loaderTag == props.loaderTag &&
        <div className="loader-container-v1">

            <div className="loader-v1"></div>


            <p style={{ marginTop: "132px", color: "red" }}>{props.LoaderStateReducer.loaderText}</p>


        </div>
    )

}

const mapStateToProps = (state) => {
    return {
        LoaderStateReducer: state.LoaderStateReducer,
    };
}
export default connect(
    mapStateToProps,
    {
        toggleLoader
    }
)(Loader);