import { PhoneNumberUtil } from "google-libphonenumber";
import React, { Component } from "react";
import { MdChevronRight } from "react-icons/lib/md";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  FormGroup,
  Label
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import * as actions from "../../actions/onboarding_actions";
import ReduxFormInputField from "./ReduxFormInputField";
import ReduxFormPasswordField from "./ReduxFormPasswordField";
import ReduxFormPhoneField from "./ReduxFormPhoneField";

class AdminDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {}
    };
    this.handleAdminDetailsSubmit = this.handleAdminDetailsSubmit.bind(this);
    this.backToLoginScreen = this.backToLoginScreen.bind(this);
  }

  handleChange = (key, value) => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [key]: value
      }
    }));
  };

  async handleAdminDetailsSubmit(values) {
    // e.preventDefault();
    const { onAdminDetailsSubmit, handleSubmit, newUserValidity } = this.props;
    //handleSubmit(onAdminDetailsSubmit);
    const { adminEmail } = values;
    const adminPhone = "+" + values.adminPhone
    await this.props.validateNewUserDetails({ adminEmail, adminPhone });

    onAdminDetailsSubmit();
  }

  // Validation functions
  isRequiredField = value => {
    return value || typeof value === "number" ? undefined : "Required";
  };

  validatePhoneNumber = value => {
    if (value) {
      let valid = false;
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        valid = phoneUtil.isValidNumber(phoneUtil.parse("+" + value));
      } catch (e) {
        valid = false;
      }
      return valid ? undefined : "Invalid phone number";
    }
    return "Required";
  };

  validateEmail = value => {
    return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Invalid email address"
      : undefined;
  };

  validatePassword = value => {
    if (value == null || value == "" || value.length < 6) {
      return "Invalid Password";
    }
    return undefined;
  };

  backToLoginScreen() {
    this.props.history.push("/");
  }

  render() {
    const { onAdminDetailsSubmit, handleSubmit, newUserValidity } = this.props;

    return (
      <div>
        <form onSubmit={handleSubmit(this.handleAdminDetailsSubmit)}>
          <Card className="h-100 w-100">
            <CardBody>
              <CardTitle className="card-title mb-3">
                Organisation Administrator Information
              </CardTitle>
              <FormGroup>
                <Label htmlFor="orgName" style={{ fontSize: "14px" }}>
                  Organisation Name
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="text"
                    component={ReduxFormInputField}
                    name="orgName"
                    id="orgName"
                    validate={this.isRequiredField}
                    placeholder="Organization Name"
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="adminName" style={{ fontSize: "14px" }}>
                  Admin Name
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="text"
                    component={ReduxFormInputField}
                    name="adminName"
                    id="adminName"
                    validate={this.isRequiredField}
                    placeholder="Admin Name"
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="adminEmail" style={{ fontSize: "14px" }}>
                  Admin Email
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="email"
                    component={ReduxFormInputField}
                    name="adminEmail"
                    id="adminEmail"
                    validate={[this.isRequiredField, this.validateEmail]}
                    placeholder="Admin Email"
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="adminPhone" style={{ fontSize: "14px" }}>
                  Admin Phone
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="text"
                    component={ReduxFormPhoneField}
                    name="adminPhone"
                    id="adminPhone"
                    //validate={this.isRequiredField}
                    validate={[this.isRequiredField, this.validatePhoneNumber]}
                    placeholder="Admin Phone"
                  />
                </Col>
              </FormGroup>
              <FormGroup>
                <Label htmlFor="adminPassword" style={{ fontSize: "14px" }}>
                  Admin Password (minimum 8 characters)
                </Label>
                <Col
                  lg={8}
                  md={8}
                  sm={12}
                  xs={12}
                  style={{ padding: "0px 0px" }}
                >
                  <Field
                    type="password"
                    component={ReduxFormPasswordField}
                    name="adminPassword"
                    id="adminPassword"
                    validate={[this.isRequiredField, this.validatePassword]}
                    placeholder="Admin Password"
                  />
                </Col>
              </FormGroup>

              <div className="mt-3">
                {
                  <Button
                    type="submit"
                    disabled={newUserValidity.isFetching}
                    className="btn btn-default btn-outline-secondary text-left"
                  >
                    Next
                    <MdChevronRight />
                  </Button>
                }
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    );
  }
}

function mapStateToProps({ newUserValidity }) {
  return { newUserValidity };
}
export default connect(
  mapStateToProps,
  actions
)(
  withRouter(
    reduxForm({
      form: "signUpWizard",
      destroyOnUnmount: false,
      forceUnregisterOnUnmount: true // <------ unregister fields on unmount
    })(AdminDetailsForm)
  )
);
