import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { connect } from 'react-redux';

import IconCopy from 'react-icons/lib/fa/copy';
import PropTypes from 'prop-types';
import QRCode from "qrcode";

import {
    fetchOrgsFromLocalStorage
} from "../utils/localStorageUtils"
import {
    createConnectionInvitationForCloudagent,
    generateFirebaseDynamicLinkForConnectionInvitation
} from "../services/index";
import {
    showLoader,
    hideLoader,
} from '../actions/loaderActions'

import Loader from "../components/Loader";



const OrganisationQrCodeModal = ({ isOpen, toggleIsOpen, ...props }) => {

    const [QRPayload, setQRPayload] = useState(null);
    const [FirebaseDynamicLink, setFirebaseDynamicLink] = useState(null);
    const [organisationName, setOrganisationName] = useState(null);

    const headerStyle = {
        fontSize: "16px",
        backgroundColor: "#eeeeee",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
    };

    const qrContainerStyle = {
        display: "flex",
        justifyContent: "center",
    };

    const footerButtonStyle = {
        height: "1.8rem",
        width: "10rem",
        padding: 0,
        fontSize: "12px",
    }

    const firebaseDynamicLinkPreviewStyle = {
        width: '85%',
        maxWidth: '85%',
        color: "#495057",
        border: "1",
        borderWidth: 0,
        padding: 0,
        paddingBottom: 1,
        borderRadius: 0,
        fontSize: "16px",
        borderBottomWidth: 1.2,
        borderBottomColor: "lightgray",
    }

    useEffect(() => {

        // Current organisation name from localstorage.
        setOrganisationName(fetchOrgsFromLocalStorage() ? fetchOrgsFromLocalStorage().Name : "Organisation");

    })

    /**
     * Generate Qr code image.
     */
    const generateQrCode = () => {

        props.showLoader("OrganisationQrCodeModal");

        // Create connection invitation.
        createConnectionInvitationForCloudagent().then((res) => {

            const { connection_id: connectionId } = res.data;

            // Generate Firebase dynamic link for a qr code.
            generateFirebaseDynamicLinkForConnectionInvitation(connectionId).then((res) => {

                const { firebase_dynamic_link: firebaseDynamicLink } = res.data;

                setFirebaseDynamicLink(firebaseDynamicLink);

                // Generate QR code image.
                QRCode.toDataURL(firebaseDynamicLink, (err, url) => {

                    if (err) {

                        // Error while generating QR code image.

                        console.error(err);

                        props.hideLoader("OrganisationQrCodeModal");
                    }

                    setQRPayload(url);

                });

                props.hideLoader("OrganisationQrCodeModal");


            }).catch((err) => {

                // Error while generating Firebase dynamic link.

                console.error(err);

                props.hideLoader("OrganisationQrCodeModal");

            });


        }).catch(err => {

            // Error while generating Qr code.

            console.error(err);

            props.hideLoader("OrganisationQrCodeModal");

        });

    };

    /**
     * Delete Qr code image.
     */
    const deleteQrCode = () => {

        setQRPayload(null);
        setFirebaseDynamicLink(null);

    }

    /**
     * Download Qr code image.
     */
    const downloadQrCode = () => {

        const link = document.createElement("a");
        link.download = "connection-qr-code.png";
        link.href = QRPayload;
        link.click();

    }

    /**
     * Modal tear down function.
     * 
     * Triggered when modal is closed.
     */
    const modalTearDown = () => {

        deleteQrCode();

    }

    return (

        <Modal isOpen={isOpen} onClosed={modalTearDown}>

            <Loader loaderTag={"OrganisationQrCodeModal"} />

            <div className="modal-content">

                <div className="modal-header" style={headerStyle}>

                    <h5 className="modal-title">{organisationName} QR Code (Connection)</h5>

                    <button type="button" className="close" aria-label="Close" onClick={toggleIsOpen}><span aria-hidden="true">×</span></button>

                </div>

                <ModalBody>

                    <div className="form-group" key="Description">

                        <div>

                            <button className="btn btn-default w-100 mb-2" disabled={QRPayload !== null} style={{ cursor: !QRPayload ? "pointer" : "not-allowed" }} onClick={() => { generateQrCode(); }}>Genarate QR Code</button>

                        </div>

                        <div>

                            <button className="btn btn-default w-100 mb-2" disabled={QRPayload === null} style={{ cursor: QRPayload ? "pointer" : "not-allowed" }} onClick={() => { deleteQrCode(); }}>Delete QR Code</button>

                        </div>

                    </div>

                    {QRPayload && (
                        <div className="form-group" key="QRCode" style={qrContainerStyle}>

                            <img src={QRPayload} />

                        </div>

                    )}

                    {FirebaseDynamicLink && (
                        <div className="form-group" key="QRCodeDynamicLinkPreviewAndCopy">

                            <input readOnly type="text" value={FirebaseDynamicLink} style={firebaseDynamicLinkPreviewStyle}></input>

                            <span className="qr-copy" onClick={() => { navigator.clipboard.writeText(FirebaseDynamicLink); }}><IconCopy />Copy</span>

                        </div>
                    )}




                </ModalBody>

                <ModalFooter>

                    <button style={footerButtonStyle} size="ig" className="btn btn-default" onClick={toggleIsOpen}>Close</button>

                    <button style={{ ...footerButtonStyle, cursor: QRPayload !== null ? "pointer" : "not-allowed" }} className="btn btn-default" disabled={QRPayload === null} onClick={downloadQrCode}>Download</button>

                </ModalFooter>

            </div >

        </Modal >
    )

}

OrganisationQrCodeModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleIsOpen: PropTypes.func.isRequired,
};

OrganisationQrCodeModal.defaultProps = {
    isOpen: true,
    toggleIsOpen: () => { },
};

const mapStateToProps = (state) => {
    return {};
}

export default connect(
    mapStateToProps,
    {
        showLoader,
        hideLoader,
    }
)(OrganisationQrCodeModal);


