import logo from "../../assets/img/logo/iGrant_210_55_BW.svg";
import { Content, Footer } from "./index";
import React from "react";
import { Navbar, NavbarBrand } from "reactstrap";

class OnboardingLayout extends React.Component {
  componentDidMount() {}

  render() {
    const { children } = this.props;
    const footerStyle = {
      marginTop: "-40px"
    };
    return (
      <main className="cr-app bg-light">
        <Content fluid>
          <Navbar color="dark" dark>
            {" "}
            <NavbarBrand href="/">
              <img src={logo} alt="iGrant.io" />
            </NavbarBrand>{" "}
          </Navbar>
          <div> {children}</div>
          <Footer />
        </Content>
      </main>
    );
  }
}

export default OnboardingLayout;
