import {
    TOGGLE_LOADER,
    SHOW_LOADER,
    HIDE_LOADER,
} from "./action_types";

export const toggleLoader = (loaderTag, loaderText) => {
    return {
        type: TOGGLE_LOADER,
        payload: {
            loaderTag: loaderTag,
            loaderText: loaderText
        }
    };
};

export const showLoader = (loaderTag, loaderText) => async (dispatch) => {

    dispatch({
        type: SHOW_LOADER,
        payload: {
            loaderTag: loaderTag,
            loaderText: loaderText
        }
    })

}

export const hideLoader = (loaderTag) => async (dispatch) => {

    dispatch({
        type: HIDE_LOADER,
        payload: {
            loaderTag: loaderTag,
        }
    })

}


