import { MdAddCircleOutline } from "react-icons/lib/md";
import { FaAngleLeft } from "react-icons/lib/fa";
import { connect } from "react-redux";
import React, { useState, useEffect } from "react";
import Select from "react-select";

import DataAgreementPersonalDataTable from "./DataAgreementPersonalDataTable";
import {
  addPersonalDataInDataAgreementState,
  toggleConfigureDataUsingServicePanel,
  replaceAllPersonalData
} from "../actions/dataAgreementActions";
import { fetchPreDefinedVerificationTemplates } from "../services/index"

const AddVerificationModalV2 = (props) => {

  const [newAttributeIndex, setNewAttributeIndex] = useState(null);
  const [selectedVerificationTemplate, setSelectedVerificationTemplate] = useState(null);
  const [predefinedVerificationTemplates, setPredefinedVerificationTemplates] = useState([]);


  /**
   * Triggered when component mounts.
   */
  useEffect(() => {

    // Fetch all predefined verification templates and populate the drop down.
    fetchPreDefinedVerificationTemplates().then((response) => {

      let options = [];

      // Populate drop down options.
      for (const opt of Object.keys(response.data)) {

        options.push({ label: opt, value: response.data[opt] });

      }

      console.log("options", options);

      setPredefinedVerificationTemplates(options);

      // Set the first verification template as selected.
      setSelectedVerificationTemplate(options[0]);


    }).catch((err) => {

      console.log(err);

      // Toast error message.

    });



  }, []);

  const selectElementStyle = {
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "gray" }, // border style on hover
      border: "1px solid lightgray", // default border color
      boxShadow: "none", // no box-shadow
    }),
  };

  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
    display: "flex",

    justifyContent: "flex-start",
  };

  const headerH3Style = {
    margin: "0px",
  };

  const textStyle = {
    color: "#212529",
    fontSize: "16px",
    paddingBottom: 1,
  };

  const attributePreviewTabletBgStyle = {
    background: "rgb(229, 229, 229)",
    borderRadius: "20px",
    color: "black",
    textAlignLast: "center",
    fontSize: "14px",
    padding: "7px"
  };

  const attributePreviewContainerStyle = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "30px 0px"
  }

  /**
   * Triggered when pre-defined verification template is selected.
   * 
   * @param {*} selectedOption 
   */
  const handleVerificationTemplateChange = (selectedOption) => {
    setSelectedVerificationTemplate(selectedOption);
  };

  /**
   * Triggered when "+" button near attributes from pre-defined verification template is clicked.
   * 
   * @param {*} personalData 
   */
  const addPersonalDataToDataAgreement = (attributeName, attributeDescription) => {



    // Empty personal data.
    let newPersonalData = {
      attributeName: attributeName,
      attributeDescription: attributeDescription,
      restrictions: [
        {
          "schema_id": "",
          "cred_def_id": "",
        }
      ]
    };

    // Check if data agreement contains only one attribute.
    // If yes, then check if the attribute name and description are empty.
    // If yes, then new attribute must override the existing attribute.

    // Index of new personal data.
    setNewAttributeIndex(props.dataAgreementState.personalData.length);

    if (props.dataAgreementState.personalData.length === 1) {

      // Index of new personal data.
      setNewAttributeIndex(0);

      if (props.dataAgreementState.personalData[0].attributeName.trim().length === 0 &&
        props.dataAgreementState.personalData[0].attributeDescription.trim().length === 0) {

        // Add new personal data to the data agreement.
        props.replaceAllPersonalData([newPersonalData]);

      } else {

        // Add new personal data to the data agreement.
        props.addPersonalDataInDataAgreementState(newPersonalData);

      }

    } else {
      // Add new personal data to the data agreement.
      props.addPersonalDataInDataAgreementState(newPersonalData);
    }

  };

  /**
   * Fn handleBackButton

   * 
   * Triggered when back button is clicked from create Verification Panel (Parent)
   * 
   */
  const handleBackButton = () => {

    // Toggle data using service configuration panel.
    props.toggleConfigureDataUsingServicePanel();

  };

  return (
    <div className="modal-content">


      <div className="modal-header" style={headerStyle}>


        <FaAngleLeft
          size="20"
          className="modal-back-button"
          onClick={handleBackButton}
        />


        <h3 style={headerH3Style}>
          Choose Existing Schema: Data Using Service (Verifier)
        </h3>


      </div>


      <div className="modal-body-without-bottom-padding" style={{ paddingTop: "0" }}>


        <div className="form">

          <div className="form-group">


            <label className="mt-3" style={textStyle}>
              Data Schema Template<span className="required-field"></span>{" "}
            </label>

            <Select
              isSearchable={true}
              options={predefinedVerificationTemplates}
              value={selectedVerificationTemplate}
              styles={selectElementStyle}
              onChange={handleVerificationTemplateChange}
            />


          </div>


          <div style={attributePreviewContainerStyle}>


            {selectedVerificationTemplate && Object.keys(selectedVerificationTemplate.value).map((attributeName) => (


              <label className="bg-grey ml-2  " key={`${selectedVerificationTemplate.label}:${attributeName}`} style={attributePreviewTabletBgStyle}>


                {" "}


                {attributeName}


                <MdAddCircleOutline size={"20px"} style={{ cursor: "pointer", marginLeft: "5px", }} onClick={() => addPersonalDataToDataAgreement(attributeName, selectedVerificationTemplate.value[attributeName])} />


                {" "}


              </label>


            ))}


          </div>


          <DataAgreementPersonalDataTable personalDataTableLabel={"Attribute names must be exact match of the credentials"} newAttributeIndex={newAttributeIndex} />


        </div>


      </div>


    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dataAgreementState: state.DataAgreementStateUpdate,
  };
}
export default connect(
  mapStateToProps,
  {
    addPersonalDataInDataAgreementState,
    toggleConfigureDataUsingServicePanel,
    replaceAllPersonalData
  }
)(AddVerificationModalV2);
