import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, CardTitle } from "reactstrap";

import { GoCircleSlash } from "react-icons/lib/go";
import { FaTrashO } from "react-icons/lib/fa";

import {
    updatePersonalDataInDataAgreementState,
    deletePersonalDataInDataAgreementStateAction
} from "../actions/dataAgreementActions";

class DataAgreementPersonalDataTableItem extends Component {

    constructor(props) {


        super(props);

        // State
        this.state = {

            restrictionToggle: false,
            personalData: [],

        }

        // Bind class methods.
        this.onInputAttributeValues = this.onInputAttributeValues.bind(this);

        // Ref
        this.personalDataTableItemRef = React.createRef();


    }

    componentDidMount() {

        const { newAttributeIndex, index } = this.props;

        // Scroll if new attribute is added by clicking "+" button in personal data table.
        if (newAttributeIndex && newAttributeIndex === index) {
            if (this.personalDataTableItemRef.current) {
                const { index: personalDataItemIndex } = this.props;

                // Note: Don't scroll for the first item.
                if (personalDataItemIndex > 0) {
                    setTimeout(() => { this.personalDataTableItemRef.current.scrollIntoView({ behavior: "smooth" }); }, 0);
                }



            }
        }



    }

    componentWillUnmount() { }

    /**
     * Triggered when the user changes the attribute value.
     * 
     * @param {*} e 
     * @param {*} pd 
     * @param {*} index 
     */
    onInputAttributeValues = (e, pd, index) => {


        if (e.target.name.includes("personalDataName_")) {
            if (!this.props.configureDataSource.isExisting) {
                this.props.updatePersonalDataInDataAgreementState(index, {
                    attributeName: e.target.value,
                    attributeDescription: pd.attributeDescription
                });
            }
        } else {

            this.props.updatePersonalDataInDataAgreementState(index, {
                attributeName: pd.attributeName,
                attributeDescription: e.target.value
            });

        }





    }


    render() {

        const { pd, index, toggleRestrictionCallback: triggerToggleRestriction } = this.props;

        const inputStyleAttr = {
            width: "85%",
            color: "#495057",
            border: "1",
            borderWidth: 0,
            padding: 0,
            paddingBottom: 1,
            borderRadius: 0,
            fontSize: "16px",
            borderBottomWidth: 1.2,
            borderBottomColor: "lightgray", //'#DFE0E1',
            marginRight: "10px",
        };


        return (

            <div id={"personalData_" + index} key={"personalData_" + index} ref={this.personalDataTableItemRef} className="form-group">


                <Card className="attr-restriction-card" key={index}>


                    <CardTitle className="title-attr-restriction">


                        <table className="table-attr-additional-info">


                            <tbody>


                                <tr style={{ display: "flex" }}>


                                    <td className="table-attr-additional-info-column" style={{ flexBasis: "80%" }}>
                                        <input
                                            disabled={this.props.dataAgreementPanel.mode === "READ"}
                                            className="personal-data-table-attribute-name"
                                            placeholder="Attribute name (minimum 3 characters)"
                                            style={{ ...inputStyleAttr, border: "none", width: "100%", cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }}
                                            type="text"
                                            autoComplete="off"
                                            name={"personalDataName_" + index}
                                            value={pd.attributeName || ""}
                                            onChange={(e) => { this.onInputAttributeValues(e, pd, index) }}
                                        />
                                    </td>


                                    <th style={{ marginRight: "14px", marginLeft: "17px" }}>
                                        <GoCircleSlash
                                            size="20px"
                                            style={{ zIndex: 10 }}
                                            onClick={() => { if (this.props.dataAgreementPanel.mode !== "READ") { triggerToggleRestriction(); } }}
                                            cursor={this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : this.props.dataAgreementState.methodOfUse === "data-using-service" ? "pointer" : "not-allowed"}
                                        />
                                    </th>


                                    <th>
                                        <FaTrashO
                                            size="20px"
                                            style={{ float: "right", zIndex: 10 }}
                                            onClick={() => {

                                                if (this.props.dataAgreementPanel.mode !== "READ") {
                                                    if (!this.props.configureDataSource.isExisting) {
                                                        this.props.deletePersonalDataInDataAgreementStateAction(index);
                                                    }
                                                }


                                            }}
                                            cursor={this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : this.props.configureDataSource.isExisting ? "not-allowed" : "pointer"}
                                        />
                                    </th>


                                </tr>


                            </tbody>


                        </table>
                    </CardTitle>


                    <CardTitle className="title-personal-data-table-attribute-description">


                        <table className="table-attr-additional-info">


                            <tbody>


                                <tr style={{ display: "flex" }}>


                                    <td className="table-attr-additional-info-column" style={{ flexBasis: "100%" }} >
                                        <input
                                            disabled={this.props.dataAgreementPanel.mode === "READ"}
                                            className="personal-data-table-attribute-description"
                                            placeholder="Attribute description (minimum 3 characters)"
                                            style={{ ...inputStyleAttr, border: "none", width: "100%", cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }}
                                            type="text"
                                            autoComplete="off"
                                            name={"personalDataDescription_" + index}
                                            value={pd.attributeDescription || ""}
                                            onChange={(e) => { this.onInputAttributeValues(e, pd, index) }}
                                            onKeyPress={(event) => {
                                                if (event.key === "Enter") {
                                                    if(pd.attributeName.length >=3 && pd.attributeDescription.length >=3)
                                                    {
                                                        this.props.addAttributeField();
                                                    }
                                                }
                                              }}
                                        />
                                    </td>


                                </tr>


                            </tbody>


                        </table>


                    </CardTitle>


                </Card>



            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dataAgreementState: state.DataAgreementStateUpdate,
        configureDataSource: state.ConfigureDataSource,
        dataAgreementPanel: state.DataAgreementPanel
    };
}
export default connect(
    mapStateToProps,
    {
        updatePersonalDataInDataAgreementState,
        deletePersonalDataInDataAgreementStateAction
    }
)(DataAgreementPersonalDataTableItem);