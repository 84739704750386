// Pricing
export const FETCH_PRICING_INFO = "FETCH_PRICING_INFO";

// Invoices
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_ERROR = "FETCH_INVOICES_ERROR";

// Card
export const ADD_CARD_REQUEST = "ADD_CARD_REQUEST";
export const ADD_CARD_SUCCESS = "ADD_CARD_SUCCESS";
export const ADD_CARD_FAILURE = "ADD_CARD_FAILURE";
export const FETCH_CARD_REQUEST = "FETCH_CARD_REQUEST";
export const FETCH_CARD_SUCCESS = "FETCH_CARD_SUCCESS";
export const FETCH_CARD_FAILURE = "FETCH_CARD_FAILURE";

// Address
export const ADD_ADDRESS_REQUEST = "ADD_ADDRESS_REQUEST";
export const ADD_ADDRESS_SUCCESS = "ADD_ADDRESS_SUCCESS";
export const ADD_ADDRESS_FAILURE = "ADD_ADDRESS_FAILURE";
export const FETCH_ADDRESS_REQUEST = "FETCH_ADDRESS_REQUEST";
export const FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_FAILURE = "FETCH_ADDRESS_FAILURE";

// Org
export const FETCH_ORG_REQUEST = "FETCH_ORG_REQUEST";
export const FETCH_ORG_SUCCESS = "FETCH_ORG_SUCCESS";
export const FETCH_ORG_FAILURE = "FETCH_ORG_FAILURE";

// Subscribe Plan
export const SUBSCRIBE_PLAN_REQUEST = "SUBSCRIBE_PLAN_REQUEST";
export const SUBSCRIBE_PLAN_SUCCESS = "SUBSCRIBE_PLAN_SUCCESS";
export const SUBSCRIBE_PLAN_FAILURE = "SUBSCRIBE_PLAN_FAILURE";

// Get current plan
export const FETCH_ACTIVE_PLAN_REQUEST = "FETCH_ACTIVE_PLAN_REQUEST";
export const FETCH_ACTIVE_PLAN_SUCCESS = "FETCH_ACTIVE_PLAN_SUCCESS";
export const FETCH_ACTIVE_PLAN_FAILURE = "FETCH_ACTIVE_PLAN_FAILURE";

// Cancel Plan
export const CANCEL_PLAN_REQUEST = "CANCEL_PLAN_REQUEST";
export const CANCEL_PLAN_SUCCESS = "CANCEL_PLAN_SUCCESS";
export const CANCEL_PLAN_FAILURE = "CANCEL_PLAN_FAILURE";

// Service Agreement
export const FETCH_SERV_AGREEMENT_REQUEST = "FETCH_SERV_AGREEMENT_REQUEST";
export const FETCH_SERV_AGREEMENT_SUCCESS = "FETCH_SERV_AGREEMENT_SUCCESS";
export const FETCH_SERV_AGREEMENT_FAILURE = "FETCH_SERV_AGREEMENT_FAILURE";

// Organization registration
export const REG_ORG_REQUEST = "REG_ORG_REQUEST";
export const REG_ORG_SUCCESS = "REG_ORG_SUCCESS";
export const REG_ORG_FAILURE = "REG_ORG_FAILURE";

// Verify New User
export const VALIDATE_NEW_USER_REQUEST = "VALIDATE_NEW_USER_REQUEST";
export const VALIDATE_NEW_USER_SUCCESS = "VALIDATE_NEW_USER_SUCCESS";
export const VALIDATE_NEW_USER_FAILURE = "VALIDATE_NEW_USER_FAILURE";

// Verify Phone
export const VERIFY_PHONE_OTP_REQUEST = "VERIFY_PHONE_OTP_REQUEST";
export const VERIFY_PHONE_OTP_SUCCESS = "VERIFY_PHONE_OTP_SUCCESS";
export const VERIFY_PHONE_OTP_FAILURE = "VERIFY_PHONE_OTP_FAILURE";

// Resend Otp
export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

// Fetch Org Types
export const FETCH_ORG_TYPES_REQUEST = "FETCH_ORG_TYPES_REQUEST";
export const FETCH_ORG_TYPES_SUCCESS = "FETCH_ORG_TYPES_SUCCESS";
export const FETCH_ORG_TYPES_FAILURE = "FETCH_ORG_TYPES_FAILURE";

// Authenticate Token
export const IS_LOGIN = "IS_LOGIN";

// API Validation
export const IS_API_KEY_VALID = "IS_API_KEY_VALID"

// Data agreements
export const INIT_DATA_AGREEMENT_STATE = "INIT_DATA_AGREEMENT_STATE";
export const REPLACE_DATA_AGREEMENT_STATE = "REPLACE_DATA_AGREEMENT_STATE";
export const UPDATE_DATA_CONTROLLER_NAME = "UPDATE_DATA_CONTROLLER_NAME";
export const UPDATE_JURISDICTION = "UPDATE_JURISDICTION";
export const UPDATE_INDUSTRY_SECTOR = "UPDATE_INDUSTRY_SECTOR";
export const UPDATE_DATA_RETENTION_PERIOD = "UPDATE_DATA_RETENTION_PERIOD";
export const UPDATE_POLICY_URL = "UPDATE_POLICY_URL";
export const UPDATE_DATA_CONTROLLER_URL = "UPDATE_DATA_CONTROLLER_URL";
export const UPDATE_LAWFUL_BASIS = "UPDATE_LAWFUL_BASIS";
export const UPDATE_USAGE_PURPOSE = "UPDATE_USAGE_PURPOSE";
export const UPDATE_USAGE_PURPOSE_DESCRIPTION = "UPDATE_USAGE_PURPOSE_DESCRIPTION";
export const UPDATE_METHOD_OF_USE = "UPDATE_METHOD_OF_USE";
export const UPDATE_GEOGRAPHIC_RESTRICTION = "UPDATE_GEOGRAPHIC_RESTRICTION";

// Personal data (CRUD)
export const ADD_PERSONAL_DATA_IN_DATA_AGREEMENT = "ADD_PERSONAL_DATA_IN_DATA_AGREEMENT";
export const REMOVE_PERSONAL_DATA_IN_DATA_AGREEMENT = "REMOVE_PERSONAL_DATA_IN_DATA_AGREEMENT";
export const UPDATE_PERSONAL_DATA_IN_DATA_AGREEMENT = "UPDATE_PERSONAL_DATA_IN_DATA_AGREEMENT";
export const REPLACE_ALL_PERSONAL_DATA = "REPLACE_ALL_PERSONAL_DATA";

// Configure data source
export const INIT_DATA_SOURCE_CONFIGURATION = "INIT_DATA_SOURCE_CONFIGURATION";
export const UPDATE_DATA_SOURCE_CONFIGURATION = "UPDATE_DATA_SOURCE_CONFIGURATION";
export const TOGGLE_CONFIGURE_DATA_SOURCE_PANEL = "TOGGLE_CONFIGURE_DATA_SOURCE_PANEL";

// Loader
export const TOGGLE_LOADER = "TOGGLE_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

// Data agreement form validation
export const VALIDATE_DATA_AGREEMENT_FORM = "VALIDATE_DATA_AGREEMENT_FORM";

// Configure data using service
export const INIT_DATA_USING_SERVICE_CONFIGURATION = "INIT_DATA_USING_SERVICE_CONFIGURATION";
export const TOGGLE_CONFIGURE_DATA_USING_SERVICE_PANEL = "TOGGLE_CONFIGURE_DATA_USING_SERVICE_PANEL";

// Data agreements table.
export const RESET_DATA_AGREEMENTS_TABLE = "RESET_DATA_AGREEMENTS_TABLE";
export const POPULATE_DATA_AGREEMENTS_TABLE = "POPULATE_DATA_AGREEMENTS_TABLE";
export const UPDATE_DATA_AGREEMENTS_TABLE_FILTER = "UPDATE_DATA_AGREEMENTS_TABLE_FILTER";
export const DATA_AGREEMENTS_TABLE_UPDATE_IS_TABLE_LOADING = "DATA_AGREEMENTS_TABLE_UPDATE_IS_TABLE_LOADING";
export const DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_TOTAL_COUNT = "DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_TOTAL_COUNT";
export const DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_CURRENT_PAGE = "DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_CURRENT_PAGE";
export const DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_PAGE_SIZE = "DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_PAGE_SIZE";

// Data agreement panel (CRUD)
export const TOGGLE_DATA_AGREEMENT_PANEL = "TOGGLE_DATA_AGREEMENT_PANEL";
export const UPDATE_DATA_AGREEMENT_PANEL_MODE = "UPDATE_DATA_AGREEMENT_PANEL_MODE";
export const INIT_DATA_AGREEMENT_PANEL_MODE = "INIT_DATA_AGREEMENT_PANEL_MODE";

// Personal data table.
export const RESET_PERSONAL_DATA_TABLE = "RESET_PERSONAL_DATA_TABLE";
export const POPULATE_PERSONAL_DATA_TABLE = "POPULATE_PERSONAL_DATA_TABLE";
export const UPDATE_PERSONAL_DATA_TABLE_FILTER = "UPDATE_PERSONAL_DATA_TABLE_FILTER";
export const PERSONAL_DATA_TABLE_UPDATE_IS_TABLE_LOADING = "PERSONAL_DATA_TABLE_UPDATE_IS_TABLE_LOADING";
export const PERSONAL_DATA_TABLE_UPDATE_PAGINATION_TOTAL_COUNT = "PERSONAL_DATA_TABLE_UPDATE_PAGINATION_TOTAL_COUNT";
export const PERSONAL_DATA_TABLE_UPDATE_PAGINATION_CURRENT_PAGE = "PERSONAL_DATA_TABLE_UPDATE_PAGINATION_CURRENT_PAGE";
export const PERSONAL_DATA_TABLE_UPDATE_PAGINATION_PAGE_SIZE = "PERSONAL_DATA_TABLE_UPDATE_PAGINATION_PAGE_SIZE";
