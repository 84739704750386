import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FaAngleLeft } from "react-icons/lib/fa";
import { MdAddCircleOutline } from "react-icons/lib/md";

import {
    addPersonalDataInDataAgreementState,
} from "../actions/dataAgreementActions";

import DataAgreementPersonalDataRestrictionItem from "../components/DataAgreementPersonalDataRestrictionItem";

class DataAgreementPersonalDataRestriction extends Component {

    constructor(props) {

        super(props);

        this.state = {
            newAttributeIndex: null,
        }

    }

    /**
     * Triggered when the user clicks the add attribute button.
     */
    addAttributeField = () => {

        let newPersonalData = {
            attributeName: "",
            attributeDescription: "",
        }

        // Data agreement state from redux.
        const { dataAgreementState } = this.props;

        // Personal data from redux.
        const { personalData } = dataAgreementState;

        // If method of use is data-using-service
        // then add the new personal data with restrictions.
        if (dataAgreementState.methodOfUse === "data-using-service") {
            newPersonalData["restrictions"] = [
                {
                    "schema_id": "",
                    "cred_def_id": "",
                }
            ];
        }

        // Index of new personal data.
        this.setState({ newAttributeIndex: personalData.length });

        // Add new personal data to the data agreement.
        this.props.addPersonalDataInDataAgreementState(newPersonalData);

    }

    /**
     * 
     * Triggered when back button is clicked.
     */
    handleBackButton = () => {

        this.props.backButtonCallback();

    }


    render() {

        const headerStyle = {
            fontSize: "16px",
            backgroundColor: "#eeeeee",
            display: "flex",

            justifyContent: "flex-start",
        };

        const headerH3StyleWithoutMargin = {
            marginTop: "0px",
        };

        return (
            <div className="modal-content">

                <div className="modal-header" style={headerStyle}>
                    <FaAngleLeft size="20" className="modal-back-button" onClick={this.handleBackButton} />

                    <h3 className="modal-heading-h3" style={headerH3StyleWithoutMargin}>
                        {" "}
                        Restrictions {" "}
                    </h3>

                </div>

                <div className="modal-body-without-bottom-padding" style={{ paddingTop: "0", paddingBottom: "0px" }}>

                    {this.props.dataAgreementState.personalData.map((attr, key) => {
                        return (
                            <DataAgreementPersonalDataRestrictionItem personalData={attr} itemIndex={key} key={key} newAttributeIndex={this.state.newAttributeIndex} />
                        );
                    })}



                    <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <MdAddCircleOutline size={"20px"} style={{ cursor: "pointer", marginLeft: "5px", }} onClick={this.addAttributeField} />
                        <label style={{ marginLeft: "10px" }}>Add more</label>
                    </div>

                </div>




            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dataAgreementState: state.DataAgreementStateUpdate,
    };
}
export default connect(
    mapStateToProps,
    {
        addPersonalDataInDataAgreementState,
    }
)(DataAgreementPersonalDataRestriction);