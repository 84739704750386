import React from "react";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";

export default ({
  input: { value, onChange },
  meta: { touched, error, warning }
}) => {
  const style =
    touched && error
      ? { fontSize: "14px", border: "1px solid red" }
      : { fontSize: "14px" };
  return (
    <div>
      <ReactPhoneInput
        defaultCountry="se"
        value={value}
        onChange={onChange}
        inputStyle={{ width: "100%", ...style }}
      />
      {/* {touched &&
        ((error && (
          <span style={{ color: "red", fontSize: "12px" }}>{error}</span>
        )) ||
        (warning && <span>{warning}</span>))}*/}
    </div>
  );
};
