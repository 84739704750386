import React from "react";
import { Label } from "reactstrap";
import ReduxFormComponentSlider from "./ReduxFormComponentSlider";
export default props => {
  return (
    <div className="mt-0 mb-0 ">
      <Label style={{ fontSize: "14px" }}>{props.label}: </Label>
      <div className="d-none d-md-block ml-1" style={{ width: "40%" }}>
        <ReduxFormComponentSlider {...props} />
      </div>
      <div className="d-block d-md-none ml-1">
        <ReduxFormComponentSlider {...props} />
      </div>
    </div>
  );
};
