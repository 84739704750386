import React from 'react';
import axios from 'axios';
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
 } from 'reactstrap';

import Page from '../components/Page';
import { store } from '../services/localStorageStore'

class OrganizationPage extends React.Component {
    constructor () {
      super()
      this.state = {
          status:'' ,statusType:'' ,
          user_org:'',  
          orgDetails:[], 
          user_orgID:'',
          org_name:'',
          org_desc:'',
          org_loc:''
      }
      this.handleChangeListOrgID =this.handleChangeListOrgID.bind(this);
      this.handleChangeOrgDetails = this.handleChangeOrgDetails.bind(this);
      this.handleSetOrganizationByDetails=this.handleSetOrganizationByDetails.bind(this);
      this.handleChangeOrg = this.handleChangeOrg.bind(this);
      this.handleSetOrganizationByType = this.handleSetOrganizationByType.bind(this);
     
    }

    handleChangeListOrgID(event) {
        
        this.setState({user_orgID: event.target.value})  ;
        this.setState({status: ''});
    }

    handleChangeOrgDetails(event) {
      if(event.target.name === 'org_name')  this.setState({org_name: event.target.value});
      if(event.target.name === 'org_desc')  this.setState({org_desc: event.target.value});
      if(event.target.name === 'org_loc')   this.setState({org_loc: event.target.value});
        this.setState({status: ''})
    }

    handleGetOrganizationDetails ()
    {
        var axiosConfig = {
            headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
              }
        }; 
        axios.get('https://staging-api.igrant.io/v1/organizations/types',axiosConfig)
        .then((response) => {           
        let initialPlanets3 = [];
        var i=0;  
        initialPlanets3[i]= { ID : '' , Type :'' };
        for(var i=0;i<response.data.length ;i++)
        {
            initialPlanets3[i+1]= { ID : response.data[i].ID , Type :response.data[i].Type };
        }
        this.setState({ orgDetails: initialPlanets3 }); 
       });     
}

      handleSetOrganizationByDetails ()
    {
        if(this.state.org_name.trim().length==0)
        {
            this.setState({status :  'Error : Organization Data Required ' }); 
           // this.refs.uorg_name.focus();
        }
        else 
        {
            
            var url = 'https://staging-api.igrant.io/v1/organizations'
            var data = {
                'name' : this.state.org_name,
                'description' : this.state.org_desc,
                'location' : this.state.org_loc,
                'typeid':this.state.user_orgID,
              };
     
            var axiosConfig = {
                headers: {
                    'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
            }; 
      
            axios.post(url, data,axiosConfig)
             .then((response) => {
                this.setState({status :  'Organization Added'  }); 
        })
        .catch((err) => {
            switch(err.response.status)
           {
             case 500:
                this.setState({status :  'Error : Organsation Details Already Added'   });
                break;
            case 502:
                 this.setState({statusType :  'Please Select Type '  }); 
                 this.refs.optorgidd.focus();
                 break;
           default:
                this.setState({status :  'Error : Organization Data Required ' }); 
                break;
               }


        /*    if(err.response.status==500)
            {
              this.setState({status :  'Error : Organsation Details Already Added'   });
              alert("Error :Organsation Details Already Added" );
            }
            else
            {   
                 this.setState({status :  'Error : Organization Data Required ' }); 
                 alert("Error : Organization Data Required " + err);
            }*/
      });      
  } 
}

handleChangeOrg(event) {
    if(event.target.name === 'user_org' ) this.setState({user_org: event.target.value}) ;
    this.setState({statusType: ''})
}

handleSetOrganizationByType ()
{
    if(this.state.user_org.trim().length==0)
    {
        this.refs.uorg.focus();
        this.setState({statusType :  'Error : Type Data Required'  });
    }
    else 
    {
        var url = 'https://staging-api.igrant.io/v1/organizations/types'
        var data = {
            'type' : this.state.user_org
        };
     
        var axiosConfig = {
            headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
      
        axios.post(url, data,axiosConfig)
         .then((response) => {
             window.location.reload(); 
        this.setState({statusType :  'Organization Type Added'  }); 
         
    })
    .catch((err) => {
    if(err.response.status==500)
        {
          this.setState({statusType :  'Type Already Added'  }); 
      }
      
      else
       {
           this.setState({statusType :  'Type Data Required'  }); 
      }
});      
} 
}

componentDidMount() {
      this.handleGetOrganizationDetails();
}

componentWillUnmount() {
     this.handleGetOrganizationDetails();
}

render() {
    return (
      <Page
        title=""
      breadcrumbs={[{ name: 'Organization', active: true }]}
    >
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader>Organization Details</CardHeader>
            <CardBody>
              <InputGroup>
                   <Input placeholder="Organization Name" ref="uorg_name" type="text" name="org_name" value={this.state.org_name} onChange={this.handleChangeOrgDetails}  required />
              </InputGroup>
              <br />
              <InputGroup>
                 <Input type="textarea" placeholder="Description" ref="uorg_desc" name="org_desc" value={this.state.org_desc} onChange={this.handleChangeOrgDetails}  required/>
              </InputGroup>
              <br />
              <InputGroup>
                 <Input placeholder="Location" ref="uorg_loc" type="text" name="org_loc" value={this.state.org_loc} onChange={this.handleChangeOrgDetails}  required />
              </InputGroup>
              <br />
              <Input className="mb-2" type="select"  ref="optorgidd"  name="user_org"  onLoad={this.handleChangeListOrgID}  onClick={this.handleChangeListOrgID}  onChange={this.handleChangeListOrgID} required>
                    {this.state.orgDetails.map(options => <option  value={options.ID} key={options.ID}>{options.Type}</option>)}
               </Input>
             <br />
                   <Button color="secondary" size="lg" block active onClick={this.handleSetOrganizationByDetails}>Set Organization</Button>
             <hr/>
             <InputGroup>
                 Status : {this.state.status} 
             </InputGroup>
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
         <Row>
          <Col md={12}> 
           <Card>
            <CardHeader>Background</CardHeader>
            <CardBody>
              <InputGroup>
                    <Input type="file" name="file" />
              </InputGroup>
             <br />
              <InputGroup  color="muted">
                      This is some placeholder block-level help text for the
              </InputGroup>
             </CardBody>
          </Card>
         </Col>
         <Col md={12}> 
           <Card>
            <CardHeader>Logo</CardHeader>
            <CardBody>
              <InputGroup>
                    <Input type="file" name="file" />
              </InputGroup>
             <br />
              <InputGroup  color="muted">
                      This is some placeholder block-level help text for the
              </InputGroup>
             </CardBody>
          </Card>
         </Col>
         </Row>
       </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card>
            <CardHeader>Organization Types</CardHeader>
            <CardBody>
              <InputGroup>
                 <Input ref="uorg" type="text" name="user_org" value={this.state.user_org} onChange={this.handleChangeOrg}  required/>
                 <InputGroupAddon addonType="prepend">
                 <Button color="secondary" size="lg" block active onClick={this.handleSetOrganizationByType}>Set</Button></InputGroupAddon>
              </InputGroup>
              <br />
             <hr/>
             <InputGroup>
                          Status : {this.state.statusType} 
             </InputGroup>
           
            </CardBody>
          </Card>
        </Col>

        <Col md={6}>
          <Card>
            <CardHeader>Settings</CardHeader>
            <CardBody>
             
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Page>
  );
}
}
export default OrganizationPage;
