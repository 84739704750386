import {
  FETCH_ORG_TYPES_REQUEST,
  FETCH_ORG_TYPES_SUCCESS,
  FETCH_ORG_TYPES_FAILURE
} from "../actions/action_types";

export const OrgTypesReducer = (state = null, action) => {
  switch (action.type) {
    case FETCH_ORG_TYPES_REQUEST:
      return { ...state, isFetching: true };
    case FETCH_ORG_TYPES_SUCCESS:
      return { data: action.payload, isFetching: false };
    case FETCH_ORG_TYPES_FAILURE:
      return {
        ...state,
        isFetching: false,
        errorMessage: action.error
      };
    default:
      return state;
  }
};
