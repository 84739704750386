import React, { Component } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/lib/md";
import "react-phone-input-2/lib/style.css";
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";

class PasswordShowHide extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      password: ""
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  handlePasswordChange(e) {
    if (e.target.value === "") {
      this.setState({ hidden: true, password: e.target.value });
      return;
    }
    this.setState({ password: e.target.value });
    this.props.onChange(e);
  }

  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  componentDidMount() {
    if (this.props.password) {
      this.setState({ password: this.props.password });
    }
  }

  render() {
    return (
      <div>
        <InputGroup>
          <Input
            style={this.props.style}
            type={this.state.hidden ? "password" : "text"}
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
          <InputGroupAddon addonType="append">
            <Button
              style={this.props.style}
              outline
              className="btn btn-default"
              onClick={this.toggleShow}
            >
              {this.state.hidden ? <MdVisibility /> : <MdVisibilityOff />}
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

export default PasswordShowHide;
