import axios from 'axios';
import React from 'react';
import { getColor } from '../utils/colors';
import IconEye from 'react-icons/lib/fa/eye';
import { Tooltip } from '@material-ui/core';
import IconEyeSlash from 'react-icons/lib/fa/eye-slash'; 
import GeneralDeleteModal from "../components/GeneralDeleteModal"
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import { toast } from 'react-toastify';
import { MdAddCircleOutline, MdNearMe } from "react-icons/lib/md";
import { FaTrashO, FaEdit } from "react-icons/lib/fa";
import {
  Card,CardText,Label,
  CardBody,
  Input, 
  CardLink, 
  ButtonGroup, 
  Button,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Modal
  } from 'reactstrap';
import AddDataVerifierModal from '../components/AddDataVerifierModal';
import EditDataVerifierModal from '../components/EditDataVerifierModal';
import UserImageUpload   from '../components/UserImageUpload';
import { store } from '../services/localStorageStore';
import {GetUserDetails,} from '../components/Sources';
import Page from '../components/Page';
import * as BootstrapTable2 from 'react-bootstrap-table-next';
import { BootstrapTable, TableHeaderColumn,} from 'react-bootstrap-table';
import jsonData from '../setting/settings.json';
import UserImage from '../assets/img/users/user.jpg';

const BootstrapTable22 = BootstrapTable2.default
var grants= true;
const api = JSON.parse(JSON.stringify(jsonData)).api;
class ActiveLineByLineFormatter extends React.Component {
     render() {
         return (
          <div >
             {(store.getData('data') != null)?(store.getData('data').User.Name):''}<br/>
             <span style={{color:'#6C757D'}}> {(store.getData('data') != null)?(store.getData('data').User.Email):''}<br/>
             { (store.getData('data') != null)?(store.getData('data').User.ID):''} </span>
         </div> 
        );
}
}

function activeLineByLineFormatter(cell, row) {
    return (
      <ActiveLineByLineFormatter />
);
}
 
class ActiveFormatter extends React.Component {
    render() {
        return (
                 <input type='checkbox' checked={this.props.active} name="u_status"  readOnly />
    );
}
}

class ActiveCheck extends React.Component {
    render() {
        return (
                 <input type='checkbox' checked={this.props.active} name="u_status"  disabled  />
    );
}
}


function activeFormatter(cell, row  ) {
    return (
        <ActiveFormatter active={grants} />
  );
}

function activeCheck(cell, row  ) {
    return (
        <ActiveCheck active={true}  />
);
}

const options = {
};


const selectRow = {

};

 
var userImage=''; 
if((store.getData('data') != null) && (store.getData('data').User.ImageID!=0))
    userImage=  api + 'v1/users/'  + ( (store.getData('data') != null)?(store.getData('data').User.ID):'' )  + '/image/' + ((store.getData('data') != null)?(store.getData('data').User.ImageID):'' ) +  '/web' ;
else
    userImage=UserImage;
var userName= (store.getData('data') != null)?(store.getData('data').User.Name):'' ;               
var userEmail =(store.getData('data') != null)?store.getData("data").User.Email:'' ;                  
var userID =(store.getData('data') != null)?store.getData("data").User.ID:'' ;  
 
const jobs = [
   /* { id :1 , user: {name:'Business Admin (Demo)', email : 'demo@business.com' , ID : '5ba07e684f89b90001850865'} ,owner :  true ,admin: true , developer: true , dpo : true },
    { id :2 , user: {name :'Lal Chandran', email : 'lal@business.com' , ID : '5ba07e684f89b90001850865' }  ,owner :  false ,admin: true , developer: true , dpo : true },*/
    { id :3 , user: {name : userName, email : userEmail, ID : userID}  ,owner :  false ,admin: true , developer: true , dpo : true },

];
class  ManagePage extends React.Component {

    constructor () {
        super()
        this.state = {
            u_image:'',  
            u_name:'',
            u_email:'',
            u_id:'',
            u_pwd:'',
            edit_mode: true,
            edit_pwd: true,
            show: false,
            dataVerifierUsers: [],
            addDataVerifierModal: false,
            editDataVerifierModal: false,
            selectedDataVerifier: {},
            deleteDataVerifierModal: false,
            sendCursor: "pointer"
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handlePwdEdit = this.handlePwdEdit.bind(this);
        this.handlePwdCancel = this.handlePwdCancel.bind(this);
        this.handlePwdSave = this.handlePwdSave.bind(this);
        this.getDataVerifier = this.getDataVerifier.bind(this)
        this.getData();
    }
 
    getData()
    {
        GetUserDetails();
           
        if(store.getData('data') != null) 
        {
         if(store.getData('data').User.ImageID!=0)
            userImage= api + 'v1/users/'  + store.getData('data').User.ID  + '/image/' + store.getData('data').User.ImageID +  '/web' ;
          else
            userImage=UserImage;
          this.setState({u_image:userImage})  ;
          this.setState({u_name:store.getData('data').User.Name})  ;
          this.setState({u_email:store.getData('data').User.Email})  ;
          this.setState({u_id:store.getData('data').User.ID})  ;
          this.setState({u_pwd:''})  ;
        }
        else
        {
            this.setState({u_image:UserImage})  ;
            this.setState({u_name:'' })  ;
            this.setState({u_email:'' })  ;
            this.setState({u_id: ''})  ;
            this.setState({u_pwd:''})  ;
        }
        
    }
    getDataVerifier(){
        const axiosConfig = {
            headers: {
              Authorization:
                "Bearer " +
                store.getData("data").Token.access_token,
            },
          };
          const url = `${api}v1/organizations/${store.getData("orgs").ID}/data-verifier-users`
          axios.get(url, axiosConfig).then((response) => {
              this.setState({dataVerifierUsers: response.data})
          }).catch(err =>{
              this.setState({dataVerifierUsers: []})
          })
    }

    componentDidMount() {
        this.getData();
        this.getDataVerifier()
        
    }
    
      
    handleChange(event) {
        if(event.target.name === 'u_name') this.setState({u_name: event.target.value}) ;
        if(event.target.name === 'u_pwd')  this.setState({u_pwd: event.target.value}) ;
    }

    handleCancel(event) {
        this.setState({edit_mode:true})  ;
        this.setState({u_name: (store.getData('data') != null)?(store.getData('data').User.Name):''});
        this.setState({u_email: (store.getData('data') != null)?(store.getData('data').User.Email):''});
        this.setState({u_id: (store.getData('data') != null)?(store.getData('data').User.ID):''});
       
    }
    handlePwdCancel(event) {
        this.setState({u_pwd:''})  ;
        this.setState({ show: false});
        this.setState({edit_pwd:true})  ;
    }

    handleEdit(event) {
        this.setState({edit_mode:false})  ;
    } 
    handlePwdEdit(event) {
        this.setState({edit_pwd:false})  ;
    } 
    setName()
    { 

        if(this.state.u_name.trim().length<3)
        {
            this.setState({status :  'Error : Invalid User Name' }); 
        }
        else 
        { 
            var url = api + 'v1/user'
            var data = {
                'name' : this.state.u_name,
            };
     
            var axiosConfig = {
                headers: {
                    'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
            }; 
            axios.patch(url, data,axiosConfig)
             .then((response) => {
                 this.getData();
                 GetUserDetails();
                 userName= (store.getData('data') != null)?(store.getData('data').User.Name):'' ;               
                  
               
        });
          
    }
}

setPassword()
{ 
    if(this.state.u_pwd.trim().length<3)
    {
        this.setState({status :  'Error : Invalid Password' }); 

    }
    else 
    { 
        var url = api + 'v1/user/password/reset'
        var data = {
            'password' : this.state.u_pwd,
        };
     
        var axiosConfig = {
            headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        };  
        axios.put(url, data,axiosConfig)
         .then((response) => {
              GetUserDetails();
         });
    }
}

handleSave(event) {
    this.setName(); 
    this.setState({edit_mode:true})  ;
    this.props.history.push("/manage");
    this.setState({edit_mode:true})  ;
}
handlePwdSave(event) {
    this.setPassword(); 
    this.setState({u_pwd:''})  ;
    this.setState({ show: false});
    this.setState({edit_pwd:true})  ;
}
toggle = () => {
    this.setState({
        show: !this.state.show,
    });
};

toggleAddVerifierModal = () => {
    this.setState({ addDataVerifierModal: !this.state.addDataVerifierModal })
}

toggleEditVerifierModal = () => {
    this.setState({ editDataVerifierModal: !this.state.editDataVerifierModal })
}

toggleDeleteDataVerifier = () => {
    this.setState({ deleteDataVerifierModal: !this.state.deleteDataVerifierModal })
}


onAfterDeleteDataVerifierUser = (row) => {
    const axiosConfig = {
        headers: {
          Authorization: "Bearer " + store.getData("data").Token.access_token,
        },
      };
      const url = `${api}v1/organizations/${
        store.getData("orgs").ID
      }/data-verifier-user/${row.ID}`;
      axios
        .delete(url, axiosConfig)
        .then((response) => {
          this.getDataVerifier();
          this.toggleDeleteDataVerifier();
        })
        .catch((err) => {
          this.getDataVerifier();
          this.toggleDeleteDataVerifier();
        });
}

sendMagicLink = (row) => {
    const axiosConfig = {
        headers: {
          Authorization: "Bearer " + store.getData("data").Token.access_token,
        },
      };
      const url = `${api}v1/organizations/${
        store.getData("orgs").ID
      }/data-verifier-user/${row.ID}/send-magic-link`;
      axios
        .post(url, "", axiosConfig)
        .then((response) => {
            toast.error(`Magic link sent to ${row.Name}` )
            this.setState({sendCursor: "pointer"})
        })
        .catch((err) => {
          toast(`Something went wrong. Please try again later` )
          this.setState({sendCursor: "pointer"})
        });
}

render() {
    const secondaryColor = getColor('secondary');
    const colDispStyle = { fontSize: '14px', cursor:'pointer', padding : '.35rem', borderWidth:'1px solid !important', borderColor : '#dee2e6'};
    const headerDispStyle = { backgroundColor: '#f0f0f0', padding : '.35rem', fontWeight: 'bold', border:'solid', borderWidth: "0px 1px 3px 1px", borderColor : '#dee2e6'};
    const buttonDispStyle = {width: '3%', backgroundColor: '#f0f0f0', padding : '.35rem', borderWidth: '0px', borderBottomWidth:'3px'}
    const colDispStyleButton = { fontSize: '14px', cursor:'pointer', borderWidth: '0px', borderBottomWidth:'1px', padding : '.35rem'};

    const dataVerifierColumn = [
      { dataField: "ID", text: "ID", hidden: true },
      {
        dataField: "Name",
        text: "Name",
        headerStyle: {...headerDispStyle, width: '30%'},
        style: colDispStyle,
      },
      {
        dataField: "Phone",
        text: "Phone",
        headerStyle: {...headerDispStyle,  width: '30%'},
        style: colDispStyle,
      },
      {
        dataField: "Email",
        text: "Email",
        headerStyle: {...headerDispStyle, 'borderRightWidth':'0px', width: '30%'},
        style: {...colDispStyle, 'borderWidth':'0px 0px 1px 1px'} 
      },
      {
        dataField: 'send',
        text: '',
        headerStyle: buttonDispStyle, style: {...colDispStyleButton, textAlign: "center"},
        formatExtraData: this.state.sendCursor,
        formatter: (cellContent, row, rowIndex, cursor) => {
          return (
            <Tooltip title={`Send magic link`}>
              <MdNearMe  size='20px'
                style={{cursor}}
                onClick={() => {
                  this.setState({sendCursor: "wait"})
                  this.sendMagicLink(row)
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        dataField: 'Edit',
        text: '',
        headerStyle: buttonDispStyle, style: {...colDispStyleButton, textAlign: "center"},
        formatter: (cellContent, row) => {
          return (
            <Tooltip title={`Edit`}>
              <FaEdit size='20px'
                onClick={()=>{
                  this.setState({selectedDataVerifier: row})
                  this.toggleEditVerifierModal()
                }}
              />
            </Tooltip>
          );
        },
      },
      {
        dataField: 'Delete',
        text: '',
        headerStyle: buttonDispStyle, style: {...colDispStyleButton, textAlign: "center"},
        formatter: (cellContent, row) => {
          return (
            <Tooltip title={`Delete`}>
              <FaTrashO size='20px'
                onClick={()=>{
                  this.setState({selectedDataVerifier: row})
                  this.toggleDeleteDataVerifier()
                }}
              />
            </Tooltip>
          );
        },
      }
    ];
    const editStyle = {
        border:'1',
        borderRadius:0,
        backgroundColor:'white',
        padding:2,
        fontSize: 14 ,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth : 0, 
        borderBottomWidth: 1,
        borderBottomColor: 'white', 
        outline: 'none',
        boxShadow:'none', 
    }
    const editStyleEnable = {
        border:'1',
        borderRadius:0,
        backgroundColor:'white',
        paddingLeft:2,
        paddingTop:2,
        paddingBottom:1.8,
        fontSize: 14 ,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth : 0, 
        borderBottomWidth: .8,
        borderBottomColor: secondaryColor, 
        outline: 'none',
        boxShadow:'none',
    }
    
    const editPwdStyleEnable = {
        border:'1',
        borderRadius:0,
        backgroundColor:'white',
        paddingLeft:2,
        paddingBottom:1.2,
        paddingRight:1,
        fontSize: 14 ,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth : 0, 
        borderBottomWidth: .8,
        borderBottomColor: secondaryColor, 
        outline: 'none',
        boxShadow:'none',
        }
        const editPwdStyleDisable = {
            border:'1',
            borderRadius:0,
            backgroundColor:'white',
            paddingLeft:2,
            paddingBottom:1.2,
            paddingRight:1,
            fontSize: 14 ,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth : 0, 
            borderBottomWidth: .8,
            borderBottomColor: 'white', 
            outline: 'none',
            boxShadow:'none',
            }

            const editStyleEnable2 = {
            border:'1',
            borderRadius:0,
            backgroundColor:'white',
            paddingLeft:2,
            paddingTop:2,
            paddingBottom:2,
            fontSize: 14 ,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth : 0, 
            borderBottomWidth: 1.2,
            borderBottomColor: 'white', 
            outline: 'none',
            boxShadow:'none', 
        }
    
    return (
      <Page title="" breadcrumbs={[{ name: "Account / Team", active: true }]}>
        <Row className="d-flex justify-content-right">
          <Col md="12" sm="12" xs="12">
            <CardTitle>Account Managers</CardTitle>
            <CardText>Below are users of your account.</CardText>
          </Col>
        </Row>
        <Row className="d-flex justify-content-right">
          <Col md="12" sm="12" xs="12">
            <BootstrapTable data={jobs} striped={false} hover={true}>
              <TableHeaderColumn
                className="table-secondary"
                hidden
                isKey={true}
                autoValue={true}
                dataField="id"
                width="50"
                dataSort={true}
              >
                ID
              </TableHeaderColumn>
              <TableHeaderColumn
                className="table-secondary"
                dataField="user"
                width="30%"
                dataSort={true}
                dataFormat={activeLineByLineFormatter}
              >
                User
              </TableHeaderColumn>
              <TableHeaderColumn
                className="table-secondary"
                dataField="user"
                width="15%"
                dataSort={true}
                dataFormat={activeFormatter}
                dataAlign="center"
              >
                Owner
              </TableHeaderColumn>
              <TableHeaderColumn
                className="table-secondary"
                dataField="user"
                width="15%"
                dataSort={true}
                dataFormat={activeCheck}
                dataAlign="center"
              >
                Admin
              </TableHeaderColumn>
              <TableHeaderColumn
                className="table-secondary"
                dataField="user"
                width="15%"
                dataSort={true}
                dataFormat={activeCheck}
                dataAlign="center"
              >
                Developer
              </TableHeaderColumn>
              <TableHeaderColumn
                className="table-secondary"
                dataField="user"
                width="15%"
                dataSort={true}
                dataFormat={activeCheck}
                dataAlign="center"
              >
                Data Protection Officer
              </TableHeaderColumn>
            </BootstrapTable>
          </Col>
        </Row>

        <Row>
          <Col lg={8} md={6} sm={12} xs={12}>
            <Card className="h-100" style={{ fontSize: "14px" }}>
              <CardBody className="d-flex flex-column justify-content-start ">
                <CardTitle className="card-title">User Settings</CardTitle>
              </CardBody>
              <CardBody className="pt-0 pb-0">
                <Row>
                  <Col lg={4} md={4} sm={12} xs={12}>
                    <img
                      src={this.state.u_image}
                      disabled={this.state.edit_mode}
                      style={{
                        borderRadius: 50,
                        opacity: this.state.edit_mode ? 1 : 0.75,
                        width: "100px",
                        height: "100px",
                        border: "solid white 2px",
                        backgroundColor: "white",
                      }}
                    />
                    {this.state.edit_mode ? (
                      <span></span>
                    ) : (
                      <span
                        style={{
                          position: "absolute",
                          top: "1px",
                          left: "15px",
                          width: "100px",
                          height: "0px",
                        }}
                      >
                        <UserImageUpload />
                      </span>
                    )}
                  </Col>
                  <Col lg={8} md={8} sm={12} xs={12}>
                    <Form>
                      <FormGroup row className="mt-0 mb-0">
                        <Label
                          for="userName"
                          sm={4}
                          className="pr-0 pt-2 pb-0 "
                        >
                          Name:
                        </Label>
                        <Col sm={7} className="pr-0 pt-2">
                          <Input
                            placeholder="Name"
                            style={
                              this.state.edit_mode ? editStyle : editStyleEnable
                            }
                            disabled={this.state.edit_mode}
                            ref="u_name"
                            type="text"
                            name="u_name"
                            value={this.state.u_name}
                            onChange={this.handleChange}
                            required
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mt-0 mb-0">
                        <Label
                          for="UserEmail"
                          sm={4}
                          className="pr-0 pt-0 pb-0 "
                        >
                          Email:
                        </Label>
                        <Col sm={7} className="pr-0">
                          <Input
                            placeholder="Email"
                            style={
                              this.state.edit_mode
                                ? editStyle
                                : editStyleEnable2
                            }
                            disabled={true}
                            value={this.state.u_email}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup row className="mt-0 mb-0">
                        <Label for="userID" sm={4} className="pr-0 pt-0 pb-0 ">
                          User ID:
                        </Label>
                        <Col sm={7} className="pr-0">
                          <Input
                            placeholder="User ID"
                            style={
                              this.state.edit_mode
                                ? editStyle
                                : editStyleEnable2
                            }
                            disabled={true}
                            value={this.state.u_id}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
              <CardBody className="d-flex justify-content-end align-items-end pt-0 pb-3">
                <CardText style={{ textAlign: "right" }}>
                  {this.state.edit_mode ? (
                    <CardLink
                      disabled={!this.state.edit_mode}
                      onClick={this.handleEdit}
                      href="#"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Edit{" "}
                    </CardLink>
                  ) : (
                    <ButtonGroup>
                      <Button
                        size="ig"
                        outline
                        className="btn btn-default"
                        disabled={this.state.edit_mode}
                        onClick={this.handleCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="ig"
                        outline
                        className="btn btn-default"
                        disabled={this.state.edit_mode}
                        onClick={this.handleSave}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <Card className="h-100" style={{ fontSize: "14px" }}>
              <CardBody
                className="d-flex justify-content-start "
                style={{ flex: 0 }}
              >
                <CardTitle className="card-title">User Credentials</CardTitle>
              </CardBody>
              <CardBody>
                <Form>
                  <FormGroup row className="mt-0 mb-0">
                    <Label
                      for="password"
                      className="pr-0"
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                    >
                      Password:
                    </Label>
                    <Col sm={6} className="pr-0" lg={8} md={8} sm={12} xs={12}>
                      <Row>
                        <Col
                          lg={10}
                          md={10}
                          sm={11}
                          xs={10}
                          className="pr-0 mr-0 "
                        >
                          <Input
                            type={this.state.show ? "text" : "password"}
                            placeholder="xxxxxxxxxxxx"
                            style={
                              this.state.edit_pwd
                                ? editPwdStyleDisable
                                : editPwdStyleEnable
                            }
                            disabled={this.state.edit_pwd}
                            ref="u_pw"
                            name="u_pwd"
                            value={this.state.u_pwd}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <Col
                          lg={2}
                          md={2}
                          sm={1}
                          xs={2}
                          className="pl-0 ml-0 mt-1"
                        >
                          {!this.state.edit_pwd ? (
                            <a
                              disabled={true}
                              onClick={this.toggle}
                              className="mb-0 mt-1"
                              style={{
                                cursor: "pointer",
                                fontSize: "12px",
                                paddingLeft: "0px",
                              }}
                            >
                              {this.state.show ? <IconEyeSlash /> : <IconEye />}
                            </a>
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardBody className="d-flex justify-content-end align-items-end pt-0 pb-3">
                <CardText className="text-right">
                  {this.state.edit_pwd ? (
                    <CardLink
                      disabled={!this.state.edit_pwd}
                      onClick={this.handlePwdEdit}
                      href="#"
                      style={{ cursor: "pointer" }}
                    >
                      Change password{" "}
                    </CardLink>
                  ) : (
                    <ButtonGroup className="flex flex-row">
                      <Button
                        size="ig"
                        outline
                        className="btn btn-default"
                        disabled={this.state.edit_pwd}
                        onClick={this.handlePwdCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        size="ig"
                        outline
                        className="btn btn-default"
                        disabled={this.state.edit_pwd}
                        onClick={this.handlePwdSave}
                      >
                        Save
                      </Button>
                    </ButtonGroup>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 style={{ fontWeight: "bold" }}>
              Data Verifier Users{" "}
              <MdAddCircleOutline
                size={"25px"}
                style={{
                  cursor: "pointer",
                }} onClick={this.toggleAddVerifierModal}
              />
            </h4>
            <BootstrapTable22
              id="btPurpose"
              data={this.state.dataVerifierUsers}
              bootstrap4={true}
              hover={true}
              keyField="ID"
              columns={dataVerifierColumn}
              striped={false}
            />
          </Col>
        </Row>
        <Modal
          backdrop="static"
          isOpen={this.state.addDataVerifierModal}
          toggle={this.toggleAddVerifierModal}
        >
          <AddDataVerifierModal
            onModalClose={this.toggleAddVerifierModal}
            toggle={this.toggleAddVerifierModal}
            updateDataVerifier={this.getDataVerifier}
          />
        </Modal>
        <Modal
          backdrop="static"
          isOpen={this.state.editDataVerifierModal}
          toggle={this.toggleEditVerifierModal}
        >
          <EditDataVerifierModal
            onModalClose={this.toggleEditVerifierModal}
            toggle={this.toggleEditVerifierModal}
            updateDataVerifier={this.getDataVerifier}
            row={this.state.selectedDataVerifier}
          />
        </Modal>
        <GeneralDeleteModal
            header={`Delete Data Verifier User: ${this.state.selectedDataVerifier.Name}`}
            toggle={this.toggleDeleteDataVerifier} 
            type='secure' 
            confirmText='DELETE'
            message='You are about to delete an existing data verifier user. Please type DELETE to confirm and click OK. This action is not reversible.'
            delete={ () => this.onAfterDeleteDataVerifierUser(this.state.selectedDataVerifier)}
            toggleState={this.state.deleteDataVerifierModal}
        />
      </Page>
    );
};
};
export default ManagePage;
