import React, { useState, useEffect } from "react";
import axios from "axios";
import jsonData from "../setting/settings.json";
import { IoCheckmark, IoClose } from "react-icons/lib/io";
import { Tooltip } from '@material-ui/core';
import { store } from '../services/localStorageStore'

import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  ButtonGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";

const api = JSON.parse(JSON.stringify(jsonData)).api;

const WebhookLogPage = (props) => {
  const [webhookDelivery, setWebhookDelivery] = useState([]);
  useEffect(() => {
    const axiosConfig = {
      headers: {
        Authorization:
          "Bearer " +
          store.getData("data").Token.access_token,
      },
    };
    const url =
      api +
      "v1/organizations/" +
      store.getData("orgs").ID +
      "/webhooks/" +
      props.selectedWebhook +
      "/delivery";
    axios.get(url, axiosConfig).then((response) => {
      setWebhookDelivery(response.data.WebhookDeliveries);
    });
  }, []);
  return (
    <div className="h- w-100">
    <h6 className="sub-list"> Recent deliveries</h6>
      {webhookDelivery.map(
        ({ WebhookID, ResponseStatusCode, ID, TimeStamp, Status }, index) => (
          <CardBody
            key={index}
            className="d-flex flex-column justify-content-start "
          >
            <ButtonGroup className="d-flex flex-row justify-content-between mb-0">
              <Row>
                <Col>
                  {ResponseStatusCode >= 200 && ResponseStatusCode <= 299 && Status === "completed" ? (
                    <Tooltip title={Status}><IoCheckmark fill="green" /></Tooltip>
                  ) : (
                    <Tooltip title={Status}><IoClose fill="red" /></Tooltip>
                  )}
                </Col>
                <CardText className="card-list" id={WebhookID}>
                  {ID}
                </CardText>
                </Row>
                <Row>
                  <Col>
                    <CardText className="card-list">{new Date(TimeStamp*1000).toLocaleString('en-GB', { dateStyle: "long", timeStyle: "medium", hour12: true })}</CardText>
                  </Col>
                </Row>
            </ButtonGroup>
          </CardBody>
        )
      )}
    </div>
  );
};

export default WebhookLogPage;
