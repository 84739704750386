import React, { useEffect, useState } from "react";
import { Modal, Card, Row, Col, ModalFooter, CardBody } from "reactstrap";
import { FaAngleLeft } from "react-icons/lib/fa";
import {
    AiFillLock,
    AiFillUnlock
} from "react-icons-latest/ai";

const SignedDataAgreementModal = (
    {
        dataAgreement,
        backButtonToggle,
        toggleState,
        toggle,
        modalHeaderTitleText,
        modelHeaderDescriptionText,
        ...props
    }
) => {

    const [dataPolicy, setDataPolicy] = useState({});

    /**
     * Triggered once on component mount.
     */
    useEffect(() => {

        const {
            lawful_basis,
            data_policy: {
                data_retention_period,
                policy_URL,
                jurisdiction,
                industry_sector,
                geographic_restriction,
                storage_location
            }
        } = dataAgreement;

        let updatedLawfulBasis = lawful_basis.charAt(0).toUpperCase() + lawful_basis.slice(1);
        updatedLawfulBasis = updatedLawfulBasis.split("_").join(" ");

        setDataPolicy({
            "Lawful basis of processing": updatedLawfulBasis,
            "Data retention period": data_retention_period,
            "Policy URL": policy_URL,
            "Jurisdiction": jurisdiction,
            "Industry sector": industry_sector,
            "Geographic restriction": geographic_restriction,
            "Storage location": storage_location
        })

    }, []);


    return (
        <Modal
            backdrop="static"
            unmountOnClose={true}
            isOpen={toggleState}
            toggle={toggle}
        >


            <div className="modal-header" style={{ fontSize: "16px", backgroundColor: "#eeeeee", }}>


                <div style={{ display: "flex", justifyContent: "space-between", flexBasis: "100%", alignItems: "center" }}>

                    <FaAngleLeft size="20" className="modal-back-button" onClick={backButtonToggle} />

                    <div>

                        <h1
                            style={{
                                marginRight: "auto",
                                marginBlock: "auto",
                                overflow: "hidden",
                                width: "350px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            Data Agreement Policy
                        </h1>


                    </div>


                    <button style={{ paddingTop: "13px" }} type="button" className="close" aria-label="Close" onClick={toggle}>


                        <span aria-hidden="true">×</span>


                    </button>


                </div>

            </div>

            <div className="modal-body">

                <Card>
                    <CardBody style={{ padding: "20px" }}>
                        {
                            Object.keys(dataPolicy).map((attributeName, index) => {

                                const attributeValue = dataPolicy[attributeName];

                                const attributesLength = Object.keys(dataPolicy).length;

                                return (
                                    <Row key={index}>
                                        <Col xs="12" sm="12" md="12" lg="12" className="data-agreement-presentation-preview">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    borderBottom: index !== attributesLength - 1 ? "1px solid #e1e1e1" : "0px",
                                                    padding: "5px 0px 5px 0px"
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginBottom: "10px", fontSize: "14px" }}>{attributeName}</p>
                                                </div>
                                                <div>
                                                    <p
                                                        style={{
                                                            marginBottom: "0px",
                                                            fontSize: "14px",
                                                            maxWidth: "300px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: '-webkit-box',
                                                            lineClamp: 2,
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: 'vertical',
                                                            color: 'grey'
                                                        }}
                                                    >
                                                        {attributeValue}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                );

                            })
                        }
                    </CardBody>
                </Card>

                {
                    dataAgreement &&
                    dataAgreement.proof &&
                    <div

                        style={{
                            padding: "20px 0px 0px 0px",
                        }}

                    >
                        <Card>
                            <CardBody>
                                <div

                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}

                                >
                                    <div>Verified Data Agreement</div>
                                    <div>
                                        <AiFillLock size="20" color="green" />
                                    </div>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                        }}
                                    >Controller Decentralised Identifier</div>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: "grey",
                                        }}
                                    >{dataAgreement && dataAgreement.proof.verificationMethod}</div>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <div>
                                        <div style={{ fontSize: "14px", }}>Signature</div>
                                        <div style={{ fontSize: "14px", color: "grey", }}>{dataAgreement && dataAgreement.proof.proofValue}</div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                    </div>
                }

                {
                    dataAgreement &&
                    dataAgreement.proofChain &&
                    dataAgreement.proofChain[0] &&
                    <div

                        style={{
                            padding: "20px 0px 0px 0px",
                        }}

                    >
                        <Card>
                            <CardBody>
                                <div

                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}

                                >
                                    <div>Verified Data Agreement</div>
                                    <div>
                                        <AiFillLock size="20" color="green" />
                                    </div>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                        }}
                                    >Controller Decentralised Identifier</div>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: "grey",
                                        }}
                                    >{dataAgreement && dataAgreement.proofChain[0].verificationMethod}</div>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <div>
                                        <div style={{ fontSize: "14px", }}>Signature</div>
                                        <div style={{ fontSize: "14px", color: "grey", }}>{dataAgreement && dataAgreement.proofChain[0].proofValue}</div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                    </div>
                }

                {
                    dataAgreement &&
                    dataAgreement.proofChain &&
                    dataAgreement.proofChain[1] &&
                    <div

                        style={{
                            padding: "20px 0px 20px 0px",
                        }}

                    >
                        <Card>
                            <CardBody>
                                <div>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                        }}
                                    >Individual Decentralised Identifier</div>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            color: "grey",
                                        }}
                                    >{dataAgreement && dataAgreement.proofChain[1].verificationMethod}</div>
                                </div>
                                <div style={{ paddingTop: "10px" }}>
                                    <div>
                                        <div style={{ fontSize: "14px", }}>Signature</div>
                                        <div style={{ fontSize: "14px", color: "grey", }}>{dataAgreement && dataAgreement.proofChain[1].proofValue}</div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                    </div>
                }

            </div>

            <ModalFooter>

                <button size="ig" className="btn btn-default" onClick={() => { toggle(); }}>CLOSE</button>

            </ModalFooter>

        </Modal>
    )

}

export default SignedDataAgreementModal;
