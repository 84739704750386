import React from "react";
import Select from "react-select";
import { connect } from 'react-redux';
import { Modal, Card, CardImg, Row, Col, } from "reactstrap";

import { store } from "../services/localStorageStore";
import jsonData from "../setting/settings.json";
import Avatar from "../components/Avatar";
import DefaultOrgCoverImage from "../assets/img/bg/background_org.jpg";
import DefaultOrgLogoImage from "../assets/img/bg/background_company.jpg";
import AddCredentialModalV2 from "../components/AddCredentialModalV2";
import AddVerificationModalV2 from "../components/AddVerificationModalV2";
import DataAgreementPersonalDataTable from "../components/DataAgreementPersonalDataTable";
import DataAgreementPolicyConfiguration from "../components/DataAgreementPolicyConfiguration";
import Loader from "../components/Loader";

import {
  resetDataAgreementState,
  updateDataControllerNameInDataAgreementState,
  updateJurisdictionInDataAgreementState,
  updateIndustrySectorInDataAgreementState,
  updateDataRetentionPeriodInDataAgreementState,
  updatePolicyUrlInDataAgreementState,
  updateDataControllerUrlInDataAgreement,
  updateLawfulBasisInDataAgreementState,
  updateUsagePurposeInDataAgreementState,
  updateUsagePurposeDescriptionInDataAgreementState,
  updateMethodOfUseInDataAgreementState,
  replaceAllPersonalData,
  initDataSourceConfiguration,
  updateDataSourceConfiguration,
  toggleConfigureDataSourcePanel,
  initDataUsingServiceConfiguration,
  toggleConfigureDataUsingServicePanel,
} from '../actions/dataAgreementActions';

import {
  showLoader,
  hideLoader,
} from '../actions/loaderActions'

import {
  fetchGlobalDataPolicyConfiguration,
  fetchAllLawfulBasis,
  createDataAgreementInCloudagent,
  udpateDataAgreementInCloudagent
} from "../services/index"

class DataAgreementCrudModalV2 extends React.Component {

  constructor(props) {


    super(props);


    this.state = {
      usagePurpose: "",
      usagePurposeDescription: "",
      orgDescription: "",
      dataExchangeModes: [],
      selectedDataExchangeMode: "",
      lawfulBasisOfProcessing: [],
      orgCoverImageURL: "",
      orgLogoURL: "",
    };


    // Bind "this" to component methods.
    this.handleChangeDADetails = this.handleChangeDADetails.bind(this);
  }

  componentDidMount() {

    let api = JSON.parse(JSON.stringify(jsonData)).api;

    // Construct organisation cover image url. (if not available then use default image)
    if (
      store.getData("orgs") != null &&
      store.getData("orgs").CoverImageID != 0
    ) {

      this.state.orgCoverImageURL = api + "v1/organizations/" + (store.getData("data") != null ? store.getData("data").User.ID : "") + "/image/" + (store.getData("orgs") != null ? store.getData("orgs").CoverImageID : "") + "/web";

    } else {

      this.state.orgCoverImageURL = DefaultOrgCoverImage;

    }

    // Construct organisation logo image url. (if not available then use default image.)
    if (store.getData("orgs") != null && store.getData("orgs").LogoImageID != 0) {

      this.state.orgLogoURL = api + "v1/organizations/" + (store.getData("data") != null ? store.getData("data").User.ID : "") + "/image/" + (store.getData("orgs") != null ? store.getData("orgs").LogoImageID : "") + "/web";

    } else {

      this.state.orgLogoURL = DefaultOrgLogoImage;

    }

    // Data Exchange modes available (method_of_use)

    let dataExchangeModes = [
      {
        "value": 0,
        "label": "Data Source"
      },
      {
        "value": 1,
        "label": "Data Using Service"
      }
    ];

    this.setState({ dataExchangeModes: dataExchangeModes });

    // Default data exchange mode - Data Source
    this.setState({ selectedDataExchangeMode: dataExchangeModes.filter((purposeType) => purposeType.value === 0)[0] });

    // Fetch all lawful basis and populate the drop down in create data agreement panel.
    fetchAllLawfulBasis().then((response) => {

      const lawfulBasisOfProcessing = [];

      response.data.forEach((lawUse) => {

        lawfulBasisOfProcessing.push({ label: lawUse.Str, value: lawUse.ID });

      });

      this.setState({ lawfulBasisOfProcessing: lawfulBasisOfProcessing });


    });


    const { dataAgreementState } = this.props;
    const { dataPolicy: { policyUrl, industrySector, jurisdiction, dataRetentionPeriod } } = dataAgreementState;

    // Fetch global data policy configuration for the current organisation.
    fetchGlobalDataPolicyConfiguration().then((response) => {

      const data = response.data;

      if (industrySector.trim().length === 0) {

        this.props.updateIndustrySectorInDataAgreementState(data.Type.Type);

      }



      if (data.PolicyURL.trim().length > 0) {

        if (policyUrl.trim().length === 0) {

          this.props.updatePolicyUrlInDataAgreementState(data.PolicyURL);

        }



        this.props.updateDataControllerUrlInDataAgreement(data.PolicyURL);

      }


      if (dataRetentionPeriod === 0) {

        // Update data retention period in redux state (after converting to years)
        if (data.DataRetention.RetentionPeriod >= 31536000) {

          this.props.updateDataRetentionPeriodInDataAgreementState(data.DataRetention.RetentionPeriod / 31536000);

        } else {

          // Default to 3 years.
          this.props.updateDataRetentionPeriodInDataAgreementState(3);

        }

      }





    }).catch((err) => {

      console.log(err);

    });


    // Organisation name in data agreement redux state.
    this.props.updateDataControllerNameInDataAgreementState(store.getData("orgs").Name);

    if (jurisdiction.trim().length === 0) {

      // Update jurisdiction in data agreement redux state (Organisation location)
      this.props.updateJurisdictionInDataAgreementState(store.getData("orgs").Location);

    }


    this.setState({ orgDescription: store.getData("orgs").Description });

  }

  componentWillUnmount() {

    // Clear data agreement state.
    this.props.resetDataAgreementState();

  }


  /**
   * fn handleChangeDataExchangeMode
   *
   * Triggered when a data exchange mode is selected.
   *
   * @param {*} selectedDataExchangeMode
   */
  handleChangeDataExchangeMode = (selectedDataExchangeMode) => {

    const { methodOfUse } = this.props.dataAgreementState;

    const updatedMethodOfUse = selectedDataExchangeMode.label.toLowerCase().split(" ").join("-");

    if (methodOfUse != updatedMethodOfUse) {

      // Reset data source configuration
      this.props.initDataSourceConfiguration();

      this.props.updateMethodOfUseInDataAgreementState(updatedMethodOfUse);

      // Iterate through personal data and add restrictions if data exchange mode is DUS
      if (selectedDataExchangeMode.value == 1) {

        const pd = this.props.dataAgreementState.personalData;
        const updatedPd = pd.map((value, index) => { return { ...value, restrictions: [{ "schema_id": "", "cred_def_id": "" }] } });

        this.props.replaceAllPersonalData(updatedPd);

      } else {
        // Remove restrictions if data exchange mode is DS

        const pd = this.props.dataAgreementState.personalData;
        const updatedPd = pd.map((value, index) => { const { ["restrictions"]: toBeRemovedRestrictions, ...rest } = value; return rest });

        this.props.replaceAllPersonalData(updatedPd);
      }
    }



  };

  /**
   * fn handleDataExchangeModeConfigureButton
   *
   * Triggered when data exchange configure link is clicked
   */
  handleDataExchangeModeConfigureButton = () => {

    const { dataAgreementPanel } = this.props;
    const { mode } = dataAgreementPanel;

    if (mode === "CREATE" || mode === "UPDATE") {

      const selectedDataExchangeMode = this.state.dataExchangeModes.filter((value, index) => value.label.toLowerCase().trim().split(" ").join("-") === this.props.dataAgreementState.methodOfUse)[0];

      if (selectedDataExchangeMode.value == 0) {
        // DS

        // Toggle data source configuration panel.
        this.props.toggleConfigureDataSourcePanel();

      } else {
        // DUS

        // Toggle data using service configuration panel.
        this.props.toggleConfigureDataUsingServicePanel();
      }

    }


  };


  /**
   * Triggered when lawful basis is selected from drop down.
   * 
   * @param {*} selectedOption 
   */
  handleChangeLawfulBasis = (selectedOption) => {

    this.props.updateLawfulBasisInDataAgreementState(selectedOption.label.toLowerCase().split(" ").join("_"));

  };

  /**
   * Triggered when usage purpose or description is updated.
   * 
   * @param {*} event 
   */
  handleChangeDADetails(event) {
    if (event.target.name === "usagePurpose") {

      this.props.updateUsagePurposeInDataAgreementState(
        event.target.value
      )

    } else if (event.target.name === "usagePurposeDescription") {
      this.props.updateUsagePurposeDescriptionInDataAgreementState(
        event.target.value
      )
    }
  }

  /**
   * Triggered when "PUBLISH" button is clicked.
   */
  handlePublishBtnClick = () => {

    const { dataAgreementState, configureDataSource } = this.props;
    const { onModalClose } = this.props;
    const { showLoader, hideLoader } = this.props;



    switch (this.props.dataAgreementPanel.mode) {

      case "CREATE":

        showLoader("DataAgreementCrudModalV2");

        createDataAgreementInCloudagent(dataAgreementState, configureDataSource, false).then((response) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Reload data agreements in the table.


        }).catch((err) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Show toast message.
          console.log(err.message);

        })

        break;

      case "UPDATE":

        showLoader("DataAgreementCrudModalV2");

        udpateDataAgreementInCloudagent(dataAgreementState, configureDataSource, false).then((response) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Reload data agreements in the table.


        }).catch((err) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Show toast message.
          console.log(err.message);

        })

        break;


    }



  }

  /**
   * Triggered when "SAVE" button is clicked.
   * 
   */
  handleSaveBtnClick = () => {

    const { dataAgreementState, configureDataSource } = this.props;
    const { onModalClose } = this.props;
    const { showLoader, hideLoader } = this.props;

    switch (this.props.dataAgreementPanel.mode) {


      case "CREATE":

        showLoader("DataAgreementCrudModalV2");

        createDataAgreementInCloudagent(dataAgreementState, configureDataSource, true).then((response) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Reload data agreements in the table.


        }).catch((err) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Show toast message.
          console.log(err.message);

        });

        break;


      case "UPDATE":

        showLoader("DataAgreementCrudModalV2");

        udpateDataAgreementInCloudagent(dataAgreementState, configureDataSource, true).then((response) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Reload data agreements in the table.


        }).catch((err) => {

          hideLoader("DataAgreementCrudModalV2");

          // FIXME: Update this to redux state ?
          onModalClose();

          // TODO: Show toast message.
          console.log(err.message);

        });

        break;


    }



  };


  render() {

    const { onModalClose, dataAgreementState } = this.props;

    const customStylesDD = {
      control: (base, state) => ({
        ...base,
        "&:hover": { borderColor: "gray" }, // border style on hover
        border: "1px solid lightgray", // default border color
        boxShadow: "none", // no box-shadow
        cursor: state.selectProps.cursorDisabled ? "not-allowed" : "pointer",
      }),
    };

    const headerStyle = {
      fontSize: "16px",
      backgroundColor: "#eeeeee",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    };

    const modalHeaderTitleStyle = {
      marginRight: "auto",
      marginBlock: " auto",
      display: "flex"
    };

    const modalHeaderTitleSpanStyle = {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "inline-block",
      maxWidth: "240px",
      marginLeft: "5px"
    };

    const modalHeaderDataAgreementIdStyle = {
      fontStyle: "italic",
      color: "gray",
    };

    const textStyle = {
      color: "#212529",
      fontSize: "16px",
      paddingBottom: 1,
    };

    const inputStyle = {
      height: "24px",
      color: "#495057",
      border: "1",
      borderWidth: 0,
      padding: 0,
      paddingLeft: "2px",
      paddingBottom: 0,
      borderRadius: 0,
      fontSize: "16px",
      backgroundColor: "#ffff",
      borderBottomWidth: 1.2,
      borderBottomColor: "lightgray", //'#DFE0E1',
    };

    const myStyle = {
      border: "0px",
      backgroundColor: "#ffff",
      padding: "0px",
      margin: "0px",
    };

    const editStyle = {
      padding: 0,
      fontSize: 20,
      fontBold: true,
      fontWeight: 800,
      backgroundColor: "#ffff",
      borderWidth: 1,
      borderRadius: 0,
      borderColor: "#ffff",
      borderBottomStyle: "solid",
      borderBottomColor: "#ffff",
      height: "wrap",
    };

    const editStyleL = {
      padding: 0,
      fontSize: 14,
      fontWeight: 400,
      color: "#6C757D",
      backgroundColor: "#ffff",
      borderWidth: 1,
      borderRadius: 0,
      borderColor: "#ffff",
      borderBottomStyle: "solid",
      borderBottomColor: "#ffff",
    };

    // Conditional modal header title based on crud mode.
    let modalHeaderTitle = "";

    // Conditional modal header data agreement id.
    let modalHeaderDataAgreementId = "";

    switch (this.props.dataAgreementPanel.mode) {

      case "CREATE":

        modalHeaderTitle = <h1 style={modalHeaderTitleStyle}> Add Data Agreement </h1>;

        break;

      case "UPDATE":

        modalHeaderTitle = <h1 style={modalHeaderTitleStyle}> Edit Data Agreement: <span className="dataagreement-modal-header-title-span" style={modalHeaderTitleSpanStyle}>{dataAgreementState.usagePurpose}</span></h1>;

        modalHeaderDataAgreementId = <p style={modalHeaderDataAgreementIdStyle}>{dataAgreementState.templateId}</p>;

        break;

      case "READ":

        modalHeaderTitle = <h1 style={modalHeaderTitleStyle}> View Data Agreement: <span className="dataagreement-modal-header-title-span" style={modalHeaderTitleSpanStyle}>{dataAgreementState.usagePurpose}</span></h1>;

        modalHeaderDataAgreementId = <p style={modalHeaderDataAgreementIdStyle}>{dataAgreementState.templateId}</p>;

        break;
    }

    return (



      <div className="modal-content">


        <div>


          <div className="modal-header" style={headerStyle}>


            {modalHeaderTitle}


            <button type="button" className="close" aria-label="Close" onClick={onModalClose} >
              <span aria-hidden="true">×</span>
            </button>

            {modalHeaderDataAgreementId}

          </div>


        </div>


        <div className="modal-body-without-top-padding" style={{ paddingTop: "0px" }}>


          <Row>


            <Card className="flex-row" style={myStyle}>


              <CardImg top src={this.state.orgCoverImageURL} style={{ height: "200px", padding: "0", marginBottom: "20px" }} />


            </Card>


          </Row>


          <Row>


            <Col lg={3} md={6} sm={12} xs={12} className="mb-3" style={{ height: "20px" }}>


              <Card className="flex-row" style={myStyle}>


                <Avatar src={this.state.orgLogoURL} style={{ position: "absolute", opacity: 1, left: "20px", top: "-95px", width: "130px", height: "130px", border: "solid white 6px", backgroundColor: "white", }} />


              </Card>


            </Col>


          </Row>


          <Col>


            <Row lg={6} md={6} sm={12} xs={12} style={{ marginTop: "10px" }}>


              <h2 style={editStyle}> {this.props.dataAgreementState.dataControllerName} </h2>


            </Row>


            <Row style={{ marginBottom: "10px" }}>


              <p style={editStyleL}> {this.props.dataAgreementState.jurisdiction} </p>


            </Row>


            <Row style={{ marginBottom: "10px" }}>


              <label style={{ ...textStyle, flexBasis: "100%" }}> Overview </label>


              <p style={editStyleL}> {this.state.orgDescription} </p>


            </Row>


          </Col>


          <div className="form">


            <div className="form-group" key="usage_purpose">


              <label style={textStyle}>
                {" "}
                Usage Purpose<span className="required-field">*</span>{" "}
              </label>


              <input disabled={this.props.dataAgreementPanel.mode === "READ"} className="w-100 pt-0" placeholder="E.g. Marketing and campaign (minimum 3 characters)" ref="usagePurpose" type="text" style={{ ...inputStyle, cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }} name="usagePurpose" value={this.props.dataAgreementState.usagePurpose} onChange={this.handleChangeDADetails} autoComplete="off" />


            </div>

            <div className="form-group" key="template_version">


              <label style={{ ...textStyle }}>
                {" "}
                Version<span className="required-field">*</span>{" "}
              </label>


              <input className="w-100 pt-0" placeholder="1.0.0" ref="usagePurpose" type="text" style={{ ...inputStyle, cursor: "not-allowed" }} name="usagePurpose" value={this.props.dataAgreementState.templateVersion + ".0"} autoComplete="off" disabled />


            </div>


            <div className="form-group data-exchange-toggle" key="data-exchange-toggle" >


              <label style={textStyle}> Data Exchange </label>


              <div
                className="data-exchange-toggle-btn"
                style={{
                  cursor: this.props.dataAgreementPanel.mode !== "CREATE" || (this.props.fromCredentialsPage !== undefined || this.props.fromVerificationsPage !== undefined) ? "not-allowed" : "pointer"
                }}
              >


                <Select

                  isDisabled={this.props.dataAgreementPanel.mode !== "CREATE" || (this.props.fromCredentialsPage !== undefined || this.props.fromVerificationsPage !== undefined)}

                  isSearchable={false}

                  isMulti={false}

                  options={this.state.dataExchangeModes}

                  value={this.state.dataExchangeModes.filter((value, index) => value.label.toLowerCase().trim().split(" ").join("-") === this.props.dataAgreementState.methodOfUse)[0]}

                  styles={customStylesDD}

                  onChange={this.handleChangeDataExchangeMode}

                  cursorDisabled={this.props.dataAgreementPanel.mode !== "CREATE" || (this.props.fromCredentialsPage !== undefined || this.props.fromVerificationsPage !== undefined)}

                />

              </div>


            </div>


            <div className="data-exchange-configure-container">


              <span
                className={this.props.dataAgreementPanel.mode === "READ" ? "data-exchange-configure-btn-disabled" : "data-exchange-configure-btn"}
                onClick={this.handleDataExchangeModeConfigureButton}
                style={{
                  fontSize: "14px",
                  textDecoration: "underline",
                  cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "pointer"
                }}
              >
                (Choose existing schemas)
              </span>


            </div>

            <div className="mt-3 form-group" key="Description">


              <label style={textStyle}>
                {" "}
                Description<span className="required-field">*</span>{" "}
              </label>


              <textarea disabled={this.props.dataAgreementPanel.mode === "READ"} className="w-100 pt-0" placeholder="Breif description about the usage of data (3-500 characters)" ref="usagePurposeDescription" type="text" style={{ ...inputStyle, height: "120px", cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }} name="usagePurposeDescription" value={this.props.dataAgreementState.usagePurposeDescription} onChange={this.handleChangeDADetails} rows="5" cols="25" maxLength="500" />


            </div>


            <div className="form-group" key="LawfulUsage">


              <label style={textStyle}>
                {" "}
                Lawful Basis Of Processing
                <span className="required-field">*</span>{" "}
              </label>


              <div style={{ cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "pointer" }} >


                <Select isDisabled={this.props.dataAgreementPanel.mode === "READ"} placeholder="Select a basis of processing" isSearchable={false} isMulti={false} options={this.state.lawfulBasisOfProcessing} value={this.state.lawfulBasisOfProcessing.filter((value, index) => value.label.toLowerCase().trim().split(" ").join("_") === this.props.dataAgreementState.lawfulBasis)[0]} styles={customStylesDD} onChange={this.handleChangeLawfulBasis} />


              </div>


            </div>


            <DataAgreementPolicyConfiguration />


            <DataAgreementPersonalDataTable />


          </div>


        </div>

        <div className="modal-footer data-agreement-modal-footer">


          <button

            size="ig"

            className="btn btn-default"

            disabled={!this.props.dataAgreementState.isValid || this.props.dataAgreementPanel.mode === "READ"}

            style={{ cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : this.props.dataAgreementState.isValid ? "pointer" : "not-allowed" }}

            onClick={() => this.handlePublishBtnClick()}
          >
            Publish
          </button>


          <button

            size="ig"

            className="btn btn-default"

            disabled={!this.props.dataAgreementState.isValid || this.props.dataAgreementPanel.mode === "READ" || this.props.fromCredentialsPage !== undefined || this.props.fromVerificationsPage !== undefined}

            style={{ cursor: (this.props.dataAgreementPanel.mode === "READ" || this.props.fromCredentialsPage !== undefined || this.props.fromVerificationsPage !== undefined) ? "not-allowed" : this.props.dataAgreementState.isValid ? "pointer" : "not-allowed" }}

            onClick={() => this.handleSaveBtnClick()}
          >
            Save
          </button>


        </div>


        <Modal backdrop="static" unmountOnClose={false} isOpen={this.props.configureDataSource.openConfigureDataSourcePanel} backdropClassName="data-agreement-credentials-modal-backdrop">


          <Loader loaderTag={"AddCredentialModalV2"} />


          <AddCredentialModalV2 />


        </Modal>

        <Modal backdrop="static" unmountOnClose={false} isOpen={this.props.configureDataUsingService.openConfigureDataUsingServicePanel} backdropClassName="data-agreement-credentials-modal-backdrop">


          <AddVerificationModalV2 />


        </Modal>


      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    dataAgreementState: state.DataAgreementStateUpdate,
    configureDataSource: state.ConfigureDataSource,
    configureDataUsingService: state.ConfigureDataUsingService,
    dataAgreementPanel: state.DataAgreementPanel
  };
}
export default connect(
  mapStateToProps,
  {
    resetDataAgreementState,
    updateDataControllerNameInDataAgreementState,
    updateJurisdictionInDataAgreementState,
    updateIndustrySectorInDataAgreementState,
    updateDataRetentionPeriodInDataAgreementState,
    updatePolicyUrlInDataAgreementState,
    updateDataControllerUrlInDataAgreement,
    updateLawfulBasisInDataAgreementState,
    updateUsagePurposeInDataAgreementState,
    updateUsagePurposeDescriptionInDataAgreementState,
    updateMethodOfUseInDataAgreementState,
    replaceAllPersonalData,
    toggleConfigureDataSourcePanel,
    initDataSourceConfiguration,
    updateDataSourceConfiguration,
    initDataUsingServiceConfiguration,
    toggleConfigureDataUsingServicePanel,
    showLoader,
    hideLoader
  }
)(DataAgreementCrudModalV2);