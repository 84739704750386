import React, { useEffect, useState } from "react";
import { Modal, Card, CardImg, Row, Col, ModalFooter, CardBody } from "reactstrap";

import Avatar from "../components/Avatar";

import {
    fetchOrgCoverImageFromLocalStorage,
    fetchOrgLogoImageFromLocalStorage,
    fetchOrgsFromLocalStorage
} from "../utils/localStorageUtils";

import SignedDataAgreementModal from "../components/SignedDataAgreementModal";

import {
    AiOutlineEye,
    AiOutlineEyeInvisible,
} from "react-icons-latest/ai";
import {
    MdOutlineKeyboardArrowRight
} from "react-icons-latest/md";

const ViewVerifiedDataAndDataAgreementModal = (
    {
        presentationExchangeRecord,
        toggleState,
        toggle,
        modalHeaderTitleText,
        modelHeaderDescriptionText,
        ...props
    }
) => {

    const [orgLogoURL, setOrgLogoURL] = useState('');
    const [orgOverview, setOrgOverview] = useState('');
    const [orgCoverImageURL, setOrgCoverImageURL] = useState('');
    const [orgName, setOrgName] = useState('');
    const [orgLocation, setOrgLocation] = useState('');
    const [blurPersonalData, setBlurPersonalData] = useState(true);

    const [showSignedDataAgreementModal, setShowSignedDataAgreementModal] = useState(false);

    /**
     * Triggered once on component mount.
     */
    useEffect(() => {

        setOrgCoverImageURL(fetchOrgCoverImageFromLocalStorage());
        setOrgLogoURL(fetchOrgLogoImageFromLocalStorage());

        const orgs = fetchOrgsFromLocalStorage();
        if (orgs) {

            const { Name, Location, Description } = orgs;

            setOrgName(Name);

            setOrgLocation(Location);

            setOrgOverview(Description);

        } else {

            console.log("No organisation found.");

            // Providing default values.

            provideDefaultsForOrg();

        }

    }, []);

    /**
     * Toggle signed data agreement modal.
     */
    const toggleSignDataAgreementModal = () => {
        setShowSignedDataAgreementModal(!showSignedDataAgreementModal);
    }

    /**
     * Provide default values for org name, location and overview.
     */
    const provideDefaultsForOrg = () => {

        setOrgName("Happy Shopping Inc.");

        setOrgLocation("Stockholm, SE");

        setOrgOverview("Sample description.");

    }

    const presentationData = () => {

        if (presentationExchangeRecord) {

            const presentation = presentationExchangeRecord.presentation.requested_proof;

            const { revealed_attrs, self_attested_attrs } = presentation;

            const presentationRequest = presentationExchangeRecord.presentation_request.requested_attributes;

            // Populate presentation request attributes with verified (or presented) values.
            Object.keys(presentationRequest).forEach(key => {

                const attribute = presentationRequest[key];

                if (key in revealed_attrs) {

                    // Indy based credential.

                    attribute["presentation"] = revealed_attrs[key].raw;

                } else if (key in self_attested_attrs) {

                    // Self attested credential.

                    attribute["presentation"] = self_attested_attrs[key];

                } else {

                    // No value for this attribute. (Could be a non-revealed attribute as well.)

                    attribute["presentation"] = "";
                }

            });


            return (
                <Card>
                    <CardBody style={{ padding: "15px" }}>
                        {
                            Object.keys(presentationRequest).map((key, index) => {

                                const attribute = presentationRequest[key];

                                const { name, presentation } = attribute;

                                const attributesLength = Object.keys(presentationRequest).length;

                                return (
                                    <Row key={index}>
                                        <Col xs="12" sm="12" md="12" lg="12" className="data-agreement-presentation-preview">
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    borderBottom: index !== attributesLength - 1 ? "1px solid #e1e1e1" : "0px",
                                                    padding: "5px 0px 5px 0px"
                                                }}
                                            >
                                                <div>
                                                    <p style={{ marginBottom: "0px", fontSize: "14px" }}>{name}</p>
                                                </div>
                                                <div>
                                                    <p

                                                        className={blurPersonalData ? "blurry-text" : "non-blurry-text"}
                                                        style={{
                                                            marginBottom: "0px",
                                                            fontSize: "14px",
                                                            maxWidth: "300px",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: '-webkit-box',
                                                            lineClamp: 2,
                                                            WebkitLineClamp: 2,
                                                            WebkitBoxOrient: 'vertical',
                                                        }}
                                                    >
                                                        {presentation}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                );

                            })
                        }
                    </CardBody>

                </Card>
            )

        } else {

            return (
                <Card>Data not available</Card>
            )

        }


    }


    const editStyle = {
        padding: 0,
        fontSize: 20,
        fontBold: true,
        fontWeight: 800,
        backgroundColor: "#ffff",
        borderWidth: 1,
        borderRadius: 0,
        borderColor: "#ffff",
        borderBottomStyle: "solid",
        borderBottomColor: "#ffff",
        height: "wrap",
    };

    const editStyleL = {
        padding: 0,
        fontSize: 14,
        fontWeight: 400,
        color: "#6C757D",
        backgroundColor: "#ffff",
        borderWidth: 1,
        borderRadius: 0,
        borderColor: "#ffff",
        borderBottomStyle: "solid",
        borderBottomColor: "#ffff",
    };


    return (
        <Modal
            backdrop="static"
            unmountOnClose={true}
            isOpen={toggleState}
            toggle={toggle}
        >


            <div className="modal-header" style={{ fontSize: "16px", backgroundColor: "#eeeeee", }}>


                <div style={{ display: "flex", justifyContent: "space-between", flexBasis: "100%", alignItems: "center" }}>

                    <div>

                        <h1
                            style={{
                                marginRight: "auto",
                                marginBlock: "auto",
                                overflow: "hidden",
                                width: "400px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            View Exchange Record: {modalHeaderTitleText}
                        </h1>


                    </div>


                    <button type="button" className="close" aria-label="Close" onClick={toggle}>


                        <span aria-hidden="true">×</span>


                    </button>


                </div>


                {modelHeaderDescriptionText && <p style={{ fontStyle: "italic", color: "gray", }}>{modelHeaderDescriptionText}</p>}


            </div>

            <div className="modal-body-without-top-padding" style={{ paddingTop: "0px" }}>

                <Row>

                    <Card
                        className="flex-row"
                        style={{
                            border: "0px",
                            backgroundColor: "#ffff",
                            padding: "0px",
                            margin: "0px",
                        }}
                    >

                        <CardImg
                            top
                            src={orgCoverImageURL}
                            style={{
                                height: "200px",
                                padding: "0",
                                marginBottom: "20px"
                            }}
                        />

                        <div

                            style={{
                                position: "absolute",
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",
                                justifyContent: "center",
                                display: "flex",
                                alignItems: "center",
                                background: "#000000bf",
                                right: "10px",
                                top: "20px",
                                cursor: "pointer"
                            }}

                            onClick={() => {
                                setBlurPersonalData(!blurPersonalData);
                            }}

                        >
                            {blurPersonalData ?
                                (<AiOutlineEyeInvisible size="25px" color="white" />) :
                                (<AiOutlineEye size="25px" color="white" />)}
                        </div>

                    </Card>

                </Row>

                <Row>

                    <Col lg={3} md={6} sm={12} xs={12} className="mb-3" style={{ height: "20px" }}>

                        <Card
                            className="flex-row"
                            style={{
                                border: "0px",
                                backgroundColor: "#ffff",
                                padding: "0px",
                                margin: "0px",
                            }}
                        >

                            <Avatar src={orgLogoURL} style={{ position: "absolute", opacity: 1, left: "20px", top: "-95px", width: "130px", height: "130px", border: "solid white 6px", backgroundColor: "white", }} />

                        </Card>

                    </Col>

                </Row>


                <Col>


                    <Row lg={6} md={6} sm={12} xs={12} style={{ marginTop: "10px" }}>


                        <h2 style={editStyle}> {orgName} </h2>


                    </Row>


                    <Row style={{ marginBottom: "10px" }}>


                        <p style={editStyleL}> {orgLocation} </p>


                    </Row>


                    <Row style={{ marginBottom: "10px" }}>


                        <label style={{ color: "#212529", fontSize: "16px", paddingBottom: 1, flexBasis: "100%" }}> Overview </label>


                        <p style={editStyleL}> {orgOverview} </p>


                    </Row>


                </Col>

                <div>
                    <div>
                        <p
                            style={{
                                textTransform: "uppercase",
                                color: "grey",
                                fontSize: "16px",
                                letterSpacing: "2px",
                                maxWidth: "446px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden"
                            }}
                        >
                            {modalHeaderTitleText}
                        </p>
                    </div>
                    {presentationData()}
                </div>

                <div

                    style={{
                        padding: "40px 0px 20px 0px",
                    }}

                >
                    <Card
                        style={{ cursor: "pointer" }}
                        onClick={toggleSignDataAgreementModal}
                    >
                        <CardBody
                            style={{
                                padding: "10px 15px 10px 15px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between"
                            }}>
                            <div style={{ color: "grey" }}>
                                Data Agreement Policy
                            </div>
                            <div style={{ display: "flex" }}>
                                <MdOutlineKeyboardArrowRight size="20" />
                            </div>
                        </CardBody>
                    </Card>
                </div>


            </div>

            <ModalFooter>

                <button size="ig" className="btn btn-default" onClick={() => { toggle(); }}>CLOSE</button>

            </ModalFooter>

            {
                presentationExchangeRecord &&
                <SignedDataAgreementModal

                    dataAgreement={presentationExchangeRecord.data_agreement}
                    backButtonToggle={toggleSignDataAgreementModal}
                    toggleState={showSignedDataAgreementModal}
                    toggle={() => {

                        // Toggle view verified data and data agreement modal.
                        toggle();

                        // Toggle signed data agreement modal.
                        toggleSignDataAgreementModal();
                    }}
                    modalHeaderTitleText={modalHeaderTitleText}
                    modelHeaderDescriptionText={modelHeaderDescriptionText}

                />
            }

        </Modal>
    )

}

export default ViewVerifiedDataAndDataAgreementModal;
