import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from "reactstrap";

import { MdAddCircleOutline } from "react-icons/lib/md";

import DataAgreementPersonalDataRestriction from "../components/DataAgreementPersonalDataRestriction";
import DataAgreementPersonalDataTableItem from "../components/DataAgreementPersonalDataTableItem";
import {
    addPersonalDataInDataAgreementState,
} from "../actions/dataAgreementActions";

class DataAgreementPersonalDataTable extends Component {

    constructor(props) {


        super(props);

        this.state = {
            newAttributeIndex: null,
            restrictionToggle: false,

        }

        // Bind class methods.
        this.addAttributeField = this.addAttributeField.bind(this);
        this.triggerToggleRestriction = this.triggerToggleRestriction.bind(this);


    }

    componentDidMount() { }

    componentWillUnmount() { }

    /**
     * Triggered when the user clicks the add attribute button.
     */
    addAttributeField = () => {

        // Empty personal data.
        let newPd = {
            attributeName: "",
            attributeDescription: "",
        }

        // Data agreement state from redux.
        let { dataAgreementState: daState } = this.props;

        // Personal data from redux.
        let { personalData: pd } = daState;

        // If method of use is data-using-service
        // then add the new personal data with restrictions.
        if (daState.methodOfUse === "data-using-service") {
            newPd["restrictions"] = [
                {
                    "schema_id": "",
                    "cred_def_id": "",
                }
            ];
        }

        // Index of new personal data.
        this.setState({ newAttributeIndex: pd.length });

        // Add new personal data to the data agreement.
        this.props.addPersonalDataInDataAgreementState(newPd);

    }

    /**
     * Triggered when the user clicks restriction button.
     */
    triggerToggleRestriction = () => {

        if (this.props.dataAgreementState.methodOfUse === "data-using-service") {

            this.setState({
                restrictionToggle: !this.state.restrictionToggle
            });
        }
    }


    render() {

        const { personalDataTableLabel = null } = this.props;

        const textStyle = { color: "#212529", fontSize: "16px", paddingBottom: 1, };

        return (
            <div className="form-group" key="Credential-Name">


                <label style={textStyle}>
                    Data Attributes<span className="required-field">*</span>
                </label>


                <MdAddCircleOutline size={"20px"} style={{ cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : !this.props.configureDataSource.isExisting ? "pointer" : "not-allowed", marginLeft: "5px", }} onClick={this.props.dataAgreementPanel.mode === "READ" ? () => { } : !this.props.configureDataSource.isExisting ? this.addAttributeField : () => { }} />


                {personalDataTableLabel && (<label>{personalDataTableLabel}</label>)}


                {this.props.dataAgreementState.personalData.map((pd, index) => (

                    <DataAgreementPersonalDataTableItem pd={pd} index={index} key={index} toggleRestrictionCallback={this.triggerToggleRestriction} newAttributeIndex={this.state.newAttributeIndex ? this.state.newAttributeIndex : this.props.newAttributeIndex} addAttributeField={this.addAttributeField}/>

                ))}


                {this.state.restrictionToggle && (


                    <Modal backdrop="static" isOpen={this.state.restrictionToggle} toggle={this.triggerToggleRestriction} backdropClassName="data-agreement-credentials-modal-backdrop">


                        <DataAgreementPersonalDataRestriction backButtonCallback={this.triggerToggleRestriction} />


                    </Modal>


                )}


            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dataAgreementState: state.DataAgreementStateUpdate,
        configureDataSource: state.ConfigureDataSource,
        dataAgreementPanel: state.DataAgreementPanel
    };
}
export default connect(
    mapStateToProps,
    {
        addPersonalDataInDataAgreementState,
    }
)(DataAgreementPersonalDataTable);