import React, { Component } from "react";
import { Button, Form, FormGroup, Col, Input, Label } from "reactstrap";
import SliderWidget from "../Widget/SliderWidget";

export default class PricingSimulator extends Component {
  constructor(props) {
    super(props);
    this.handleNumUsersChange = this.handleNumUsersChange.bind(this);
    this.handleUserCommitmentChange = this.handleUserCommitmentChange.bind(
      this
    );
    this.handleTimeCommitmentChange = this.handleTimeCommitmentChange.bind(
      this
    );
    this.reset = this.reset.bind(this);

    this.userCommitmentSliderValues = this.createSliderMarks(
      props.userCommitmentValues
    );

    this.timeCommitmentSliderValues = this.createSliderMarks(
      props.timeCommitmentDiscounts.map(timeOption => timeOption.displayOption)
    );

    this.defaultReservationOption = 1; // Slider Position
    this.defaultUserCommitmentOption = 1; // Slider Position
    // }
    this.state = {
      numUsers: "5000",
      userCommitment: this.defaultUserCommitmentOption,
      timeCommitment: this.defaultReservationOption,
      pricePerUser: 0,
      monthlyFee: 0,
      numUsersValidationState: null
    }
  }

  componentDidMount() {
    this.calculatePrice();
  }

  handleNumUsersChange(e) {
    this.setState(
      {
        numUsers: e.target.value,
        numUsersValidationState: null
      },
      () => this.calculatePrice()
    );
  }

  handleUserCommitmentChange(value) {
    this.setState(
      {
        userCommitment: value
      },
      () => this.calculatePrice()
    );
  }

  handleTimeCommitmentChange(value) {
    this.setState({ timeCommitment: value }, () => this.calculatePrice());
  }

  validateInputs() {
    let retVal = true;
    // When user clears all inputs treat as RESET.Dont show error indication
    if (
      this.state.numUsers === "" &&
      this.state.userCommitment === 0 &&
      this.state.timeCommitment === 0
    ) {
      retVal = false;
      this.setState({
        numUsersValidationState: null
      });
    } else {
      if (this.state.numUsers === "" || this.state.numUsers <= 0) {
        this.setState({ numUsersValidationState: "error" });
        retVal = false;
      }
    }

    return retVal;
  }

  reset() {
    this.setState({
      numUsers: "",
      userCommitment: this.defaultUserCommitmentOption,
      timeCommitment: this.defaultReservationOption,
      pricePerUser: 0,
      monthlyFee: 0,
      numUsersValidationState: null
    });
  }

  calculatePrice() {
    let pricePerUser = 0;
    let monthlyFee = 0;
    if (this.validateInputs()) {
      let pricePerUserWithoutTimeCommitmentDiscounts =
        this.state.userCommitment > 0
          ? this.props.priceCurveParamA *
            Math.pow(
              this.props.userCommitmentValues[this.state.userCommitment],
              this.props.priceCurveParamB
            )
          : this.props.basePrice;
      if (this.state.numUsers) {
        pricePerUser =
          pricePerUserWithoutTimeCommitmentDiscounts *
          (1 -
            this.props.timeCommitmentDiscounts[this.state.timeCommitment]
              .value /
              100);
        if (
          this.state.numUsers <
          this.props.userCommitmentValues[this.state.userCommitment]
        ) {
          monthlyFee =
            this.props.userCommitmentValues[this.state.userCommitment] *
            pricePerUser;
        } else {
          monthlyFee = this.state.numUsers * pricePerUser;
        }
      }
    }
    this.setState({
      pricePerUser,
      monthlyFee
    });
  }

  formatMoney(amount, decimalCount = 3, decimal = ".", thousands = ",") {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {
      console.log(e);
    }
  }

  createSliderMarks(labelArray) {
    const sliderMarks = {};
    let sliderPosition = 0;
    for (const label of labelArray) {
      sliderMarks[sliderPosition] = label;
      sliderPosition++;
    }
    return sliderMarks;
  }

  render() {
    const resultStyle = {
      fontBold: true,
      fontSize: 16,
      fontWeight: 700,
      fontStyle: "italic"
    };
    const inputStyle = { fontSize: "14px", width: "50%" };
    const errorInputStyle = {
      fontSize: "14px",
      width: "50%",
      border: " 1px solid red"
    };

    return (
      <div className="mt-0">
        <Form inline>
          <FormGroup className="mt-0 mb-0 w-100">
            <Label htmlFor="numUsers">Number of Subscribers: </Label>
            <Input
              className="ml-2"
              type="number"
              id="numUsers"
              placeholder="eg: 5000000"
              value={this.state.numUsers}
              onChange={this.handleNumUsersChange}
              min="0"
              style={
                this.state.numUsersValidationState === "error"
                  ? errorInputStyle
                  : inputStyle
              }
            />
          </FormGroup>
        </Form>
        <Form>
          <FormGroup className="mt-2 mb-0">
            <Label htmlFor="userCommitment">User Commitment:</Label>
            <Col>
              <div className="pl-2 pr-1">
                <SliderWidget
                  marks={this.userCommitmentSliderValues}
                  onSliderChange={this.handleUserCommitmentChange}
                  min={0}
                  max={this.props.userCommitmentValues.length - 1}
                  value={this.state.userCommitment}
                />
              </div>
            </Col>
          </FormGroup>

          <FormGroup className="mt-4 mb-0">
            <Label htmlFor="timeCommitment">Time Commitment:</Label>
            <Col>
              <div className="pl-2 pr-1">
                <SliderWidget
                  marks={this.timeCommitmentSliderValues}
                  onSliderChange={this.handleTimeCommitmentChange}
                  min={0}
                  max={this.props.timeCommitmentDiscounts.length - 1}
                  value={this.state.timeCommitment}
                />
              </div>
            </Col>
          </FormGroup>
          <div className="mt-5 pt-0" style={{ backgroundColor: "#F0F0F0" }}>
            <div className="text-center">
              <div className="pt-2 pb-2">
                Average cost per user:{" "}
                <span style={resultStyle}>
                  SEK {this.formatMoney(this.state.pricePerUser)}
                </span>
              </div>

              <div className="pt-2 pb-2">
                Estimated {this.state.timeCommitment === 0? "monthly": "yearly"} fee:{" "}
                <span style={resultStyle}>
                  SEK {this.state.timeCommitment === 0? this.formatMoney(this.state.monthlyFee, 0): this.formatMoney(this.state.monthlyFee*12, 0)}
                </span>
              </div>
            </div>
          </div>
          <div className="text-right mt-3">
            <Button outline className="btn btn-default" onClick={this.reset}>
              CLEAR
            </Button>
          </div>
        </Form>
      </div>
    );
  }
}
