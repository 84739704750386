import React, { useState, useEffect } from "react";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { store } from "../services/localStorageStore";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "react-phone-input-2";
import axios from "axios";
import jsonData from '../setting/settings.json';

const api = JSON.parse(JSON.stringify(jsonData)).api;


const AddDataVerifierModal = (props) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [activeBtn, setActiveBtn] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true)
  const [isEmailValid, setIsEmailValid] = useState(true)

  const inputStyle = {
    height: "24px",
    color: "#495057",
    border: "1",
    borderWidth: 0,
    padding: 0,
    paddingLeft: "2px",
    paddingBottom: 0,
    borderRadius: 0,
    fontSize: "16px",
    backgroundColor: "#ffff",
    borderBottomWidth: 1.2,
    borderBottomColor: "lightgray", //'#DFE0E1',
  };
  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
  };
  const btnSz = {
    height: "1.8rem",
    width: "10rem",
    padding: 0,
    fontSize: "12px",
  };
  const textStyle = {
    color: "#212529",
    fontSize: "16px",
    paddingBottom: 1,
  };
  const customStylesDD = {
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "gray" }, // border style on hover
      border: "1px solid lightgray", // default border color
      boxShadow: "none", // no box-shadow
    }),
  };

  const validateEmail = value => {
    if(value) {
      const valid = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? false
        : true;
      valid?
        setIsEmailValid(true)
        :
        setIsEmailValid(false)
      return valid
    }
    return false
  };

  const validatePhoneNumber = value => {
    if (value) {
      let valid = false;
      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        valid = phoneUtil.isValidNumber(phoneUtil.parse("+" + value));
      } catch (e) {
        valid = false;
      }
      valid?
        setIsPhoneValid(true)
      :
        setIsPhoneValid(false)
      return valid ;
    } 
    return false
  };

  const handleChangeDetails = (event) => {
    if (event.target.name === "email") {

      setEmail(event.target.value);
      activateButton(event.target.value, "email");
    }
    if (event.target.name === "name") {
      setName(event.target.value);
      activateButton(event.target.value, "name");
    } 
  };

  const activateButton = (value, event) => {
    if (event === "name") {
      if (value.length > 2 && validatePhoneNumber(phone) && validateEmail(email)) {
        setActiveBtn(true);
      } else {
        setActiveBtn(false);
      }
    }
    if (event === "email") {
        if (validateEmail(value) && name.length > 2 && validatePhoneNumber(phone) ) {
          setActiveBtn(true);
        } else {
          setActiveBtn(false);
        }
      }
      if (event === "phone") {
        if (validatePhoneNumber(value) && name.length > 2 &&  validateEmail(email)) {
          setActiveBtn(true);
        } else {
          setActiveBtn(false);
        }
      }
  };

  const onSave = () => {
    const body = {Name: name, Email: email, Phone: phone}
    const axiosConfig = {
        headers: {
          Authorization:
            "Bearer " +
            store.getData("data").Token.access_token,
        },
      };
      const url = `${api}v1/organizations/${store.getData("orgs").ID}/data-verifier-user`
      axios.post(url, body, axiosConfig).then((response) => {
          props.updateDataVerifier()
          props.toggle()
      }).catch(err =>{
          props.updateDataVerifier()
          props.toggle()
      })
  }

  return (
    <div className="modal-content">
      <div className="modal-header" style={headerStyle}>
        <h3 className="modal-header-text">Data Verifier Users</h3>
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={props.toggle}
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <ModalBody className="pb-0 ">
        <div className="form-group ">
          <p>Please make sure the email address and phone number is correct.</p>
        </div>
        <div className="form-group">
          <label style={textStyle}>
            {" "}
            Name <span className="required-field">*</span>{" "}
          </label>
          <input
            className="w-100 pt-0"
            type="text"
            style={inputStyle}
            value={name}
            name="name"
            onChange={handleChangeDetails}
          />
        </div>
        <div className="form-group" >
          <label style={textStyle}>
            {" "}
            Email <span className="required-field">*</span>{" "}
          </label>
          <input
            className="w-100 pt-0"
            type="email"
            name="email"
            style={isEmailValid? inputStyle: { ...inputStyle, borderBottomColor:"red"} }
            value={email}
            onChange={handleChangeDetails}
          />
        </div>
        <div className="form-group" >
          <label style={textStyle}>
            {" "}
            Mobile Number <span className="required-field">*</span>{" "}
          </label>
          <ReactPhoneInput
            defaultCountry="se"
            inputProps={{name:"phone"}}
            value={phone}
            onChange={(value)=>{
              setPhone(value)
              activateButton(value, "phone")
            }}
            inputStyle= {isPhoneValid? { ...inputStyle, width: "91%", marginLeft:"40px"} : { ...inputStyle, width: "91%", marginLeft:"40px", borderBottomColor:"red"} }
          />
        </div>
      </ModalBody>
      <ModalFooter className="modal-footer">
        <button
          style={btnSz}
          size="ig"
          className="btn btn-default"
          onClick={props.toggle}
        >
          Close
        </button>
        <button
          style={btnSz}
          className="btn btn-default"
          disabled={!activeBtn}
          style={{ cursor: activeBtn ? "pointer" : "not-allowed" }}
          onClick={onSave}
        >
          Save
        </button>
      </ModalFooter>
    </div>
  );
};

export default AddDataVerifierModal;
