import React, { useState, useEffect } from "react";
import { ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import { store } from "../services/localStorageStore";
import axios from "axios";
import jsonData from "../setting/settings.json";

const api = JSON.parse(JSON.stringify(jsonData)).api;


const EditSubscriptionMethodModal = (props) => {
 
  // const [subKey, setSubkey] = useState('');
  const [authorizationUrl, setAuthorizationUrl] = useState("");
  const [tokenUrl, setTokenUrl] = useState("");
  const [logoutUrl, setLogoutUrl] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [jwksUrl, setJwksUrl] = useState("");
  const [userInfoUrl, setUserInfoUrl] = useState("");
  const [defaultScope, setDefaultScope] = useState("");
  const [validateSignature, setValidateSignature] = useState(false);
  const [isOkSave, setIsOkSave] = useState(false);
  const [subKey, setSubkey] = useState("");
  const axiosConfig = {
    headers: {
      Authorization: "Bearer " + store.getData("data").Token.access_token,
    },
  };

  useEffect(() => {
    if (props.OrgSubMethodSelected[0].ID === 2) {
      const url =
        api + `v1/organizations/${store.getData("orgs").ID}/idp/open-id`;
      axios
        .get(url, axiosConfig)
        .then((response) => {
          setAuthorizationUrl(response.data.authorizationUrl);
          setTokenUrl(response.data.tokenUrl);
          setLogoutUrl(response.data.logoutUrl);
          setClientId(response.data.clientId);
          setClientSecret(response.data.clientSecret);
          setJwksUrl(response.data.jwksUrl);
          setUserInfoUrl(response.data.userInfoUrl);
          setDefaultScope(response.data.defaultScope);
          setValidateSignature(response.data.validateSignature);
        })
        .catch((err) => console.log(err));
    }
    if (props.OrgSubMethodSelected[0].ID === 1) {
      const url =
        api + "v1/organizations/" + store.getData("orgs").ID + "/subscribe-key";

      axios
        .get(url, axiosConfig)
        .then((response) => {
          let data = response.data;
          setSubkey(data.SubscribeKey);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
  };
  const inputStyle = {
    color: "#495057",
    border: "1",
    borderWidth: 0,
    padding: 0,
    paddingBottom: 1,
    borderRadius: 0,
    fontSize: "16px",
    borderBottomWidth: 1.2,
    borderBottomColor: "lightgray", //'#DFE0E1',
  };
  const btnSz = {
    height: "1.8rem",
    width: "10rem",
    padding: 0,
    fontSize: "12px",
  };
  const textStyle = {
    color: "#212529",
    fontSize: "16px",
    paddingBottom: 1,
  };
  const customStylesDD = {
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "gray" }, // border style on hover
      border: "1px solid lightgray", // default border color
      boxShadow: "none", // no box-shadow
    }),
  };

  const onchange = (event) => {
    if (event.target.name === "authorisation_url") {
      setAuthorizationUrl(event.target.value);
      if (
        event.target.value &&
        tokenUrl &&
        logoutUrl &&
        clientId &&
        clientSecret &&
        jwksUrl &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "token_url") {
      setTokenUrl(event.target.value);
      if (
        event.target.value &&
        authorizationUrl &&
        logoutUrl &&
        clientId &&
        clientSecret &&
        jwksUrl &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "logout_url") {
      setLogoutUrl(event.target.value);
      if (
        event.target.value &&
        authorizationUrl &&
        tokenUrl &&
        clientId &&
        clientSecret &&
        jwksUrl &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "client_id") {
      setClientId(event.target.value);
      if (
        event.target.value &&
        authorizationUrl &&
        tokenUrl &&
        logoutUrl &&
        clientSecret &&
        jwksUrl &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "client_secret") {
      setClientSecret(event.target.value);
      if (
        event.target.value &&
        authorizationUrl &&
        tokenUrl &&
        logoutUrl &&
        clientId &&
        jwksUrl &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "jwks_url") {
      setJwksUrl(event.target.value);
      if (
        event.target.value &&
        authorizationUrl &&
        tokenUrl &&
        logoutUrl &&
        clientId &&
        clientSecret &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "user_info_url") {
      setUserInfoUrl(event.target.value);
      if (
        event.target.value &&
        authorizationUrl &&
        tokenUrl &&
        logoutUrl &&
        clientId &&
        clientSecret &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "default_scope") {
      setDefaultScope(event.target.value);
      if (
        event.target.value &&
        authorizationUrl &&
        tokenUrl &&
        logoutUrl &&
        clientId &&
        clientSecret &&
        userInfoUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
    if (event.target.name === "validate_signature") {
      setValidateSignature(event.target.checked);
      if (
        authorizationUrl &&
        tokenUrl &&
        logoutUrl &&
        clientId &&
        clientSecret &&
        jwksUrl &&
        defaultScope
      ) {
        setIsOkSave(true);
      } else {
        setIsOkSave(false);
      }
    }
  };
  const updateKey = () => {
    const url =
      api + `v1/organizations/${store.getData("orgs").ID}/subscribe-key/renew`;
    axios
      .post(url, "", axiosConfig)
      .then((response) => {
        setSubkey(response.data.SubscribeKey);
        setIsOkSave(true)
      })
      .catch((err) => console.log(err));
  };
  const onSave = () => {
    if (props.OrgSubMethodSelected[0].ID === 2) {
      const body = {
        authorizationUrl,
        tokenUrl,
        logoutUrl,
        clientId,
        clientSecret,
        jwksUrl,
        validateSignature,
        userInfoUrl,
        defaultScope
      };
      const url =
        api + `v1/organizations/${store.getData("orgs").ID}/idp/open-id`;
      axios
        .put(url, body, axiosConfig)
        .then((response) => {
          props.updateSubscriptionPage();
          props.toggle();
        })
        .catch((err) => console.log(err));
    }
    if (props.OrgSubMethodSelected[0].ID === 1) {
      props.updateSubscriptionPage();
      props.toggle();
    }
  };

  return (
    <div className="modal-content">
      <ModalHeader toggle={props.toggle} style={headerStyle}>
        Edit Subscription Method
      </ModalHeader>
      <ModalBody className="pb-0 ">
        {props.OrgSubMethodSelected[0].ID === 2 ? (
          <div className="form">
            <div className="form-group" key="authorisation_url">
              <label style={textStyle}> Authorisation URL* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="authorisation_url"
                value={authorizationUrl}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="token_url">
              <label style={textStyle}> Token URL* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="token_url"
                value={tokenUrl}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="logout_url">
              <label style={textStyle}> Logout URL* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="logout_url"
                value={logoutUrl}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="client_id">
              <label style={textStyle}> Client ID* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="client_id"
                value={clientId}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="client_secret">
              <label style={textStyle}> Client Secret* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="client_secret"
                value={clientSecret}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="jwks_url">
              <label style={textStyle}> JWKS URL* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="jwks_url"
                value={jwksUrl}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="user_info_url">
              <label style={textStyle}> User Info URL* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="user_info_url"
                value={userInfoUrl}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="default_scope">
              <label style={textStyle}> Default Scopes* </label>
              <input
                className="w-100 pt-0"
                type="text"
                style={inputStyle}
                name="default_scope"
                value={defaultScope}
                onChange={onchange}
              />
            </div>
            <div className="form-group" key="validate_signature">
              Validate Signature
              <input
                className="box"
                type="checkbox"
                style={{ display: "inline", marginInline: "5px" }}
                value="Validate Signature"
                name="validate_signature"
                checked={validateSignature}
                onChange={onchange}
              />
            </div>
          </div>
        ) : (
          <div className="form-group" key="subscription_key">
            <label style={textStyle}> Subscription Key </label>{" "}
            <button
              style={btnSz}
              className="btn btn-default"
              onClick={updateKey}
              style={{ float: "right" }}
            >
              Renew
            </button>
            <input
              className="w-100 pt-0"
              type="text"
              style={inputStyle}
              name="subscription_key"
              value={subKey}
              readOnly={true}
            />
          </div>
        )}
      </ModalBody>
      <ModalFooter className="modal-footer">
        <button
          style={btnSz}
          size="ig"
          className="btn btn-default"
          onClick={props.toggle}
        >
          Close
        </button>
        <button
          style={btnSz}
          className="btn btn-default"
          disabled={!isOkSave  }
          style={{ cursor: isOkSave  ? "pointer" : "not-allowed" }}
          onClick={onSave}
        >
          Save
        </button>
      </ModalFooter>
    </div>
  );
};

export default EditSubscriptionMethodModal;
