import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';

import { FaTrashO } from "react-icons/lib/fa";
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";

import {
    updatePersonalDataInDataAgreementState,
    deletePersonalDataInDataAgreementStateAction
} from "../actions/dataAgreementActions";

const CONSOLE_LOG_TAG = "[DataAgreementPersonalDataRestriction] ";

const DataAgreementPersonalDataRestrictionItem = (props) => {

    const restrictionItemRef = useRef();

    const { personalData: attr, itemIndex: key } = props;

    const inputStyle = {
        color: "#495057",
        border: "1",
        borderWidth: 0,
        padding: 0,
        paddingBottom: 1,
        borderRadius: 0,
        fontSize: "16px",
        borderBottomWidth: 1.2,
        borderBottomColor: "lightgray", //'#DFE0E1',
    };

    useEffect(() => {

        const { newAttributeIndex, itemIndex } = props;

        // Scroll if new attribute is added by clicking "+ Add more" button in restrictions.
        if (newAttributeIndex && newAttributeIndex === itemIndex) {
            if (restrictionItemRef.current) {
                setTimeout(() => {
                    restrictionItemRef.current.scrollIntoView({ behavior: 'smooth' })
                }, 0)
            }
        }



    }, []);

    /**
     * Triggered when user changes the restriction.
     * 
     * @param {*} e 
     * @param {*} pd 
     * @param {*} index 
     */
    const handleRestrictionChange = (e, pd, index) => {

        if (e.target.name.includes("schema_id-")) {
            props.updatePersonalDataInDataAgreementState(index, {
                attributeName: pd.attributeName,
                attributeDescription: pd.attributeDescription,
                restrictions: [
                    {
                        schema_id: e.target.value,
                        cred_def_id: pd.restrictions[0].cred_def_id,
                    }
                ]
            });


        } else {
            props.updatePersonalDataInDataAgreementState(index, {
                attributeName: pd.attributeName,
                attributeDescription: pd.attributeDescription,
                restrictions: [
                    {
                        schema_id: pd.restrictions[0].schema_id,
                        cred_def_id: e.target.value,
                    }
                ]
            });

        }

    }

    /**
     * Triggered when "checkbox" is clicked. (schema_id, cred_def_id)
     * 
     * @param {*} e 
     * @param {*} pd 
     * @param {*} index 
     */
    const changeRestrictionCheckedState = (e, pd, index) => {

        if (e.target.name.includes("schema_id-")) {
            props.updatePersonalDataInDataAgreementState(index, {
                attributeName: pd.attributeName,
                attributeDescription: pd.attributeDescription,
                restrictions: [
                    {
                        schema_id: "",
                        cred_def_id: pd.restrictions[0].cred_def_id,
                    }
                ]
            });
        } else {
            props.updatePersonalDataInDataAgreementState(index, {
                attributeName: pd.attributeName,
                attributeDescription: pd.attributeDescription,
                restrictions: [
                    {
                        schema_id: pd.restrictions[0].schema_id,
                        cred_def_id: "",
                    }
                ]
            });
        }

    }

    /**
     * Triggered when the user changes the attribute value.
     * 
     * @param {*} e 
     * @param {*} pd 
     * @param {*} index 
     */
    const onInputAttributeValues = (e, pd, index) => {

        if (e.target.name.includes("attribute_name_")) {

            // Attribute name
            props.updatePersonalDataInDataAgreementState(index, {
                attributeName: e.target.value,
                attributeDescription: pd.attributeDescription
            });

        } else {

            // Attribute description
            props.updatePersonalDataInDataAgreementState(index, {
                attributeName: pd.attributeName,
                attributeDescription: e.target.value
            });

        }
    }





    return (

        <div ref={restrictionItemRef} key={key}>




            <Card className="attr-restriction-card" key={key}>


                <CardTitle className="title-attr-restriction">
                    <table className="table-attr-additional-info">
                        <tbody>
                            <tr>
                                <td className="table-attr-additional-info-column">
                                    <input
                                        className="w-100 pt-0"
                                        name={"attribute_name_" + key}
                                        type="text"
                                        autoComplete='off'
                                        style={{ ...inputStyle, borderBottom: 0 }}
                                        placeholder='Attribute name'
                                        value={attr.attributeName || ""}
                                        onChange={(e) => { onInputAttributeValues(e, attr, key) }}
                                    />
                                </td>
                                <th>
                                    <FaTrashO
                                        cursor="pointer"
                                        className="card-delete-icon"
                                        id={key}
                                        onClick={() => { props.deletePersonalDataInDataAgreementStateAction(key); }}
                                    />
                                </th>
                            </tr>
                        </tbody>
                    </table>
                </CardTitle>


                <CardTitle className="title-attr-restriction">
                    <input
                        className="w-100 pt-0"
                        type="text"
                        autoComplete='off'
                        name={'attribute_description_' + key}
                        placeholder='Attribute description'
                        value={attr.attributeDescription || ""}
                        style={{ ...inputStyle, borderBottom: 0 }}
                        onChange={(e) => { onInputAttributeValues(e, attr, key) }}
                    />
                </CardTitle>


                <CardSubtitle className="title-sub-attr-restriction">
                    Restrictions
                </CardSubtitle>


                <CardBody className="card-attr-restrict">
                    <table className="table-attr-restriction">
                        <tbody>

                            <tr>


                                <th className="table-attr-restriction-column">
                                    {" "}
                                    <input
                                        name={"schema_id-" + key}
                                        type="checkbox"
                                        style={{ cursor: (attr.restrictions[0].schema_id) ? 'pointer' : 'not-allowed' }}
                                        checked={
                                            attr.restrictions[0].schema_id
                                                ? true
                                                : false
                                        }
                                        disabled={
                                            attr.restrictions[0].schema_id
                                                ? false
                                                : true
                                        }
                                        onChange={(e) => { changeRestrictionCheckedState(e, attr, key) }}
                                    />
                                </th>


                                <th className="table-attr-restriction-column" scope="row">
                                    Schema ID
                                </th>


                                <td className="table-attr-restriction-column">
                                    <input
                                        className="w-100 pt-0"
                                        type="text"
                                        autoComplete='off'
                                        style={{ ...inputStyle, borderBottom: 0 }}
                                        name={"schema_id-" + key}
                                        value={
                                            attr.restrictions[0].schema_id
                                        }
                                        onChange={(e) => { handleRestrictionChange(e, attr, key) }}
                                    />
                                </td>


                            </tr>


                            <tr>


                                <th className="table-attr-restriction-column">
                                    <input
                                        type="checkbox"
                                        style={{ cursor: (attr.restrictions[0].cred_def_id) ? 'pointer' : 'not-allowed' }}
                                        name={"cred_def_id-" + key}
                                        checked={attr.restrictions[0].cred_def_id ? true : false}
                                        disabled={attr.restrictions[0].cred_def_id ? false : true}
                                        onChange={(e) => { changeRestrictionCheckedState(e, attr, key) }}
                                    />
                                </th>


                                <th className="table-attr-restriction-column" scope="row">
                                    Cred. Def. ID
                                </th>


                                <td className="table-attr-restriction-column" data-title="cred-def-id">
                                    <input
                                        className="w-100 pt-0"
                                        type="text"
                                        autoComplete='off'
                                        style={{ ...inputStyle, borderBottom: 0 }}
                                        name={"cred_def_id-" + key}
                                        value={attr.restrictions[0].cred_def_id}
                                        onChange={(e) => { handleRestrictionChange(e, attr, key) }}
                                    />
                                </td>


                            </tr>


                        </tbody>
                    </table>
                </CardBody>
            </Card>





        </div>


    )


}


const mapStateToProps = (state) => {
    return {
        dataAgreementState: state.DataAgreementStateUpdate,
        personalDataRestrictionsPanel: state.PersonalDataRestrictionsPanel,
    };
}
export default connect(
    mapStateToProps,
    {
        updatePersonalDataInDataAgreementState,
        deletePersonalDataInDataAgreementStateAction
    }
)(DataAgreementPersonalDataRestrictionItem);