import React, { useState, useEffect } from "react";
import {
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { connect } from "react-redux";

import Select from "react-select";

import { showLoader, hideLoader } from "../actions/loaderActions";
import {
  fetchConnectionsInCloudAgent,
  issueCredentialsInCloudagent
} from "../services";

const IssueCredentialModal = ({ dataAgreementId, dataAgreementPurpose, dataAgreementPersonalData, dataAgreementCredDefId, toggle, ...props }) => {

  const [dataAgreementTemplateName, setDataAgreementTemplateName] = useState(dataAgreementPurpose);

  const [personalData, setPersonalData] = useState(dataAgreementPersonalData);

  const [personalDataFieldValues, setPersonalDataFieldValues] = useState({});

  const [connections, setConnections] = useState([]);

  const [selectedConnection, setSelectedConnection] = useState(null);

  const [isOkToIssue, setIsOkToIssue] = useState(false);

  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
  };

  const inputStyle = {
    color: "#495057",
    border: "1",
    borderWidth: 0,
    padding: 0,
    paddingBottom: 1,
    borderRadius: 0,
    fontSize: "16px",
    borderBottomWidth: 1.2,
    borderBottomColor: "lightgray", //'#DFE0E1',
  };

  const btnSz = {
    height: "1.8rem",
    width: "10rem",
    padding: 0,
    fontSize: "12px",
  };

  const textStyle = {
    color: "#212529",
    fontSize: "16px",
    paddingBottom: 1,
  };

  const modalHeaderTitleStyle = {
    marginRight: "auto",
    marginBlock: " auto",
  };

  const customStyleForSelect = {
    control: (base, state) => ({
      ...base,
      "&:hover": { borderColor: "gray" }, // border style on hover
      border: "1px solid lightgray", // default border color
      boxShadow: "none", // no box-shadow
    }),
  };

  /**
   * Fetch all connections from the cloud agent.
   */
  const fetchConnections = () => {

    fetchConnectionsInCloudAgent(1, 250000).then((response) => {

      const connectionRecords = response.data.results;

        const data = connectionRecords.filter(obj => {
          return obj.their_label !=='MyData-DID-Registry'
        }).map((connection) => {

        const updatedConnection = {
          value: connection.connection_id,
          label: `${connection.connection_id} (${connection.their_label})`,
        }

        return updatedConnection;

      });

      setConnections(data);

    }).catch((err) => {

      // Handle error.

      console.log(err);

      setConnections([]);

    });

  };

  /**
   * Run once when component is mounted.
   */
  useEffect(() => {

    fetchConnections();

  }, []);

  /**
   * Listen for changes to data agreement personal data.
   */
  useEffect(() => {

    // Initialize personal data field values.
    dataAgreementPersonalData.forEach((pd, index) => {

      setPersonalDataFieldValues({ ...personalDataFieldValues, [pd.attribute_name]: "", });

    });

  }, [dataAgreementPersonalData]);

  /**
   * Handle changes to personal data field values.
   * 
   * @param {*} event 
   */
  const handleChange = (event) => {

    const { name, value } = event.target;

    setPersonalDataFieldValues({ ...personalDataFieldValues, [name]: value });

  };

  /**
   * Select connection from drop down.
   * 
   * @param {*} value 
   */
  const selectConnection = (currentSelection) => {

    setSelectedConnection(currentSelection);

  };

  /**
   * Listen for changes to personal data field values.
   */
  useEffect(() => {

    // Validate personal data field values and enable issue button.
    checkIfIsOkToIssue();

  }, [personalDataFieldValues, selectedConnection]);

  const checkIfIsOkToIssue = () => {

    let valid = true;

    // Validate personal data field values.

    for (const [key, value] of Object.entries(personalDataFieldValues)) {

      if (value.trim().length === 0) {

        valid = false;

        break;

      }

    }

    // Validate selected connection.

    if (selectedConnection === null) {

      valid = false;

    }

    setIsOkToIssue(valid);

  }

  const onIssue = () => {

    props.showLoader("IssueCredentialModal");

    const attributes = [];

    personalData.forEach((pd) => {

      attributes.push({
        name: pd.attribute_name,
        value: personalDataFieldValues[pd.attribute_name]
      });

    });


    issueCredentialsInCloudagent({
      dataAgreementId: dataAgreementId,
      dataAgreementTemplatePurpose: dataAgreementPurpose,
      credDefId: dataAgreementCredDefId,
      connectionId: selectedConnection.value,
      attributes: attributes,
    }).then((response) => {

      // Toggle modal.
      toggle();

      // Hide loader.

      props.hideLoader("IssueCredentialModal");




    }).catch((err) => {

      // Handler error.

      console.log(err);

      // Toggle modal.
      toggle();

      // Hide loader.
      props.hideLoader("IssueCredentialModal");


    });


  }

  return (

    <div>


      <div className="modal-header" style={headerStyle}>


        <div style={{ display: "flex", justifyContent: "space-between", flexBasis: "100%" }}>


          <h1 style={modalHeaderTitleStyle}>Issue Credentials: {dataAgreementTemplateName}</h1>


          <button type="button" className="close" aria-label="Close" onClick={() => { toggle(); }}>


            <span aria-hidden="true">×</span>


          </button>


        </div>


        {dataAgreementCredDefId && <p style={{ fontStyle: "italic", color: "gray", }}>{dataAgreementCredDefId}</p>}


      </div>

      <ModalBody className="pb-0 ">

        <div className="form-group " key="connection_id">

          <label style={textStyle}> Connection ID<span className="required-field">*</span> </label>

          <Select

            placeholder="Select a connection"

            isSearchable={true}

            isMulti={false}

            options={connections}

            name="connection_id"

            styles={customStyleForSelect}

            value={selectedConnection}

            onChange={selectConnection}

          />

        </div>

        {personalData.map((pd, index) => (

          <div className="form-group" key={pd.attribute_id}>

            <label style={textStyle}>{pd.attribute_name}<span className="required-field">*</span></label>

            <input

              autoComplete="off"

              className="w-100 pt-0"

              placeholder={pd.attribute_name}

              style={inputStyle}

              type="text"

              name={pd.attribute_name}

              value={personalDataFieldValues[index]}

              onChange={handleChange}

            />
          </div>

        ))}

      </ModalBody>

      <ModalFooter className="modal-footer">

        <button

          style={btnSz}

          size="ig"

          className="btn btn-default"

          onClick={toggle}

        >
          Close
        </button>

        <button

          style={{ ...btnSz, cursor: isOkToIssue ? "pointer" : "not-allowed" }}

          className="btn btn-default"

          disabled={!isOkToIssue}

          onClick={onIssue}

        >
          Issue
        </button>

      </ModalFooter>

    </div>

  );

};


const mapStateToProps = (state) => {
  return {};
}

export default connect(
  mapStateToProps,
  {
    showLoader,
    hideLoader,
  }
)(IssueCredentialModal);
