
import React, { Component } from 'react';
import { Tooltip } from "@material-ui/core";
import { connect } from 'react-redux';
import Select from 'react-select';

import {
    updateJurisdictionInDataAgreementState,
    updateIndustrySectorInDataAgreementState,
    updateDataRetentionPeriodInDataAgreementState,
    updatePolicyUrlInDataAgreementState,
    updateGeographicRestriction,
} from '../actions/dataAgreementActions';

class DataAgreementPolicyConfiguration extends Component {

    constructor() {

        super();

        this.state = {
            geographicRestrictions: [
                {
                    value: "Europe",
                    label: "Europe",
                },
                {
                    value: "Not restricted",
                    label: "Not restricted",
                }
            ]
        }

        this.handleChangeDataPolicyConfig = this.handleChangeDataPolicyConfig.bind(this);
    }

    /**
   * Handle changes to data policy configuration.
   * 
   * @param {*} event 
   */
    handleChangeDataPolicyConfig = (event) => {

        // Update data retention period in data agreement.
        if (event.target.name === "dataRetentionPeriod") {

            let dataRetentionPeriod = 0;

            if (event.target.value.trim().length > 0) {
                dataRetentionPeriod = parseInt(event.target.value);
            }

            this.props.updateDataRetentionPeriodInDataAgreementState(dataRetentionPeriod);

        } else if (event.target.name === "policyUrl") {

            this.props.updatePolicyUrlInDataAgreementState(event.target.value);

        } else if (event.target.name === "jurisdiction") {
            this.props.updateJurisdictionInDataAgreementState(event.target.value);
        } else if (event.target.name === "industryScope") {


            this.props.updateIndustrySectorInDataAgreementState(event.target.value)

        }

    };

    handleChangeDataPolicyGeographicRestriction = (selectedOption) => {

        this.props.updateGeographicRestriction(selectedOption.value.trim());

    }


    render() {

        const textStyle = {
            color: "#212529",
            fontSize: "16px",
            paddingBottom: 1,
        };

        const inputDataConfigStyle = {
            color: "#495057",
            border: "0",
            borderWidth: 0,
            padding: 0,
            paddingLeft: "5px",
            paddingBottom: 0,
            borderRadius: 0,
            fontSize: "14px",
            backgroundColor: "#ffff",
            borderBottomColor: "lightgray", //'#DFE0E1',
            borderBottom: 0,
        };

        const dropDownStyle = {
            color: "#495057",
            border: 0,
            borderWidth: 0,
            padding: 0,
            paddingLeft: "2px",
            paddingBottom: 0,
            borderRadius: 0,
            fontSize: "14px",
            backgroundColor: "#ffff",
            borderColor: "#ffff", //'#DFE0E1',
        };



        return (
            <div className="form-group" key="dataPolicyConfiguration">
                <label style={textStyle}> Data Policy Configurations </label>
                <table className="table-policy-configuration">
                    <tbody>
                        <tr>
                            <th
                                className="table-policy-configuration-column"
                                scope="row"
                            >
                                {" "}
                                {"Policy URL"}
                            </th>
                            <td className="table-policy-configuration-column table-policy-configuration-column-right">
                                <input
                                    disabled={this.props.dataAgreementPanel.mode === "READ"}
                                    className="w-100 pt-0"
                                    type="text"
                                    style={{ ...inputDataConfigStyle, cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }}
                                    name={"policyUrl"}
                                    value={this.props.dataAgreementState.dataPolicy.policyUrl}
                                    autoComplete="off"
                                    onChange={this.handleChangeDataPolicyConfig}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th
                                className="table-policy-configuration-column"
                                scope="row"
                            >
                                {"Jurisdiction"}
                            </th>

                            <td className="table-policy-configuration-column table-policy-configuration-column-right">
                                <input
                                    disabled={this.props.dataAgreementPanel.mode === "READ"}
                                    className="w-100 pt-0"
                                    autoComplete='off'
                                    type="text"
                                    style={{ ...inputDataConfigStyle, cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }}
                                    name={"jurisdiction"}
                                    value={this.props.dataAgreementState.dataPolicy.jurisdiction}
                                    onChange={this.handleChangeDataPolicyConfig}
                                />
                            </td>
                        </tr>

                        <tr>
                            <th
                                className="table-policy-configuration-column"
                                scope="row"
                            >
                                {"Industry scope"}
                            </th>
                            <td className="table-policy-configuration-column table-policy-configuration-column-right">
                                <input
                                    disabled={this.props.dataAgreementPanel.mode === "READ"}
                                    className="w-100 pt-0"
                                    type="text"
                                    style={{ ...inputDataConfigStyle, cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }}
                                    autoComplete="off"
                                    name={"industryScope"}
                                    value={this.props.dataAgreementState.dataPolicy.industrySector}
                                    onChange={this.handleChangeDataPolicyConfig}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th
                                className="table-policy-configuration-column"
                                scope="row"
                            >
                                {" "}
                                {"Data retention period in year (s)"}
                            </th>
                            <td className="table-policy-configuration-column table-policy-configuration-column-right">
                                <input
                                    disabled={this.props.dataAgreementPanel.mode === "READ"}
                                    className="w-100 pt-0"
                                    type="number"
                                    style={{ ...inputDataConfigStyle, cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }}
                                    name={"dataRetentionPeriod"}
                                    value={this.props.dataAgreementState.dataPolicy.dataRetentionPeriod}
                                    onChange={this.handleChangeDataPolicyConfig}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th
                                className="table-policy-configuration-column"
                                scope="row"
                            >
                                {"Geographic restriction"}
                            </th>
                            <td className="table-policy-configuration-column table-policy-configuration-column-right" style={{ cursor: this.props.dataAgreementPanel.mode === "READ" ? "not-allowed" : "auto" }}>
                                <Select
                                    isDisabled={this.props.dataAgreementPanel.mode === "READ"}
                                    className="w-100 pt-0"
                                    type="text"
                                    style={dropDownStyle}
                                    name={"geographicRestriction"}
                                    options={this.state.geographicRestrictions}
                                    value={this.state.geographicRestrictions.filter((value) => { return value.label.trim() === this.props.dataAgreementState.dataPolicy.geographicRestriction.trim(); })}
                                    onChange={this.handleChangeDataPolicyGeographicRestriction}
                                />
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dataAgreementState: state.DataAgreementStateUpdate,
        dataAgreementPanel: state.DataAgreementPanel
    };
}
export default connect(
    mapStateToProps,
    {
        updateJurisdictionInDataAgreementState,
        updateIndustrySectorInDataAgreementState,
        updateDataRetentionPeriodInDataAgreementState,
        updatePolicyUrlInDataAgreementState,
        updateGeographicRestriction,
    }
)(DataAgreementPolicyConfiguration);