import React, { Component } from "react";
import ReactDOM from 'react-dom';
import PropTypes from "prop-types";
import { Button, Form, FormGroup, Label, Input, FormText,Col,Row,CardText,CardTitle,Card,CardBody,Modal,ModalBody,ModalFooter,ModalHeader, } from 'reactstrap';
import jsonData from '../../setting/settings.json';
import Select from 'react-select';
import { store } from '../../services/localStorageStore'
import axios from 'axios';
import { checkURL,checkEmail} from '../Sources';
const api=JSON.parse(JSON.stringify(jsonData)).api;
const customStylesDD = {
	control: (base, state) => ({
	  ...base,
		'&:hover': { borderColor: 'gray' }, // border style on hover
		 border: '1px solid #DFDFDF', // default border color
		 boxShadow: 'none', // no box-shadow
		 fontSize:'14px', 
		 fontColor:'#808080',
		 top:'-6px',
		 padding:0,
		 margin:0,
		 backgroundColor:'white',//'#F8F9FA',
		 borderRadius:0,
		 outline: 'none',
 })
};

const styleText = {
	padding:0,
	paddingTop:'6px',
	paddingBottom:'0px',
	fontSize: '14px', 
	boxShadow:'none',
	outline: 'none',
 	borderWidth: 1, 
	borderRadius:0,
	borderColor:'#F8F9FA',
	borderBottomStyle:'solid',
	borderBottomColor: '#DFE0E1', 
};

const styleTextArea= {
 
	paddingBottom:'1px',
	fontSize: '14px', 
	boxShadow:'none',
	outline: 'none',
	height:100,
	padding:0,
	borderWidth: 1, 
	borderRadius:0,
	borderColor:'#F8F9FA',
	borderBottomStyle:'solid',
	borderBottomColor: '#DFE0E1', 
};


export default class NotificationForms extends Component {

	static propTypes = {
		config: PropTypes.array,
		onSubmit: PropTypes.func
	};

    state = {
		selected:'Data Breaches',
		values:{},
		isOKBtn: false,
		msg:'NOTIFY',
		msgDetailed:'',
		cancelPopUp: false,
		cancelConfirmationText: "",
		subUserCount:'',
	}
	
    static defaultProps={
        config:[{
			type:'EULA Updates',
			label:"EULA Update Notification",
            elements:[{field:'EULA URL',width:5,placeholder:'Please type a valid URL',labelSpan:5}]
        },
        {
			type:'Data Breaches',			
			label:'Data Breach Notification',
			elements:[{field:'Nature of personal data breach',width:5,placeholder:'Please type atleast 5 characters',labelSpan:5},
			{field:'Approximate number of data subjects',type:'number',width:5,placeholder:'Please type atleast a digit',labelSpan:5},
			{field:'DPO contact email for more info.',type:'email',width:5,placeholder:'Please type a valid email ID',labelSpan:5},
			{field:'Likely consequences of the personal data breach',type:'textarea',placeholder:'Please type atleast 5 characters',direction:'column',width:12,labelSpan:0,mutiline:true},
			{field:'Measures to be taken',type:'textarea',placeholder:'Please type atleast 5 characters',direction:'column',width:12,labelSpan:0,mutiline:true}]
        },
        {
			type:'Other Events',			
			label:'Event Notification',
            elements:[{field:'Enter Event Details',type:'textarea',placeholder:'Please type atleast 5 characters',direction:'column',width:12,labelSpan:0,mutiline:true}]
        }]
    }

    handleEventType = (selectedOption)=>{
		this.handleClearAll();
		this.setState({isOKBtn: false,msg:'NOTIFY', msgDetailed:''}); 
		this.setState({selected:selectedOption.value});
    }

	notifyPopup = ()=> {
	   this.getSubscribeUserCount();
    	this.setState({ cancelPopUp: !this.state.cancelPopUp, cancelConfirmationText: ""});
	}
	
	confirmNotification = () => {
	  if (this.state.cancelConfirmationText && this.state.cancelConfirmationText.toUpperCase() === 'NOTIFY') 
	  {
	    this.props.onSubmit(this.state.values,this.state.selected);
		var Msg='Notification sent to ' +  this.state.subUserCount + ' number of subscribers';
		//this.setState({ msgDetailed:Msg});
		//this.setState({isOKBtn:false,msg:'NOTIFIED'});
		this.setState({ cancelPopUp: !this.state.cancelPopUp });
		this.handleClearAll();
		}
	  }
	
	  handleCancelConfirmationText =(event) => {
		this.setState({ cancelConfirmationText: event.target.value });
	  }
  
	handleChange = (key,value)=>{
 		if (key === "Approximate number of data subjects") {
		    this.state.values[key]=parseInt(value, 10);
		} else {
			this.state.values[key]=value;
		}	
		switch(key)
		{
			case 'EULA URL':
			   this.setState({isOKBtn: (value.length>18 && checkURL(value))?true:false }); 
			   break;	
			case 'Enter Event Details':
			   this.setState({isOKBtn: (value.length>4)?true: false }); 
			   break;
			default:
				   var nature  = (this.state.values['Nature of personal data breach'] && this.state.values['Nature of personal data breach'].length>4)?true:false;
				   var subjects= (this.state.values['Approximate number of data subjects'] && parseInt(this.state.values['Approximate number of data subjects'])>-1 )?true:false; 
				   var email= (this.state.values['DPO contact email for more info.'] && checkEmail(this.state.values['DPO contact email for more info.']) )?true:false; 
				   var consequences= (this.state.values['Likely consequences of the personal data breach'] && this.state.values['Likely consequences of the personal data breach'].length>4 ) ?true:false; 
				   var measure = (this.state.values['Measures to be taken'] && this.state.values['Measures to be taken'].length>4 ) ?true:false;
				   this.setState({isOKBtn:(nature && subjects && email && consequences && measure)?true:false}); 
			       break;
   		}
	}

	getSubscribeUserCount =() => {
		 
			  let data = store.getData("data");
			  let orgs = store.getData('orgs');
			  var url ='';
			  if( data && orgs ) 
			  {
				var axiosConfig = {
				  headers: {
					  'Authorization': 'Bearer ' + data.Token.access_token,
					  }
				  }; 
		
				  url = api +'v1/organizations/'+ store.getData('orgs').ID +'/users/count';
				  var Msg='';
				  axios.get(url ,axiosConfig)
				  .then((response) => {    
					var SubUserCount=response.data.SubscribeUserCount;
					this.setState({  subUserCount :SubUserCount });
				}) ;
			  } 
			}
			

	handleClearAll = ()=>{
		const inputs = ReactDOM.findDOMNode(this).querySelectorAll('input');
		for(const input of inputs){
			input.value = '';
		}
 		const inputs1 = ReactDOM.findDOMNode(this).querySelectorAll('textarea');
		for(const input1 of inputs1){
			input1.value = '';
		}
		this.setState({isOKBtn: false,msg:'NOTIFY', msgDetailed:''}); 
	}

	render() {
        const {config,onSubmit,isSent} = this.props;
        const {selected,values} = this.state;
		const selectedEvent = config.find(event=>event.type === selected);
		const options = config.map(option => {return {value:option.type,label:option.type}});

		return (
			<div>
				<Form className='notify-form'>
				<Modal backdrop="static" isOpen={this.state.cancelPopUp} toggle={this.notifyPopup}>
					<ModalHeader toggle={this.notifyPopup}>
						<strong>Are you sure?</strong>
					</ModalHeader>
					<ModalBody> 
					    Please type NOTIFY to send notifications and click OK
						<Input className="mt-1" type="text" id="cancelConfirmationText" value={this.state.cancelConfirmationText} onChange={this.handleCancelConfirmationText}/>
					</ModalBody>
					<ModalBody className="mt-0 pt-0"> 
					   You are about to send notifications to {this.state.subUserCount} { this.state.subUserCount>1 ? 'users' :'user' }
					</ModalBody> 
					<div className="mt-1">
						<ModalFooter>
						<Button outline className="btn btn-default" onClick={this.notifyPopup}>
							Cancel
						</Button>
						<div className="text-center">
							<Button outline className="btn btn-default" onClick={this.confirmNotification} > OK </Button>
						</div>
						</ModalFooter>
					</div>
					</Modal>
					<FormGroup row>
						<Label style={{paddingTop:3}} htmlFor="exampleSelect">
							Notification Type:
						</Label>
						<Col lg={4} md={6} sm={12} xs={12} >
							<Select defaultValue={options[1]} id='exampleSelect' placeholder="Notification Type" onChange={this.handleEventType} options={options} styles={customStylesDD} />
						</Col> 
						{!this.state.isOKBtn && <Label style={{paddingTop:3}} htmlFor="exampleMessage">
						   {this.state.msgDetailed}
						</Label>}
					</FormGroup>
					<Card  className="h-100 w-100 mt-3">    
					<CardBody>
					<CardTitle >{selectedEvent.label}</CardTitle> 

					{selectedEvent && selectedEvent.elements.map((element, i) => (
						<FormGroup key={i} row style={{flexDirection:element.direction}}>
							<Col className='pl-0 pt-0' md={{ size: element.labelSpan, offset: 0}} lg={{size:element.labelSpan,offset:0}}>
								<Label htmlFor={element.field}> {element.field}: </Label>
							</Col>
							<Col lg={element.width || 10} md={element.width || 10} sm={10}>
								<Input style={ (element.mutiline)?styleTextArea:styleText} type={element.type || "text"}  min="0" placeholder={element.placeholder} onChange={(e)=>{this.handleChange(element.field,e.target.value)}} name="text" id={element.field} />
							</Col>
						</FormGroup>
					))
					}
					{selectedEvent && <div className='text-right mt-3'>
					<Button className='btn btn-default btn-outline-secondary' onClick={(e)=>{e.preventDefault();this.handleClearAll();}} >Clear </Button>
					<Button className='btn btn-default btn-outline-secondary' onClick={(e)=>{e.preventDefault();this.notifyPopup();}} disabled={ !this.state.isOKBtn } style={{cursor:(this.state.isOKBtn)?'pointer':'not-allowed',fontStyle:(this.state.msg==='NOTIFY')?'normal':'italic'}}  > {this.state.msg}  {isSent && <i className="fas fa-check fa-12" />} </Button>
					</div>}
					</CardBody>
					</Card>	
				</Form>
			</div> 
		);
	}
}
