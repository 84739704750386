import React from "react";
import IconEye from "react-icons/lib/fa/eye";
import IconEyeSlash from "react-icons/lib/fa/eye-slash";
import { MdRefresh } from "react-icons/lib/md";
import jsonData from "../setting/settings.json";
import { connect } from "react-redux";
import { GetOrganizationDetails } from "../components/Sources";
import { store } from "../services/localStorageStore";
import IconCopy from "react-icons/lib/fa/copy"; //edit
import QRImageNull from "../assets/img/products/qr.png";
import axios from "axios";
import {
  Row,
  Col,
  Card,
  CardText,
  CardTitle,
  CardBody,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  CardImg,
} from "reactstrap";
import Page from "../components/Page";
import jwtDecode from "jwt-decode";
import { checkAPIKeyExpiry, setAPIKeyValid } from "../actions";

const btnSz = {
  height: "1.8rem",
  width: "10rem",
  padding: "0rem",
  fontSize: "12px",
};
const btnLz = {
  height: "1.8rem",
  width: "16rem",
  padding: "0rem",
  fontSize: "12px",
};
let api = JSON.parse(JSON.stringify(jsonData)).api;

const headerStyle = {
  fontSize: "16px",
  backgroundColor: "#eeeeee",
};

const textStyle = {
  color: "#212529",
  fontSize: "16px",
  paddingBottom: 1,
};

var qrImage = "";

class DeveloperPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleApiCopy = this.handleApiCopy.bind(this);
    this.refreshApiKey = this.refreshApiKey.bind(this);
    this.getApikey = this.getApikey.bind(this);
    this.getTokenFullDetails = this.getTokenFullDetails.bind(this);
    this.state.tokenStr =
      store.getData("data") != null
        ? store.getData("data").Token.access_token
        : "";
    this.getApikey();
  }

  state = {
    show: true,
    showApi: false,
    tokenStr: null,
    apiStr: null
  };

  handleCopy(event) {
    let textarea = document.getElementById("txtarea");
    if (!this.state.show)
      textarea.value =
        store.getData("data") != null
          ? store.getData("data").Token.access_token
          : "";
    textarea.select();
    document.execCommand("copy");
    textarea.selectionStart = 0;
    textarea.selectionEnd = 0;
    if (!this.state.show)
      textarea.value =
        store.getData("data") != null
          ? store.getData("data").Token.access_token.replace(/./g, "*")
          : "";
  }

  copyToClipboard = (text) => {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  handleApiCopy(event) {
    this.copyToClipboard(
      store.getData("data") != null ? store.getData("data").User.APIKey : ""
    );
  }

  refreshApiKey = () => {
    var url = api + "v1/user/apikey";
    var axiosConfig = {
      headers: {
        Authorization: "Bearer " + store.getData("data").Token.access_token,
      },
    };
    axios.post(url, {}, axiosConfig).then((response) => {
      let data = response.data;
      let storeData = store.getData("data");

      storeData["User"]["APIKey"] = data.APIKey;

      store.writeData("data", storeData);

      this.setState({ apiStr: data.APIKey });

      this.props.setAPIKeyValid()

      if (!this.state.showApi) {
        this.state.apiStr = this.state.apiStr.replace(/./g, "*");
      }
    });
  };

  getApikey = () => {
    if (store.getData("data") != null && !store.getData("data").User.APIKey) {
      var url = api + "v1/user/apikey";
      var axiosConfig = {
        headers: {
          Authorization: "Bearer " + store.getData("data").Token.access_token,
        },
      };
      axios.post(url, {}, axiosConfig).then((response) => {
        let data = response.data;

        let storeData = store.getData("data");

        storeData["User"]["APIKey"] = data.APIKey;

        store.writeData("data", storeData);

        this.setState({ apiStr: data.APIKey });

        if (!this.state.showApi) {
          this.state.apiStr = this.state.apiStr.replace(/./g, "*");
        }

      });
    }
    if (this.state.showApi) {
      this.state.apiStr =
        store.getData("data") != null ? store.getData("data").User.APIKey : "";
    } else {
      this.state.apiStr =
        store.getData("data") != null
          ? store.getData("data").User.APIKey.replace(/./g, "*")
          : "";
    }
    this.props.checkAPIKeyExpiry()
  };

  getTokenFullDetails = () => {
    if (this.state.show) {
      this.state.tokenStr =
        store.getData("data") != null
          ? store.getData("data").Token.access_token.replace(/./g, "*")
          : "";
    } else {
      this.state.tokenStr =
        store.getData("data") != null
          ? store.getData("data").Token.access_token
          : "";
    }
  };

  toggle = () => {
    this.setState({
      show: !this.state.show,
    });
    this.getTokenFullDetails();
  };

  toggleApi = () => {
    this.setState({
      showApi: !this.state.showApi,
    });
    if (this.state.showApi) {
      this.state.apiStr =
        store.getData("data") != null
          ? store.getData("data").User.APIKey.replace(/./g, "*")
          : "";
    } else {
      this.state.apiStr =
        store.getData("data") != null ? store.getData("data").User.APIKey : "";
    }
  };

  render() {
    return (
      <Page title="" breadcrumbs={[{ name: "Developer API", active: true }]}>
        <Row className="d-flex justify-content-right">
          <Col md="12" sm="12" xs="12">
            <CardTitle>API Credentials</CardTitle>
          </Col>
        </Row>
        <Row className="d-flex justify-content-right mb-2">
          <Col lg="8" md="8" sm="12" xs="12">
            <CardText>
              All requests to iGrant.io's RestFul APIs require you to
              authenticate using the credentials below.
            </CardText>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={6} sm={6} xs={12}>
            <Card className="h-100 w-100">
              <CardBody className="d-flex flex-column justify-content-start ">
                <CardTitle>Organization ID</CardTitle>
                <CardText>
                  {store.getData("orgs") != null
                    ? store.getData("orgs").ID
                    : ""}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <Card className="h-100 w-100">
              <CardBody className="d-flex flex-column justify-content-start ">
                <CardTitle>Your User ID</CardTitle>
                <CardText>
                  {store.getData("data") != null
                    ? store.getData("data").User.ID
                    : ""}
                </CardText>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <Card className="h-100 w-100">
              <CardBody className="d-flex flex-column justify-content-start ">
                <CardTitle>API URL</CardTitle>
                <CardText>{JSON.parse(JSON.stringify(jsonData)).api}</CardText>
              </CardBody>
            </Card>
          </Col>
          <Col lg={3} md={6} sm={6} xs={12}>
            <Card className="h-100 w-100">
              <CardBody className="d-flex flex-column justify-content-start ">
                <CardTitle>API Specification</CardTitle>
                <CardText>
                  <a
                    href={JSON.parse(JSON.stringify(jsonData)).api_doc}
                    target="_blank"
                    style={{ color: "blue", fontSize: "14px" }}
                    htmlFor="em2"
                  >
                    {JSON.parse(JSON.stringify(jsonData)).api_doc}
                  </a>{" "}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody
                className="d-flex flex-column justify-content-start"
                style={{ paddingBottom: "0px" }}
              >
                <CardTitle>API Key</CardTitle>
                <CardText>
                  <span active={String(this.props.apiKeyExpired)}>
                    <span className={this.props.apiKeyExpired ? "blur" : ""}>
                      {this.state.apiStr}
                    </span>
                  </span>
                </CardText>
                <span
                  className="d-flex justify-content-between align-items-start pt-2"
                  style={{
                    position: "relative",
                    left: "0px",
                    top: "-15px",
                    paddingLeft: "1px",
                    paddingRight: "5px",
                    borderWidth: "0px",
                    fontSize: "14px",
                  }}
                >
                  <a
                    className="justify-content-center align-items-center"
                    onClick={this.toggleApi}
                    style={{ cursor: "pointer", fontSize: "14px" }}
                  >
                    {this.state.showApi ? (
                      <IconEyeSlash
                        style={{ position: "relative", top: "-2px" }}
                      />
                    ) : (
                      <IconEye style={{ position: "relative", top: "-2px" }} />
                    )}
                    &nbsp;{this.state.showApi ? "Hide" : "Show"}
                  </a>{" "}
                  <div className="api_key_btn">
                    {this.props.apiKeyExpired && (
                      <div
                        style={{
                          paddingRight: "15px",
                          cursor: "pointer",
                          fontSize: "14px",
                        }}
                        onClick={this.refreshApiKey}
                      >
                        Refresh <MdRefresh size={17} />
                      </div>
                    )}
                    <a
                      className="justify-content-center align-items-center"
                      onClick={this.handleApiCopy}
                      style={{ cursor: "pointer", fontSize: "14px" }}
                    >
                      <IconCopy /> Copy
                    </a>
                  </div>
                </span>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  };
}

function mapStateToProps(state) {
  return state.checkAPIKey;
}

export default connect(mapStateToProps, { checkAPIKeyExpiry, setAPIKeyValid })(
  DeveloperPage
);