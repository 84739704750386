import React from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardText, CardTitle } from "reactstrap";
import { isEmptyObject } from "../../utils/BillingUtils";
import Typography from "../Typography";
export default ({ address, ...restProps }) => {
  let isAddressStored = address && !isEmptyObject(address, "isFetching");
  return (
    <Card {...restProps} style={{ minWidth: "200px" }}>
      <CardBody>
        <CardTitle className="mb-0">Billing Address</CardTitle>
        <div
          style={{ fontSize: "14px" }}
          className="d-flex justify-content-between mb-0 pb-0"
        >
          {isAddressStored && (
            <div className="d-flex flex-column justify-content-start align-items-start mt-1 mb-0">
              {address.Name && (
                <Typography className="mb-1 small">
                  <strong>Billing Admin:</strong> {address.Name}
                </Typography>
              )}

              <Typography className="mb-0 small">
                {address.Line1}
                <br />
                {address.Line2}
                <br />
                {address.City}, {address.State}, {address.PostalCode}
              </Typography>
            </div>
          )}
          {isAddressStored && (
            <Button
              outline
              tag={Link}
              to={{
                pathname: "/billingdetails",
                state: {
                  param: "billingaddress",
                  value: address
                }
              }}
              className="btn btn-default h-100 mt-auto"
            >
              EDIT
            </Button>
          )}
        </div>
        {!isAddressStored && (
          <CardText className="mt-2" style={{ fontSize: "14px" }}>
            No billing address stored. Please add.
          </CardText>
        )}
        <div className="text-right ">
          {!isAddressStored && (
            <Button
              outline
              tag={Link}
              to={{
                pathname: "/billingdetails",
                state: {
                  param: "billingaddress",
                  value: address
                }
              }}
              className="btn btn-default"
            >
              ADD
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};
