import {
  IS_API_KEY_VALID
} from "../actions/action_types";

export const CheckAPIKey = (state = {apiKeyExpired: false}, action) => {
  switch (action.type) {
    case IS_API_KEY_VALID:
      return { ...state, apiKeyExpired: action.payload.apiKeyExpired };
    default:
      return state;
  }
};