import React, { useState, useEffect } from "react";
import { ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";

import Select from "react-select";

import { showLoader, hideLoader } from "../actions/loaderActions";
import {
  sendPresentationRequestForDataAgreementInCloudagent,
  fetchConnectionsInCloudAgent,
} from "../services";

const VerificationRequestModal = ({
  dataAgreementId,
  dataAgreementPurpose,
  toggle,
  ...props
}) => {

  const [connections, setConnections] = useState([]);

  const [selectedConnection, setSelectedConnection] = useState(null);

  const [isOkToSendVerifyRequest, setIsOkToSendVerifyRequest] = useState(false);

  const headerStyle = {
    fontSize: "16px",
    backgroundColor: "#eeeeee",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  };

  const buttonStyle = {
    height: "1.8rem",
    width: "10rem",
    padding: 0,
    fontSize: "12px",
  };

  const textStyle = {
    color: "#212529",
    fontSize: "16px",
    paddingBottom: 1,
  };

  const modalHeaderTitleStyle = {
    marginRight: "auto",
    marginBlock: " auto",
    display: "flex"
  };

  const modalHeaderTitleSpanStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    maxWidth: "200px",
    marginLeft: "5px"
  };

  const customStyleForSelect = {

    control: (base, state) => ({

      ...base,

      "&:hover": { borderColor: "gray" }, // border style on hover

      border: "1px solid lightgray", // default border color

      boxShadow: "none", // no box-shadow

    }),

  };

  /**
   * Fetch all connections from the cloud agent.
   */
  const fetchConnections = () => {

    fetchConnectionsInCloudAgent(1, 250000).then((response) => {

      const connectionRecords = response.data.results;

        const data = connectionRecords.filter(obj => {
          return obj.their_label !=='MyData-DID-Registry'
        }).map((connection) => {

        const updatedConnection = {
          value: connection.connection_id,
          label: `${connection.connection_id} (${connection.their_label})`,
        }

        return updatedConnection;

      });

      setConnections(data);

    }).catch((err) => {

      // Handle error.

      console.log(err);

      setConnections([]);

    });

  };

  /**
   * Run once on component mount.
   */
  useEffect(() => {

    fetchConnections();

  }, []);

  const selectConnection = (value) => {

    setSelectedConnection(value);

    if (value) {

      setIsOkToSendVerifyRequest(true);

    } else {

      setIsOkToSendVerifyRequest(false);

    }

  };

  /**
   * Send verification request to the end user on clicking "Verify" button.
   */
  const onVerify = () => {

    // Show loader
    props.showLoader("VerificationRequestModal");

    const connectionId = selectedConnection.value;

    sendPresentationRequestForDataAgreementInCloudagent(dataAgreementId, connectionId).then((response) => {

      // Toggle modal.
      toggle();

      // Hide loader.
      props.hideLoader("VerificationRequestModal");

    }).catch((err) => {

      // Handler error.

      console.log(err);

      // Toggle modal.
      toggle();

      // Hide loader
      props.hideLoader("VerificationRequestModal");

    });

  };

  return (
    <div className="modal-content">

      <div>

        <div className="modal-header" style={headerStyle}>


          <h1 style={modalHeaderTitleStyle}>Send Verification Request: <span className="verification-request-modal-header-title-span"  style={modalHeaderTitleSpanStyle}>{dataAgreementPurpose}</span></h1>


          <button type="button" className="close" aria-label="Close" onClick={() => { toggle(); }}>


            <span aria-hidden="true">×</span>


          </button>



          {dataAgreementId && <p style={{ fontStyle: "italic", color: "gray", }}>{dataAgreementId}</p>}


        </div>

      </div>

      <ModalBody className="pb-0 ">

        <div className="form-group " key="connection_id">

          <label style={textStyle}> Connection ID<span className="required-field">*</span></label>

          <Select

            placeholder="Select a connection"

            isSearchable={true}

            isMulti={false}

            options={connections}

            name="connection_id"

            styles={customStyleForSelect}

            value={selectedConnection}

            onChange={selectConnection}

          />
        </div>

      </ModalBody>

      <ModalFooter className="modal-footer">

        <button

          style={buttonStyle}

          size="ig"

          className="btn btn-default"

          onClick={toggle}

        >
          Close
        </button>

        <button
          style={{
            ...buttonStyle,
            cursor: isOkToSendVerifyRequest ? "pointer" : "not-allowed"
          }}

          className="btn btn-default"

          disabled={!isOkToSendVerifyRequest}

          onClick={onVerify}

        >
          Verify
        </button>

      </ModalFooter>

    </div>

  );

};

const mapStateToProps = (state) => {
  return {};
}

export default connect(
  mapStateToProps,
  {
    showLoader,
    hideLoader,
  }
)(VerificationRequestModal);
