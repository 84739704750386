import {
    initDataAgreementState,
    initConfigureDataSource
} from "../reducers/DataAgreementReducer";

/**
 * Create / Populate data source configuration from data agreement record.
 * 
 * @param {*} dataAgreementRecord 
 */
export const createDataSourceConfigurationFromDataAgreementRecord = (dataAgreementRecord) => {

    const { is_existing_schema: isExisting, schema_id: schemaId } = dataAgreementRecord;

    return {
        ...initConfigureDataSource,
        isExisting: isExisting,
        schemaId: schemaId ? schemaId : "",
        isAttributesPopulatedInDataAgreement: isExisting,
    }
}

/**
 * Transform presentation request requested attributes to personal data restrictions map.
 * 
 * @param {*} presentationRequest 
 * @returns 
 */
export const transformPresentationRequestRequestedAttributesToPersonalData = (presentationRequest) => {


    const { requested_attributes: requestedAttributes } = presentationRequest;

    const transformedOutput = {};

    const emptyRestrictions = {
        "schema_id": "",
        "cred_def_id": "",
    };

    Object.keys(requestedAttributes).map((requestedAttribute) => {

        const tempRequestedAttribute = requestedAttributes[requestedAttribute];

        transformedOutput[tempRequestedAttribute.name] = tempRequestedAttribute.restrictions ? tempRequestedAttribute.restrictions : emptyRestrictions;

    });

    return transformedOutput;

}

/**
 * Transform data agreement record from cloudagent to react state. ( in object field naming conventions.)
 * 
 * @param {*} dataAgreementRecord 
 * @returns 
 */
export const transformDataAgreementRecordFromServerToClientFormat = (dataAgreementRecord) => {

    const { data_agreement: dataAgreementFromServer, presentation_request: presentationRequest, publish_flag: publishFlag } = dataAgreementRecord;

    const dataAgreement = {
        ...initDataAgreementState,
        dataControllerName: dataAgreementFromServer.data_controller_name ? dataAgreementFromServer.data_controller_name : "",
        dataControllerUrl: dataAgreementFromServer.data_controller_url ? dataAgreementFromServer.data_controller_url : "",
        dataPolicy: {
            policyUrl: dataAgreementFromServer.data_policy.policy_URL,
            jurisdiction: dataAgreementFromServer.data_policy.jurisdiction,
            industrySector: dataAgreementFromServer.data_policy.industry_sector,
            dataRetentionPeriod: dataAgreementFromServer.data_policy.data_retention_period,
            geographicRestriction: dataAgreementFromServer.data_policy.geographic_restriction,
            storageLocation: dataAgreementFromServer.data_policy.storage_location,
        },
        usagePurpose: dataAgreementFromServer.purpose,
        usagePurposeDescription: dataAgreementFromServer.purpose_description,
        lawfulBasis: dataAgreementFromServer.lawful_basis,
        methodOfUse: dataAgreementFromServer.method_of_use,
        personalData: [],
        isValid: true,
        templateId: dataAgreementFromServer.template_id ? dataAgreementFromServer.template_id : "",
        templateVersion: dataAgreementFromServer.template_version ? dataAgreementFromServer.template_version : 1,
    }

    let transformedRestrictions = null;

    if (dataAgreement.methodOfUse === "data-using-service" && publishFlag) {

        transformedRestrictions = transformPresentationRequestRequestedAttributesToPersonalData(presentationRequest);

    }

    // Iterate through personal data from server and convert to client format
    dataAgreementFromServer.personal_data.forEach(personalDataFromServer => {

        const tempPersonalData = {
            attributeName: personalDataFromServer.attribute_name,
            attributeDescription: personalDataFromServer.attribute_description,
        }

        if (dataAgreement.methodOfUse === "data-using-service") {

            if (publishFlag) {

                tempPersonalData.restrictions = transformedRestrictions[personalDataFromServer.attribute_name];

            } else {

                tempPersonalData.restrictions = [
                    {
                        "schema_id": "",
                        "cred_def_id": "",
                    }
                ];

            }


        }

        dataAgreement.personalData.push(tempPersonalData);

    })


    return dataAgreement;

}

/**
 * Transform data agreement from cloudagent to react state. ( in object field naming conventions.)
 * 
 * @param {*} dataAgreementFromServer 
 * @returns 
 */
export const transformDataAgreementFromServerToClientFormat = (dataAgreementFromServer) => {

    const dataAgreement = {
        ...initDataAgreementState,
        dataControllerName: dataAgreementFromServer.data_controller_name ? dataAgreementFromServer.data_controller_name : "",
        dataControllerUrl: dataAgreementFromServer.data_controller_url ? dataAgreementFromServer.data_controller_url : "",
        dataPolicy: {
            policyUrl: dataAgreementFromServer.data_policy.policy_URL,
            jurisdiction: dataAgreementFromServer.data_policy.jurisdiction,
            industrySector: dataAgreementFromServer.data_policy.industry_sector,
            dataRetentionPeriod: dataAgreementFromServer.data_policy.data_retention_period,
            geographicRestriction: dataAgreementFromServer.data_policy.geographic_restriction,
            storageLocation: dataAgreementFromServer.data_policy.storage_location,
        },
        usagePurpose: dataAgreementFromServer.purpose,
        usagePurposeDescription: dataAgreementFromServer.purpose_description,
        lawfulBasis: dataAgreementFromServer.lawful_basis,
        methodOfUse: dataAgreementFromServer.method_of_use,
        personalData: [],
        isValid: true,
        templateId: dataAgreementFromServer.template_id ? dataAgreementFromServer.template_id : "",
        templateVersion: dataAgreementFromServer.template_version ? dataAgreementFromServer.template_version : 1,
    }

    // Iterate through personal data from server and convert to client format
    dataAgreementFromServer.personal_data.forEach(personalDataFromServer => {

        const tempPersonalData = {
            attributeName: personalDataFromServer.attribute_name,
            attributeDescription: personalDataFromServer.attribute_description,
        }

        if (dataAgreement.methodOfUse === "data-using-service") {

            if (personalDataFromServer.restrictions) {

                tempPersonalData.restrictions = personalDataFromServer.restrictions;

            } else {

                // If no restrictions, set to empty array
                tempPersonalData.restrictions = [
                    {
                        "schema_id": "",
                        "cred_def_id": "",
                    }
                ];

            }

        }

        dataAgreement.personalData.push(tempPersonalData);

    })


    return dataAgreement;

}


export const transformDataAgreementFromClientToServerFormat = (dataAgreementFromClient) => {



    let { methodOfUse, personalData } = dataAgreementFromClient;

    // Loop through personal data and update "attributeName" to "attribute_name" and "attributeDescription" to "attribute_description"
    personalData.forEach(pd => {

        if (pd.attributeName) {

            pd.attribute_name = pd.attributeName;

            delete pd.attributeName;

        }

        if (pd.attributeDescription) {

            pd.attribute_description = pd.attributeDescription;

            delete pd.attributeDescription;

        }

    })



    // If method of use is DUS, loop through personal data, update restrictions to empty array if schema_id and cred_def_id not present.
    if (methodOfUse === "data-using-service") {

        personalData.forEach(pd => {

            let isRestrictionsEmpty = false;

            pd.restrictions.forEach(restriction => {

                if (restriction.schema_id.trim().length == 0 && restriction.cred_def_id.trim().length == 0) {

                    isRestrictionsEmpty = true;

                } else {

                    // Remove empty schema_id and cred_def_id

                    if (restriction.schema_id.trim().length == 0) {

                        delete restriction.schema_id;

                    }

                    if (restriction.cred_def_id.trim().length == 0) {

                        delete restriction.cred_def_id;

                    }

                }

            })

            if (isRestrictionsEmpty) {

                pd.restrictions = [];

            }

        });

    }

    const dpiaDate = new Date().toISOString().replace("Z", "+00:00");

    return {
        "@context": "https://raw.githubusercontent.com/decentralised-dataexchange/automated-data-agreements/main/interface-specs/data-agreement-schema/v1/data-agreement-schema-context.jsonld",
        "data_controller_name": dataAgreementFromClient.dataControllerName,
        "data_controller_url": dataAgreementFromClient.dataControllerUrl,
        "data_policy": {
            "data_retention_period": dataAgreementFromClient.dataPolicy.dataRetentionPeriod,
            "geographic_restriction": dataAgreementFromClient.dataPolicy.geographicRestriction,
            "industry_sector": dataAgreementFromClient.dataPolicy.industrySector,
            "jurisdiction": dataAgreementFromClient.dataPolicy.jurisdiction,
            "policy_URL": dataAgreementFromClient.dataPolicy.policyUrl,
            "storage_location": dataAgreementFromClient.dataPolicy.storageLocation
        },
        "dpia": {
            "dpia_date": dpiaDate,
            "dpia_summary_url": "https://privacyant.se/dpia_results.html"
        },
        "lawful_basis": dataAgreementFromClient.lawfulBasis,
        "method_of_use": dataAgreementFromClient.methodOfUse,
        "personal_data": personalData,
        "purpose": dataAgreementFromClient.usagePurpose,
        "purpose_description": dataAgreementFromClient.usagePurposeDescription
    }

}