import {
    ADD_PERSONAL_DATA_IN_DATA_AGREEMENT,
    INIT_DATA_AGREEMENT_STATE,
    UPDATE_PERSONAL_DATA_IN_DATA_AGREEMENT,
    UPDATE_DATA_CONTROLLER_NAME,
    UPDATE_JURISDICTION,
    UPDATE_INDUSTRY_SECTOR,
    UPDATE_DATA_RETENTION_PERIOD,
    UPDATE_POLICY_URL,
    UPDATE_DATA_CONTROLLER_URL,
    UPDATE_LAWFUL_BASIS,
    UPDATE_USAGE_PURPOSE,
    UPDATE_USAGE_PURPOSE_DESCRIPTION,
    UPDATE_METHOD_OF_USE,
    REPLACE_ALL_PERSONAL_DATA,
    UPDATE_DATA_SOURCE_CONFIGURATION,
    INIT_DATA_SOURCE_CONFIGURATION,
    TOGGLE_CONFIGURE_DATA_SOURCE_PANEL,
    REMOVE_PERSONAL_DATA_IN_DATA_AGREEMENT,
    VALIDATE_DATA_AGREEMENT_FORM,
    INIT_DATA_USING_SERVICE_CONFIGURATION,
    TOGGLE_CONFIGURE_DATA_USING_SERVICE_PANEL,
    UPDATE_GEOGRAPHIC_RESTRICTION,
    POPULATE_DATA_AGREEMENTS_TABLE,
    TOGGLE_DATA_AGREEMENT_PANEL,
    UPDATE_DATA_AGREEMENT_PANEL_MODE,
    REPLACE_DATA_AGREEMENT_STATE,
    INIT_DATA_AGREEMENT_PANEL_MODE,
    UPDATE_DATA_AGREEMENTS_TABLE_FILTER,
    POPULATE_PERSONAL_DATA_TABLE,
    UPDATE_PERSONAL_DATA_TABLE_FILTER,
    DATA_AGREEMENTS_TABLE_UPDATE_IS_TABLE_LOADING,
    DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_TOTAL_COUNT,
    DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_CURRENT_PAGE,
    DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_PAGE_SIZE,
    RESET_DATA_AGREEMENTS_TABLE,
    RESET_PERSONAL_DATA_TABLE,
    PERSONAL_DATA_TABLE_UPDATE_IS_TABLE_LOADING,
    PERSONAL_DATA_TABLE_UPDATE_PAGINATION_TOTAL_COUNT,
    PERSONAL_DATA_TABLE_UPDATE_PAGINATION_CURRENT_PAGE,
    PERSONAL_DATA_TABLE_UPDATE_PAGINATION_PAGE_SIZE
} from "./action_types";

import store from "../reduxStore/store";

import {
    queryDataAgreements,
    queryPersonalDataInCloudagent
} from "../services/index"


/**
 * Validate data agreement form.
 * 
 * @returns 
 */
export const validateDataAgreementForm = () => async (dispatch) => {

    const { DataAgreementStateUpdate: dataAgreementState } = store.getState();

    let isValid = true;


    if (dataAgreementState.dataControllerName === undefined || dataAgreementState.dataControllerName.trim().length === 0) {

        // Check data controller name is not empty
        isValid = false;

    } else if (dataAgreementState.dataControllerUrl === undefined || dataAgreementState.dataControllerUrl.trim().length === 0) {

        // Check data controller Url is not empty
        isValid = false;

    } else if (dataAgreementState.dataPolicy.policyUrl.trim().length === 0) {

        // Check data policy Url is not empty
        isValid = false;

    }
    else if (dataAgreementState.dataPolicy.jurisdiction.trim().length === 0) {

        // Check jurisdiction is not empty
        isValid = false;

    } else if (dataAgreementState.dataPolicy.industrySector.trim().length === 0) {

        // Check industry sector is not empty
        isValid = false;

    } else if (dataAgreementState.dataPolicy.dataRetentionPeriod === 0) {

        // Check data retention period is greater than 0
        isValid = false;

    } else if (dataAgreementState.dataPolicy.geographicRestriction.trim().length === 0) {

        // Check geographic restriction is not empty
        isValid = false;

    } else if (dataAgreementState.dataPolicy.storageLocation.trim().length === 0) {

        // Check storage location is not empty
        isValid = false;

    } else if (dataAgreementState.usagePurpose.trim().length === 0 || dataAgreementState.usagePurpose.trim().length < 3) {

        // Check usage purpose is not empty and has minimum 3 characters in length
        isValid = false;

    } else if (dataAgreementState.usagePurposeDescription.trim().length === 0 || dataAgreementState.usagePurposeDescription.trim().length < 3) {

        // Check usage purpose description is not empty and has minimum 3 characters in length
        isValid = false;

    } else if (dataAgreementState.personalData.length === 0) {

        // Check personal data is not empty (> 0)
        isValid = false;

    } else {

        // iterate through personal data and check if attribute name and description is not empty
        for (const personalData of dataAgreementState.personalData) {

            if (personalData.attributeName.trim().length === 0 || personalData.attributeName.trim().length < 3) {

                // Check attribute name is not empty and has minimum 3 characters in length
                isValid = false;

                break;

            } else if (personalData.attributeDescription.trim().length === 0 || personalData.attributeDescription.trim().length < 3) {

                // Check attribute description is not empty and has minimum 3 characters in length
                isValid = false;

                break;

            }
        }


    }

    dispatch({

        type: VALIDATE_DATA_AGREEMENT_FORM,
        payload: {
            isValid: isValid
        }

    });
}

/**
 * Reset data agreement state.
 * 
 * @returns 
 */
export const resetDataAgreementState = () => async (dispatch) => {

    dispatch({
        type: INIT_DATA_AGREEMENT_STATE
    });

}


/**
 * Add personal data in data agreement.
 * 
 * @param {*} personalDataObj 
 * @returns 
 */
export const addPersonalDataInDataAgreementState = (personalDataObj) => async (dispatch) => {

    dispatch({
        type: ADD_PERSONAL_DATA_IN_DATA_AGREEMENT,
        payload: {
            personalData: personalDataObj
        }
    });

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}


/**
 * Update personal data at index in data agreement.
 * 
 * @param {*} index 
 * @param {*} personalDataObj 
 * @returns 
 */
export const updatePersonalDataInDataAgreementState = (index, personalDataObj) => async (dispatch) => {

    dispatch({
        type: UPDATE_PERSONAL_DATA_IN_DATA_AGREEMENT,
        payload: {
            index: index,
            personalData: personalDataObj
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update data controller name in data agreement.
 * 
 * @param {*} dataControllerName 
 * @returns 
 */
export const updateDataControllerNameInDataAgreementState = (dataControllerName) => async (dispatch) => {

    dispatch({
        type: UPDATE_DATA_CONTROLLER_NAME,
        payload: {
            dataControllerName: dataControllerName
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update jurisdiction in data agreement.
 * 
 * @param {*} jurisdiction 
 * @returns 
 */
export const updateJurisdictionInDataAgreementState = (jurisdiction) => async (dispatch) => {

    dispatch({
        type: UPDATE_JURISDICTION,
        payload: {
            jurisdiction: jurisdiction
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update industry sector in data agreement.
 * 
 * @param {*} industrySector 
 * @returns 
 */
export const updateIndustrySectorInDataAgreementState = (industrySector) => async (dispatch) => {

    dispatch({
        type: UPDATE_INDUSTRY_SECTOR,
        payload: {
            industrySector: industrySector
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update data retention period in data agreement.
 * 
 * @param {*} dataRetentionPeriod 
 * @returns 
 */
export const updateDataRetentionPeriodInDataAgreementState = (dataRetentionPeriod) => async (dispatch) => {

    dispatch({
        type: UPDATE_DATA_RETENTION_PERIOD,
        payload: {
            dataRetentionPeriod: dataRetentionPeriod
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}


/**
 * Update policy url in data agreement.
 * 
 * @param {*} policyUrl 
 * @returns 
 */
export const updatePolicyUrlInDataAgreementState = (policyUrl) => async (dispatch) => {

    dispatch({
        type: UPDATE_POLICY_URL,
        payload: {
            policyUrl: policyUrl
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update data controller url in data agreement.
 * 
 * @param {*} dataControllerUrl 
 * @returns 
 */
export const updateDataControllerUrlInDataAgreement = (dataControllerUrl) => async (dispatch) => {

    dispatch({
        type: UPDATE_DATA_CONTROLLER_URL,
        payload: {
            dataControllerUrl: dataControllerUrl
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update lawful basis in data agreement.
 * 
 * @param {*} lawfulBasis 
 * @returns 
 */
export const updateLawfulBasisInDataAgreementState = (lawfulBasis) => async (dispatch) => {

    dispatch({
        type: UPDATE_LAWFUL_BASIS,
        payload: {
            lawfulBasis: lawfulBasis
        }
    })

}

/**
 * Update usage purpose in data agreement.
 * 
 * @param {*} updatedUsagePurpose 
 * @returns 
 */
export const updateUsagePurposeInDataAgreementState = (updatedUsagePurpose) => async (dispatch) => {

    dispatch({
        type: UPDATE_USAGE_PURPOSE,
        payload: {
            usagePurpose: updatedUsagePurpose
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update usage purpose description in data agreement.
 * 
 * @param {*} usagePurposeDescription 
 * @returns 
 */
export const updateUsagePurposeDescriptionInDataAgreementState = (usagePurposeDescription) => async (dispatch) => {

    dispatch({
        type: UPDATE_USAGE_PURPOSE_DESCRIPTION,
        payload: {
            usagePurposeDescription: usagePurposeDescription
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update method of use in data agreement.
 * 
 * @param {*} methodOfUse 
 * @returns 
 */
export const updateMethodOfUseInDataAgreementState = (methodOfUse) => async (dispatch) => {

    dispatch({
        type: UPDATE_METHOD_OF_USE,
        payload: {
            methodOfUse: methodOfUse
        }
    })

}

/**
 * Replace all personal data in data agreement.
 * 
 * @param {*} personalDataArray 
 * @returns 
 */
export const replaceAllPersonalData = (personalDataArray) => async (dispatch) => {

    dispatch({
        type: REPLACE_ALL_PERSONAL_DATA,
        payload: {
            personalData: personalDataArray
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update data source configuration.
 * 
 * @param {*} dataSourceConfiguration 
 * 
 * {
 *      isExisting: false, // true if schema identifier exists in ledger
 *      schemaId: "", // schema identifier
 *      openConfigureDataSourcePanel: false, // true if "CONFIGURE" data source panel is open
 *      isAttributesPopulatedInDataAgreement: false, // true if attributes from schemaId are populated in data agreement
 * }
 * 
 * @returns 
 */
export const updateDataSourceConfiguration = (dataSourceConfiguration) => async (dispatch) => {

    dispatch({
        type: UPDATE_DATA_SOURCE_CONFIGURATION,
        payload: {
            dataSourceConfiguration: dataSourceConfiguration
        }
    })

}

/**
 * Reset data source configuration.
 * 
 * @returns 
 */
export const initDataSourceConfiguration = () => async (dispatch) => {

    dispatch({
        type: INIT_DATA_SOURCE_CONFIGURATION
    })

}

/**
 * Toggle configure data source panel
 * 
 * @returns 
 */
export const toggleConfigureDataSourcePanel = () => async (dispatch) => {

    dispatch({
        type: TOGGLE_CONFIGURE_DATA_SOURCE_PANEL
    })

}

/**
 * Delete personal data at index in data agreement.
 * 
 * @param {*} index 
 * @returns 
 */
export const deletePersonalDataInDataAgreementStateAction = (index) => async (dispatch) => {

    dispatch({
        type: REMOVE_PERSONAL_DATA_IN_DATA_AGREEMENT,
        payload: {
            index: index
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Reset data using service configuration.
 * 
 * @returns 
 */
export const initDataUsingServiceConfiguration = () => async (dispatch) => {

    dispatch({
        type: INIT_DATA_USING_SERVICE_CONFIGURATION
    })

}

/**
 * Toggle configure data using service panel.
 * 
 * @returns 
 */
export const toggleConfigureDataUsingServicePanel = () => async (dispatch) => {

    dispatch({
        type: TOGGLE_CONFIGURE_DATA_USING_SERVICE_PANEL
    })

}

/**
 * Update geographic restriction in data agreement.
 * 
 * @param {*} geographicRestriction 
 * @returns 
 */
export const updateGeographicRestriction = (geographicRestriction) => async (dispatch) => {

    dispatch({
        type: UPDATE_GEOGRAPHIC_RESTRICTION,
        payload: {
            geographicRestriction: geographicRestriction
        }
    })

    // Validate data agreement form.
    dispatch(validateDataAgreementForm());

}

/**
 * Update data agreeement table pagination - is table loading state.
 * 
 * @param {*} isTableLoading 
 * @returns 
 */
export const updateDataAgreementTablePaginationIsTableLoadingState = (isTableLoading) => async (dispatch) => {

    dispatch({
        type: DATA_AGREEMENTS_TABLE_UPDATE_IS_TABLE_LOADING,
        payload: {
            isTableLoading: isTableLoading
        }
    })

}

/**
 * Update data agreement table pagination - pagination total count.
 * 
 * @param {*} totalCount 
 * @returns 
 */
export const updateDataAgreementTablePaginationTotalCount = (totalCount) => async (dispatch) => {

    dispatch({
        type: DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_TOTAL_COUNT,
        payload: {
            totalCount: totalCount
        }
    })


}

/**
 * Update data agreement table pagination - pagination current page.
 * 
 * @param {*} currentPage 
 * @returns 
 */
export const updateDataAgreementTablePaginationCurrentPage = (currentPage) => async (dispatch) => {

    dispatch({
        type: DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_CURRENT_PAGE,
        payload: {
            currentPage: currentPage
        }
    })


}

/**
 * Update data agreement table pagination - pagination page size.
 * 
 * @param {*} pageSize 
 * @returns 
 */
export const updateDataAgreementTablePaginationPageSize = (pageSize) => async (dispatch) => {

    dispatch({
        type: DATA_AGREEMENTS_TABLE_UPDATE_PAGINATION_PAGE_SIZE,
        payload: {
            pageSize: pageSize
        }
    })

}



/**
 * Populate data agreement tables. (including personal data)
 * 
 * @returns 
 */
export const populateDataAgreementsTable = () => async (dispatch) => {

    const {
        DataAgreementTable: {
            filter,
            paginationCurrentPage,
            paginationPageSize
        }
    } = store.getState();

    const publishFlag = filter === "Published" ? true : null;


    // Show the table loader.

    dispatch(updateDataAgreementTablePaginationIsTableLoadingState(true));


    // Fetch data agreements from cloudagent.
    queryDataAgreements(paginationCurrentPage, paginationPageSize, publishFlag).then((res) => {

        const data = res.data.results;
        const pagination = res.data.pagination;

        // Set the total count of pagination
        dispatch(updateDataAgreementTablePaginationTotalCount(pagination.totalCount));

        // Populate table.
        dispatch({
            type: POPULATE_DATA_AGREEMENTS_TABLE,
            payload: {
                dataAgreements: data,
            }
        });

        // Hide the table loader.
        dispatch(updateDataAgreementTablePaginationIsTableLoadingState(false));

    }).catch((err) => {

        console.log(err);

        // Hide the table loader.
        dispatch(updateDataAgreementTablePaginationIsTableLoadingState(false));

    });

}

/**
 * Reset data agreement table to initial state.
 * 
 * @returns 
 */
export const resetDataAgreementTableToInitialState = () => async (dispatch) => {

    dispatch({
        type: RESET_DATA_AGREEMENTS_TABLE
    })

}


/**
 * Reset personal data table to initial state.
 * 
 * @returns 
 */
export const resetPersonalDataTableToInitialState = () => async (dispatch) => {

    dispatch({
        type: RESET_PERSONAL_DATA_TABLE
    })

}


/**
 * Update personal data table pagination - is table loading state.
 * 
 * @param {*} isTableLoading 
 * @returns 
 */
export const updatePersonalDataTablePaginationIsTableLoadingState = (isTableLoading) => async (dispatch) => {

    dispatch({
        type: PERSONAL_DATA_TABLE_UPDATE_IS_TABLE_LOADING,
        payload: {
            isTableLoading: isTableLoading
        }
    })

}

/**
 * Update personal data table pagination - pagination total count.
 * 
 * @param {*} totalCount 
 * @returns 
 */
export const updatePersonalDataTablePaginationTotalCount = (totalCount) => async (dispatch) => {

    dispatch({
        type: PERSONAL_DATA_TABLE_UPDATE_PAGINATION_TOTAL_COUNT,
        payload: {
            totalCount: totalCount
        }
    })


}

/**
 * Update personal data table pagination - pagination current page.
 * 
 * @param {*} currentPage 
 * @returns 
 */
export const updatePersonalDataTablePaginationCurrentPage = (currentPage) => async (dispatch) => {

    dispatch({
        type: PERSONAL_DATA_TABLE_UPDATE_PAGINATION_CURRENT_PAGE,
        payload: {
            currentPage: currentPage
        }
    })


}

/**
 * Update personal data table pagination - pagination page size.
 * 
 * @param {*} pageSize 
 * @returns 
 */
export const updatePersonalDataTablePaginationPageSize = (pageSize) => async (dispatch) => {

    dispatch({
        type: PERSONAL_DATA_TABLE_UPDATE_PAGINATION_PAGE_SIZE,
        payload: {
            pageSize: pageSize
        }
    })

}

/**
 * Populate personal data table.
 * 
 * @returns 
 */
export const populatePersonalDataTable = () => async (dispatch) => {

    // Show the table loader.

    dispatch(updatePersonalDataTablePaginationIsTableLoadingState(true));

    const {
        PersonalDataTable:
        {
            filter,
            paginationCurrentPage,
            paginationPageSize
        }
    } = store.getState();

    let methodOfUse = null;

    switch (filter) {

        case "All":
            methodOfUse = null;
            break;

        case "Data Using Service":
            methodOfUse = "data-using-service";
            break;

        case "Data Source":
            methodOfUse = "data-source";
            break;

    }

    // Fetch data agreements from cloudagent.
    queryPersonalDataInCloudagent(paginationCurrentPage, paginationPageSize, methodOfUse).then((res) => {

        const data = res.data.results;
        const pagination = res.data.pagination;

        // Set the total count of pagination
        dispatch(updatePersonalDataTablePaginationTotalCount(pagination.totalCount));

        dispatch({
            type: POPULATE_PERSONAL_DATA_TABLE,
            payload: {
                personalData: data,
            }
        });

        // Hide the table loader.

        dispatch(updatePersonalDataTablePaginationIsTableLoadingState(false));


    }).catch((err) => {

        // Handle error.

        console.log(err);

        // Hide the table loader.

        dispatch(updatePersonalDataTablePaginationIsTableLoadingState(false));

    });

}

/**
 * Toggle data agreement panel. (show/hide)
 * 
 * @returns 
 */
export const toggleDataAgreementPanel = () => async (dispatch) => {

    dispatch({
        type: TOGGLE_DATA_AGREEMENT_PANEL
    })

}

/**
 * Replace data agreement state.
 * 
 * @param {*} dataAgreementState 
 * @returns 
 */
export const replaceDataAgreementState = (dataAgreementState) => async (dispatch) => {

    dispatch({
        type: REPLACE_DATA_AGREEMENT_STATE,
        payload: {
            dataAgreementState: dataAgreementState
        }
    })

}


/**
 * Update data agreement panel mode (CREATE, READ, EDIT).
 * 
 * @param {*} dataAgreement 
 * @param {*} mode 
 * @returns 
 */
export const updateDataAgreementPanelMode = (dataAgreement, mode) => async (dispatch) => {

    // Update data agreement state.
    dispatch(replaceDataAgreementState(dataAgreement));

    // Dispatch data agreement mode.
    dispatch({
        type: UPDATE_DATA_AGREEMENT_PANEL_MODE,
        payload: {
            mode: mode
        }
    })


}

/**
 * Reset data agreement panel state.
 * 
 * @returns 
 */
export const resetDataAgreementPanelMode = () => async (dispatch) => {

    dispatch({
        type: INIT_DATA_AGREEMENT_PANEL_MODE
    })


}

/**
 * Update data agreements table filter. (All / Published)
 * 
 * @param {*} filter 
 * @returns 
 */
export const updateDataAgreementTableFilter = (filter) => async (dispatch) => {

    dispatch({
        type: UPDATE_DATA_AGREEMENTS_TABLE_FILTER,
        payload: {
            filter: filter
        }
    })

}

/**
 * Update personal data table filter. (All / Data Source / Data Using Service)
 * 
 * @param {*} filter 
 * @returns 
 */
export const updatePersonalDataTableFilter = (filter) => async (dispatch) => {

    dispatch({
        type: UPDATE_PERSONAL_DATA_TABLE_FILTER,
        payload: {
            filter: filter
        }
    })

}