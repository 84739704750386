import axios from 'axios';

import { eulaEndpoint, notifyDataBreach, notifyEvent } from './endpoints';
import { store } from './localStorageStore';
import {
    transformDataAgreementFromClientToServerFormat
} from "../utils/dataAgreementUtils";
import {
    fetchCloudAgentAdminUrl
} from "../utils";
import {
    fetchApiKey,
    fetchAccessToken,
    fetchOrgsFromLocalStorage
} from "../utils/localStorageUtils";

import jsonData from "../setting/settings.json";


export const updateEulaNotification = (orgId, eulaUrl) => {
    let data = {
        eulaURL: eulaUrl
    }
    if (store.getData('data') != null && store.getData('orgs') != null) {
        let config = { headers: { 'Authorization': `Bearer ${store.getData("data").Token.access_token}` } };
        return axios.post(eulaEndpoint(orgId), data || {}, config);
    }
    return null;
};

export const dataBreachNotification = (orgId, values) => {
    let data = {
        HeadLine: values['Nature of personal data breach'],
        UsersCount: values['Approximate number of data subjects'],
        DpoEmail: values['DPO contact email for more info.'],
        Consequence: values['Likely consequences of the personal data breach'],
        Measures: values['Measures to be taken']
    };

    if (store.getData('data') != null && store.getData('orgs') != null) {
        let config = { headers: { 'Authorization': `Bearer ${store.getData("data").Token.access_token}` } };
        return axios.post(notifyDataBreach(orgId), data || {}, config);
    }
    return null;
};

export const eventNotification = (orgId, details) => {

    if (store.getData('data') != null && store.getData('orgs') != null) {
        let config = { headers: { 'Authorization': `Bearer ${store.getData("data").Token.access_token}` } };
        return axios.post(notifyEvent(orgId), { "details": details } || {}, config);
    }
    return null;
};

/**
 * Query data agreements from cloudagent.
 * 
 * @returns 
 */
export const queryDataAgreements = (page = 1, pageSize = 10, publishFlag = null) => {

    let queryParams = `?page=${page}&page_size=${pageSize}`;

    // If publish flag available - ("false", "true")
    if (publishFlag !== null) {

        queryParams += `&publish_flag=${publishFlag}`;

    }

    const url = fetchCloudAgentAdminUrl() + "/v1/data-agreements" + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {
        headers: {
            Authorization: "ApiKey " + apiKey,
        },
    };

    return axios.get(url, axiosConfig);
}

/**
 * Query data agreements by data agreement id.
 * 
 * @returns 
 */
export const queryDataAgreementById = (dataAgreementId) => {

    let queryParams = `?data_agreement_id=${dataAgreementId}`;

    const url = fetchCloudAgentAdminUrl() + "/v1/data-agreements" + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {
        headers: {
            Authorization: "ApiKey " + apiKey,
        },
    };

    return axios.get(url, axiosConfig);
}

/**
 * Query personal data in cloudagent.
 * 
 * @param {*} page 
 * @param {*} pageSize 
 * @param {*} methodOfUse 
 */
export const queryPersonalDataInCloudagent = (page = 1, pageSize = 10, methodOfUse = null) => {

    let queryParams = `?page=${page}&page_size=${pageSize}`;

    // If method of use available - ("data-source", "data-using-service")
    if (methodOfUse !== null) {

        queryParams += `&method_of_use=${methodOfUse}`;

    }

    const url = fetchCloudAgentAdminUrl() + "/v1/data-agreements/personal-data" + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {
        headers: {
            Authorization: "ApiKey " + apiKey,
        },
    };

    return axios.get(url, axiosConfig);

}

/**
 * Query published data source data agreements from cloudagent.
 * 
 * @returns 
 */
export const queryPublishedDataSourceDataAgreements = (page = 1, pageSize = 10) => {

    const queryParams = `?method_of_use=data-source&publish_flag=true&page=${page}&page_size=${pageSize}`;

    const url = fetchCloudAgentAdminUrl() + "/v1/data-agreements" + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {
        headers: {
            Authorization: "ApiKey " + apiKey,
        },
    };

    return axios.get(url, axiosConfig);
}


/**
 * Query published data using service data agreements from cloudagent.
 * 
 * @returns 
 */
export const queryPublishedDataUsingServiceDataAgreements = (page = 1, pageSize = 10) => {

    const queryParams = `?method_of_use=data-using-service&publish_flag=true&page=${page}&page_size=${pageSize}`;

    const url = fetchCloudAgentAdminUrl() + "/v1/data-agreements" + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {
        headers: {
            Authorization: "ApiKey " + apiKey,
        },
    };

    return axios.get(url, axiosConfig);
}

/**
 * Fetch global data policy configuration for the currently logged in organisation.
 * 
 * @returns 
 */
export const fetchGlobalDataPolicyConfiguration = () => {

    const api = JSON.parse(JSON.stringify(jsonData)).api;

    const orgs = fetchOrgsFromLocalStorage();
    const orgId = orgs ? orgs.ID : "sampleOrgId";

    const url = `${api}v1/organizations/${orgId}/global-policy-configuration`;

    const accessToken = fetchAccessToken();

    const axiosConfig = {
        headers: {
            Authorization: "Bearer " + accessToken,
        },
    };

    return axios.get(url, axiosConfig);

}


/**
 * Fetch all the available lawful basis.
 * 
 * @returns 
 */
export const fetchAllLawfulBasis = () => {

    const api = JSON.parse(JSON.stringify(jsonData)).api;
    const url = `${api}v1/data-agreements/lawful-basis`;

    const axiosConfig = {
        headers: {
            Authorization: "Bearer " + store.getData("data").Token.access_token,
        },
    };

    return axios.get(url, axiosConfig);
}

/**
 * Fetch cloudagent configuration for an org.
 *  
 * @returns 
 */
export const fetchCloudAgentConfigForOrg = () => {
    const api = JSON.parse(JSON.stringify(jsonData)).api;
    const url = api + "v1/organizations/" + store.getData("orgs").ID + "/aries-cloudagent";

    const axiosConfig = {
        headers: {
            Authorization: "Bearer " + store.getData("data").Token.access_token,
        },
    };

    return axios.get(url, axiosConfig)
}

/**
 * Fetch attributes for schema from ledger.
 * 
 * @param {*} schemaId 
 */
export const fetchAttributeForSchemaFromLedger = (schemaId, adminEndpoint) => {
    const url = adminEndpoint + "/schemas/" + schemaId;
    const axiosConfig = {
        headers: {
            Authorization: "ApiKey " + store.getData("data").User.APIKey,
        },
    };
    return axios.get(url, axiosConfig)
}

/**
 * Fetch pre-defined verification templates (DUS) from iGrant.io backend. 
 */
export const fetchPreDefinedVerificationTemplates = () => {

    const api = JSON.parse(JSON.stringify(jsonData)).api;
    const url = `${api}/v1/aries-cloudagent/verification-templates/pre-defined`;

    const axiosConfig = {
        headers: {
            Authorization: "Bearer " + store.getData("data").Token.access_token,
        },
    };

    return axios.get(url, axiosConfig)
}

/**
 * Create data agreement in cloudagent.
 * 
 * @param {*} dataAgreementState 
 * @param {*} dataSourceConfiguration 
 * @param {*} draft 
 * @returns 
 */
export const createDataAgreementInCloudagent = (dataAgreementState, dataSourceConfiguration = {}, draft = false) => {
    let { methodOfUse } = dataAgreementState;
    const { isExisting, schemaId } = dataSourceConfiguration;

    const reqPayload = transformDataAgreementFromClientToServerFormat(dataAgreementState);


    let queryParams = "?draft=" + draft;

    if (methodOfUse === "data-source" && isExisting) {

        queryParams += "&existing_schema_id=" + schemaId;

    }


    const url = store.getData("orgs").AriesCloudAgentDeployment.AdminEndpoint + "/v1/data-agreements";

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + store.getData("data").User.APIKey,
        },
        validateStatus: function (status) {
            return status == 201; // Resolve only if the status code is 201
        }

    };

    return axios.post(url + queryParams, reqPayload, axiosConfig);

}

/**
 * Update data agreement in cloudagent.
 * 
 * @param {*} dataAgreementState 
 * @param {*} dataSourceConfiguration 
 * @param {*} draft 
 * @returns 
 */
export const udpateDataAgreementInCloudagent = (dataAgreementState, dataSourceConfiguration = {}, draft = false) => {

    let { methodOfUse, templateId } = dataAgreementState;
    const { isExisting, schemaId } = dataSourceConfiguration;

    const reqPayload = transformDataAgreementFromClientToServerFormat(dataAgreementState);


    let queryParams = "?draft=" + draft;

    if (methodOfUse === "data-source" && isExisting) {

        queryParams += "&existing_schema_id=" + schemaId;

    }


    const url = store.getData("orgs").AriesCloudAgentDeployment.AdminEndpoint + "/v1/data-agreements/" + templateId;

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + store.getData("data").User.APIKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.put(url + queryParams, reqPayload, axiosConfig);
}

/**
 * Delete data agreement in cloudagent.
 * 
 * @param {*} dataAgreementId 
 * @returns 
 */
export const deleteDataAgreementInCloudagent = (dataAgreementId) => {

    const url = store.getData("orgs").AriesCloudAgentDeployment.AdminEndpoint + "/v1/data-agreements/" + dataAgreementId;

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + store.getData("data").User.APIKey,
        },
        validateStatus: function (status) {
            return status == 204; // Resolve only if the status code is 204
        }

    };

    return axios.delete(url, axiosConfig);

}

/**
 * Generate Qr code for a data agreement.
 * 
 * @param {*} dataAgreementId 
 */
export const generateQrCodeForDataAgreement = (dataAgreementId, multi_use = "True") => {

    const url = fetchCloudAgentAdminUrl() + "/v1/data-agreements/" + dataAgreementId + "/qr?multi_use=" + multi_use;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 201; // Resolve only if the status code is 201
        }

    };

    return axios.post(url, null, axiosConfig);


}

/**
 * Generate firebase dynamic link for a data agreement qr code.
 * 
 * @param {*} qrId 
 * @param {*} dataAgreementId 
 */
export const generateFirebaseDynamicLinkForDataAgreementQrCode = (qrId, dataAgreementId) => {

    const url = fetchCloudAgentAdminUrl() + "/v1/data-agreements/" + dataAgreementId + "/qr/" + qrId + "/firebase";

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.post(url, null, axiosConfig);

}

/**
 * Mock service.
 * 
 * @returns 
 */
export const mockUserService = () => {

    const url = fetchCloudAgentAdminUrl() + "/user";

    return axios.get(url);

}

/**
 * Fetch available organisation types (Industry sectors ?) from iGrant.io backend.
 * 
 * @returns 
 */
export const fetchOrganisationTypes = () => {

    const api = JSON.parse(JSON.stringify(jsonData)).api;

    const accessToken = fetchAccessToken();

    const axiosConfig = {
        headers: {
            Authorization: "Bearer " + accessToken,
        },
    };

    const url = `${api}v1/organizations/types`;

    return axios.get(url, axiosConfig)

}

/**
 * Update global data policy configuration.
 * 
 * @param {*} policyUrl 
 * @param {*} dataRetentionPeriod 
 * @param {*} jurisdiction 
 * @param {*} industryScope 
 * @param {*} geographicRestriction 
 * @returns 
 */
export const updateGlobalDataPolicyConfiguration = (
    policyUrl,
    dataRetentionPeriod,
    jurisdiction,
    industryScope,
    geographicRestriction
) => {

    const api = JSON.parse(JSON.stringify(jsonData)).api;

    const accessToken = fetchAccessToken();

    const orgs = fetchOrgsFromLocalStorage();

    const orgId = orgs ? orgs.ID : "sampleOrgId";

    const url = `${api}v1/organizations/${orgId}/global-policy-configuration`;

    const axiosConfig = {
        headers: {
            Authorization: "Bearer " + accessToken,
        },
    };

    const data = {
        policyurl: policyUrl,
        retentionperiod: dataRetentionPeriod,
        jurisdiction: jurisdiction,
        disclosure: "true",
        typeid: industryScope,
        restriction: geographicRestriction,
        shared3pp: true,
    };

    return axios.post(url, data, axiosConfig)

}

/**
 * Create connection invitation for cloudagent.
 */
export const createConnectionInvitationForCloudagent = () => {

    const url = fetchCloudAgentAdminUrl() + "/v2/connections/create-invitation?multi_use=true";

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.post(url, null, axiosConfig);

}

/**
 * Generate firebase dynamic link for connection invitation.
 * 
 * @param {*} connectionId 
 * @returns 
 */
export const generateFirebaseDynamicLinkForConnectionInvitation = (connectionId) => {

    const url = fetchCloudAgentAdminUrl() + `/v1/connections/${connectionId}/invitation/firebase`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.post(url, null, axiosConfig);

}

/**
 * Publish data agreement in cloudagent.
 * 
 * @param {*} dataAgreementId 
 */
export const publishDataAgreementInCloudagent = (dataAgreementId) => {

    const url = fetchCloudAgentAdminUrl() + `/v1/data-agreements/${dataAgreementId}/publish`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.post(url, null, axiosConfig);

}

/**
 * Update personal data in cloudagent.
 * 
 * @param {*} personalDataId 
 * @param {*} personalDataDescription 
 * @returns 
 */
export const updatePersonalDataInCloudagent = (personalDataId, personalDataDescription) => {

    const url = fetchCloudAgentAdminUrl() + `/v1/data-agreements/personal-data/${personalDataId}`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    const data = {
        attribute_description: personalDataDescription
    };

    return axios.put(url, data, axiosConfig);
}

/**
 * Delete personal data in cloudagent.
 * 
 * @param {*} personalDataId 
 * @returns 
 */
export const deletePersonalDataInCloudagent = (personalDataId) => {

    const url = fetchCloudAgentAdminUrl() + `/v1/data-agreements/personal-data/${personalDataId}`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 204; // Resolve only if the status code is 204
        }

    };

    return axios.delete(url, axiosConfig);
}

/**
 * Fetch connections in cloudagent (v2).
 */
export const fetchConnectionsInCloudAgent = (page = 1, pageSize = 10) => {

    const queryParams = `?state=active&page=${page}&page_size=${pageSize}`;

    const url = fetchCloudAgentAdminUrl() + `/v2/connections` + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 204
        }

    };

    return axios.get(url, axiosConfig);

}

/**
 * Delete connection in cloudagent.
 * 
 * @param {*} connectionId 
 * @returns 
 */
export const deleteConnectionsInCloudagent = (connectionId) => {

    const url = fetchCloudAgentAdminUrl() + `/connections/${connectionId}`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.delete(url, axiosConfig);


}

/**
 * Issue credential in cloudagent.
 * 
 * @param {*} param0 
 * @returns 
 */
export const issueCredentialsInCloudagent = ({
    dataAgreementId,
    dataAgreementTemplatePurpose,
    credDefId,
    connectionId,
    attributes
}) => {

    const url = fetchCloudAgentAdminUrl() + `/issue-credential/send-offer`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    const payload = {
        comment: dataAgreementTemplatePurpose,
        auto_remove: false,
        trace: false,
        cred_def_id: credDefId,
        connection_id: connectionId,
        credential_preview: {
            "@type": "did:sov:BzCbsNYhMrjHiqZDTUASHg;spec/issue-credential/1.0/credential-preview",
            attributes: attributes,
        },
        auto_issue: true,
        data_agreement_id: dataAgreementId,
    };

    return axios.post(url, payload, axiosConfig);


}

/**
 * Send presentation request for data agreement in cloudagent.
 * 
 * @param {*} dataAgreementId 
 * @param {*} connectionId 
 */
export const sendPresentationRequestForDataAgreementInCloudagent = (dataAgreementId, connectionId) => {

    const url = fetchCloudAgentAdminUrl() + `/present-proof/data-agreement-negotiation/offer`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    const payload = {
        connection_id: connectionId,
        data_agreement_id: dataAgreementId,
    };

    return axios.post(url, payload, axiosConfig);

}

/**
 * Fetch verified presentation exchange records in cloudagent.
 * 
 * @param {*} dataAgreementId 
 * @returns 
 */
export const fetchVerifiedPresentationExchangeRecordsInCloudagent = (dataAgreementId, page = 1, pageSize = 10) => {

    const queryParams = `?state=verified&data_agreement_template_id=${dataAgreementId}&page=${page}&page_size=${pageSize}`;

    const url = fetchCloudAgentAdminUrl() + `/present-proof/records` + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.get(url, axiosConfig);

}

/**
 * Fetch issue credential records in cloudagent.
 * 
 * @param {*} dataAgreementId 
 * @returns 
 */
export const fetchIssueCredentialRecordsInCloudagent = (dataAgreementId, page = 1, pageSize = 10) => {

    const queryParams = `?data_agreement_template_id=${dataAgreementId}&page=${page}&page_size=${pageSize}`;

    const url = fetchCloudAgentAdminUrl() + `/issue-credential/records` + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.get(url, axiosConfig);

}

/**
 * Fetch data agreement signed instances in cloudagent.
 * 
 * @param {*} dataAgreementId 
 * @param {*} methodOfUse 
 */
export const fetchDataAgreementSignedInstancesInCloudagent = (dataAgreementId, methodOfUse) => {

    const queryParams = `?method_of_use=${methodOfUse}&data_agreement_template_id=${dataAgreementId}`;

    const url = fetchCloudAgentAdminUrl() + `/v1/data-agreement-instances` + queryParams;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.get(url, axiosConfig);

}

/**
 * Delete presentation exchange records in cloudagent.
 * 
 * @param {*} presentationExchangeId 
 * @returns 
 */
export const deletePresentationExchangeRecordInCloudagent = (presentationExchangeId) => {

    const url = fetchCloudAgentAdminUrl() + `/present-proof/records/${presentationExchangeId}`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.delete(url, axiosConfig);

}

/**
 * Delete issue-credential record in cloud agent.
 * 
 * @param {*} presentationExchangeId 
 * @returns 
 */
export const deleteIssueCredentialRecordInCloudagent = (credentialExchangeId) => {

    const url = fetchCloudAgentAdminUrl() + `/issue-credential/records/${credentialExchangeId}`;

    const apiKey = fetchApiKey();

    const axiosConfig = {

        headers: {
            Authorization: "ApiKey " + apiKey,
        },
        validateStatus: function (status) {
            return status == 200; // Resolve only if the status code is 200
        }

    };

    return axios.delete(url, axiosConfig);

}