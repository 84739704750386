import React from 'react';
import axios from 'axios';
import OrgImageUpload from '../components/OrgImageUpload';
import OrgLogoUpload from '../components/OrgLogoUpload';
import jsonData from '../setting/settings.json';
import {
    Card, CardTitle, CardBody, CardImg, CardText, CardLink, Row, Col, ButtonGroup,
    Button, Input,
} from 'reactstrap';
import { subHeading } from '../demos/cardPage';
import { GetOrganizationDetails, GetUserDetails, } from '../components/Sources';
import Page from '../components/Page';
import { store } from '../services/localStorageStore'
import Avatar from '../components/Avatar';
import OrgImage from '../assets/img/bg/background_org.jpg';
import OrgLogo from '../assets/img/bg/background_company.jpg';

let orgImage = '';
let orgLogo = '';
let api = JSON.parse(JSON.stringify(jsonData)).api;

class StartPage extends React.Component {

    constructor() {
        super();
        this.state = {
            edit_mode: true,
            status: '',
            user_org: '',
            orgDetails: [],
            org_Details: [],
            org_name: '',
            org_loc: '',
            org_desc: '',
            org_type: '',
            org_url: '',
            //  org_image,
            //  org_logo,
        }

        this.handleChangeListOrgID = this.handleChangeListOrgID.bind(this);
        this.handleChangeOrgDetails = this.handleChangeOrgDetails.bind(this);
        this.handleSetOrganizationByDetails = this.handleSetOrganizationByDetails.bind(this);
        this.handleGetOrganizationType = this.handleGetOrganizationType.bind(this);
        this.handleChangeOrg = this.handleChangeOrg.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    toggle = modalType => () => { };

    getOrganizationFullDetails() {
        if (store.getData('data') != null && store.getData('orgs') != null) {
            let DUNS = store.getData('orgs');
            this.setState({ org_name: DUNS.Name });
            this.setState({ org_loc: DUNS.Location });
            this.setState({ org_desc: DUNS.Description });
            this.setState({ org_type: DUNS.Type.Type });
            this.setState({ org_url: DUNS.PolicyURL });
            // this.state.org_url= DUNS.PolicyURL;
        }
    };

    getData() {
        GetOrganizationDetails();
        if (store.getData('data') != null && store.getData('orgs') != null) {
            let DUNS = store.getData('orgs');
            this.setState({ org_name: DUNS.Name });
            this.setState({ org_loc: DUNS.Location });
            this.setState({ org_desc: DUNS.Description });
            this.setState({ org_type: DUNS.Type.Type });
            this.setState({ org_url: DUNS.PolicyURL });
            /*this.state.org_name= DUNS.Name;
            this.state.org_loc=  DUNS.Location;
            this.state.org_desc= DUNS.Description;
            this.state.org_type= DUNS.Type.Type;
            this.state.org_url= DUNS.PolicyURL;*/
        }
        else {
            this.setState({ org_name: '' });
            this.setState({ org_loc: '' });
            this.setState({ org_desc: '' });
            this.setState({ org_type: '' });
            this.setState({ org_url: '' });
            /*this.state.org_name= '';
            this.state.org_loc=  '';
            this.state.org_desc= '';
            this.state.org_type= '';
            this.state.org_url= '';*/
        }
    }

    handleChangeListOrgID(event) {
        this.setState({ org_type: event.target.value });
        this.setState({ status: '' });
    }

    handleChangeOrgDetails(event) {
        if (event.target.name === 'org_name') this.setState({ org_name: event.target.value });
        if (event.target.name === 'org_desc') this.setState({ org_desc: event.target.value });
        if (event.target.name === 'org_loc') this.setState({ org_loc: event.target.value });
        if (event.target.name === 'org_url') this.setState({ org_url: event.target.value });
        this.setState({ status: '' })
    }

    validURL(myURL) {
        var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
        if (pattern.test(myURL))
            return true;
        else
            return false;
    }

    handleGetOrganizationType() {
        var axiosConfig = {
            headers: {
                'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        };
        axios.get(api + 'v1/organizations/types', axiosConfig)
            .then((response) => {
                let initialPlanets3 = [];
                let initial_Planets3 = [];
                var i = 0;
                initialPlanets3[i] = { ID: '', Type: '' };
                for (i = 0; i < response.data.length; i++) {
                    initialPlanets3[i + 1] = { ID: response.data[i].ID, Type: response.data[i].Type };
                    initial_Planets3[i] = { value: response.data[i].ID, label: response.data[i].Type };
                }
                this.setState({ orgDetails: initialPlanets3 });
                this.setState({ org_Details: initial_Planets3 });
            });
    }

    handleSetOrganizationByDetails() {
        var url = '', data = '', axiosConfig = '';
        if (store.getData('data') != null && store.getData('data').User.Roles.length == 0) {
            url = api + 'v1/organizations'
            data = {
                'name': this.state.org_name,
                'description': this.state.org_desc,
                'location': this.state.org_loc,
                'typeid': this.state.org_type,
                'PolicyURL': (this.validURL(this.state.org_url)) ? this.state.org_url : '',
            };
            axiosConfig = {
                headers: {
                    'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                }
            };

            axios.post(url, data, axiosConfig)
                .then((response) => {
                    this.setState({ status: 'Organization Added' });
                    //Setting the organization  admin as this user       
                    var urls = api + 'v1/organizations/' + response.data.Organization.ID + '/admins';
                    var datas = {
                        userID: store.getData("data").User.ID,
                        roleId: 1,
                    };
                    axios.post(urls, datas, axiosConfig)
                        .then((resp) => {
                            GetUserDetails();
                            store.writeData("orgs", resp.data.Organization);
                            this.setState({ status: 'Admin Organization Added' });
                        })
                        .catch((err) => {
                            this.setState({ status: 'Error : Organsation Details Already Added' });
                        });
                })
                .catch((err) => {
                    this.setState({ status: 'Error : Organsation Details Already Added' });

                });

        }
        else {
            if (store.getData('data') != null && store.getData('orgs') != null) {
                url = api + 'v1/organizations/' + store.getData('orgs').ID;
                data = {
                    'name': this.state.org_name,
                    'description': this.state.org_desc,
                    'location': this.state.org_loc,
                    'PolicyURL': (this.validURL(this.state.org_url)) ? this.state.org_url : '',
                };
                axiosConfig = {
                    headers: {
                        'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
                    }
                };
                axios.patch(url, data, axiosConfig)

                    .then((response) => {
                        this.setState({ status: 'Organization Modified' });

                        //Update billing data: fix for issue IDE-I223
                        url = api + 'v1/billing/organizations/' + store.getData('orgs').ID;
                        axios.patch(url, {}, axiosConfig);
                    })
                    .catch((err) => {
                        this.setState({ status: 'Error : Organization  ' });
                    });
            }
        }
        this.setState({ edit_mode: true });
    }

    handleChangeOrg(event) {
        if (event.target.name === 'user_org') this.setState({ user_org: event.target.value });
        this.setState({ statusType: '' })
    }

    handleCancel(event) {
        this.setState({ edit_mode: true });
        this.getData();
        this.setState({ status: '' });
    }

    handleEdit(event) {
        this.setState({ edit_mode: false });
    }

    getNewValues() {
        GetOrganizationDetails();
        if ((store.getData('orgs') != null) && (store.getData('orgs').CoverImageID != 0))
            orgImage = api + 'v1/organizations/' + ((store.getData('data') != null) ? (store.getData('data').User.ID) : '') + '/image/' + ((store.getData('orgs') != null) ? (store.getData('orgs').CoverImageID) : '') + '/web';
        else
            orgImage = OrgImage;

        if ((store.getData('orgs') != null) && (store.getData('orgs').LogoImageID != 0))
            orgLogo = api + 'v1/organizations/' + ((store.getData('data') != null) ? (store.getData('data').User.ID) : '') + '/image/' + ((store.getData('orgs') != null) ? (store.getData('orgs').LogoImageID) : '') + '/web';
        else
            orgLogo = OrgLogo;

    }

    componentDidMount() {
        window.scrollTo(0, 0);

        this.getData();
        this.getNewValues();

        this.getOrganizationFullDetails();
        this.getNewValues();
        this.getData();
        this.handleGetOrganizationType();
    }

    render() {

        this.getNewValues();

        const myStyle = {
            border: '0px', backgroundColor: '#F8F9FA', padding: "0px", margin: "0px",
        }

        const editStyle = {
            padding: 0,
            fontSize: 20,
            fontBold: true,
            fontWeight: 800,
            backgroundColor: '#F8F9FA',
            borderWidth: 1,
            borderRadius: 0,
            borderColor: '#F8F9FA',
            borderBottomStyle: 'solid',
            borderBottomColor: '#F8F9FA',
        }

        const editStyleEnable = {
            padding: 0,
            fontSize: 20,
            fontBold: true,
            fontWeight: 800,
            boxShadow: 'none',
            outline: 'none',
            backgroundColor: '#F8F9FA',
            borderWidth: 1,
            borderRadius: 0,
            borderColor: '#F8F9FA',
            borderBottomStyle: 'solid',
            borderBottomColor: '#DFE0E1',
        }

        const editStyleL = {
            padding: 0,
            fontSize: 14,
            fontWeight: 400,
            color: '#6C757D',
            backgroundColor: '#F8F9FA',
            borderWidth: 1,
            borderRadius: 0,
            borderColor: '#F8F9FA',
            borderBottomStyle: 'solid',
            borderBottomColor: '#F8F9FA',
        }

        const editStyleLEnable = {
            padding: 0,
            fontSize: 14,
            backgroundColor: '#F8F9FA',
            boxShadow: 'none',
            outline: 'none',
            borderWidth: 1,
            borderRadius: 0,
            borderColor: '#F8F9FA',
            borderBottomStyle: 'solid',
            borderBottomColor: '#DFE0E1',
        }

        const editStyleLDD = {
            position: 'relative',
            left: '-3px',
            padding: 0,
            marginLeft: 0,
            fontSize: 14,
            boxShadow: 'none',
            outline: 'none',
            borderWidth: 1,
            borderRadius: 0,
            borderColor: '#F8F9FA',
            backgroundColor: '#F8F9FA',
            borderBottomStyle: 'solid',
            borderBottomColor: '#DFE0E1',
        }

        const editStyleTA = {
            fontSize: 14,
            height: 130,
            backgroundColor: '#F8F9FA',
            borderWidth: 1,
            borderRadius: 0,
            borderBottomStyle: 'solid',
            borderColor: '#F8F9FA',
            borderBottomColor: '#F8F9FA',
        }

        const editStyleLTA = {
            height: 130,
            fontSize: 14,
            backgroundColor: '#F8F9FA',
            boxShadow: 'none',
            outline: 'none',
            borderWidth: 1,
            borderRadius: 0,
            borderBottomStyle: 'solid',
            borderColor: '#F8F9FA',
            borderBottomColor: '#DFE0E1',
        }

        function moveNext(param, e) {
            if (param === "https://docs.igrant.io/docs/") {
                window.open(param, '_blank');
            } else {
                this.props.history.push(param);
            }
        }

        const descMsg = "DemoOrg stores and processes the following data of yours. For each of your personal data attributes, you can view what is used as contractual basis in order for us to carry out a business relation with you. You can view the current status for all your personal data   attributes, the purpose in which they are used and provide you the choice to opt-in or opt-out.\n\nWe are GDPR compliant. For queries about how we are managing your data please contact the Data Protection Officer, dpo@demoorg.com";

        return (

            <Page title="" breadcrumbs={[{ name: 'Getting started', active: true }]} >
                <Row>
                    <Col md={12} sm={12} xs={12} className="mb-3">
                        <Card className="flex-row" style={myStyle}>
                            <CardImg top src={orgImage} disabled={this.state.edit_mode} style={{ height: '300px', opacity: (this.state.edit_mode ? (1) : (0.25)) }} />
                            {this.state.edit_mode ? (<div></div>) : (<div style={{ position: 'absolute', right: '10px', }} className="float-right"><OrgImageUpload /></div>)}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={6} sm={12} xs={12} className="mb-3" style={{ height: '80px', }}>
                        <Card className="flex-row" style={myStyle}>
                            <Avatar src={orgLogo} disabled={this.state.edit_mode} style={{ position: 'absolute', opacity: (this.state.edit_mode ? (1) : (0.75)), left: '50px', top: '-120px', width: '200px', height: '200px', border: 'solid white 6px', backgroundColor: 'white' }} onDoubleClick={this.toggle('logodrop')} />
                            {this.state.edit_mode ? (<div></div>) : (<div style={{ position: 'relative', top: '-92px', left: '75px', }} ><OrgLogoUpload /></div>)}
                        </Card>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12} style={{ height: '80px', }}>
                        <Input placeholder="Organization Name" maxLength="50" style={(this.state.edit_mode) ? editStyle : editStyleEnable} disabled={this.state.edit_mode} ref="uorg_name" type="text" name="org_name" value={this.state.org_name} onChange={this.handleChangeOrgDetails} required />
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12} className="mb-3">
                                {(store.getData('data') && store.getData('data').User.Roles.length != 0) ? (
                                    <Input placeholder="Type" style={editStyleL} disabled={true} ref="uorg_loca" type="text" name="org_loca" value={'Sector: ' + this.state.org_type} onChange={this.handleChangeOrgDetails} required />
                                ) : (
                                    (this.state.edit_mode) ? <Input placeholder="Type" style={(this.state.edit_mode) ? editStyleL : editStyleLEnable} disabled={true} value={'Sector: '} /> : <span></span>
                                )}
                                {(!this.state.edit_mode && store.getData('data') != null && store.getData('data').User.Roles.length == 0) ? (
                                    <Input type="select" style={editStyleLDD} disabled={this.state.edit_mode} ref="optorgidd" defaultValue="sales" name="user_org" value={this.state.org_type} onClick={this.handleChangeListOrgID} onChange={this.handleChangeListOrgID} required>
                                        {this.state.org_Details.map(options => <option value={options.value} key={options.value}>{options.label}</option>)}
                                    </Input>
                                ) : (<span></span>)}
                                <Input placeholder="Location" maxLength="50" style={(this.state.edit_mode) ? editStyleL : editStyleLEnable} disabled={this.state.edit_mode} ref="uorg_loc" type="text" name="org_loc" value={this.state.org_loc} onChange={this.handleChangeOrgDetails} required />
                                <Input placeholder="Policy URL" type="url" maxLength="75" style={(this.state.edit_mode) ? editStyleL : editStyleLEnable} disabled={this.state.edit_mode} ref="uorg_url" name="org_url" value={this.state.org_url} onChange={this.handleChangeOrgDetails} required />
                            </Col>
                        </Row>

                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12} className="mb-3" style={{ height: '60px', }}>
                        <CardText style={{ textAlign: "right" }}>
                            {this.state.edit_mode ? (
                                <CardLink key="cardkey" disabled={!this.state.edit_mode} onClick={this.handleEdit} style={{ cursor: 'pointer', }}> Edit Profile</CardLink>
                            ) : (
                                <ButtonGroup className="flex flex-row">
                                    <Button size="ig" outline className="btn btn-default" tag="Acancel" disabled={this.state.edit_mode} onClick={this.handleCancel}>Cancel</Button>
                                    <Button size="ig" outline className="btn btn-default" tag="Asucess" disabled={this.state.edit_mode} onClick={this.handleSetOrganizationByDetails}>Save</Button>
                                </ButtonGroup>
                            )}
                        </CardText>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardTitle style={{ position: 'relative', left: '12px', top: '0px', margin: '0', borderWidth: '0', }}>Overview</CardTitle>
                        <CardText>
                            <Input type="textarea" rows={5} style={(this.state.edit_mode) ? editStyleTA : editStyleLTA} disabled={this.state.edit_mode} placeholder={descMsg} ref="uorg_desc" name="org_desc" value={this.state.org_desc} onChange={this.handleChangeOrgDetails} required />
                        </CardText>
                    </Col>
                </Row>
                <Row>
                    {subHeading.map(({ to, text, subtext, id }) => (
                        <Col key={to} lg={3} md={6} sm={6} xs={12} className="mb-3">
                            <Card
                                inverse onClick={moveNext.bind(this, to)}
                                className={`bg-gradient-info text-center h-100`}
                                style={{ cursor: 'pointer', border: '0px', }}>
                                <CardBody className="d-flex flex-column  align-items-center">
                                    <CardTitle style={{ fontSize: '18px', }}>{text}</CardTitle>
                                    <CardText>{subtext}</CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Page>
        );
    };
};
export default StartPage;