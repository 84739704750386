import React from 'react';
import Page from '../components/Page';
import 'react-bootstrap-table/css/react-bootstrap-table.css';
import {
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';


const statustype = [ 'Request initiated', 'Request acknowledged', 'Requested processed',  ];
const statusoth = [ 'Acknowledged', 'Action taken and closed', 'Closed without action',  ];

class ModalPage extends React.Component {

  render() {

      var itemsOne = [{
          id: 1,
          consid: 'lal@skandia.se',
          datereq: '07-Aug-2018',
          via:'iGrant.io iOS app',
          status:'Request initiated'
      }, {
          id: 2,
          consid: 'jorge@skandia.se',
          datereq: '04-Aug-2018',
          via:'Skandia user portal',
          status:'Request acknowledged'
      },
     {
         id: 3,
         consid: 'lotta@skandia.se',
         datereq: '02-Aug-2018',
         via:'Skandia user portal',
         status:'Requested processed'   
     },
      ];

      var itemsTwo = [{
          id: 1,
          datereq: '01-Aug-2018',
          desc:'System: Reached 100K consumers, re-evaluate license model',
          status:'Action taken and closed'
      }, 
      ];
    

    const options = { 
    };

    const cellEditProp = {
       mode: 'dbclick',
    };
    const colDispStyle = {
      cursor:'pointer',
    };

    return (
      <Page title="" breadcrumbs={[{ name: 'Support events', active: true }]}>
       
      <Row className="d-flex justify-content-right">
        <Col md="12" sm="12" xs="12" >
              <CardTitle>
                 Forget Me Requests 
              </CardTitle>
           <BootstrapTable data={itemsOne} striped={false} hover={true} options={ options } cellEdit={ cellEditProp} >
              <TableHeaderColumn className="table-secondary" isKey dataField='id' width='50' >No</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='consid'   width='250' editable={ false }>Consumer ID</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='datereq'  width='250' editable={ false }>Date Requested</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='via'      width='250' editable={ false }>Requested Via</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='status'   width='250' editable={{ type: 'select', options: { values: statustype }}} tdStyle={colDispStyle}  dataSort={true}>Status</TableHeaderColumn>
            </BootstrapTable>
        </Col>
    </Row>
    
    <Row className="d-flex justify-content-right">
        <Col md="12" sm="12" xs="12" >
            <CardTitle>
                 Other Notifications  
            </CardTitle>
            <BootstrapTable data={itemsTwo} striped={false} hover={true} options={ options }     cellEdit={ cellEditProp } >
              <TableHeaderColumn className="table-secondary" isKey dataField='id' width='50' >No</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='datereq' width='250' editable={ false }>Date</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='desc' width='500'   editable={ false }>Notification Description</TableHeaderColumn>
              <TableHeaderColumn className="table-secondary" dataField='status' width='250' editable={{ type: 'select', options: { values: statusoth }}} tdStyle={colDispStyle} dataSort={true}>Status</TableHeaderColumn>
            </BootstrapTable>
        </Col>
    </Row>
  </Page>
    );
  }
}

export default ModalPage;
