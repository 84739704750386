import Page from "../Page";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Card, CardBody, CardText, CardTitle, Col, Row } from "reactstrap";
import * as actions from "../../actions";
import BillingAddressInput from "./BillingAddressInput";
import CardInput from "./CardInput";

class BillingDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false
    };
    this.timerID = -1;
    this.redirectToBillingDashboard = this.redirectToBillingDashboard.bind(
      this
    );
  }

  redirectToBillingDashboard() {
    this.props.history.push("/billing");
  }

  componentDidMount() {
    const { param } = this.props.location.state;
    if (!this.props.organization || !param) {
      this.redirectToBillingDashboard();
    }
  }

  renderAddressForm() {
    return (
      <div>
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            <CardTitle>Billing Details</CardTitle>
            <CardText style={{ fontSize: "14px" }}>
              Please add/edit billing address:
            </CardText>
          </Col>
        </Row>

        <Row className="mb-0">
          <Col lg={6} md={12} sm={12} xs={12} className="mb-0">
            <Card style={{ height: "275px", fontSize: "14px" }}>
              <CardBody>
                {" "}
                <BillingAddressInput
                  address={this.props.address}
                  onBillingAddressSave={this.redirectToBillingDashboard}
                  onCancel={this.redirectToBillingDashboard}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  renderPaymentForm() {
    return (
      <div>
        <Row className="d-flex justify-content-start">
          <Col lg={12} md={12} sm={12} xs={12}>
            <CardTitle>Billing Details</CardTitle>
            <CardText style={{ fontSize: "14px" }}>
              Please add your payment method:
            </CardText>
          </Col>
        </Row>

        <Row className="mb-0">
          <Col lg={6} md={12} sm={12} xs={12}>
            <Card style={{ height: "200px", fontSize: "14px" }}>
              <CardBody>
                <CardTitle>Card Details</CardTitle>
                <StripeProvider
                  apiKey={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                >
                  <div className="example">
                    <Elements>
                      <CardInput
                        onCardAdditionSuccess={this.redirectToBillingDashboard}
                        onCardAdditionFailure={this.redirectToBillingDashboard}
                      />
                    </Elements>
                  </div>
                </StripeProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { param } = this.props.location.state;

    //let lastBreadCrumb;
    // if (param === "paymentmethod") {
    //   lastBreadCrumb = "Payment Method";
    // } else if (param === "billingaddress") {
    //   lastBreadCrumb = "Billing Address";
    // }
    // breadcrumbs={[
    //     { name: "Billing", active: false },
    //     { name: lastBreadCrumb, active: true }
    //   ]}
    let breadCrumbData;
    if (param === "paymentmethod") {
      breadCrumbData = "Account / Billing / Payment Method";
    } else if (param === "billingaddress") {
      breadCrumbData = "Account / Billing / Billing Address";
    }
    return (
      <Page title="" breadcrumbs={[{ name: breadCrumbData, active: true }]}>
        {param === "paymentmethod" && this.renderPaymentForm()}
        {param === "billingaddress" && this.renderAddressForm()}
      </Page>
    );
  }
}
function mapStateToProps({ organization }) {
  return {
    organization
  };
}
export default connect(
  mapStateToProps,
  actions
)(BillingDetails);
