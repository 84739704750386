import axios from 'axios';
import jsonData from '../setting/settings.json';
import { store } from '../services/localStorageStore';
const api = JSON.parse(JSON.stringify(jsonData)).api;

export const GetOrganizationDetails  = async ()  =>
{
   if( (store.getData('data') != null) &&  store.getData('data').User.Roles.length != 0) 
   {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
        //var url="https://staging-api.igrant.io/v1/organizations/"+ OrgID;
        var roles=store.getData('data').User.Roles;
        for(var c=0;c<roles.length;c++)
        {
            if(roles[c].RoleID===1)
            {  
             var url=api +"v1/organizations/"+ store.getData('data').User.Roles[c].OrgID;
              const response = await axios.get( url ,axiosConfig)         
              store.writeData("orgs", response.data.Organization);
              break;
            }
         }  
    }
};

export const GetUserDetails  = ()  =>
{
    if( store.getData('data') != null ) 
    {
      var axiosConfig = {
        headers: {
            'Authorization': 'Bearer ' + store.getData("data").Token.access_token,
            }
        }; 
        //var url="https://staging-api.igrant.io/v1/user";
        var url= api +"v1/user";

        axios.get(url ,axiosConfig)
        .then((response) => {     
              let udata=store.getData('data');
              let userd=JSON.parse(JSON.stringify(response.data)).User;
              udata['User']=(userd)?userd:'';
              store.writeData("data", udata); 
         }) ; 
    }
};

export const checkURL = (myURL) => {
    var pattern = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    if (pattern.test(myURL)) 
         return true;
    else
        return false;
};

export const checkEmail = (emailID) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailID))
      return (true)
    else  
      return (false)
} ;

