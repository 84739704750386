
import React from 'react';
import {
  CardText,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
  } from 'reactstrap';

import Page from '../components/Page';
import { store } from '../services/localStorageStore'
import NotificationsForm from '../components/Notifications/NotificationsForm';
import {updateEulaNotification,dataBreachNotification,eventNotification} from '../services';

const NotificationTypes = {
    eulaUpdate:'EULA Updates',
    dataBreaches:'Data Breaches',
    others:'Other Events'
}
 
class  NotificationsPage extends React.Component {
state={
    isSent:null,
}
handleSubmit = (values,selected)=>{
    const orgId = store.getData('orgs').ID ;

    switch(selected){
        case NotificationTypes.eulaUpdate:
            let eulaUrl = values['EULA URL']; 
            updateEulaNotification(orgId,eulaUrl).then((res)=>{
                if(res){
                    // console.log(res);
                    this.setState({isSent:true},this.revertChange);
                }
            })
            .catch(err=>{
                this.setState({isSent:false});
            });
            break;
        case NotificationTypes.dataBreaches:
            dataBreachNotification(orgId,values)
            .then((res)=>{
                if(res){
                    // console.log(res);
                    this.setState({isSent:true},this.revertChange);
                }
            })
            .catch(err=>{
                this.setState({isSent:false});
            });
            break;
        case NotificationTypes.others:
            let details = values['Enter Event Details']; 
            eventNotification(orgId,details)
            .then((res)=>{
                if(res){
                    // console.log(res);
                    this.setState({isSent:true},this.revertChange);
                }
            })
            .catch(err=>{
                this.setState({isSent:false});
            });
            break;
        default:
            console.error('Undefined Notification type');
    }
    
}

revertChange = ()=>{
    setTimeout(()=>{this.setState({isSent:false})},5000);
}
    
render() {
    const {isSent} = this.state;
    return (
    <Page title="" breadcrumbs={[{ name: 'Manage End Users / Notifications', active: true }]}>
     {this.getData}
    <Row className="d-flex justify-content-right">
            <Col md="12" sm="12" xs="12" >
                <CardTitle>
                    Raise User Notifications
                </CardTitle>
                <p className='m-0 card-text'>
                    Raise notifications towards subscribed users to your organisation
                </p>
            </Col>
    </Row>
    <Row className="d-flex justify-content-right">
        <Col md="12" sm="12" xs="12" >
           <NotificationsForm isSent={isSent} onSubmit={this.handleSubmit}/>
        </Col>
    </Row>
 </Page>
  );
};
};
export default NotificationsPage;
